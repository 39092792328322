import React from 'react'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { BadgeIcon } from './Badge.styles'

const Badge = ({ icon, backgroundColor }: IBadgeProps) => {
    return <BadgeIcon icon={icon} backgroundColor={backgroundColor} />
}

export default Badge

export interface IBadgeProps extends FontAwesomeIconProps {
    backgroundColor: string
}
