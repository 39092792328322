import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import baseTheme from '../../../themes/baseTheme'
import { flex } from '../../../utils/mixins'

export const SearchbarContainer = styled.div`
    ${flex('center', 'space-between')};
    background: ${baseTheme.colors['ffffff']};
    border-radius: 10px;
    border: 1px solid ${baseTheme.colors['ececec']};
    box-shadow: 0px 0px 8px ${baseTheme.colors['000000']}14;
    height: 50px;
    padding: 0 20px 0 0;
`

export const SearchbarIcon = styled(FontAwesomeIcon)`
    color: ${baseTheme.colors['073045']};
    font-family: ${baseTheme.typography.fonts['font-awesome-6-pro']};
    font-size: 22px;
    line-height: 27px;
    margin: 0 0 0 6px;
    text-align: center;
`

export const SearchbarInput = styled.input`
    background: ${baseTheme.colors['ffffff']};
    border: 0;
    color: inherit;
    flex-grow: 2;
    font-family: ${baseTheme.typography.fonts.inter};
    font-size: 16px;
    font-weight: normal;
    height: 100%;
    letter-spacing: 0px;
    line-height: 35px;
    outline: 0;
    padding: 0 20px;
    text-align: left;
    width: 100%;
`

export const SearchOptions = styled.button`
    ${flex('center', 'flex-end')};
    background: ${baseTheme.colors['003750']};
    border: 1px solid ${baseTheme.colors['003750']};
    border-radius: 10px 0px 0px 10px;
    box-shadow: 0px 0px 8px ${baseTheme.colors['000000']}14;
    cursor: pointer;
    height: 100%;
    padding: 0 35px;
    position: relative;
`

export const SearchOptionsIcon = styled(FontAwesomeIcon)`
    color: ${baseTheme.colors['ffffff']};
    font-family: ${baseTheme.typography.fonts['font-awesome-6-pro']};
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin: 0 0 0 6px;
    text-align: center;
`

export const SearchOptionsItem = styled.div`
    ${flex('center', 'center')}
`

export const SearchOptionsText = styled.h3`
    color: ${baseTheme.colors['ffffff']};
    font-family: ${baseTheme.typography.fonts.inter};
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 49px;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    width: 100%;
`

export const SearchOptionsPopup = styled.div`
    background-color: ${baseTheme.colors['ffffff']};
    border-radius: 8px;
    border: 1px solid ${baseTheme.colors['dadada']};
    box-shadow: 0px 0px 20px #00375029;
    left: 0;
    padding: 0 10px;
    position: absolute;
    top: 0;
    transform: translateY(calc(50% + 10px)) translateX(calc(15px));
    width: 80%;
    z-index: 1;
`

export const SearchOptionsPopupIcon = styled(FontAwesomeIcon)`
    color: ${baseTheme.colors['073045']};
    font-family: ${baseTheme.typography.fonts['font-awesome-6-pro']};
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    margin: 0 6px 0 0;
    text-align: center;
`

export const SearchOptionsPopupLabel = styled.div`
    border-bottom: 1px solid ${baseTheme.colors['707070']}70;
    color: ${baseTheme.colors['073045']};
    font-family: ${baseTheme.typography.fonts.inter};
    font-size: 13px;
    font-weight: medium;
    letter-spacing: 0px;
    line-height: 20px;
    padding: 10px 0;
    text-align: left;
    :last-child {
        border-bottom: none;
    }
`
