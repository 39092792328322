import React, { ReactNode } from 'react'
import { AvatarContainer, AvatarDefaultImage, AvatarImage, BadgeContainer } from './Avatar.styles'

const Avatar = ({ badge, imageSrc }: IAvatarProps) => {
    return (
        <AvatarContainer>
            {imageSrc ? (
                <AvatarImage src={imageSrc} />
            ) : (
                <AvatarDefaultImage icon={['fal', 'user-circle']} />
            )}
            {badge && <BadgeContainer>{badge}</BadgeContainer>}
        </AvatarContainer>
    )
}

export default Avatar

export interface IAvatarProps {
    badge?: ReactNode
    imageSrc: string
}
