import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LoadingPage from '../../../../Other/LoadingPage'
import { getUsersCsv } from '../../../api-reports'
import { useCsvReport } from '../../../Hooks/useCsvReport'
import { useUsers } from '../../../Hooks/useUsers'
import { colors, PropsReports } from '../../../Reports'
import { ChartComponent, NoData } from './../ChartComponent'
import { ReportContainer } from '../ReportContainer'
import { getMaxValuePlusPercent } from './ValuationReports'

export const UsersReports = ({ pieChartOptions }: PropsReports) => {
    const { t } = useTranslation('teamReports')
    const { getReport } = useCsvReport()

    const { red, lightGrey, lightBlue, green, yellow, eggBlue } = colors

    const { usersGraph, isLoading } = useUsers()

    const { activeDataChart, enrolledChart } = usersGraph || {}

    const activeDataColors = [lightBlue, red, green, yellow]

    const transformEnrolled = () => {
        if (!enrolledChart) return null
        const columnNames = ['status', 'started', 'inactives', 'totals']
        const header = columnNames.flatMap((name, index) =>
            index === 0
                ? [name]
                : [
                      t(name),
                      {
                          role: 'annotation',
                          type: name === 'totals' ? 'number' : 'string',
                          calc: 'stringify',
                      },
                  ]
        )

        const rows = enrolledChart.slice(1).map((row) => {
            const status = t(row[0] as string)
            const actives = row[1] as number
            const totalActives = row[1]?.toString() ?? ''
            const inactives = row[2] as number
            const totalInactives = row[2]?.toString() ?? ''
            const totals = actives + inactives
            const maxHeight = getMaxValuePlusPercent(enrolledChart)

            return [
                status,
                actives,
                totalActives,
                inactives,
                totalInactives,
                maxHeight - totals, // Alto de la columna de totales,
                totals,
            ]
        })

        return [header, ...rows]
    }

    const transformActiveDataToChartData = () => {
        if (!activeDataChart) {
            return null
        }
        const header = [
            'status',
            'total',
            { role: 'style' },
            {
                role: 'annotation',
                type: 'string',
                calc: 'stringify',
            },
        ]

        const rows = activeDataChart.slice(1).map((row, index) => {
            const status = t(row[0] as string)
            const total = row[1] as number
            const color = activeDataColors[index]
            const value = row[1]?.toString() ?? ''
            return [status, total, color, value]
        })

        return [header, ...rows]
    }

    if (isLoading) return <LoadingPage />

    return (
        <UsersReportStyles>
            {!activeDataChart && !enrolledChart ? (
                <NoData text="No data to render yet" />
            ) : (
                <div className="column-display">
                    <ReportContainer
                        header={{
                            title: 'Participants',
                            onClick: () => getReport(getUsersCsv, 'cohorts'),
                            disabled: !activeDataChart && !enrolledChart,
                        }}
                        content={
                            <>
                                <ChartComponent
                                    chartType="BarChart"
                                    data={transformEnrolled()}
                                    height="200px"
                                    options={{
                                        legend: { position: 'top' },
                                        chartArea: {
                                            left: 100,
                                            right: 50,
                                            height: '50%',
                                        },
                                        isStacked: true,
                                        colors: [eggBlue, lightGrey, 'transparent'],
                                        bar: { groupWidth: '40%' },
                                        series: {
                                            2: {
                                                visibleInLegend: false,
                                                annotations: {
                                                    textStyle: {
                                                        fontSize: 20,
                                                        bold: true,
                                                        color: 'black',
                                                    },
                                                },
                                            },
                                        },
                                        theme: {
                                            annotations: {
                                                textStyle: { fontSize: 14 },
                                            },
                                        },
                                        hAxis: {
                                            viewWindow: {
                                                max:
                                                    enrolledChart &&
                                                    getMaxValuePlusPercent(enrolledChart),
                                            },
                                        },
                                        vAxis: {
                                            slantedText: true,
                                        },
                                    }}
                                />
                                <div className="row-display">
                                    <ChartComponent
                                        chartType="BarChart"
                                        data={transformActiveDataToChartData()}
                                        options={{
                                            legend: { position: 'none' },
                                            chartArea: {
                                                left: 100,
                                                top: 20,
                                                right: 20,
                                                bottom: 20,
                                                width: '100%',
                                                height: '100%',
                                            },
                                            hAxis: {
                                                textPosition: 'none',
                                                gridlines: { color: 'transparent' },
                                                minValue: 0,
                                            },
                                            vAxis: { gridlines: { color: 'transparent' } },
                                        }}
                                    />
                                    <ChartComponent
                                        chartType="PieChart"
                                        data={transformActiveDataToChartData()}
                                        width={pieChartOptions?.width}
                                        options={{
                                            pieSliceTextStyle: {
                                                fontSize: 12,
                                            },
                                            colors: activeDataColors,
                                            legend: { position: 'right' },
                                            chartArea: {
                                                left: 20,
                                                top: 20,
                                                right: 5,
                                                bottom: 10,
                                            },
                                        }}
                                    />
                                </div>
                            </>
                        }
                    />
                </div>
            )}
        </UsersReportStyles>
    )
}

const UsersReportStyles = styled.section`
    & > .column-display {
        display: flex;
        flex-direction: column;
        gap: 1em;
        & .report-display {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;
            gap: 1em;
            & > section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                & > * {
                    border-bottom: none;
                }
            }
        }
        & .row-display {
            display: grid;
            grid-template-columns: 69% 30%;
            gap: 1em;
            & > section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                & > * {
                    border-bottom: none;
                }
            }
        }
    }
    .chart {
        & > * {
            text-transform: capitalize;
        }
    }
    @media (max-width: 1140px) {
        & > .report-content {
            flex-direction: column;
        }
    }
`
