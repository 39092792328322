/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'wouter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MainLayout from '../../layouts/MainLayout'
import ModalEgg from '../../components/ModalEgg'
// import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'

const ShareView = (props) => {
    const { url, QRModal, setQRModal } = props
    const [, setLocation] = useLocation()
    const copy = () => {
        const toCopy = document.getElementById('share-url')
        const tooltip = document.getElementById('tooltip-url')
        toCopy.select()
        toCopy.setSelectionRange(0, 99999)
        document.execCommand('copy')
        window.getSelection().removeAllRanges()
        tooltip.style = 'opacity: 0.9;'
        setTimeout(() => {
            tooltip.style = ''
        }, 1250)
    }
    const mailto = () => {
        const link =
            'mailto:?subject=' +
            encodeURI(`Unite a mi equipo de Egg`) +
            '&body=' +
            encodeURI(
                `He creado un equipo de Egg y me gustaría que te unas.\n` +
                    'Para hacerlo entra al siguiente enlace:\n'
            ) +
            escape(url)
        window.open(link, '_blank')
    }

    const { t } = useTranslation(['wizard'])

    return (
        <MainLayout ruta="Compartir Equipo">
            <div className="step-container share-container responsive">
                <div className="onboarding-img share"></div>
                <div className="onboarding-form">
                    <div className="info">
                        <span className="step-label">{t('wizard:step')} 5/5</span>
                        <span className="step-title">{t('wizard:step5.created')}</span>
                        <p className="global-text">{t('wizard:step5.desc')}</p>
                    </div>
                    <div className="input-container" style={{ margin: '10px' }}>
                        <input className="form-input" type="" value={url} readOnly id="share-url" />
                    </div>
                    <div className="share-options">
                        <div className="share-option" onClick={mailto}>
                            <FontAwesomeIcon
                                className="share-option-icon"
                                icon={['fa', 'mailbox']}
                            />
                            <span className="share-option-label">
                                {t('wizard:step5.share_email')}
                            </span>
                        </div>
                        <div className="share-option" onClick={() => setQRModal(true)}>
                            <FontAwesomeIcon
                                className="share-option-icon"
                                icon={['fa', 'qrcode']}
                            />
                            <span className="share-option-label">{t('wizard:step5.share_qr')}</span>
                        </div>
                        <div className="share-option" onClick={copy}>
                            <FontAwesomeIcon className="share-option-icon" icon={['fa', 'copy']} />
                            <span className="share-option-label">{t('wizard:step5.copy')}</span>
                            <div className="tool-tip" id="tooltip-url">
                                {t('wizard:step5.copied')}
                            </div>
                        </div>
                    </div>
                    <div className="next-step">
                        <button
                            className="btn-next-wizard"
                            onClick={() => {
                                setLocation('.')
                            }}
                        >
                            {t('wizard:step5.start')}
                        </button>
                    </div>
                </div>
                <ModalEgg modalShow={QRModal} closeModal={() => setQRModal(false)}>
                    {/* <QRCode value={url} renderAs="svg" size="400" level="Q" className="qr-code" /> */}
                </ModalEgg>
            </div>
        </MainLayout>
    )
}

ShareView.propTypes = {
    link: PropTypes.string.isRequired,
}

export default ShareView
