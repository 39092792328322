import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import './styles/styles.scss'
import App from './App'
import './i18n'
import LoadingPage from './pages/Other/LoadingPage'
import * as serviceWorker from './serviceWorker'

import { initSentry } from './lib/sentry'

initSentry()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <React.StrictMode>
        <Suspense fallback={<LoadingPage />}>
            <App />
        </Suspense>
    </React.StrictMode>
)

serviceWorker.unregister()
