import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../../styles/globals/_variables'
import StrongUnit from '../Text/StrongUnit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const Block = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .content {
        h4 {
            text-transform: uppercase;
            font-size: 1rem;
            letter-spacing: 0.05rem;
        }
        .assistance-units {
            display: flex;
            flex: 1 1 0;
            margin-top: 15px;
            .unit {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10px 20px;
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                    margin: 10px 30px;
                }
                .icon-container {
                    background-color: ${colors.lightGreyBg};
                    height: 55px;
                    width: 55px;
                    border-radius: 30px;
                    border: 1px solid ${colors.borders};
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .icon {
                        font-size: 1.5rem;
                    }
                }
                .value {
                    margin-top: 10px;
                    @media screen and (min-width: 768px) {
                        margin-top: 0px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
`

const BtnAssistance = styled.div<{ type: string }>`
    background-color: ${colors.lightGreyBg};
    height: 55px;
    width: 55px;
    border-radius: 30px;
    border: 1px solid ${colors.borders};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
        font-size: 1.5rem;
        ${(props) =>
            props.type === 'present' &&
            css`
                color: ${colors.eggColor};
            `}
        ${(props) =>
            props.type === 'delay' &&
            css`
                color: #66ccff;
            `}
        ${(props) =>
            props.type === 'absent' &&
            css`
                color: rgba(253, 115, 115, 0.9);
            `}
    }
`

interface IAssistance {
    assistance: { timesPresent: number; timesDelay: number; timesAbsent: number }
}

const AssitanceBlock = ({ assistance }: IAssistance) => {
    const { t } = useTranslation(['teamDetailStudent'])

    return (
        <Block>
            <div className="content">
                <h4>{t('assistance.title')}</h4>
                <div className="assistance-units">
                    <div className="unit">
                        <BtnAssistance type="present">
                            <FontAwesomeIcon className="icon" icon={['fal', 'user-check']} />
                        </BtnAssistance>
                        <div className="value">
                            <StrongUnit>
                                {assistance?.timesPresent ? assistance?.timesPresent : '-'}
                            </StrongUnit>
                        </div>
                    </div>
                    <div className="unit">
                        <BtnAssistance type="delay">
                            <FontAwesomeIcon className="icon" icon={['fal', 'user-clock']} />
                        </BtnAssistance>
                        <div className="value">
                            <StrongUnit>
                                {assistance?.timesDelay ? assistance?.timesDelay : '-'}
                            </StrongUnit>
                        </div>
                    </div>
                    <div className="unit">
                        <BtnAssistance type="absent">
                            <FontAwesomeIcon className="icon" icon={['fal', 'user-slash']} />
                        </BtnAssistance>
                        <div className="value">
                            <StrongUnit>
                                {assistance?.timesAbsent ? assistance?.timesAbsent : '-'}
                            </StrongUnit>
                        </div>
                    </div>
                </div>
            </div>
        </Block>
    )
}

export default AssitanceBlock
