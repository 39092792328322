import { GetTeamSettingsResponse, rotateTeam } from '../apis/api.signup'
import { useMutation, useQueryClient } from 'react-query'

import { useGAEvents } from './useGAEvents'
import { useTeam } from '../pages/Team/Context/TeamContext'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../context/UIContext'

export const useRotate = () => {
    const queryClient = useQueryClient()
    const { courseID } = useTeam()
    const { t } = useTranslation('teamSettings')
    const { toastError } = useUIContext()
    const { sendGA } = useGAEvents()

    const defaultErrorMessage = {
        message: t('teamSettings:RotateRestore.defaultError'),
    }

    const {
        mutate: rotate,
        mutateAsync: rotateAsync,
        isLoading: isRotating,
        ...rest
    } = useMutation(
        [courseID, 'Team', 'Rotate'],
        (params: Parameters<typeof rotateTeam>[number]) => rotateTeam(params),
        {
            onMutate: (params) => {
                queryClient.setQueryData<GetTeamSettingsResponse | undefined>(
                    [courseID, 'Team', 'Teams list', 1],
                    mutateCallback
                )

                queryClient.setQueryData<GetTeamSettingsResponse | undefined>(
                    [courseID, 'Team', 'Settings', 'Get Settings'],
                    mutateCallback
                )

                const isRotation = params.launchType === 'shuffle'

                sendGA({
                    category: isRotation ? 'Team Management' : 'Engagement',
                    action: isRotation ? 'RotateStudents' : 'ResetRecognitions',
                    label: isRotation
                        ? undefined
                        : 'User resets the recognition counter for a fixed team',
                })
            },
            onError: (response) => toastError(response ?? defaultErrorMessage),
        }
    )

    return { rotate, rotateAsync, isRotating, ...rest }
}

const mutateCallback = (prev: GetTeamSettingsResponse | undefined) =>
    prev
        ? {
              ...prev,
              ableLaunchAlgorithm: {
                  ...prev.ableLaunchAlgorithm,
                  able: false,
              },
          }
        : prev
