import React, { useContext, useEffect, useState, useMemo } from 'react'
import { FillInfoContext } from './FillInfoContext'
import { UserContext } from '../../../context/UserContext'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import UIContext from '../../../context/UIContext'
import { useLocation } from 'wouter'

const UserInfo = () => {
    const { nextStep } = useContext(FillInfoContext)
    const { t } = useTranslation(['errors', 'completeProfile'])
    const { user, setUser, fetchUser } = useContext(UserContext)
    const { setInfoBlock } = useContext(UIContext)
    const [country, setCountry] = useState('')
    const requiredLabel = useMemo(() => t('errors:forms.requiredField'), [t])
    const typeNumber = useMemo(() => t('errors:forms.type-number'), [t])
    const isInteger = useMemo(() => t('errors:forms.integer'), [t])
    const isPositive = useMemo(() => t('errors:forms.positive'), [t])

    const [, setLocation] = useLocation()

    const schema = yup.object().shape({
        street: yup.string(),
        numeration: yup.string(),
        floor: yup.string(),
        apartment: yup.string(),
        country: yup.string().required(requiredLabel),
        city: yup
            .string()
            .required(requiredLabel)
            .matches(
                /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
                t('errors:forms.weird-chars')
            ),
        phone: yup
            .number()
            .typeError(typeNumber)
            .required(requiredLabel)
            .positive(isPositive)
            .integer(isInteger)
            .max(999999999999999, t('errors:forms.long-field'))
            .transform((v, o) => (o === '' ? undefined : v)),
        id: yup
            .string()
            .required(requiredLabel)
            .max(12, t('errors:forms.long-field'))
            .matches(/^[1-9]+[0-9]*$/g, typeNumber),

        genre: yup.string().required(requiredLabel),
        birthdate: yup
            .date()
            .required(requiredLabel)
            .min('1930-01-01T00:00:00+00:00', t('errors:forms.invalid-date'))
            .max('2014-01-01T00:00:00+00:00', t('errors:forms.too-young'))
            .typeError(requiredLabel),
    })

    const defaultValues = useMemo(
        () => ({
            street: user?.address?.street ?? '',
            numeration: user?.address?.numeration ?? ' ',
            floor: user?.address?.floor ?? ' ',
            apartment: user?.address?.apartment ?? '',
            country: user?.address?.country ?? '',
            city: user?.address?.city ?? '',
            phone: user?.phone ?? '',
            id: user?.dni ?? '',
            birthdate: user?.birthdate ? dayjs.utc(user.birthdate).format('YYYY-MM-DD') : '',
            genre: user?.genre ?? '',
        }),
        [user]
    )

    const { register, handleSubmit, watch, errors } = useForm<typeof defaultValues>({
        mode: 'onBlur',
        defaultValues,
        resolver: yupResolver(schema),
    })

    const watchFields = watch(['country'])

    useEffect(() => {
        setCountry(watchFields.country)
    }, [watchFields])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        // console.log(data);

        const updatedUser = {
            name: user?.name,
            lastname: user?.lastname,
            address: {
                // setUser patch api validation expects mandatory address fields wich aren't required in the form
                country: data.country,
                city: data.city,
                street: ' ',
                numeration: ' ',
                floor: ' ',
                apartment: ' ',
            },
            dni: data.id.toString(),
            birthdate: dayjs(data.birthdate).toISOString(),
            phone: data.phone.toString(),
            genre: data.genre,
        }

        try {
            await setUser({ ...user, ...updatedUser })
            await fetchUser()
            !user?.urlImage ? nextStep() : setLocation('/dashboard?rtt=false')
        } catch (error) {
            setInfoBlock(['error', error.message || error.data?.message || t('errors.generic')])
            setTimeout(() => setInfoBlock([]), 2500)
            // eslint-disable-next-line no-console
            console.error(error, error.message || error.data?.message || t('errors.generic'))
        }
    }

    const getIdLabelByCountry = (country: string) => {
        switch (country) {
            case 'argentina':
                return 'DNI'
            case 'chile':
                return 'RUT'
            case 'world':
                return 'ID'
            default:
                return 'ID'
        }
    }

    return (
        <div className="login-pages fill-wizard-page">
            <div className="egg-card container user-info">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="address-data-block block-row">
                        <h4>{t('completeProfile:personal-label')}</h4>
                        <div className="flex-row responsive">
                            <div className="form-block">
                                <label htmlFor="country">
                                    <span className="block-desc">
                                        {t('completeProfile:placeholder.country') + ' *'}
                                    </span>
                                </label>
                                <select
                                    className="form-input"
                                    name="country"
                                    required
                                    ref={register()}
                                >
                                    <option value="" disabled>
                                        {t('completeProfile:placeholder.country') + ' *'}
                                    </option>
                                    <option value="argentina">
                                        {t('completeProfile:placeholder.countries.argentina')}
                                    </option>
                                    <option value="chile">
                                        {t('completeProfile:placeholder.countries.chile')}
                                    </option>
                                    <option value="world">
                                        {t('completeProfile:placeholder.countries.world')}
                                    </option>
                                </select>
                                {errors.country && (
                                    <span className="input-error">{errors.country.message}</span>
                                )}
                            </div>
                            <div className="form-block">
                                <label htmlFor="city">
                                    <span className="block-desc">
                                        {t('completeProfile:placeholder.city') + ' *'}
                                    </span>
                                </label>
                                <input
                                    className="form-input"
                                    name="city"
                                    maxLength={40}
                                    required
                                    ref={register()}
                                />
                                {errors.city && (
                                    <span className="input-error">{errors.city.message}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="personal-data-block block-row">
                        <div className="flex-row responsive">
                            <div className="form-block w-100">
                                <label htmlFor="phone">
                                    <span className="block-desc">
                                        {t('completeProfile:placeholder.phone') + ' *'}
                                    </span>
                                </label>
                                <input
                                    className="form-input"
                                    name="phone"
                                    type="text"
                                    required
                                    ref={register()}
                                />
                                {errors.phone && (
                                    <span className="input-error">{errors.phone.message}</span>
                                )}
                            </div>
                        </div>
                        {country !== '' && (
                            <div className="flex-row responsive">
                                <div className="form-block w-100">
                                    <label htmlFor="id">
                                        <span className="block-desc">
                                            {getIdLabelByCountry(country) + ' *'}
                                        </span>
                                    </label>
                                    <input
                                        required
                                        className="form-input"
                                        type="text"
                                        name="id"
                                        placeholder={getIdLabelByCountry(country) + ' *'}
                                        ref={register()}
                                    />
                                    {errors.id && (
                                        <span className="input-error">{errors.id.message}</span>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="flex-row responsive">
                            <div className="form-block">
                                <label htmlFor="birthdate">
                                    <span className="block-desc">
                                        {t('completeProfile:placeholder.birthdate') + ' *'}
                                    </span>
                                </label>
                                <input
                                    required
                                    className="form-input"
                                    name="birthdate"
                                    type="date"
                                    style={{ padding: '23px' }}
                                    ref={register()}
                                />
                                {errors.birthdate && (
                                    <span className="input-error">{errors.birthdate.message}</span>
                                )}
                            </div>
                            <div className="form-block">
                                <label htmlFor="genre">
                                    <span className="block-desc">
                                        {t('completeProfile:placeholder.genre') + ' *'}
                                    </span>
                                </label>
                                <select
                                    required
                                    className="form-input"
                                    name="genre"
                                    ref={register()}
                                >
                                    <option value="" disabled>
                                        {t('completeProfile:placeholder.genre') + ' *'}
                                    </option>
                                    <option value="male">
                                        {t('completeProfile:placeholder.genre-options.male')}
                                    </option>
                                    <option value="female">
                                        {t('completeProfile:placeholder.genre-options.female')}
                                    </option>
                                    <option value="other">
                                        {t('completeProfile:placeholder.genre-options.other')}
                                    </option>
                                    <option value="other">
                                        {t('completeProfile:placeholder.genre-options.notsaying')}
                                    </option>
                                </select>
                                {errors.genre && (
                                    <span className="input-error">{errors.genre.message}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="submit-block">
                        <button type="submit" className="btn-outline">
                            {t('completeProfile:save-button-label')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UserInfo
