/**
 * Documentation: docs/hooks/useNotifications.md
 */

import Axios from 'axios'
import { useTranslation } from 'react-i18next'
import DayJS from 'dayjs'

//Config
import Config from '../config'

// Context
import { useEffect } from 'react'
import { useUIContext } from '../context/UIContext'
import { useUser } from '../context/UserContext'

// Interfaces
import { useLocation } from 'wouter'
import { changeHelpRequestStatus, GetTeamResponse } from '../apis/api.signup'
import { downloadReport } from '../helpers/downloadBlob'
import { useQueryClient } from 'react-query'
import { playSound, SOUNDS } from '../helpers/audio'
import { useGAEvents } from './useGAEvents'

export const useNotifications = () => {
    const { t } = useTranslation('notifications')
    const { user, setUserButNotSaveIt } = useUser()
    const { notifications, setNotifications, toastNotification } = useUIContext()
    const endpoint = `${Config.API}/user/${user?._id}/notifications`
    const { sendGA } = useGAEvents()

    useEffect(() => {
        if (!user) return

        const notificationsCount = notifications.filter(({ status }) => status === 'unread').length
        setUserButNotSaveIt((prev) => prev && { ...prev, notificationsCount })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications])

    const fetchAllNotification = () =>
        user?._id &&
        Axios.get<GetNotificationsResponse>(endpoint)
            .then(({ data }) => setNotifications(data.notifications))
            .catch(() => toastNotification(t('fetchError'), 'error'))

    const newNotification = (notification: NotificationDTO) => {
        // Play sound if HR notification
        if (notification.action?.type === 'search-by') playSound(SOUNDS.DEFAULT)

        setNotifications((prev) => [
            { ...notification, status: 'unread', isClosed: false },
            ...prev,
        ])
    }

    const updateNotifications = (
        notifications: NotificationDTO[],
        options: Omit<Partial<NotificationDTO>, '_id'>
    ) => {
        if (!notifications.length) return
        const notificationsIDs = notifications.map((item) => item._id)
        Axios.put(endpoint, {
            notifications: notificationsIDs,
            ...options,
        })
            .then(() => {
                if (options.action?.type === 'search-by' && options.status === 'read') {
                    sendGA({
                        category: 'Help Request',
                        action: 'HelpRequestNotificationViewed',
                        label: options.action?._helpRequestId,
                    })
                }
                setNotifications((prev) => [
                    ...prev
                        .filter((item) => notificationsIDs.includes(item._id))
                        .map((item) => ({ ...item, ...options })), // Modified notifications
                    ...prev.filter((item) => !notificationsIDs.includes(item._id)), // Rest of notifications
                ])
            })
            .finally(fetchAllNotification)
    }

    const markNotificationsAsRead = (notifications: NotificationDTO[]) => {
        updateNotifications(
            notifications.filter(({ status }) => status !== 'read'),
            { status: 'read' }
        )
        closeNotificationPopup(notifications)
    }

    const markAllNotificationsAsRead = () => markNotificationsAsRead(notifications)

    const deleteNotification = (notifications: NotificationDTO[]) =>
        updateNotifications(
            notifications.filter(({ status }) => status !== 'deleted'),
            { status: 'deleted' }
        )

    const deleteAllNotifications = () => deleteNotification(notifications)

    const toggleReadNotification = (notification: NotificationDTO) =>
        updateNotifications([notification], {
            status: notification.status === 'read' ? 'unread' : 'read',
        })

    const sendNotification = async (
        title: string,
        message: string,
        options: Record<string, unknown>
    ) => {
        Axios.post(Config.API + '/profile/hit', {
            title: title,
            message: message,
            ...options,
        })
            .then(() => toastNotification(t('teamDetailStudent:notification.hand-up'), 'success'))
            .catch(() => toastNotification(t('errors:notifications.professorUnavailable'), 'error'))
    }

    const closeAllNotificationPopups = () => updateNotifications(notifications, { isClosed: true })

    const closeNotificationPopup = (notifications: NotificationDTO[]) =>
        updateNotifications(notifications, { isClosed: true })

    return {
        notifications,
        fetchAllNotification,
        newNotification,
        markNotificationsAsRead,
        markAllNotificationsAsRead,
        deleteNotification,
        deleteAllNotifications,
        toggleReadNotification,
        sendNotification,
        closeNotificationPopup,
        closeAllNotificationPopups,
    }
}

export const useCustomProps = (notification: NotificationDTO) => {
    const [location, setLocation] = useLocation()
    const { t } = useTranslation('notifications')
    const { toastNotification, toastError, searchBox } = useUIContext()
    const { markNotificationsAsRead } = useNotifications()
    const { action } = notification
    const queryClient = useQueryClient()

    const { sendGA } = useGAEvents()

    let onClick: (() => void) | undefined

    const helpRequestGAEvent = (helpRequestId: string, courseId: string) => {
        const team = queryClient.getQueryData<GetTeamResponse['data']>([
            courseId,
            'Team',
            'Teams list',
            1,
        ])

        sendGA({
            category: 'Help Request',
            action: 'HelpRequestNotificationViewed',
            label: helpRequestId,
            team,
        })
    }

    if (action?.type === 'search-by') {
        const { courseId, searchQuery, searchType, _helpRequestId, closeHelpRequest } = action
        queryClient.invalidateQueries([courseId, 'Team', 'Monitor', 'Help request pendings'])
        onClick = () => {
            const url = `/admin/team/${courseId}`
            const queryParams = `?searchType=${searchType}&searchQuery=${searchQuery}`

            if (_helpRequestId && courseId) helpRequestGAEvent(_helpRequestId, courseId)

            if (closeHelpRequest) changeHelpRequestStatus(_helpRequestId).catch(toastError)

            if (url === location)
                searchBox[1]({
                    type: searchType,
                    query: `${searchQuery}`,
                })
            else setLocation(`${url}${queryParams}`)

            markNotificationsAsRead([notification])
        }
    }

    if (action?.type === 'document-download') {
        onClick = () => {
            const { expire, fileName, url } = action
            const expireDate = DayJS(expire)
            const isExpired = DayJS().isAfter(expireDate)
            if (isExpired)
                toastNotification(
                    t('The document is no longer available, you must generate it again.'),
                    'error'
                )
            else downloadReport(url, fileName).catch(toastError)

            markNotificationsAsRead([notification])
        }
    }

    return {
        notification,
        onClick,
    }
}

// Interfaces
interface GetNotificationsResponse {
    notifications: NotificationDTO[]
    totalCount: number
}

export interface NotificationDTO {
    _id: string
    status: 'unread' | 'read' | 'deleted'
    type: 'normal' | 'alert'
    title?: string
    message: string
    createdAt: string
    isClosed?: boolean
    action?: NotificationDTOAction
}

type NotificationDTOAction =
    | {
          type: 'search-by'
          courseId: string
          searchType: 'people' | 'teams'
          searchQuery: number | string
          _helpRequestId: string
          closeHelpRequest: boolean
      }
    | {
          type: 'document-download'
          expire: string
          fileName: string
          url: string
      }
