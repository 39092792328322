/**
 * Documentation: docs/context/ui-context.md
 *                docs/hooks/useNotifications.md
 */

import { Actions, NewRotationPayload } from '../interfaces/actions'
import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'

// Interfaces
import { ErrorType } from '../ErrorBoundary'
import { NotificationDTO } from '../hooks/useNotifications'
import { Styles } from 'react-modal'
import { errorHandler } from '../helpers/errorHandler'
import { iSearchQuery } from '../pages/Teams/Manage/Components/SearchBox'

export type UIContextInterface = ReturnType<typeof useUIContextValue>

const outOfContextFn = () => {
    throw Error(`Not inside ${UIContext.displayName}`)
}

export const initialModalState: {
    content: ReactNode | null
    customStyles?: Styles
} = {
    content: null,
    customStyles: {},
}

export const UIContext = createContext<UIContextInterface>({
    infoBlock: [],
    setInfoBlock: outOfContextFn,
    notifications: [],
    setNotifications: outOfContextFn,
    titleCaseFn: outOfContextFn,
    error: undefined,
    raiseError: outOfContextFn,
    toastNotification: outOfContextFn,
    toastError: outOfContextFn,
    subscribeToAction: outOfContextFn,
    unSubscribeToAction: outOfContextFn,
    notifyToObservers: outOfContextFn,
    rotationInfo: {
        inCourse: false,
    } as RotationInfo,
    setRotationInfo: outOfContextFn,
    page: 0 as number,
    setPage: outOfContextFn as () => void,
    searchBox: [null, outOfContextFn] as [
        iSearchQuery | null,
        Dispatch<SetStateAction<iSearchQuery | null>>
    ],
    modal: (outOfContextFn as unknown) as typeof initialModalState,
    setModal: outOfContextFn,
    closeModal: outOfContextFn,
    file: null,
    setFile: outOfContextFn,
})
UIContext.displayName = 'UI Context'

export const useUIContextValue = () => {
    const [infoBlock, setInfoBlock] = useState<[type: 'success' | 'error', message: string] | []>(
        []
    )
    const [notifications, setNotifications] = useState<NotificationDTO[]>([])
    const [error, raiseError] = useState<ErrorType>()
    const [page, setPage] = useState<number>(1)
    const searchBox = useState<iSearchQuery | null>(null)
    const [rotationInfo, setRotationInfo] = useState<RotationInfo>({ inCourse: false })
    const [modal, setModal] = useState(initialModalState)
    const [file, setFile] = useState<Blob | null>(null)
    const closeModal = () => setModal({ content: null, customStyles: {} })

    /**
     * Replace a RegExp to make it uppercase
     * @param {string} str E
     */
    const titleCaseFn = (str: string | undefined) =>
        str?.replace
            ? str.replace(
                  /[A-Z\u00C0-\u00DC]+/gi,
                  (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
              )
            : ''

    const toastNotification = (
        message: string,
        type: 'success' | 'error' = 'success',
        timer = 3
    ) => {
        if (!message) return
        const milliseconds = timer * 1000
        setInfoBlock([type, message])
        setTimeout(() => setInfoBlock([]), milliseconds)
    }

    const toastError = (error: unknown) => toastNotification(errorHandler(error), 'error', 5)

    // Observer Pattern
    const observers = useRef(new Map<Actions, (args?: unknown[]) => void>())
    const subscribeToAction = (action: Actions, callback: () => void) =>
        observers.current.set(action, callback)
    const unSubscribeToAction = (action: Actions) => observers.current.delete(action)
    const notifyToObservers = (action: Actions, args: unknown[]) =>
        observers.current.get(action)?.call(args)
    // Observer Pattern

    const value = {
        infoBlock,
        setInfoBlock,
        titleCaseFn,
        notifications,
        setNotifications,
        raiseError,
        error,
        toastNotification,
        toastError,
        subscribeToAction,
        unSubscribeToAction,
        notifyToObservers,
        page,
        setPage,
        rotationInfo,
        setRotationInfo,
        searchBox,
        modal,
        setModal,
        closeModal,
        file,
        setFile,
    }

    return value
}

export default UIContext

export const useUIContext = () => useContext(UIContext)

// This hook subscribe and unsubscribe to any action
export const useSubscription = (action: Actions, callback: () => unknown) => {
    const { subscribeToAction, unSubscribeToAction } = useUIContext()
    useEffect(() => {
        subscribeToAction(action, callback)
        return () => {
            unSubscribeToAction(action)
        }
    }, [action, callback, subscribeToAction, unSubscribeToAction])
}
export type RotationInfo = Partial<NewRotationPayload>
