import React, { useContext, useState, useEffect } from 'react'
import TeamManagerStudentView from './TeamManagerStudentView'
import LoadingPage from '../../Other/LoadingPage'
import { SubjectStudentContext } from '../../../context/SubjectStudentContext'
// Hooks

const TeamManagerStudentContainer = () => {
    const [ready, setReady] = useState(false)

    const { meeting, votedTable, joinMeeting, teamData, ownData, mainLinks } = useContext(
        SubjectStudentContext
    )

    useEffect(() => {
        setReady(!!teamData)
    }, [teamData])

    if (!ready) return <LoadingPage />

    return (
        <TeamManagerStudentView
            mainLinks={mainLinks}
            onMeeting={joinMeeting}
            meeting={meeting}
            // Vote table
            votedTable={votedTable}
            // Fetched data
            teamData={teamData && teamData}
            ownData={ownData && ownData}
        />
    )
}

export default TeamManagerStudentContainer
