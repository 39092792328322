import React, { useContext, useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import { useTranslation } from 'react-i18next'
import UserContext from '../../context/UserContext'
import { useLocation } from 'wouter'

const TermsAndConditions = () => {
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const { user, setUser } = useContext(UserContext)
    const [, setLocation] = useLocation()
    const { t } = useTranslation(['privacyPolicy'])

    const acceptTerms = async () => {
        user &&
            (await setUser({
                termsAndConditions: true,
            }))
        setLocation('/dashboard')
    }

    return (
        <MainLayout>
            <div
                className="terms-container"
                style={{
                    width: '80%',
                    margin: '0 auto',
                }}
            >
                <div className="content">
                    <h2
                        style={{
                            textAlign: 'center',
                            marginBottom: '50px',
                        }}
                    >
                        {t('title')}
                    </h2>
                    <div
                        className="content-text"
                        style={{
                            fontSize: '.85rem',
                            textAlign: 'justify',
                            maxHeight: '500px',
                            overflow: 'auto',
                        }}
                    >
                        <p>{t('longText')}</p>
                    </div>
                </div>

                <div
                    className="agreement"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '50px',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <input
                            type="checkbox"
                            name="acceptPrivacy"
                            onChange={({ target }) => setAcceptedTerms(target.checked)}
                            placeholder="Aceptar Política de Privacidad"
                        />
                        <label style={{ marginLeft: '10px' }} htmlFor="acceptPrivacy">
                            {t('label')}
                        </label>
                    </div>
                    <button
                        onClick={() => acceptTerms()}
                        disabled={!acceptedTerms}
                        className="btn-yellow"
                    >
                        {t('button')}
                    </button>
                </div>
            </div>
        </MainLayout>
    )
}

export default TermsAndConditions
