import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import { CardInfo, CardsHeader, CardsHeaderProps } from './CardsHeader'
import Styles from './ProgressBars.module.css'

export const ProgressBars = ({ metrics = [], ...rest }: ProgressBarsProps) => (
    <section className={Styles.card}>
        <CardsHeader {...rest} />

        <div className={Styles.bars}>
            {metrics.map((item, index) => (
                <Progress {...item} key={`${index}-progress`} />
            ))}
        </div>

        <CardInfo {...rest} />
    </section>
)

export const Progress = ({
    icon,
    caption,
    value,
    percentage,
    variant,
    maxValue = 100,
}: ProgressProps) => (
    <div className={`${Styles.bar} ${Styles[variant]}`}>
        <div className={Styles.label}>
            <FontAwesomeIcon icon={icon} className={Styles.icon} />
            <span className={Styles.caption}>{caption}</span>
            <span className={Styles.value}>{value}</span>
            <span className={Styles.percentage}>({percentage ?? 0}%)</span>
        </div>
        <progress max={maxValue} value={percentage ?? 0} className={Styles.progress} />
    </div>
)

// Interfaces
interface ProgressBarsProps extends CardsHeaderProps {
    metrics: ProgressProps[]
}

export interface ProgressProps {
    icon: IconProp
    caption: ReactNode
    value: ReactNode
    percentage: React.ProgressHTMLAttributes<HTMLProgressElement>['value'] | null
    variant: 'success' | 'info' | 'danger' | 'egg'
    maxValue?: React.ProgressHTMLAttributes<HTMLProgressElement>['max']
}
