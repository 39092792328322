import React from 'react'
import Axios from 'axios'
import Config from '../../config'

export function useStats(
    /** INPUT */
    _professorScheduleId: string,
    _subjectId: string,
    // eslint-disable-next-line no-console
    onError = console.error
): [
    /** OUTPUT */
    StatsType | null | /*Case of error */ undefined /*Still loading*/
] {
    const [stats, setStats] = React.useState<StatsType | null | undefined>()

    React.useEffect(
        function getStats() {
            if (!_professorScheduleId || !_subjectId) return
            Axios.get(
                Config.API +
                    '/professorSchedule/' +
                    _professorScheduleId +
                    '/subject/' +
                    _subjectId +
                    '/stats/'
            )
                .then(({ data }) => {
                    // Create a map where the key is the studentId and the value is the object {status, comments}
                    const stats = data.students.reduce(
                        (result: StatsType, current: { _profileId: string } & StatsItem) => {
                            const { timesFacilitator, votes, _profileId } = current
                            result[_profileId] = { timesFacilitator, votes }
                            return result
                        },
                        {}
                    )
                    setStats(stats)
                })
                .catch((error) => {
                    onError && onError(error)
                    setStats(null)
                })
        },
        [_professorScheduleId, _subjectId, onError]
    )

    return [stats]
}

// Interfaces

export type StatsType = { [studentId: string]: StatsItem }

export type StatsItem = {
    timesFacilitator: number
    votes: {
        received: number
        given: number
    }
}
