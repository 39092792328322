import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useUIContext } from '../../../../context/UIContext'
import useDebounce from '../../../../hooks/useDebounce'
import Styles from './SearchBox.module.css'

export const SearchBox = ({ options, optionsTitle, isBusy, debounce }: iProps) => {
    const { searchBox } = useUIContext()
    const [query, setQuery] = useState('')
    const [debouncedQuery] = useDebounce(query, debounce || 700)
    const [searchOption, setSearchOption] = useState<iOptionsSearchBox>(options[0])

    const [showOptions, setShowOptions] = useState(false)
    const [inputIcon, setInputIcon] = useState<'search' | 'times' | 'spinner'>('search')

    /** Get helpers list  */
    const helpSearchList = options.filter((type) => type.type === searchOption.type)[0].list || []

    const handleInputIconClick = () => {
        if (inputIcon !== 'times' || !query) return
        const { type } = searchOption
        searchBox[1]({ type, query: '' })
        setQuery('')
    }

    /** Handle input changes */
    useEffect(() => {
        const { type } = searchOption
        const newQuery: iSearchQuery | null = !query.trim() ? null : { type, query: query.trim() }
        searchBox[1](newQuery)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedQuery])

    const handleOptionChange = (option: iOptionsSearchBox) => {
        setQuery('')
        setSearchOption(option)
    }

    /** Changing input icon */
    useEffect(() => {
        const icon: typeof inputIcon = isBusy ? 'spinner' : debouncedQuery ? 'times' : 'search'
        setInputIcon(icon)
    }, [isBusy, debouncedQuery])

    /** Clearing search input */
    useEffect(() => {
        if (searchBox[0] === null) return setQuery('')
        const option = options.find((option) => option.type === searchBox[0]?.type)
        setQuery(searchBox[0]?.query)
        option && setSearchOption(option)
    }, [options, searchBox])

    /** Get last option searched from localStorage */
    useEffect(() => {
        if (options?.length) {
            // Search from URL params if exists
            // Usage: .../manage/search?type=team&query=test
            const params = new URLSearchParams(window.location.search)
            const urlType = params.get('searchType')
            const urlQuery = params.get('searchQuery')
            let option = options.find((option) => option.type === urlType)
            if (urlQuery && option) {
                const newQuery: iSearchQuery = { type: option.type, query: urlQuery }
                searchBox[1](newQuery)

                setQuery(urlQuery)
                setSearchOption(option)
                return
            }

            // Else try to search from localStorage
            const latestSearchType = localStorage.getItem('lastSearchOption')
            let type = options[0]['type']
            if (latestSearchType?.includes('type')) type = JSON.parse(latestSearchType)['type']
            option = options.find((option) => option.type === type)
            option && setSearchOption(option)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /** Saving current option, to localStorage */
    useEffect(() => {
        localStorage.setItem('lastSearchOption', JSON.stringify(searchOption))
    }, [searchOption])

    return (
        <form onSubmit={(event) => event.preventDefault()} className={Styles.form}>
            {!!options && (
                <div className={Styles.options} onClick={() => setShowOptions((prev) => !prev)}>
                    <span>{searchOption?.label}</span>
                    <ArrowSVG toUp={searchOption !== options[0]} />
                    {showOptions && (
                        <div className={Styles.moreOptions}>
                            <div>{optionsTitle}</div>
                            {options.map((option) => (
                                <div
                                    className={
                                        option.label === searchOption?.label
                                            ? Styles.disable
                                            : Styles.enable
                                    }
                                    onClick={() => {
                                        handleOptionChange(option)
                                    }}
                                    key={option.label}
                                >
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            <input
                placeholder={searchOption?.placeholder}
                type="text"
                name="searchInput"
                id="searchInput"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
                autoComplete="off"
                list="list"
            />
            <button onClick={() => handleInputIconClick()}>
                <InputIconRight icon={inputIcon} />
            </button>
            <datalist id="list">
                {helpSearchList.map((item, index) => (
                    <option value={item} key={`${index} + ${item}`} />
                ))}
            </datalist>
        </form>
    )
}

const ArrowSVG = ({ toUp }: { toUp: boolean }) => (
    <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="sort-down"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-100 100 512 512"
        className={toUp ? Styles.vFlip : 'normal'}
    >
        <path
            fill="currentColor"
            d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"
        ></path>
    </svg>
)

const InputIconRight = ({ icon }: iInputIconRight) => {
    const variant = icon === 'spinner' ? 'fas' : 'fal'
    return <FontAwesomeIcon className="icon" icon={[variant, icon]} spin={icon === 'spinner'} />
}

// Interfaces
interface iProps {
    options: iOptionsSearchBox[]
    optionsTitle: string
    isBusy: boolean
    debounce?: number
}

export interface iOptionsSearchBox {
    type: 'people' | 'teams'
    label: string
    placeholder: string
    list?: string[] | number[]
}

interface iInputIconRight {
    icon: 'search' | 'times' | 'spinner'
}

export interface iSearchQuery {
    type: iOptionsSearchBox['type']
    query: string
}
