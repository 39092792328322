import { useEffect, useState } from 'react'
// import { useWindowSize } from './useWindowsSize';

export const useBreakpoint = (): number | undefined => {
    const [width, setWindowWidth] = useState(0)

    useEffect(() => {
        updateDimensions()

        window.addEventListener('resize', updateDimensions)
        return () => window.removeEventListener('resize', updateDimensions)
    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    return width
}
