import React, { useContext, useState, useEffect } from 'react'
import MeetingServiceSelector from './MeetingServiceSelector'
import { SubjectContext } from '../../../../../context/SubjectContext'
import { MeetingService } from '../../../../../hooks/ApiHooks/useMeetings'
import MeetConfig from './MeetConfig'
import ZoomManualConfig from './ZoomManualConfig'
import styled from 'styled-components'
import { colors } from '../../../../../styles/globals/_variables'

const ComponentBorder = styled.div`
    border-bottom: 1px solid ${colors.separadorLineas};
    margin-bottom: 30px;
`

type BlockByServiceProps = {
    service?: MeetingService
    method?: 'automatic' | 'manual'
}
const BlockByService = ({ service }: BlockByServiceProps) => {

    switch (service) {
        case MeetingService.ZOOM: {
            return <ZoomManualConfig />
        }
        case MeetingService.MEET:
            return (
                <MeetConfig
                    service={MeetingService.MEET}
                    placeholder="Ej: https://meet.google.com/ons-jcjb-qxy"
                    validate={(value) =>
                        !value ||
                        // Allow meet.google domain
                        value.match(
                            /^(https:\/\/|http:\/\/)?(www\.)?(meet\.google\.com\/)([a-z]{3})-([a-z]{4})-([a-z]{3})$/gi
                        ) ||
                        'Formato incorrecto'
                    }
                />
            )
        case MeetingService.ZOOM_LIST:
            return (
                <MeetConfig
                    service={MeetingService.ZOOM_LIST}
                    placeholder="Ej: https://zoom.us/j/92181393317?pwd=eXl0UUtOY25ENXFhMlQ5NEhpa0V0dz09"
                    validate={(value) =>
                        !value ||
                        // Allow zoom.us domain
                        value.match(
                            /^(https:\/\/|http:\/\/)?(www\.)?(zoom\.us)?(\/[a-z])?(\/)?([0-9]+\?pwd=)?([a-z ?&%=.\-0-9_])*$/gi
                        ) ||
                        'Formato incorrecto'
                    }
                />
            )
        case MeetingService.TEAMS:
            return (
                <MeetConfig
                    service={MeetingService.TEAMS}
                    placeholder="Ej: https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZTc3OWFiODEtOGI2Mi00OTQwLTg4OGQtZTg0ZTA5MWU1OTRk%40thread.v2/0?context=%7b%22Tid%22%3a%22132b9871-e025-4ead-a34d-7bd5e7a383b4%22%2c%22Oid%22%3a%2244ae139e-3f29-4adc-9483-72c389b80fd9%22%7d"
                    validate={(value) =>
                        !value ||
                        value.match(
                            /^(https:\/\/|http:\/\/)?(www\.)?(teams\.microsoft\.com\/)([a-z ?&%=.\-0-9/_])*$/gi
                        ) ||
                        'Formato incorrecto'
                    }
                />
            )
        case MeetingService.JITSI:
            return (
                <MeetConfig
                    service={MeetingService.JITSI}
                    placeholder="Ej: https://meet.jit.si/eggeducacion"
                    validate={(value) =>
                        !value ||
                        // Allow meet.jit.si domain
                        value.match(
                            /^(https:\/\/|http:\/\/)?(www\.)?(meet\.jit\.si\/)([a-z ?&%=.\-0-9/_])*$/gi
                        ) ||
                        'Formato incorrecto'
                    }
                />
            )
        case MeetingService.WHATSAPP:
            return (
                <MeetConfig
                    service={MeetingService.WHATSAPP}
                    placeholder="Ej: https://chat.whatsapp.com/LCInEzET4nkQL9F4WsCR9Y"
                    validate={(value) =>
                        !value ||
                        value.match(
                            /^(https:\/\/|http:\/\/)?(www.)?(chat.whatsapp.com\/)[a-z0-9]{22}$/gi
                        ) ||
                        'Formato incorrecto'
                    }
                />
            )
        default:
            return null
    }
}

export const MeetingConfigBlock = () => {
    //const { setInfoBlock } = useContext(UIContext)
    const [show, setShow] = useState<boolean>(true)
    const { meeting, setMeeting, clearMeeting } = useContext(SubjectContext)
    useEffect(() => {
        setShow(!!meeting)
    }, [meeting])
    return (
        <ComponentBorder>
            <div className="meeting-configuration">
                <MeetingServiceSelector
                    meeting={meeting}
                    onChange={setMeeting}
                    show={show}
                    setShow={setShow}
                    clearMeeting={clearMeeting}
                />
                {show && <BlockByService service={meeting?.service} method={meeting?.method} />}
            </div>
        </ComponentBorder>
    )
}

export default MeetingConfigBlock
