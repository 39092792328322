import Accordion from '../../../../components/DesignSystem/components/molecules/Accordion/Accordion.component'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useIntercom } from '../../../../hooks/useIntercom'

export const MeetingSupport = () => {
    const { t } = useTranslation('teamSettings')
    const [controlledCollapsed, setControlledCollapsed] = useState(false)
    const { openChat } = useIntercom()
    return (
        <Accordion
            header={{
                title: t('teamSettings:content.virtual-classroom.header.header'),
                headerTitleStyles: {
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: 17,
                    letterSpacing: 0,
                    color: '#003750',
                },
            }}
            controlledCollapsed={controlledCollapsed}
            setControlledCollapsed={setControlledCollapsed}
        >
            <div
                className="meeting-configuration settings-inside-subtitle"
                style={{ paddingBottom: '3em' }}
            >
                <Trans
                    i18nKey="teamSettings:content.virtual-classroom.callForSupport"
                    t={t}
                    components={[
                        // eslint-disable-next-line react/jsx-key
                        <button className="link" onClick={openChat} />,
                    ]}
                />
            </div>
        </Accordion>
    )
}
