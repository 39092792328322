import { useEffect, useState } from 'react'

import DayJS from '../../../../../helpers/DayJS'
import { FinishMeetingButton } from '../../FinishMeetingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MoveToTeamsButton } from './MoveToTeams.button'
import Styles from './ManageMeeting.module.css'
import { useTeam } from '../../../Hooks/useTeam'
import { useTranslation } from 'react-i18next'

export const NoTeamManage = () => {
    const { t } = useTranslation('teamMonitor')
    const { courseID, algorithm } = useTeam()
    const [teamsMeetingEndDate, setTeamsMeetingEndDate] = useState<DayJS.Dayjs | null>(null)
    const [keepsTeam, setKeepsTeam] = useState(false)

    // Store the end date in the session storage for later use (Rotation, etc)
    useEffect(() => {
        if (!teamsMeetingEndDate) return sessionStorage.removeItem('teams-meeting-end-date')
        sessionStorage.setItem('teams-meeting-end-date', teamsMeetingEndDate.toISOString())
    }, [teamsMeetingEndDate])

    if (!courseID) return null

    const duration = teamsMeetingEndDate
        ? DayJS.duration(teamsMeetingEndDate.diff(DayJS())).humanize()
        : t('Undefined')

    return (
        <main>
            <div className={Styles.time}>
                <span className={Styles.endDate}>{t("Team's meeting's end date")}</span>
                <div>
                    <input
                        className={Styles.teamsMeetingEndDate}
                        type="time"
                        value={teamsMeetingEndDate?.format('HH:mm') ?? ''}
                        onChange={({ target: { value } }) =>
                            setTeamsMeetingEndDate(DayJS(value, 'HH:mm'))
                        }
                        min={DayJS().format('HH:mm')}
                        disabled={!teamsMeetingEndDate}
                    />
                    <input
                        type="checkbox"
                        defaultChecked={false}
                        onChange={({ target: { checked } }) =>
                            setTeamsMeetingEndDate(checked ? DayJS().add(30, 'minutes') : null)
                        }
                    />
                </div>
                <small>
                    <FontAwesomeIcon icon={['fas', 'clock']} style={{ color: '#003750' }} />
                    {t("Meeting's duration: {{ duration }}", { duration })}
                </small>
            </div>
            <label className={Styles.keepsTeam}>
                <input
                    type="checkbox"
                    disabled={!algorithm || algorithm.isExpired}
                    defaultChecked={keepsTeam}
                    onChange={({ target: { checked } }) => setKeepsTeam(checked)}
                />
                {t('Maintain previous assignment')}
            </label>
            <MoveToTeamsButton keepsTeam={keepsTeam} />
            <hr style={{ margin: 0 }} />
            <FinishMeetingButton />
        </main>
    )
}
