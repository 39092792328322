import React, { useContext, useState } from 'react'
import dayjs from 'dayjs'
import Axios from 'axios'

import Styles from './AttendanceReport.module.css'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'
import { SubjectContext } from '../../../../../context/SubjectContext'
import UserContext from '../../../../../context/UserContext'
import downloadBlob from '../../../../../helpers/downloadBlob'
import Config from '../../../../../config'
import ReactGA from 'react-ga'
import { useTeam } from '../../../../Team/Context/TeamContext'

export const AttendanceReport = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { toastNotification } = useUIContext()
    const { t } = useTranslation(['teamManager'])
    const { professorScheduleId, subject, course, hq } = useContext(SubjectContext)
    const { role } = useContext(UserContext)
    const today = dayjs().format('YYYY-MM-DD')
    const { team } = useTeam()

    const btnIcon: FontAwesomeIconProps['icon'] = isLoading ? 'spinner' : ['far', 'arrow-to-bottom']

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!subject?.id || !professorScheduleId) return
        setIsLoading(true)

        const subjectId = subject.id
        const { value: datePicked } = document.getElementById('datePicker') as HTMLInputElement
        const localDate = dayjs(datePicked).format('DD/MM/YYYY')
        const tzOffset = -(new Date().getTimezoneOffset() / 60)
        const fileName = `${course?.name.replace(/ /g, '-')}_attendance_${dayjs(datePicked).format(
            'YYYY-MM-DD'
        )}.xlsx`

        downloadAttendanceReport({
            fileName,
            localDate,
            tzOffset,
            professorScheduleId,
            subjectId,
        }).finally(() => setIsLoading(false))
    }

    const downloadAttendanceReport = ({
        fileName,
        localDate,
        tzOffset,
        professorScheduleId,
        subjectId,
    }: DownloadAttendanceReportProps) =>
        Axios.get<GetAttendanceResponse['data']>(
            `${Config.API}/professorSchedule/${professorScheduleId}/subject/${subjectId}/attendances/export?startDate=${localDate}&endDate=${localDate}&timezone=${tzOffset}`,
            { responseType: 'blob' }
        )
            .then(({ data }) => {
                downloadBlob(data, fileName)
                return true
            })
            .catch(({ data }) => {
                data.text().then((text: string) => {
                    const { message } = JSON.parse(text) || "You can't download this report now"
                    toastNotification(message, 'error')
                })
                return false
            })

    return (
        <div className={Styles.container}>
            <form onSubmit={(event) => handleSubmit(event)}>
                <span className={Styles.title}>{t('actions.attendance.report')}</span>
                <input
                    type="date"
                    name="datePicker"
                    id="datePicker"
                    className={Styles.datePicker}
                    defaultValue={today}
                    max={today}
                />
                <button
                    className={Styles.btnDownload}
                    type="submit"
                    disabled={isLoading}
                    onClick={() => {
                        const { value: datePicked } = document.getElementById(
                            'datePicker'
                        ) as HTMLInputElement
                        if (datePicked != today) {
                            //ga event si es historico el reporte
                            ReactGA.event({
                                category: 'Reporting',
                                action: 'HistoricalAttendanceReportDownloaded',
                                dimension1: 'platform',
                                dimension2: hq?.name.toLowerCase(),
                                dimension3: course?.name.toLowerCase(),
                                dimension4: subject?.name.toLowerCase(),
                                dimension7: role,
                                dimension9: team?.algorithm
                                    ? team?.algorithm?.algorithmType
                                    : 'no algorithm available',
                            })
                        } else {
                            //ga event si el reporte es del dia
                            ReactGA.event({
                                category: 'Reporting',
                                action: 'AttendanceReportDownloaded',
                                dimension1: 'platform',
                                dimension2: hq?.name.toLowerCase(),
                                dimension3: course?.name.toLowerCase(),
                                dimension4: subject?.name.toLowerCase(),
                                dimension7: role,
                                dimension9: team?.algorithm
                                    ? team?.algorithm?.algorithmType
                                    : 'no algorithm available',
                            })
                        }
                    }}
                >
                    {t('actions.attendance.download')}
                    <FontAwesomeIcon icon={btnIcon} spin={isLoading} />
                </button>
            </form>
        </div>
    )
}

interface GetAttendanceResponse {
    status: number
    data: Blob
}

interface DownloadAttendanceReportProps {
    fileName: string
    localDate: string
    tzOffset: number
    professorScheduleId: string
    subjectId: string
}
