import React, { Dispatch, ReactNode, SetStateAction } from 'react'

//  Libraries
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useLocation } from 'wouter'

// Components
import { useUser } from '../../context/UserContext'
import useNavBarItems, { ItemMenuProps, MenuProps } from './useNavBarItems'
import { colors } from '../../styles/globals/_variables'

import eggLogo from '../../assets/images/egg_no_shadow.svg'

export const NavBarMobile = ({ mainLinks = [] }: { mainLinks?: MenuProps[] }) => {
    const [, setLocation] = useLocation()

    const { topMenuItems, bottomMenuItems, courseMenuItems, states } = useNavBarItems(mainLinks)
    const { visibleMenu, setVisibleMenu } = states
    const { t } = useTranslation('navbar')
    const { user } = useUser()
    return (
        <Header className="only-mobile">
            <>
                <div className="header-logo">
                    <button className="icon" onClick={() => setLocation('/dashboard')}>
                        <img src={eggLogo} alt="EGG" />
                    </button>
                    <span className="user-name">
                        <Trans
                            i18nKey="Hi <0>{{ name }}</0>!"
                            t={t}
                            values={{ name: user?.name }}
                            // eslint-disable-next-line react/jsx-key
                            components={[<p>{user?.name}</p>]}
                        />
                    </span>
                </div>
                <nav className="header-icons">
                    <MenuButton
                        className="icon toggle"
                        onClick={() => setVisibleMenu(!visibleMenu)}
                        open={visibleMenu}
                    >
                        <FontAwesomeIcon icon={visibleMenu ? ['far', 'times'] : ['far', 'bars']} />
                    </MenuButton>
                </nav>
            </>

            <Menu open={visibleMenu} className="menu-animation">
                <div>
                    <TopMenuRows>
                        {topMenuItems?.map((item) => (
                            <MenuItem item={item} states={states} key={item.label} />
                        ))}
                    </TopMenuRows>

                    <CourseMenuRows>
                        {courseMenuItems?.map((item) => (
                            <MenuItem item={item} states={states} key={item.label} />
                        ))}
                    </CourseMenuRows>
                </div>

                <BottomMenuRows>
                    {bottomMenuItems?.map((item) => (
                        <MenuItem item={item} states={states} key={item.label} />
                    ))}
                </BottomMenuRows>
            </Menu>
        </Header>
    )
}

const MenuItem = ({ item, states }: { item: ItemMenuProps; states: ItemStates }) => {
    const {
        svgIcon,
        icon,
        activeIcon,
        activeSvgIcon,
        logo,
        label,
        active,
        hidden,
        notifications,
    } = item

    const selectIconOrImage = (svgIconItem: ReactNode, iconItem?: IconProp) =>
        svgIconItem ??
        (iconItem ? <FontAwesomeIcon icon={iconItem} /> : <img src={logo} alt="EGG" />)

    const iconToUse = selectIconOrImage(
        active ? activeSvgIcon : svgIcon,
        active ? activeIcon : icon
    )

    if (hidden) return null
    return (
        <MenuRow
            onClick={() => {
                handleMenuItemOnClick(item, states)
            }}
            active={active}
        >
            {iconToUse}
            <p className="small">{label}</p>
            {!!notifications && notifications > 0 && (
                <div className="circle-container">
                    <span className="cant-notif">{notifications}</span>
                    <FontAwesomeIcon className="circle" icon={['fas', 'circle']} />
                </div>
            )}
        </MenuRow>
    )
}

export const handleMenuItemOnClick = (item: ItemMenuProps, states: ItemStates) => {
    const { onClick, neverActiveMobile } = item
    const { setActiveItem, visibleMenu, setVisibleMenu } = states

    !neverActiveMobile && setActiveItem(item)
    setVisibleMenu(!visibleMenu)

    onClick?.()
}

type ItemStates = {
    activeItem: ItemMenuProps | undefined
    setActiveItem: Dispatch<SetStateAction<ItemMenuProps | undefined>>
    visibleMenu: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setVisibleMenu: Dispatch<any>
}

const Header = styled.header`
    width: 100vw;
    height: 64px;
    padding: 0 40px 0 32px;
    display: grid;
    grid-template-columns: auto max-content;
    grid-gap: 1.3em;
    position: fixed;
    z-index: 3;
    right: 0em;
    top: 0em;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.lightGreyBg};

    & > .header-logo {
        display: flex;
        gap: 1em;
        -webkit-box-align: center;
        align-items: center;
        & > button.icon {
            padding: 0;
            min-width: unset;
            width: 25px;
            height: 36px;
        }
        & > button.icon:hover {
            padding: 0;
            min-width: unset;
        }
        & > .user-name {
            display: flex;
            align-items: center;
            gap: 0.2em;
            font-size: 1.2rem;
            font-weight: 600;
            & > p {
                font-size: 1.2rem;
            }
        }
    }

    & > .header-icons {
        display: flex;
        gap: 0.786rem;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: end;
        justify-content: flex-end;
        & > button.icon {
            padding: 0;
            min-width: unset;
            margin: 0;
            width: 18px;
            height: 21px;
            & > svg {
                width: 18px;
                height: 21px;
            }
        }
        & > button.icon:hover {
            padding: 0;
            min-width: unset;
        }
    }
`
const MenuButton = styled.button<{ open?: boolean }>`
    display: block;
    z-index: 1000;
    padding: 1em;
    text-align: ${({ open }) => (open ? 'end' : 'start')};
    margin-right: 1em;
`

const Menu = styled.div<{ open: boolean }>`
    display: ${({ open }) => (open ? 'grid' : 'none')};
    position: fixed;
    inset: 0;
    background-color: ${colors.white};
    padding: 5em 3em;
    grid-template-columns: 1fr;
    grid-template-rows: min-content max-content;
    justify-content: end;
    align-content: space-between;
    gap: 1.5rem;
    overflow-x: hidden;
    transition: all 0.3s ease-in-out;

    opacity: ${({ open }) => (open ? '1' : '0')};
    .menu-animation,
    > * {
        animation: ${({ open }) => (open ? 'fadeIn 0.3s ease-in-out' : 'fadeOut 0.3s ease-in-out')};
        @keyframes fadeIn {
            0% {
                display: none;
                opacity: 0;
            }
            1% {
                display: block;
                opacity: 0;
            }
            100% {
                display: block;
                opacity: 1;
            }
        }

        @keyframes fadeOut {
            0% {
                display: block;
                opacity: 1;
            }
            99% {
                display: block;
                opacity: 0;
            }
            100% {
                display: none;
                opacity: 0;
            }
        }
    }
`

const MenuRows = styled.div`
    display: grid;
    justify-items: center;
    gap: 2rem;
`

const TopMenuRows = styled(MenuRows)`
    padding-bottom: 2rem;
`
const CourseMenuRows = styled(MenuRows)`
    gap: 0;
`
const BottomMenuRows = styled(MenuRows)`
    gap: 0;
`

const MenuRow = styled.div<{ active?: boolean }>`
    width: 100%;
    display: grid;
    grid-template-columns: 15% max-content 15%;
    align-items: center;
    cursor: pointer;
    color: ${colors.darkGreyEgg};
    * {
        color: ${({ active }) => (active ? '#00A3FF' : '#073045')};
    }
    svg,
    img,
    div > .avatar {
        width: 22px;
        height: 22px;
    }

    svg {
        font-size: 22px;
        justify-self: flex-start;
    }
    &:hover {
        color: ${colors.active};
    }
    & .avatar > .main > .gravatar {
        font-size: 1em;
    }
    .circle-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .cant-notif {
            font-weight: bold;
            font-size: 0.8rem;
            position: absolute;
            color: ${colors.white};
        }
        .circle {
            > * {
                color: ${colors.salmon};
            }
        }
    }
`
