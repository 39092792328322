import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import Styles from './ManageMeeting.module.css'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'

export const ConfirmMeetingChange = ({ text, icon, onConfirm, success }: Props) => {
    const { t } = useTranslation('teamMonitor')
    const { setModal } = useUIContext()

    return (
        <section className={Styles.confirm}>
            <div className={Styles.top}>
                {success ? (
                    <FontAwesomeIcon icon={['far', 'circle-check']} style={{ color: '#15C1B0' }} />
                ) : (
                    icon
                )}
                <p className={Styles.text}>{text}</p>
            </div>
            <div className={Styles.bottom}>
                {success ? (
                    <button className="white" onClick={() => setModal({ content: null })}>
                        {t('Got it')}
                    </button>
                ) : (
                    <>
                        <button className="primary" onClick={onConfirm}>
                            {t('Confirm')}
                        </button>
                        <button className="white" onClick={() => setModal({ content: null })}>
                            {t('Cancel')}
                        </button>
                    </>
                )}
            </div>
        </section>
    )
}

export const SuccessModal = ({ text }: Pick<Props, 'text'>) => (
    <ConfirmMeetingChange text={text} success />
)

type Props = {
    text: ReactNode
} & (
    | {
          icon: ReactNode
          onConfirm: () => void
          success?: never
      }
    | {
          icon?: never
          onConfirm?: never
          success: true
      }
)
