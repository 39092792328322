import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ManageMeetingCustomStyles } from './ManageMeeting.modal'
import { MoveToTeamsModal } from './MoveToTeams.modal'
import { useTimeline } from '../../../Hooks/useTimeline'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'

export const MoveToTeamsButton = ({ keepsTeam }: { keepsTeam: boolean }) => {
    const { t } = useTranslation('teamMonitor')
    const { setModal } = useUIContext()
    const { nextEvent } = useTimeline()

    return (
        <button
            className="primary"
            disabled={nextEvent?.room === 'teams'}
            onClick={() =>
                setModal({
                    content: <MoveToTeamsModal keepsTeam={keepsTeam} />,
                    customStyles: ManageMeetingCustomStyles,
                })
            }
        >
            <FontAwesomeIcon icon={keepsTeam ? ['fas', 'circle-arrow-right'] : ['fas', 'rotate']} />
            {keepsTeam ? t("Move to team's room") : t('Assign new teams')}
        </button>
    )
}
