import React, { useContext, useState, useRef } from 'react'
import { UserContext } from '../../../context/UserContext'
import { useLocation } from 'wouter'
import AvatarEgg from '../../../components/Styled/AvatarEgg'
import loadImage from 'blueimp-load-image'
import imageCompression from 'browser-image-compression'
import firebase from 'firebase/app'
import uid from 'uid'
import { useTranslation } from 'react-i18next'
import Webcam from 'react-webcam'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AvatarUpload = () => {
    // Refs
    const webcamRef = useRef(null)
    const fileInput = useRef(null)

    const { t } = useTranslation(['completeProfile'])
    const { user, setUser, setUserButNotSaveIt, fbUser } = useContext(UserContext)
    const [, setLocation] = useLocation()

    const [mode, setMode] = useState('select')
    const [imgSrc, setImgSrc] = useState(null)
    const [webcamStream, setWebcamStream] = useState(true)

    const [saving, setSaving] = useState(false)

    const baseToFile = async (image) => {
        const arr = image.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], 'tempo', { type: mime })
    }

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })

    const takeCapture = () => {
        const image = webcamRef.current.getScreenshot()
        setImgSrc(image)
        setMode('select')
    }

    const handleFileSelect = async (event) => {
        setImgSrc(null)
        const image = event.target.files[0]
        const file = await toBase64(image)
        setImgSrc(file)
    }

    const compressAndUpload = async () => {
        const file = await baseToFile(imgSrc)
        const compressed = await imageCompression(file, {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 600,
            useWebWorker: true,
        })
        const urlImage = await loadImageAsync(compressed)
        // Updates user to show recently loaded img
        setUserButNotSaveIt({ ...user, urlImage })
        // Redirect
        setLocation('/dashboard?rtt=false')
    }

    const loadImageAsync = (compressed) => {
        return new Promise((resolve) => {
            loadImage(
                compressed,
                (img) => {
                    img.toBlob(async (blob) => {
                        const urlImage = await handleUpload(blob)
                        resolve(urlImage)
                    }, 'image/jpeg')
                },
                {
                    maxWidth: 452,
                    maxHeight: 452,
                    crop: true,
                    contain: true,
                    meta: true,
                    orientation: true,
                }
            )
        })
    }

    const handleUpload = async (fileToUpload) => {
        if (!fbUser) return
        setSaving(true)
        const uidkey = uid(6)
        const filename = `users/${fbUser.uid}/${uidkey}-profile-image.jpg`
        const storageRef = firebase.storage().ref()
        const userProfileRef = storageRef.child(filename)
        const snapshot = await userProfileRef.put(fileToUpload)
        const urlImage = await snapshot.ref.getDownloadURL()
        await setUser({ urlImage }).then(() => {
            setSaving(false)
        })
        return urlImage
    }

    const disableWebcam = () => {
        setMode('select')
        setWebcamStream(false)
    }

    const videoConstraints = {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        facingMode: 'user',
        aspectRatio: 1.33333333,
    }

    const skip = () => {
        setUserButNotSaveIt({ ...user, settings: { ...user.settings, avatarUploadSkipped: true } })
        setLocation('/dashboard?rtt=false')
    }

    // Está bastante feo esto. Hacerlo igual pero bien
    return (
        <div className="login-pages fill-wizard-page">
            <div className="egg-card container avatar-info">
                {
                    <div>
                        <input
                            style={{ display: 'none' }}
                            ref={fileInput}
                            type="file"
                            onChange={handleFileSelect}
                        />
                    </div>
                }

                {mode === 'select' && (
                    <div className="stage-select">
                        <AvatarEgg size={{ mobile: 170, desktop: 210 }} src={imgSrc} />
                        {/* <h4>{t('completeProfile:upload-picture.title')}</h4> */}
                        {/* <p className="global-text">{t('completeProfile:upload-picture.desc')}</p> */}
                        {!imgSrc && (
                            <div className="heading">
                                <h4>{t('completeProfile:heading.title-no-img')}</h4>
                                <p className="global-text">
                                    {t('completeProfile:heading.desc-no-img')}
                                </p>
                            </div>
                        )}

                        {imgSrc && (
                            <div className="heading">
                                <h4>{t('completeProfile:heading.title-img')}</h4>
                                <p className="global-text">
                                    {t('completeProfile:heading.desc-img')}
                                </p>
                            </div>
                        )}

                        <div className="action-buttons responsive">
                            {!imgSrc && (
                                <div>
                                    <button
                                        className="btn-outline"
                                        onClick={() => fileInput.current.click()}
                                    >
                                        {t('completeProfile:heading.load-img')}
                                    </button>
                                    {!!webcamStream && (
                                        <button
                                            className="btn-outline"
                                            onClick={() => {
                                                setMode('upload')
                                                setImgSrc(null)
                                            }}
                                        >
                                            {t('completeProfile:heading.take-sht')}
                                        </button>
                                    )}
                                </div>
                            )}
                            {imgSrc && (
                                <div>
                                    <button
                                        className="btn-outline"
                                        onClick={() => {
                                            setMode('select')
                                            setImgSrc(null)
                                        }}
                                    >
                                        {t('completeProfile:heading.use-another')}
                                    </button>
                                    <button
                                        className="btn-yellow"
                                        onClick={() => compressAndUpload()}
                                    >
                                        {!saving ? (
                                            t('completeProfile:heading.save')
                                        ) : (
                                            <FontAwesomeIcon icon={('fas', 'spinner')} spin />
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>

                        {!imgSrc && (
                            <button onClick={() => skip()} className="action-link underline-link">
                                {t('completeProfile:upload-picture.skip')}
                            </button>
                        )}
                    </div>
                )}

                {mode === 'upload' && (
                    <div className="stage-stream">
                        {
                            <Webcam
                                audio={false}
                                videoConstraints={videoConstraints}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                height={346}
                                width={500}
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'center center',
                                    borderRadius: '15px',
                                }}
                                minScreenshotHeight={484}
                                minScreenshotWidth={700}
                                onUserMediaError={disableWebcam}
                            />
                        }
                        {imgSrc && (
                            <div className="stage-stream">
                                <img
                                    style={{ maxWidth: '530px', minHeight: '360px' }}
                                    src={imgSrc}
                                    alt="stage-stream"
                                />
                                <img
                                    style={{ maxWidth: '530px', minHeight: '360px' }}
                                    src={'data:image/jpeg;base64' + imgSrc}
                                    alt="stage-stream"
                                />
                            </div>
                        )}

                        <div className="action-button">
                            <button className="btn-outline" onClick={() => takeCapture()}>
                                {'TOMAR'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AvatarUpload
