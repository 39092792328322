import React, { useState, MouseEvent, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { UIContext } from '../../../../context/UIContext'

export interface Props {
    course: string
    subject: string
    onCourseChange: (newCourse: string) => unknown
    onSubjectChange: (newSubject: string) => unknown
    spinnerCourse: boolean
    spinnerSubject: boolean
}
type IProps = {
    cursor?: boolean
}

const EditBtn = styled.button`
    font-size: 1.3rem;
    position: relative;
    right: 45px;
    margin-right: -45px;
    background-color: white;
    border: none;
    cursor: pointer;
`

const EditInput = styled.input<IProps>`
    width: 100%;
    padding-right: 45px;
    cursor: ${({ cursor }) => (cursor ? 'pointer' : 'auto')};
`

export const TeamInfo = (props: Props) => {
    const { t } = useTranslation(['teamSettings'])
    const { setInfoBlock } = useContext(UIContext)
    const [course, setCourse] = useState(props.course)
    const [subject, setSubject] = useState(props.subject)
    const [courseIsEditable, setCourseIsEditable] = useState(false)
    const [subjectIsEditable, setSubjectIsEditable] = useState(false)
    const inputCourseName = useRef<HTMLInputElement>(null)
    const inputCourseSubject = useRef<HTMLInputElement>(null)

    const saveCourse = (event: MouseEvent) => {
        const re = /^[a-zÀ-ÿ\d\s]+$/i
        if (re.test(course) && course.length <= 50) {
            event.preventDefault()
            props.onCourseChange(course)
            setCourseIsEditable(false)
        } else {
            event.preventDefault()
            setInfoBlock([
                'error',
                'Formato incorrecto, solo permite,  letras, números y un máximo de 50 caracteres ',
            ])
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }
    function handleFocusName() {
        inputCourseName.current?.focus()
    }

    function handleFocusSubject() {
        inputCourseSubject.current?.focus()
    }
    const handleChangeCourse = (event: React.ChangeEvent<HTMLInputElement>) => {
        //const re = /^[a-zÀ-ÿ\d/^\s]+$/i
        // if (re.test(event.target.value))
        setCourse(event.target.value)
    }

    const handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
        //const re = /^[a-zÀ-ÿ\d/^\s]+$/i

        // if (re.test(event.target.value))
        setSubject(event.target.value)
    }

    const saveSubject = (event: MouseEvent) => {
        const re = /^[a-zÀ-ÿ\d\s]+$/i

        if (re.test(subject) && subject.length <= 50) {
            event.preventDefault()
            props.onSubjectChange(subject)
            setSubjectIsEditable(false)
        } else {
            event.preventDefault()
            setInfoBlock([
                'error',
                'Formato incorrecto, solo permite, letras, números y un máximo de 50 caracteres ',
            ])
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }

    return (
        <div className="team-info">
            <h3>{t('teamSettings:content.team-info.title')}</h3>
            <form action="">
                <div className="form-group">
                    <label htmlFor="course">{t('teamSettings:content.team-info.course')}</label>
                    <div className="input-group">
                        <EditInput
                            ref={inputCourseName}
                            type="text"
                            className="form-input"
                            id="course"
                            value={course}
                            placeholder={t('teamSettings:content.team-info.coursePlaceholder')}
                            onChange={(event) => {
                                handleChangeCourse(event)
                            }}
                            readOnly={!courseIsEditable}
                            maxLength={50}
                        />
                        {!courseIsEditable && !props.spinnerCourse && (
                            <EditBtn
                                onClick={() => {
                                    setCourseIsEditable(true)
                                    handleFocusName()
                                }}
                            >
                                <FontAwesomeIcon icon={['fal', 'pencil']} />
                            </EditBtn>
                        )}
                        {(courseIsEditable || props.spinnerCourse) && (
                            <EditBtn onClick={saveCourse}>
                                {!props.spinnerCourse ? (
                                    <FontAwesomeIcon icon={['fal', 'save']} />
                                ) : (
                                    <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                )}
                            </EditBtn>
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="subject">{t('teamSettings:content.team-info.subject')}</label>
                    <div className="input-group">
                        <EditInput
                            ref={inputCourseSubject}
                            type="text"
                            className="form-input"
                            id="subject"
                            value={subject}
                            placeholder={t('teamSettings:content.team-info.subjectPlaceholder')}
                            onChange={(event) => handleChangeSubject(event)}
                            readOnly={!subjectIsEditable}
                            maxLength={50}
                        />
                        {!subjectIsEditable && !props.spinnerSubject && (
                            <EditBtn
                                onClick={() => {
                                    setSubjectIsEditable(true)
                                    handleFocusSubject()
                                }}
                            >
                                <FontAwesomeIcon icon={['fal', 'pencil']} />
                            </EditBtn>
                        )}
                        {(subjectIsEditable || props.spinnerSubject) && (
                            <EditBtn onClick={saveSubject}>
                                {!props.spinnerSubject ? (
                                    <FontAwesomeIcon icon={['fal', 'save']} />
                                ) : (
                                    <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                )}
                            </EditBtn>
                        )}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default TeamInfo
