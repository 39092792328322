import React, { useRef } from 'react'
import replaceUrl from '../helpers/replaceUrl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface IAvatarEggProps {
    size?: 'sm' | 'xxl'
    border?: string
    src?: string
    isEditable?: boolean
    handleFileSelect?: (event: React.ChangeEvent<HTMLInputElement>) => unknown
    imageIsLoading?: boolean
    facilitator?: boolean | undefined | null
}

const AvatarEgg = (props: IAvatarEggProps) => {
    const { size, border, src, isEditable, handleFileSelect, imageIsLoading, facilitator } = {
        size: 'sm',
        ...props,
    }
    const fileInput = useRef<HTMLInputElement>(null)

    return (
        <>
            {handleFileSelect && (
                <div>
                    <input
                        style={{ display: 'none' }}
                        ref={fileInput}
                        type="file"
                        onChange={handleFileSelect}
                    />
                </div>
            )}

            <div
                onClick={() => (isEditable ? fileInput.current?.click() : null)}
                className={`avatar-egg ${size} ${border} ${
                    src ? 'no-border' : ''
                } is-editable avatar-egg ${facilitator ? ' is-facilitator' : ''}`}
            >
                {!src && <FontAwesomeIcon className="default" icon={['fal', 'user-circle']} />}
                {src && <img src={replaceUrl(src)} alt="" />}
                {isEditable && (
                    <FontAwesomeIcon
                        className="editButton"
                        icon={!imageIsLoading ? ['fal', 'camera'] : ['fas', 'spinner']}
                        spin={imageIsLoading}
                    />
                )}
            </div>
        </>
    )
}

export default AvatarEgg
