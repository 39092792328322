import React, { useContext, useEffect, useState, useCallback } from 'react'
import UserItem from './UserItem'
import { SubjectContext } from '../../../../context/SubjectContext'
import Axios from 'axios'
import Config from '../../../../config'
import useDebounce from '../../../../hooks/useDebounce'
import ManagerContext from '../../../../context/ManagerContext'
import Pagination from '../../../../components/Pagination'
import AvatarEgg from '../../../../components/Styled/AvatarEgg'
import styled from 'styled-components'
import usePrevious from '../../../../hooks/usePrevious'
import { ROLES } from '../../../../constants'
import { useTranslation } from 'react-i18next'
import { StudentType } from '../../../../hooks/ApiHooks/useAssignation'

interface IGETSearchResponse {
    _id: string
    name: string
    students: {
        email: string
        lastname: string
        name: string
        urlImage: string
        _id: string
        belong?: boolean
        role?: ROLES
    }[]
    totalCount: number
}

const TopPaginationContainer = styled.div({
    position: 'absolute',
    width: '100%',
    top: '-75px',
})

const AddMembersList = () => {
    const { members, hq } = useContext(SubjectContext)
    const { search } = useContext(ManagerContext)
    const [debouncedSearch, isDebouncing] = useDebounce(search, 1000)
    const [list, setList] = useState<IGETSearchResponse['students']>()
    const [page, setPage] = useState<number>(1)
    const [limit] = useState<number>(10)
    const [maxPage, setMaxPage] = useState<number>()
    const previousLength = usePrevious<number | undefined>(list?.length)

    const { t } = useTranslation(['teamManager'])

    useEffect(() => {
        if (isDebouncing) setList(undefined)
    }, [isDebouncing])

    useEffect(() => {
        setList(undefined)
    }, [page])

    const fetchList = useCallback(async () => {
        try {
            const response = await Axios.get<IGETSearchResponse>(
                `${Config.API}/hq/${hq?.id}/students`,
                {
                    params: {
                        limit: limit,
                        skip: limit * (page - 1),
                        search: debouncedSearch,
                    },
                }
            )
            setMaxPage(Math.ceil(response.data.totalCount / limit))

            for (const key in members) {
                const role = key as keyof typeof members
                for (let i = 0; i < response.data.students.length; i++) {
                    if (members[role].find(({ _id }) => _id === response.data.students[i]._id)) {
                        response.data.students[i].role = role
                    }
                }
            }

            for (let i = 0; i < response.data.students.length; i++) {
                let local = false
                if (members?.student) {
                    for (let j = 0; j < members?.student.length; j++) {
                        if (members?.student[j]._id === response.data.students[i]._id) {
                            local = true
                        }
                    }
                }
                if (local === true) {
                    response.data.students[i].belong = true
                } else {
                    response.data.students[i].belong = false
                }
            }

            setList(response.data.students)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }, [hq, limit, members, page, debouncedSearch])

    useEffect(() => {
        fetchList()
    }, [fetchList])

    return (
        <>
            <TopPaginationContainer>
                <Pagination page={page} onChange={setPage} count={maxPage} />
            </TopPaginationContainer>
            <div className="table-item">
                <div className="heading">
                    <div className="label-and-meeting">
                        <h3>{t('teamManager:add-students.heading')}</h3>
                    </div>
                </div>
                <div className="user-list">
                    {list ? (
                        list.map((member, i) => (
                            <UserItem
                                isAssigned={true}
                                student={(member as unknown) as StudentType}
                                key={i}
                            />
                        ))
                    ) : (
                        <LoadingSkeleton quantity={previousLength ?? limit} />
                    )}
                </div>
                <Pagination page={page} onChange={setPage} count={maxPage} />
            </div>
        </>
    )
}

const Skeleton = styled.div({
    backgroundColor: '#F0F0F0',
    height: '1.2rem',
    marginLeft: '4px',
    maxWidth: '200px',
    width: '100%',
    borderRadius: '5px',
})

const LoadingSkeleton = ({ quantity }: { quantity: number }) => {
    return (
        <>
            {[...Array(quantity)].map((_, i) => (
                <div className="user-item" key={i}>
                    <div className="user-info-block">
                        <div className="avatar">
                            <AvatarEgg />
                        </div>
                        <div className="name-label">
                            <Skeleton />
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default AddMembersList
