import React from 'react'
import styled from 'styled-components'

interface IProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    diameter: number
    initial: number
    clockwise: boolean
    center?: React.ReactElement
}

/** Given a phase (in tau units) return the x and y component */
const toPolar = (phase: number): [number, number] => [
    Math.sin(phase * 2 * Math.PI),
    Math.cos(phase * 2 * Math.PI),
]

const Align = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    .nose-quees {
        display: inline-block;
        padding-top: 100%;
        vertical-align: middle;
    }
    .central-logo {
        height: 62px;
        @media screen and (min-width: 768px) {
            height: 80px;
        }
        &.inactive {
            filter: grayscale(100%);
        }
        &.active,
        &:hover {
            filter: grayscale(0);
        }
    }
`

const CircularAlign = ({ children, diameter, initial, clockwise, center }: IProps) => {
    return (
        <Align>
            <div className="nose-quees"></div>
            {center && <div>{center}</div>}
            {React.Children.map(children, (child, i) => {
                const element = child as React.ReactElement
                const n = React.Children.count(children)
                const [x, y] = toPolar(initial / 360 + (i / n) * (clockwise ? -1 : 1))
                return React.cloneElement(element, {
                    style: {
                        ...element.props.style,
                        position: 'absolute',
                        top: 50 - (diameter / 2) * x + '%',
                        left: 50 + (diameter / 2) * y + '%',
                        transform: `translate(-50%,-50%) ${element.props.style?.transform ?? ''}`,
                    },
                })
            })}
        </Align>
    )
}

export default CircularAlign
