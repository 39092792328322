import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { SubjectContext } from '../../../../../context/SubjectContext'
import UIContext from '../../../../../context/UIContext'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ROLES } from '../../../../../constants'

const Block = styled.div({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
})

const Button = styled.button({
    width: '175px',
    background: '#FFCD00',
    color: '#37474F',
    padding: '10px 30px',
    fontSize: '1rem',
    fontWeight: 'bold',
    borderRadius: '30px',
    border: '1px solid #FFCD00',
    cursor: 'pointer',
    ':hover': {
        background: '#37474F',
        color: 'white',
        border: '1px solid #37474F',
    },
    ':disabled': {
        background: '#F8F8F8',
        color: '#d0d0d0',
        border: '1px solid #d0d0d0',
        cursor: 'default',
    },
})

interface BaseStudentProps {
    name: string
    lastname: string
    _id?: string
    role?: ROLES
}
const AddAction = <S extends BaseStudentProps>({ student }: { student: S }) => {
    const { t } = useTranslation(['errors'])
    const { setMembers, course } = useContext(SubjectContext)
    const { setInfoBlock, titleCaseFn } = useContext(UIContext)
    const [sending, setSending] = useState(false)

    const onClick = async () => {
        if (!student._id) return
        try {
            const fullName = titleCaseFn(`${student.name} ${student.lastname}`)
            const courseName = titleCaseFn(course?.name)
            if (!window.confirm(`¿Desea agregar a ${fullName} al curso ${courseName}?`)) return
            setSending(true)
            await setMembers?.(student._id, ROLES.STUDENT)
            setInfoBlock(['success', `Se ha agregado a ${fullName} al curso ${courseName}`])
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            setInfoBlock([
                'error',
                error.message || error.data?.message || t('errors:genericRetry'),
            ])
        } finally {
            setSending(false)
            setTimeout(() => setInfoBlock([]), 2000)
        }
    }

    return (
        <Block>
            <Button onClick={onClick} disabled={!!student?.role}>
                {!sending ? (
                    t(`teamManager:actions.add.${student?.role}`)
                ) : (
                    <FontAwesomeIcon icon="spinner" spin />
                )}
            </Button>
        </Block>
    )
}

export default AddAction
