import React, { useState, useContext, useEffect } from 'react'
import { CourseResponse } from './EnrollContainer'
import { useLocation } from 'wouter'
// Components
import { useForm } from 'react-hook-form'
import NoHeaderLayout from '../../layouts/NoHeaderLayout'
// Hooks
import { useTranslation, Trans } from 'react-i18next'
// Assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IUserSignup } from '../../hooks/ApiHooks/useEnroll'
import { UIContext } from '../../context/UIContext'
import styled from 'styled-components'
import LoadingPage from '../Other/LoadingPage'

interface INotLogedProps {
    course: CourseResponse
    onSubmit?: (user: IUserSignup) => void
    onEnrollAsExisting?: () => void
}

const BoringInput = styled.input({
    border: 'none',
    backgroundColor: 'transparent',
    flex: 1,
    margin: '0 !important',
    fontWeight: 200,
    height: '100%',
})

const SuffixInput = styled.span({
    fontSize: 25,
    position: 'relative',
    zIndex: 20,
    color: '#37474F',
    height: '100%',
    transition: 'color 100ms',
    ':hover': {
        color: '#FFCD00',
        cursor: 'pointer',
    },
})

const ContainerInput = styled.div({
    display: 'flex',
    flexDirection: 'row',
    margin: '0 !important',
})
export const EnrollViewNotLoged = ({ course, onSubmit, onEnrollAsExisting }: INotLogedProps) => {
    const { t, i18n } = useTranslation(['signup', 'errors'])
    const [lang, setLang] = useState(i18n.language.toUpperCase())
    const { titleCaseFn } = useContext(UIContext)
    const [passwordShown, setPasswordShown] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const [, setLocation] = useLocation()
    if (!onEnrollAsExisting) {
        onEnrollAsExisting = () =>
            setLocation(
                '/login?onLogin=' +
                    encodeURI(window.location.pathname + window.location.search) +
                    '&onSignup=' +
                    encodeURI(window.location.pathname + window.location.search)
            )
    }

    const switchLang = () => {
        if (lang === 'ES') {
            i18n.changeLanguage('en')
            setLang('EN')
        } else {
            i18n.changeLanguage('es')
            setLang('ES')
        }
    }

    const { register, handleSubmit, errors, formState } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            password: '',
            password_confirmation: '',
            lastname: '',
            email: '',
        },
    })

    const requiredField = t('errors:forms.requiredField')

    const [spinner, setSpinner] = useState(true);

    useEffect(()=>{        
        setTimeout(()=>{setSpinner(false)}, 4000);                   
    },[spinner])

    if(spinner) return <LoadingPage></LoadingPage>
    else return (
        <NoHeaderLayout>
            <div className="login-pages">
                <div className="lang-switch">
                    <button onClick={switchLang}>
                        <FontAwesomeIcon className="icon" icon={['fal', 'globe-americas']} />
                        <span>{lang}</span>
                    </button>
                </div>
                <div className="container">
                    <div className="onboarding-card signup-form">
                        <div className="onboarding-img"></div>
                        <div className="onboarding-form">
                            <div className="info">
                                <span className="step-title">{t('signup:title')}</span>
                                <p className="global-text">
                                    <Trans i18nKey="signup:desc-course-2">
                                        <strong>
                                            {{ course: titleCaseFn(course?.course.name) }}
                                        </strong>
                                        <strong>{{ hq: titleCaseFn(course?.hq.name) }}</strong>.
                                    </Trans>
                                </p>
                                {/*<p className="global-text">
                            <Trans i18nKey="signup:desc-course"><strong>{{course: titleCaseFn(course?.course.name)}}</strong><strong>{{hq: titleCaseFn(course?.hq.name)}}</strong>.</Trans>
                        </p>*/}
                                <form onSubmit={onSubmit && handleSubmit(onSubmit)}>
                                    <div className="responsive">
                                        <div>
                                            <input
                                                type="text"
                                                name="name"
                                                autoFocus
                                                className="form-input"
                                                placeholder={t('signup:name')}
                                                required
                                                ref={register({
                                                    required: requiredField,
                                                    pattern: {
                                                        //value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
                                                        value: /[a-zA-ZÀ-ÿ-'\u00f1\u00d1\s]+$/,
                                                        message: t('errors:forms.weird-chars'),
                                                    },
                                                })}
                                            />
                                            {errors.name?.message && (
                                                <div className="input-error-msg">
                                                    {errors.name?.message}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="lastname"
                                                className="form-input"
                                                placeholder={t('signup:lastname')}
                                                ref={register({
                                                    required: requiredField,
                                                    pattern: {
                                                        //value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
                                                        value: /[a-zA-ZÀ-ÿ-'\u00f1\u00d1\s]+$/,
                                                        message: t('errors:forms.weird-chars'),
                                                    },
                                                })}
                                                required
                                            />
                                            {errors.lastname?.message && (
                                                <div className="input-error-msg">
                                                    {errors.lastname?.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="responsive">
                                        <div>
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder={t('signup:email')}
                                                className="form-input"
                                                required
                                                ref={register({
                                                    required: requiredField,
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                        message: t('errors:forms.valid-email'),
                                                    },
                                                })}
                                            />
                                            {errors.email?.message && (
                                                <div className="input-error-msg">
                                                    {errors.email?.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        className="responsive"
                                        style={{ marginTop: '10px', marginBottom: '10px' }}
                                    >
                                        <div>
                                            <ContainerInput className="form-input">
                                                <BoringInput
                                                    type={passwordShown ? 'text' : 'password'}
                                                    name="password"
                                                    required
                                                    placeholder={t('signup:password')}
                                                    ref={register({
                                                        required: requiredField,
                                                        minLength: {
                                                            value: 8,
                                                            message: t(
                                                                'errors:login.shortPassword'
                                                            ),
                                                        },
                                                    })}
                                                />
                                                <SuffixInput onClick={togglePasswordVisiblity}>
                                                    {/* {console.log(passwordShown)} */}
                                                    {passwordShown == true ? (
                                                        <FontAwesomeIcon
                                                            icon={['fal', `eye`]}
                                                            className="icon"
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={['fal', `eye-slash`]}
                                                            className="icon"
                                                        />
                                                    )}
                                                </SuffixInput>
                                            </ContainerInput>
                                            {errors.password?.message && (
                                                <div className="input-error-msg">
                                                    {errors.password?.message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* clase para dejar los  botones en la mis ma linea className="action-buttons responsive"*/}
                                    <div className="container-buttons-signup">
                                        <button
                                            className="btn-yellow"
                                            style={{ margin: '5px' }}
                                            type="submit"
                                            disabled={formState.isSubmitting || !formState.isValid}
                                        >
                                            {!formState.isSubmitting ? (
                                                t('signup:create')
                                            ) : (
                                                <FontAwesomeIcon icon="spinner" spin />
                                            )}
                                        </button>
                                        <button
                                            onClick={onEnrollAsExisting}
                                            style={{ margin: '5px' }}
                                            className="btn-outline"
                                        >
                                            {t('signup:have-account')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NoHeaderLayout>
    )
}

export default EnrollViewNotLoged
