import React from 'react'

interface IProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    diameter: number
    initial: number
    clockwise: boolean
    center?: React.ReactElement
}

/** Given a phase (in tau units) return the x and y component */
const toPolar = (phase: number): [number, number] => [
    Math.sin(phase * 2 * Math.PI),
    Math.cos(phase * 2 * Math.PI),
]

const CircularAlign = ({ children, diameter, initial, clockwise, center, ...rest }: IProps) => (
    <div
        {...rest}
        style={{
            width: '100%',
            height: '100%',
            ...rest.style,
            position: 'relative',
            textAlign: 'center',
            whiteSpace: 'nowrap',
        }}
    >
        <div
            style={{ display: 'inline-block', paddingTop: '100%', verticalAlign: 'middle' }}
            data-test-id="circular-table"
        ></div>
        {center && <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>{center}</div>}
        {React.Children.map(children, (child, i) => {
            const element = child as React.ReactElement
            const n = React.Children.count(children)
            const [x, y] = toPolar(initial / 360 + (i / n) * (clockwise ? -1 : 1))
            return React.cloneElement(element, {
                style: {
                    ...element.props.style,
                    position: 'absolute',
                    top: 50 - (diameter / 2) * x + '%',
                    left: 50 + (diameter / 2) * y + '%',
                    transform: `translate(-50%,-50%) ${element.props.style?.transform ?? ''}`,
                },
            })
        })}
    </div>
)

export default CircularAlign
