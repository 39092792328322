import React, { useCallback, useContext, useState } from 'react'
import EnrollViewNotLoged from './EnrollViewNotLoged'
import EnrollViewLoged from './EnrollViewLoged'
import { UserContext } from '../../context/UserContext'
import LoadingPage from '../Other/LoadingPage'
import { useEnroll } from '../../hooks/ApiHooks/useEnroll'
import { AlreadyEnrolled } from './EnrollAlreadyEnrolled'
import UIContext from '../../context/UIContext'
import { useLocation } from 'wouter'

export interface CourseResponse {
    hq: { id: string; name: string }
    course: { id: string; name: string }
}

/**
 * Takes a mode that can be: student - professor or new professor,
 * Takes the courseId,
 * Takes an UUID that travels in the url as part of the path. Not a query param.
 */
interface IProps {
    mode: string
    course?: CourseResponse
    uuid?: string
}
export const EnrollContainerLogged = ({ mode, uuid }: IProps) => {
    const { setInfoBlock } = useContext(UIContext)
    const [, setLocation] = useLocation()

    const onError = useCallback(
        (error) => {
            // eslint-disable-next-line no-console
            console.error(error)
            setInfoBlock([
                'error',
                error?.data?.message || error?.message || 'Ups! Ha ocurrido un error.',
            ])
            setLocation('/', { replace: true })
        },
        [setInfoBlock, setLocation]
    )
    const [course, enroll] = useEnroll(mode, uuid ?? '', onError)

    const { user } = useContext(UserContext)
    const [newUser, setNewUser] = useState<boolean>(false)

    if (!course) return <LoadingPage />

    if (newUser) {
        return (
            <EnrollViewNotLoged
                course={course}
                onSubmit={enroll}
                onEnrollAsExisting={() => setNewUser(false)}
            />
        )
    }

    if (!enroll) {
        return <AlreadyEnrolled course={course} userName={user?.name} />
    }

    return (
        <EnrollViewLoged
            course={course}
            userName={user?.name}
            onSubmit={enroll}
            onEnrollAsNew={() => setNewUser(true)}
        />
    )
}

interface IProps {
    mode: string
    course?: CourseResponse
}
export const EnrollContainerNotLogged = ({ mode, uuid }: IProps) => {
    const { setInfoBlock } = useContext(UIContext)
    const [, setLocation] = useLocation()
    const onError = useCallback(
        (error) => {
            // eslint-disable-next-line no-console
            console.error(error)
            setInfoBlock([
                'error',
                error?.data?.message || error?.message || 'Ups! Ha ocurrido un error.',
            ])
            setLocation('/', { replace: true })
        },
        [setInfoBlock, setLocation]
    )
    const [course, enroll] = useEnroll(mode, uuid ?? '', onError)

    if (!course) return <LoadingPage />

    return <EnrollViewNotLoged course={course} onSubmit={enroll} />
}
