import React, { useState, useEffect } from 'react'
import { string, number, bool } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const INITIAL_OFFSET = 25
const circleConfig = {
    viewBox: '0 0 38 38',
    x: '19',
    y: '19',
    radio: '15.91549430918954',
}

const CircleProgressBarBase = ({
    className,
    strokeColor,
    strokeWidth,
    percentage,
    trailStrokeWidth,
    trailStrokeColor,
    trailSpaced,
    speed,
}) => {
    const [progressBar, setProgressBar] = useState(0)
    const [showPercent] = useState(false)

    const pace = percentage / speed

    let timerID

    const updatePercentage = () => {
        timerID = setTimeout(() => {
            setProgressBar(progressBar + 1)
        }, pace)
    }

    useEffect(() => {
        if (percentage > 0) updatePercentage()
        return () => clearTimeout(timerID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [percentage])

    useEffect(() => {
        if (progressBar < percentage) updatePercentage()
        return () => clearTimeout(timerID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressBar])

    return (
        <figure className={className}>
            {' '}
            {/* onClick={switchPercentShow} */}
            {!showPercent && (
                <FontAwesomeIcon className="fingerprint-icon" icon={['fal', 'fingerprint']} />
            )}
            <svg viewBox={circleConfig.viewBox}>
                <circle
                    className="donut-ring"
                    cx={circleConfig.x}
                    cy={circleConfig.y}
                    r={circleConfig.radio}
                    fill="transparent"
                    stroke={trailStrokeColor}
                    strokeWidth={trailStrokeWidth}
                    strokeDasharray={trailSpaced ? 1 : 0}
                />

                <circle
                    className="donut-segment"
                    cx={circleConfig.x}
                    cy={circleConfig.y}
                    r={circleConfig.radio}
                    fill="transparent"
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${progressBar} ${100 - progressBar}`}
                    strokeDashoffset={INITIAL_OFFSET}
                />

                <g className="chart-text">
                    <text x="50%" y="50%" className="chart-number">
                        {showPercent && `${progressBar}%`}
                    </text>
                </g>
            </svg>
        </figure>
    )
}

CircleProgressBarBase.propTypes = {
    className: string.isRequired,
    strokeColor: string,
    strokeWidth: number,
    innerText: string,
    legendText: string,
    percentage: number,
    trailStrokeWidth: number,
    trailStrokeColor: string,
    trailSpaced: bool,
    speed: number,
}

export default CircleProgressBarBase
