import React from 'react'
import { OutlineButton } from '../../atoms'
import Button from '../../atoms/Button/Button.component'

const AccordionFooter = ({
    primaryButtonOnClick,
    primaryButtonText,
    secondaryButtonOnClick,
    secondaryButtonText,
}: IAccordionFooterProps) => {
    return (
        <div
            style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 95px 60px', gap: 15 }}
        >
            <OutlineButton onClick={secondaryButtonOnClick}>{secondaryButtonText}</OutlineButton>
            <Button onClick={primaryButtonOnClick}>{primaryButtonText}</Button>
        </div>
    )
}

interface IAccordionFooterProps {
    primaryButtonOnClick: () => void
    primaryButtonText: string
    secondaryButtonOnClick: () => void
    secondaryButtonText: string
}

export default AccordionFooter
