import React, { useEffect } from 'react'
import Config from '../../config'
import Axios from 'axios'

export enum ShareModes {
    COACH = 'professor',
    STUDENT = 'student',
    REAL_COACH = 'professornew',
}
export type ShareLinkMap = { [mode in ShareModes]: string | undefined | null }

// eslint-disable-next-line no-console
export function useShareLink(courseId: string, onError = console.error) {
    const [shareLink, setShareLink] = React.useState<ShareLinkMap>({
        [ShareModes.COACH]: undefined,
        [ShareModes.STUDENT]: undefined,
        [ShareModes.REAL_COACH]: undefined,
    })
    useEffect(
        function getShareLink() {
            if (!courseId) return
            Axios.get(Config.API + '/class/' + courseId + '/enrollToken/')
                .then((response) => {
                    //console.log('response useShareLink', response)
                    // Use the current domain
                    const { protocol, hostname, port } = window.location
                    const domain = `${protocol}//${hostname}${port ? `:${port}` : ''}`
                    // setShareLink({
                    //     [ShareModes.COACH]: `${domain}/enroll/${ShareModes.COACH}/?token=${response.data.short.tutorToken}`,
                    //     [ShareModes.STUDENT]: `${domain}/enroll/${ShareModes.STUDENT}/?token=${response.data.short.token}`,
                    //     [ShareModes.REAL_COACH]: `${domain}/enroll/${ShareModes.REAL_COACH}/?token=${response.data.short.professorToken}`,
                    // })
                    setShareLink({
                        [ShareModes.COACH]: `${domain}/enroll/${ShareModes.COACH}/${response.data.short.tutorToken}`,
                        [ShareModes.STUDENT]: `${domain}/enroll/${ShareModes.STUDENT}/${response.data.short.token}`,
                        [ShareModes.REAL_COACH]: `${domain}/enroll/${ShareModes.REAL_COACH}/${response.data.short.professorToken}`,
                    })
                })
                .catch((error) => {
                    onError && onError(error)
                    setShareLink({
                        [ShareModes.COACH]: null,
                        [ShareModes.STUDENT]: null,
                        [ShareModes.REAL_COACH]: null,
                    })
                })
        },
        [courseId, onError]
    )

    return shareLink
}
