/* eslint-disable react/prop-types */
import React from 'react'
import MainLayout from '../../../layouts/MainLayout'

const DataRevisionView = (props) => {
    const { students } = props

    return (
        <MainLayout>
            <div className="data-revision-container">
                <div className="header">
                    <h2>
                        Carga masiva {'>'} <span className="yellow">Revisión de datos</span>
                    </h2>
                </div>
                <div className="content">
                    <div className="egg-list">
                        {students.map((item, idx) => (
                            <div className="list-item responsive" key={item._profileId}>
                                <div>{idx}</div>
                                <div>
                                    <input
                                        type="text"
                                        className="form-input"
                                        placeholder="Placeholder"
                                        value={item.name}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        className="form-input"
                                        placeholder="Placeholder"
                                        value={item.lastname}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        className="form-input"
                                        placeholder="Placeholder"
                                        value={item._profileId}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="info-footer responsive">
                    <div className="text">
                        Se cargarán <strong>{students.length} usuarios</strong>
                    </div>
                    <div className="action">
                        <button className="btn-outline">Cancelar</button>
                        <button className="btn-yellow">Cargar</button>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default DataRevisionView
