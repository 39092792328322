// API
import React, { useEffect, useState, useContext } from 'react'
// Context
import { SubjectContext } from '../../../context/SubjectContext'
import { UserContext } from '../../../context/UserContext'
// Hooks
import { useTranslation } from 'react-i18next'
// Components
import TeamDetailView from './TeamDetailView'
import LoadingPage from '../../../pages/Other/LoadingPage'
import dayjs from 'dayjs'
import { UIContext } from '../../../context/UIContext'
import { ROLES } from '../../../constants'
import { CourseOptions } from '../../../hooks/ApiHooks/useCourse'
import { MeetingService } from '../../../hooks/ApiHooks/useMeetings'

const teamStats = {
    presentes: 12,
    tardanzas: 4,
    ausentes: 3,
}

// TODO: Prettify
const csvContentFromAsignation = (assignation) =>
    'Pre-assign Room Name,Email Address\n' +
    assignation
        ?.map((table) => table.students.map((student) => `${table.tableNumber},${student.email}`))
        .flat()
        .join('\n')

const TeamDetailContainer = () => {
    // State
    const [ready, setReady] = useState(false)
    const {
        shareUrl,
        professorScheduleId,
        subject,
        facilitators,
        assignation,
        setAssignation,
        course,
        hq,
        unassigments,
        nextAssignation,
        setVoteTable,
        joinMeeting,
        options,
        visibleAssign,
        meeting,
        setMeeting,
    } = useContext(SubjectContext)

    const { role } = useContext(UserContext)
    const { setInfoBlock } = useContext(UIContext)
    const { t } = useTranslation(['teamManager', 'errors'])
    const [tableInfo] = useState({ loaded: false, error: false, errorMessage: '' })

    const mainLinks = [
        {
            name: t('teamManager:main-links.room'),
            url: `/team/${professorScheduleId}/subject/${subject?.id}/`,
            active: true,
        },
        {
            name: t('teamManager:main-links.students'),
            url: `/team/${professorScheduleId}/subject/${subject?.id}/manager`,
        },
    ]

    if ([ROLES.DIRECTOR].includes(role) && options?.[CourseOptions.DisplayStats]) {
        mainLinks.push({
            name: t('teamManager:main-links.stats'),
            url: `/team/${professorScheduleId}/subject/${subject?.id}/hca`,
        })
    }
    if ([ROLES.COACH, ROLES.DIRECTOR].includes(role)) {
        mainLinks.push({
            name: t('teamManager:main-links.settings'),
            url: `/team/${professorScheduleId}/subject/${subject?.id}/settings`,
        })
    }

    const onList = (assignation, filename) => () => {
        const csvContent = csvContentFromAsignation(assignation)

        const DOMBinding = document.createElement('a')
        DOMBinding.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(csvContent)
        )
        DOMBinding.setAttribute('download', filename)

        DOMBinding.style.display = 'none'
        document.body.appendChild(DOMBinding)

        DOMBinding.click()

        document.body.removeChild(DOMBinding)
    }

    const launchAlgorithm = (...args) =>
        setAssignation
            .launch(...args)
            .then(() => {
                setInfoBlock(['success', t('teamDetailTeacher:messages.algorithm-success')])
                setTimeout(() => setInfoBlock([]), 2500)
            })
            .catch((error) => {
                setInfoBlock([
                    'error',
                    error.message || (error.data && error.data.message) || t('errors:genericRetry'),
                ])
                setTimeout(() => setInfoBlock([]), 2500)
            })

    const onVoteTable = (...args) =>
        setVoteTable(...args).catch((error) => {
            setInfoBlock([
                'error',
                error.message || (error.data && error.data.message) || t('errors:genericRetry'),
            ])
            setTimeout(() => setInfoBlock([]), 2500)
        })

    useEffect(() => {
        setReady(assignation !== undefined && unassigments !== undefined)
    }, [assignation, unassigments])

    if (!ready) {
        return <LoadingPage />
    }

    return (
        <TeamDetailView
            mainLinks={mainLinks}
            stats={teamStats}
            professorScheduleId={professorScheduleId}
            classId={course.id}
            subjectId={subject.id}
            subjectName={subject && subject.name ? subject.name : ''}
            hqName={hq && hq.name ? hq.name : ''}
            visibleAssign={visibleAssign}
            courseName={course && course.name ? course.name : ''}
            shareUrl={shareUrl}
            tableInfo={tableInfo}
            tables={assignation}
            facilitators={facilitators}
            launchAlgorithm={launchAlgorithm}
            zoom={{
                onList: onList(
                    assignation,
                    `zoom_${course?.name}_${dayjs().format('YY-MM-DD')}.csv`
                ),
                onMeeting: joinMeeting,
                disabledMeeting: false,
                next: {
                    onList: onList(nextAssignation, 'next.csv'),
                },
            }}
            meeting={meeting}
            onSetAutoMeeting={() => setMeeting(MeetingService.JITSI, 'automatic')}
            exploreMeetingsUrl={'/'}
            onVoteTable={onVoteTable}
            nextAssignation={nextAssignation}
            options={options}
        />
    )
}

export default TeamDetailContainer
