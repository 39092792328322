import React, { useContext } from 'react'
import styled from 'styled-components'
import CircleProgressBar from '../../components/Styled/CircleProgressBar'
import { colors } from '../../styles/globals/_variables'
import LeadersBlock from '../../components/Styled/LeadersBlock'
import StatsBlock from '../../components/Styled/StatsBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIContext } from '../../context/UIContext'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { AttendanceStatus } from '../../hooks/ApiHooks/useAttendance'

const HeadingStudent = ({
    progress,
    goal,
    stats,
    owners,
    meeting,
    onMeeting,
    page,
    tableNumber,
    isVirtual,
    attendance,
    markAttendance,
    unassigned,
    enableMeetingUnassigned,
    meetingEnabled,
}) => {
    const { titleCaseFn } = useContext(UIContext)
    const { t } = useTranslation(['teamDetailStudent'])
    const getAttendanceElms = () => {
        switch (attendance) {
            case AttendanceStatus.UNSET:
                return (
                    <BtnAttend
                        attendance={attendance}
                        onClick={markAttendance}
                        title="Marcar presente"
                    >
                        <div>
                            <FontAwesomeIcon className="icon" icon={['fal', 'hand-paper']} />
                            <span>Dar presente</span> {/* Present (en translation) */}
                        </div>
                    </BtnAttend>
                )
            case AttendanceStatus.PRESENT:
                return (
                    <BtnAttend
                        disabled
                        attendance={attendance}
                        onClick={markAttendance}
                        title="Marcar presente"
                    >
                        <div>
                            <FontAwesomeIcon className="icon" icon={['fal', 'check-circle']} />
                            <span>Presente</span>
                        </div>
                    </BtnAttend>
                )
            case AttendanceStatus.DELAY:
                return (
                    <BtnAttend
                        disabled
                        attendance={attendance}
                        onClick={markAttendance}
                        title="Marcar presente"
                    >
                        <div>
                            <FontAwesomeIcon className="icon" icon={['fal', 'clock']} />
                            <span>Tardanza</span>
                        </div>
                    </BtnAttend>
                )
            case AttendanceStatus.ABSENT:
                return (
                    <BtnAttend
                        disabled
                        attendance={attendance}
                        onClick={markAttendance}
                        title="Marcar presente"
                    >
                        <div>
                            <FontAwesomeIcon className="icon" icon={['fal', 'times-circle']} />
                            <span>Ausente</span>
                        </div>
                    </BtnAttend>
                )
        }
    }

    // Enables meeting button only if
    // - Team is set up to work as virtual
    // - User is assigned or in case is unassigned, enableMeetingUnassigned setting is set up to true
    // - Is in class (between start and end of class)
    const enableMeetingButton = () => {
        if (meetingEnabled()) {
            return true
        }
    }

    const showMeetingButton = () => {
        if (isVirtual) {
            if (!unassigned || enableMeetingUnassigned) {
                if (meeting?.general) {
                    return true
                }
                // Show button in automatic zoom
                if (meeting?.start_url) {
                    return true
                }
            }
        }
    }

    return (
        <Heading>
            <div className="left">
                <div className="ring-container">
                    {progress && (
                        <CircleProgressBar
                            trailStrokeColor="#F9F1C9"
                            strokeColor={colors.eggColor}
                            percentage={progress}
                        />
                    )}
                </div>
                <h3>{titleCaseFn(goal)?.substring(0, 20)}</h3>
            </div>
            <div className="right">
                {page !== 'team' && <StatsBlock stats={stats} />}
                <LeadersBlock owners={owners} />
                {showMeetingButton() && (
                    <MeetingIcon
                        active
                        className="meeting-icon-main"
                        disabled={!enableMeetingButton()}
                        meeting={meeting}
                        onClick={() =>
                            onMeeting &&
                            onMeeting(
                                tableNumber && meeting.service === 'zoom_list'
                                    ? tableNumber
                                    : undefined
                            )
                        }
                    >
                        <FontAwesomeIcon className="icon" icon={['fal', 'video']} />
                        <span>
                            {meeting
                                ? t('teamDetailStudent:header.virtualClass')
                                : t('teamDetailStudent:header.no-configured')}
                        </span>
                        <div className="active-meeting">
                            <FontAwesomeIcon className="circle-white" icon={['fas', 'circle']} />
                            {enableMeetingButton() ? (
                                <FontAwesomeIcon className="circle" icon={['fas', 'circle']} />
                            ) : (
                                <FontAwesomeIcon
                                    className="circle-disabled"
                                    icon={['fas', 'circle']}
                                />
                            )}
                        </div>
                    </MeetingIcon>
                )}
                {!isVirtual && getAttendanceElms()}
            </div>
        </Heading>
    )
}

export default HeadingStudent

HeadingStudent.propTypes = {
    progress: PropTypes.number,
    goal: PropTypes.string,
    stats: PropTypes.object,
    owners: PropTypes.array,
    meeting: PropTypes.object,
    onMeeting: PropTypes.func,
    page: PropTypes.string,
    tableNumber: PropTypes.number,
    isVirtual: PropTypes.bool,
    attendance: PropTypes.string,
    markAttendance: PropTypes.func,
    unassigned: PropTypes.bool,
    enableMeetingUnassigned: PropTypes.bool,
    meetingEnabled: PropTypes.func,
}

const Heading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: -14px;
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 0px;
        }
        .ring-container {
            width: 90px;
            margin: 0;
            padding: 0;
            figure {
                margin: 0;
            }
            @media screen and (min-width: 768px) {
                margin-right: 20px;
            }
        }
    }
    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: flex-start;
        }
    }
`

const MeetingIcon = styled.button`
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background-color: white;
    border: 1px solid ${colors.borders};
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    position: relative;
    @media screen and (min-width: 768px) {
        margin-top: 0px;
        display: flex;
        margin-left: 15px;
        margin-bottom: 0px;
    }
    .icon {
        font-size: 1.4rem;
    }
    span {
        margin-left: 10px;
        font-weight: bold;
    }
    &:hover {
        cursor: pointer;
    }
    &:disabled {
        cursor: default;
        box-shadow: none;
    }
    .active-meeting {
        position: absolute;
        top: 12px;
        left: 15px;
        z-index: 1;
        /* font-size:0.6rem;
			border:2px solid white; */
        .circle-white {
            position: absolute;
            top: 3px;
            left: 0;
            color: white;
            font-size: 11px;
            z-index: -1;
            visibility: ${(props) => (props.meeting ? 'visible' : 'hidden')};
        }
        .circle-disabled {
            color: gray;
            font-size: 8px;
            z-index: 200;
            position: absolute;
            top: 3px;
            left: 0;
            visibility: ${(props) => (props.meeting ? 'visible' : 'hidden')};
        }
        .circle {
            color: #4285f4;
            font-size: 8px;
            z-index: 200;
            position: absolute;
            top: 3px;
            left: 0;
            visibility: ${(props) => (props.meeting ? 'visible' : 'hidden')};
        }
    }
`

const handleAttendanceBg = (value) => {
    switch (value) {
        case AttendanceStatus.UNSET:
            return 'white'
        case AttendanceStatus.PRESENT:
            return colors.eggColor
        case AttendanceStatus.DELAY:
            return '#66CCFF'
        case AttendanceStatus.ABSENT:
            return 'rgba(253,115,115,0.9);'
    }
}

export const BtnAttend = styled(MeetingIcon)`
    div {
        display: flex;
        align-items: center;
    }
    &:hover {
        opacity: 0.6;
    }
    &:disabled {
        &:hover {
            opacity: 1;
        }
    }
    background-color: ${({ attendance }) => handleAttendanceBg(attendance)};
`
