// API
import React, { useContext, useState } from 'react'
import loadImage from 'blueimp-load-image'
import imageCompression from 'browser-image-compression'
// Context
import { UserContext } from '../../context/UserContext'
import { UIContext } from '../../context/UIContext'
// Components
import ProfilePageView, { ProfileViewProps } from './ProfilePageView'
import { useTranslation } from 'react-i18next'

const ProfilePageContainer = () => {
    const { t } = useTranslation()
    const { user, setUser, handleUploadPhoto, imageIsLoading, setImageIsLoading } = useContext(
        UserContext
    )
    const [isEditable, setIsEditable] = useState(false)
    const { setInfoBlock } = useContext(UIContext)
    const handleFileSelect: ProfileViewProps['handleFileSelect'] = (event) => {
        // Acá empieza la compresión de la img
        const selectedFile = event.target.files?.[0]
        if (!selectedFile) return
        // console.log(selectedFile)
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 600,
            useWebWorker: true,
        }

        imageCompression(selectedFile, options)
            .then((compressedFile) => {
                // Acá empieza la rotación de la imagen
                loadImage(
                    compressedFile,
                    (img) => {
                        // Acá paso el canvas que devuelve la fn anterior a blob y la guardo en state
                        const canvas = img

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        canvas?.toBlob?.(async (blob: Blob) => {
                            // Do something with the blob object,
                            setImageIsLoading(true)
                            if (user?._id) await handleUploadPhoto(blob, user._id)
                            // setFileToUpload(fileToUpload)
                        }, 'image/jpeg')
                    },
                    {
                        maxWidth: 452,
                        maxHeight: 452,
                        crop: true,
                        contain: true,
                        meta: true,
                        orientation: true,
                    }
                )
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error)
                setInfoBlock([
                    'error',
                    error.message || error.data?.message || t('errors:genericRetry'),
                ])
                setTimeout(() => setInfoBlock([]), 2500)
            })
    }

    return (
        <div>
            <ProfilePageView
                user={user}
                onEdit={setUser}
                handleFileSelect={handleFileSelect}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                imageIsLoading={imageIsLoading}
            />
        </div>
    )
}

export default ProfilePageContainer
