import Axios from 'axios'
import DayJS from '../../helpers/DayJS'

export const apiUrlStats = `${process.env.REACT_APP_REPORTS}`

const tz = DayJS.tz.guess()

export const ApiReports = Axios.create({
    baseURL: apiUrlStats,
    headers: { tz },
})

export const getCohorts = (courseId: string) =>
    ApiReports.get<GetCohortsResponse>(`v1/dashboard/cohorts/list/${courseId}`).then(
        ({ data }) => data.data
    )

export const getUsersGraph = ({ courseId, cohorts }: BasicProps) =>
    ApiReports.get<GetUsersGraphResponse>(
        `v1/dashboard/cohorts/graphs/${courseId}?cohorts=${cohorts}`
    ).then(({ data }) => data.data)

export const getUsersCsv = ({ courseId, cohorts }: BasicProps) =>
    ApiReports.get(`v1/dashboard/cohorts/csv/${courseId}?cohorts=${cohorts}`).then((data) => data)

export const getAttendanceGraph = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get<GetAttendanceGraphResponse>(
        `v1/dashboard/attendances/graphs/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then(({ data }) => data.data)

export const getAttendanceCsv = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get(
        `v1/dashboard/attendances/csv/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then((data) => data)

export const getParticipationGraph = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get<GetParticipationGraphResponse>(
        `v1/dashboard/participation/graphs/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then(({ data }) => data.data)

export const getParticipationCsv = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get(
        `v1/dashboard/participation/csv/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then((data) => data)

export const getAudioParticipationCsv = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get(
        `v1/dashboard/participation/audio/csv/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then((data) => data)

export const getValuationGraph = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get<GetValuationGraphResponse>(
        `v1/dashboard/assessment/feedback/graphs/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then(({ data }) => data.data)

export const getValuationCsv = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get(
        `v1/dashboard/assessment/feedback/csv/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then((data) => data)

export const getValuationComments = ({ courseId, cohorts, date, score }: ValuationProps) =>
    ApiReports.get<GetCSATCommentsResponse>(
        `v1/dashboard/assessment/feedback/graphs/tables/${courseId}?cohorts=${cohorts}&dates=${date}&scores=${score}`
    ).then(({ data }) => data.data.commentTableGraph)

export const getPulsesGraph = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get<GetPulsesGraphResponse>(
        `v1/dashboard/pulses/utilization/graphs/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then(({ data }) => data.data)

export const getPulsesCsv = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get(
        `v1/dashboard/pulses/utilization/csv/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then((data) => data)

export const getPulsesRanking = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get<GetPulsesRankingResponse>(
        `v1/dashboard/pulses/ranking/graphs/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then(({ data }) => data.data)

export const getNPSGraph = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get<GetNPSGraphResponse>(
        `v1/dashboard/assessment/nps/graphs/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then(({ data }) => data.data)

export const getNPSCsv = ({ courseId, cohorts, from, to }: DateFilterProps) =>
    ApiReports.get(
        `v1/dashboard/assessment/nps/csv/${courseId}?cohorts=${cohorts}&from=${from}&to=${to}`
    ).then((data) => data)

export type BasicProps = {
    courseId: string
    cohorts: string[]
}
export type DateFilterProps = {
    from?: string
    to?: string
} & BasicProps

export type ValuationProps = {
    date: string[]
    score: string[]
} & BasicProps

export interface API_RESPONSE<TData> {
    message: string
    code: 200 | 401 | 403 | 404 | 409 | 500
    success: boolean
    data: TData
}
type GetCohortsResponse = API_RESPONSE<CohortsData>
type GetUsersGraphResponse = API_RESPONSE<UsersData>
type GetAttendanceGraphResponse = API_RESPONSE<AttendanceData>
type GetParticipationGraphResponse = API_RESPONSE<ParticipationData>
type GetValuationGraphResponse = API_RESPONSE<ValuationData>
type GetPulsesGraphResponse = API_RESPONSE<PulsesData>
type GetPulsesRankingResponse = API_RESPONSE<PulsesRakingData[]>
type GetCSATCommentsResponse = API_RESPONSE<CSATComments>
type GetNPSGraphResponse = API_RESPONSE<NPSData>

export interface CohortsData {
    cohorts: string[]
    lastMigrationDate: string
}
export interface UsersData {
    activeDataChart: (number | string)[][]
    enrolledChart: (number | string)[][]
}
interface AttendanceData {
    barChart: (number | string)[][]
    totalPieChart: (number | string)[][]
}
interface ParticipationData {
    lineChart: (null | number | string | string | string)[][]
    audioLineChart: (null | number | string | string | string)[][]
}

interface ValuationData {
    csatBarChart: (number | string)[][]
    pieChart: (number | string)[][]
    scoreBarChart: (number | string)[][]
}
interface PulsesData {
    barChart: (number | string)[][]
    totalPulses: number
    totalPulsesBarChart: (number | string)[][]
}
interface PulsesRakingData {
    position: number
    email: string
    pulsesIn: number
    urlImage: string
    displayName: string
}

interface CSATComments {
    commentTableGraph: (number | string)[][]
}
interface NPSData {
    cohortSize: number
    detractors: number
    detractorsPercentage: number
    nps: number
    passives: number
    passivesPercentage: number
    promoters: number
    promotersPercentage: number
    totalResponses: number
}
