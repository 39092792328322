import React, { useEffect, useState } from 'react'
import Styles from './ShowSearchResult.module.css'
import { useTranslation } from 'react-i18next'
import { iGetMentorsProps, iSearchParsedResult } from '../TeamManagerContainer'
import UserItem from './UserItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TeamHeader } from './TeamHeader'
import { StudentType } from '../../../../hooks/ApiHooks/useAssignation'
import { useUIContext } from '../../../../context/UIContext'

export const ShowSearchResult = ({ data, showMentors }: iProps) => {
    const { searchBox } = useUIContext()
    const [listExpanded, setListExpanded] = useState(true)
    const { t } = useTranslation(['teamManager'])

    useEffect(() => {
        setListExpanded(!data?.team?.isMentorTable)
    }, [data])

    const toggleExpand = () => setListExpanded((prev) => !prev)

    if (!data) return null
    return (
        <section className={Styles.section}>
            <header className={Styles.mainHeader}>
                <h3>{t('teamManager:search.results-title')}</h3>
                <button className={Styles.btnClearResult} onClick={() => searchBox[1](null)}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                </button>
            </header>
            <div className={Styles.results}>
                {data.team?.table && (
                    <TeamHeader
                        teamNumber={data.team?.table}
                        isProgressive={data.team.type === 'progressive'}
                        isMentorTeam={data.team.isMentorTable}
                        guide={data.team.guide || undefined}
                        hasVote={true}
                        isExpanded={listExpanded}
                        toggleExpand={toggleExpand}
                        showMentors={showMentors}
                    />
                )}
                {
                    // People / Team list
                    listExpanded &&
                        data.people.map((item) => (
                            <UserItem
                                key={item._profileId}
                                student={(item as unknown) as StudentType}
                                isAssigned={true}
                                searchAssigned={item.searchAssigned}
                            />
                        ))
                }
                {
                    // People / Team list
                    listExpanded && !data.people.length && <h4>No se encontraron resultados</h4>
                }
            </div>
        </section>
    )
}

interface iProps {
    data: iSearchParsedResult | null
    showMentors: (
        team: iGetMentorsProps['team'],
        guide: iGetMentorsProps['guide'],
        algorithm?: iGetMentorsProps['algorithm'] | undefined
    ) => void | ''
}
