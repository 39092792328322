// TODO Reveer todo esto, está demasiado complicado.
import React, { useState, createContext, useEffect, useContext, useCallback } from 'react'
import { UIContext } from './UIContext'
import { UserContext } from './UserContext'
import { useRubric, RubricItem, RubricSaveType, QuestionType } from '../hooks/ApiHooks/useRubric'
import { useShareLink, ShareLinkMap } from '../hooks/ApiHooks/useSharelink'
import {
    useAssignation,
    StudentType,
    AlgorithmLaunchType,
    TableType,
} from '../hooks/ApiHooks/useAssignation'
import {
    useAttendance,
    AttendanceItem,
    AttendanceSaveType,
    SummaryType,
} from '../hooks/ApiHooks/useAttendance'
import { useSchedule, ScheduleItem, ScheduleSaveType } from '../hooks/ApiHooks/useSchedules'
import { useTablesVote, VoteTablesFunction } from '../hooks/ApiHooks/useTableVote'
import { useStats } from '../hooks/ApiHooks/useStats'
import { useMeetings, Meeting, MeetingService } from '../hooks/ApiHooks/useMeetings'
import { StudentUser, MembersMap } from '../hooks/ApiHooks/useCourseMembers'
import useVoteList from '../hooks/ApiHooks/useVoteList'
import NotFoundPage from '../pages/Other/NotFoundPage'
import useCourse, { CourseOptions, CourseOptionsTypes } from '../hooks/ApiHooks/useCourse'
import { ROLES } from '../constants'
import { capitalize } from '../helpers/formatter'
import JitsiView from '../components/Jitsi/JitsiView'

export interface AssignationItem extends StudentType {
    attendance?: AttendanceItem
    rubric?: RubricItem
    hasVoted?: boolean
}

export interface TableItem extends TableType {
    egged: boolean
    students: AssignationItem[]
    tableNumber: number
    meetingLink?: string
}

export interface ContextInterface {
    hq: { id: string; name: string } | undefined
    course: { id: string; name: string; isProgressive: boolean } | undefined
    setCourse: ReturnType<typeof useSchedule>[6] // Que hay en esta pos?
    setSubject: ReturnType<typeof useSchedule>[7] // Que hay en esta pos?
    options: CourseOptionsTypes | undefined
    subject: { id: string; name: string } | undefined
    professorSchedule: { id: string } | undefined
    teamsNumbers: number[]
    profile: { id: string } | undefined
    assignation: TableItem[] | null | undefined
    assignationCount: number | undefined
    members: MembersMap | null | undefined
    unassigments: StudentUser[] | null | undefined
    facilitators: StudentType[] | null | undefined
    professorScheduleId: string
    schedules: ScheduleItem[] | null | undefined
    attendance: {
        (uid: string): AttendanceItem | undefined
        summary: SummaryType | null | undefined
    }
    shareUrl: ShareLinkMap | null | undefined
    meeting: Meeting | null | undefined
    algorithmId: string | undefined
    // stats?: { timesFacilitator: number, votes: number } | null,

    setSchedules: ScheduleSaveType
    setAttendance: AttendanceSaveType
    setRubric: (studentId: string, value: number) => ReturnType<RubricSaveType>
    rubricQuestions: QuestionType[] | null | undefined
    setAssignation: AlgorithmLaunchType | undefined
    setMeeting: ReturnType<typeof useMeetings>[1]
    joinMeeting: ReturnType<typeof useMeetings>[2]
    clearMeeting: ReturnType<typeof useMeetings>[7]
    setOptions: ReturnType<typeof useCourse>['setOptions']
    setMembers: ReturnType<typeof useCourse>['setMembers']
    setVoteTable: VoteTablesFunction

    fetchAssignation: (page: number) => void
    totalPages: number
    totalTables: number | undefined
    unassigned: StudentType[] | undefined | null
    fetchUnassigned: (page: number) => void
    totalUnassigned: number | undefined
    unassignedCount: number | undefined
    visibleAssign: number | undefined
}

// Create Context Object
export const SubjectContext = createContext<Partial<ContextInterface>>({})
SubjectContext.displayName = 'SubjectContext'

// Create a provider for components to consume and subscribe to changes
export const SubjectContextProvider = (props: {
    professorSchedule: string
    subject: string
    children: React.ReactNode
}) => {
    const { setInfoBlock } = useContext(UIContext)
    const { profiles, role } = useContext(UserContext)
    const { professorSchedule: _professorScheduleId, subject: _subjectId } = props

    /** Defining the generic onError function */
    const onError = useCallback(
        (error, timeout = 3000) => {
            // eslint-disable-next-line no-console
            console.error(error)
            if (process.env.REACT_APP_DEBUG_MODE) {
                setInfoBlock([
                    'error',
                    error.message ||
                        (error.data && error.data.message) ||
                        'Ups! Ocurrió un error. Intenta nuevamente.',
                ])
                setTimeout(() => setInfoBlock([]), timeout)
            }
            throw error
        },
        [setInfoBlock]
    )

    const [_profileId, setProfileId] = useState<string>('')

    const [schedules, setSchedules, hq, course, subject, , setCourse, setSubject] = useSchedule(
        _professorScheduleId,
        _subjectId,
        onError
    )

    const [completeAssignation, setCompleteAssignation] = useState<TableItem[] | null | undefined>()

    const [today] = useState<Date>(new Date())
    const [selectedRubric, setSelectedRubric] = useState<string>('')

    const [
        assignation,
        setAssignation,
        algorithmId,
        fetchAssignation,
        totalPages,
        totalTables,
        unassigned,
        fetchUnassigned,
        totalUnassigned,
        unassignedCount,
        assignationCount,
        isProgressive,
        teamsNumbers,
        // eslint-disable-next-line no-console
    ] = useAssignation('professor', course?.id ?? '', console.error)

    const [votesTable, setVoteTable] = useTablesVote(algorithmId, onError)

    const shareUrl = useShareLink(course?.id ?? '', onError)

    const [attendance, summaryAttendance, setAttendance] = useAttendance(
        _professorScheduleId,
        _subjectId,
        today,
        onError
    )

    const { members, setMembers, options, setOptions } = useCourse(course?.id, onError)

    const [
        meeting,
        setMeeting,
        joinMeeting,
        ,
        meetingModalShow,
        setMeetingModalShow,
        ,
        clearMeeting,
    ] = useMeetings(_professorScheduleId, _subjectId, _profileId, course?.id, role, onError)

    const [rubric, setRubric, rubricQuestions] = useRubric(
        _professorScheduleId,
        _subjectId,
        onError
    )

    const [stats] = useStats(_professorScheduleId, _subjectId, onError)

    const votes = useVoteList(algorithmId)

    const [visibleAssign, setVisibleAssign] = useState<number>(0)

    useEffect(() => {
        if (options?.[CourseOptions.ReassignPresents]) {
            if (attendance) {
                let countPresent = 0
                unassigned?.forEach((unassignedElement) => {
                    if (attendance[unassignedElement?._profileId || '']?.status == 'present')
                        countPresent++
                })
                setVisibleAssign(countPresent)
            }
        }
    }, [attendance, options, unassigned])

    useEffect(() => {
        /**  Since directors doesnt have a specific course. course.id is undefined
         *   but profile.id is defined. So it was returning before setting profile Id,
         *   fetchMeetings uses profile id to get meetings.
         * */
        if (role !== ROLES.DIRECTOR) {
            if (!course?.id) return
        }
        const selectedProfile = profiles?.find(
            (profile) => profile?.course?._id === course?.id && role === profile.role
        )
        if (selectedProfile && selectedProfile._profileId !== _profileId) {
            // console.log('Your profile for this subject is: ', selectedProfile)
            setProfileId(selectedProfile._profileId)
        }
    }, [profiles, course, role, _profileId])

    useEffect(() => {
        if (
            !selectedRubric ||
            !rubricQuestions?.some((question) => question?._id === selectedRubric)
        )
            if (rubricQuestions?.length) setSelectedRubric(rubricQuestions[0]._id ?? '')
    }, [rubricQuestions, selectedRubric])

    /** This appends the rubric and the attendance to the assignation. Useful to the render process */
    useEffect(
        function mergeAssignationField() {
            if (assignation === null) setCompleteAssignation(null)
            else
                setCompleteAssignation(
                    assignation?.map((table, i) => {
                        const tableNumber = table.tableNumber
                        const egged = votesTable?.[tableNumber] ?? false
                        const meetingLink =
                            meeting?.service === 'zoom_list' || meeting?.service === 'jitsi'
                                ? meeting.rooms?.[i]
                                : undefined
                        const students = table.students
                            // Beware of this, it is a comparison between two arrays O(n^2)
                            // .filter(student => members?.student?.find(member => member._profileId === student._profileId))
                            .map((student) => ({
                                ...student,
                                attendance: attendance?.[student._profileId],
                                rubric: rubric?.[student._profileId]?.[selectedRubric],
                                stats: stats?.[student._profileId],
                                hasVoted: !!votes?.tables
                                    // As the tables are not sorted, I should find the corresponding table
                                    // I could sort it but I may have some problems with initial table
                                    .find((table) => table.tableNumber === tableNumber)
                                    ?.studentsVote // Once I find the correct table I should find if the student vote
                                    .find(({ _profileId }) => student._profileId === _profileId),
                            }))
                        return { ...table, egged, meetingLink, tableNumber, students }
                    })
                )
        },
        [
            rubric,
            selectedRubric,
            attendance,
            assignation,
            votesTable,
            stats,
            meeting,
            votes,
            members,
        ]
    )

    const setOptionsWrapper: typeof setOptions = async (option, arg) => {
        const value = await setOptions(option, arg)
        switch (option) {
            case CourseOptions.InitialTable: {
                const initial = arg as number
                setAssignation.tableNumbers(
                    [...Array(assignation?.length)].map((_, i) => initial + i)
                )
            }
        }
        return value
    }
    function getAttendance(uid: string) {
        return attendance?.[uid]
    }
    getAttendance.summary = summaryAttendance

    const value: Required<ContextInterface> = {
        hq,
        course: course ? { ...course, isProgressive } : undefined, // Add if course is progressive
        options: options,
        subject,
        setCourse,
        setSubject,
        professorSchedule: { id: _professorScheduleId },
        professorScheduleId: _professorScheduleId,
        // Students tables
        teamsNumbers,
        assignation: completeAssignation,
        assignationCount,
        fetchAssignation,
        // Students who are not yet assigned
        unassigned,
        fetchUnassigned,
        totalUnassigned,
        unassignedCount,
        totalPages,
        totalTables,
        members,
        unassigments: members?.student.filter(
            (student) =>
                !assignation?.some((table) =>
                    table.students.some((s) => s._profileId === student._profileId)
                )
        ),
        facilitators: assignation?.facilitators,
        attendance: getAttendance,
        schedules,
        shareUrl,
        algorithmId,
        setSchedules,
        setAttendance,
        setOptions: setOptionsWrapper,
        setRubric: (studentId: string, value: number) =>
            setRubric(selectedRubric, studentId, value),
        rubricQuestions: rubricQuestions,
        setAssignation,
        setMeeting,
        clearMeeting,
        setVoteTable,
        joinMeeting,
        setMembers,
        meeting,
        profile: { id: _profileId },
        visibleAssign,
    }

    return (
        <SubjectContext.Provider value={value}>
            {schedules === null ? <NotFoundPage /> : props.children}
            {course &&
                subject &&
                typeof meetingModalShow === 'number' &&
                meeting?.service === MeetingService.JITSI &&
                meeting.method === 'automatic' && (
                    <JitsiView
                        onClose={() => setMeetingModalShow(false)}
                        roomId={`${_professorScheduleId}-${meetingModalShow}-Egg`}
                        password={algorithmId}
                        roomName={capitalize(
                            `${course?.name} - ${subject?.name} - ${meetingModalShow}`
                        )}
                    />
                )}
        </SubjectContext.Provider>
    )
}
