import styled, { css } from 'styled-components'
import { colors } from '../../styles/globals/_variables'

type IEggCard = {
    centeredContent?: boolean
    fullHeight?: boolean
    colChildren?: boolean
    block?: boolean
    glass?: boolean
    show?: boolean
}

const EggCard = styled.div<IEggCard>`
    padding: 20px;
    background: white;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    ${(props) =>
        props.centeredContent &&
        css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        `}
    ${(props) =>
        props.fullHeight &&
        css`
            min-height: 100%;
        `}
    .table-container {
        width: 90%;
        height: 90%;
        padding: 70px 0;
        @media screen and (min-width: 768px) {
            width: 60%;
            height: 60%;
            padding: 70px 0 50px;
        }
    }
    ${(props) =>
        props.colChildren &&
        css`
            overflow: hidden;
            min-height: 170px;
            @media screen and (min-width: 768px) {
                flex: 1 1 0;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            &:first-child {
                margin-bottom: 30px;
                @media screen and (min-width: 768px) {
                    margin-right: 15px;
                    margin-bottom: 0px;
                }
            }
            &:last-child {
                @media screen and (min-width: 768px) {
                    margin-left: 15px;
                }
            }
        `}
    ${(props) =>
        props.block &&
        css`
            display: block;
        `}
    ${(props) =>
        props.glass &&
        css`
            background-color: #ffffff60;
            backdrop-filter: blur(5px);
        `}
    .content-no-assignation {
        .table-empty-msg {
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 40%;
            }
            text-align: center;
            padding: 20px;
            .empty-icon {
                color: ${colors.eggColor};
                font-size: 4rem;
                margin-bottom: 20px;
            }
            span {
                display: block;
            }
            .first-line {
                font-size: 1.4rem;
                font-weight: bold !important;
            }
            .second-line {
                font-size: 1.4rem;
                line-height: 1.8rem;
                margin-top: 5px;
            }
        }
    }
`

export default EggCard
