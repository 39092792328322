import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import AvatarEgg from '../../../../components/Styled/AvatarEgg'
import UIContext from '../../../../context/UIContext'
import { useProfile } from '../../Hooks/useProfile'

export const StudentProfile = ({ profileId }: { profileId: string }) => {
    const { titleCaseFn, closeModal } = useContext(UIContext)
    const { profile, isLoading } = useProfile(profileId)
    if (isLoading)
        return (
            <div
                style={{
                    height: '270px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <FontAwesomeIcon icon="spinner" spin />
            </div>
        )
    if (!profile) return null
    const { urlImage, name, email } = profile
    return (
        <div className="container profile-detail-container">
            <div className="student-heading-container">
                <button
                    style={{
                        border: 0,
                        background: 0,
                        width: '100%',
                        justifyContent: 'flex-end',
                        fontSize: '1.5rem',
                        marginBottom: '1rem',
                    }}
                    onClick={closeModal}
                >
                    <FontAwesomeIcon icon={['far', 'times']} />
                </button>
                <div className="student-data responsive">
                    <div className="student-info">
                        <div className="avatar-container">
                            <AvatarEgg src={urlImage} size={{ mobile: 180, desktop: 210 }} />
                        </div>
                        <div className="rest-info">
                            <h2>{titleCaseFn(name)}</h2>
                            <p className="email">{email}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
