/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react'
import StudentDetailView from './StudentDetailView'
import Axios from 'axios'
import Config from '../../../config'
import LoadingPage from '../../Other/LoadingPage'
import { UIContext } from '../../../context/UIContext'
import { UserContext } from '../../../context/UserContext'

const StudentDetailContainer = (props) => {
    const {
        profile: _profileId,
        professorSchedule: _professorScheduleId,
        subject: _subjectId,
    } = props

    const [student, setStudent] = useState({})
    const [stats, setStats] = useState()
    const [ready, setReady] = useState(true)

    const { setInfoBlock } = useContext(UIContext)
    const { role } = useContext(UserContext)

    useEffect(() => {
        const fetchStudent = async () => {
            try {
                setReady(false)

                // Gets student data
                const response = await Axios.get(Config.API + '/profile/' + _profileId)
                const { user } = response.data
                setStudent(user)
            } catch (error) {
                setInfoBlock(['error', 'Ups! Ocurrió un error al obtener los datos del alumno.'])
            } finally {
                setReady(true)
                setTimeout(() => {
                    setInfoBlock([])
                }, 4000)
            }
        }

        const fetchStats = async () => {
            try {
                const { data } = await Axios.get(
                    Config.API + '/profile/' + _profileId + '/stats/',
                    {
                        params: { _professorScheduleId, _subjectId },
                    }
                )
                //await new Promise(resolve => setTimeout(resolve, 5000))
                const filteredQuestions = data.rubric.questions?.filter(
                    ({ _questionId }) => !!_questionId
                )
                if (filteredQuestions?.length) {
                    data.rubric = data.rubric.questions[0].average * 3 - 2
                } else {
                    data.rubric = null
                }
                setStats(data)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
                setStats(null)
                //setInfoBlock(['error', error.message || (error.data && error.data.message) || 'Ups! Ocurrió un error. Intenta nuevamente.'])
                //setTimeout( () => {setInfoBlock([])}, 3000)
            }
        }

        fetchStudent()
        if (role != 'student') fetchStats()
    }, [_professorScheduleId, _profileId, _subjectId, role, setInfoBlock])

    const saveRubric = async (stars) => {
        try {
            //return await new Promise(resolve => setTimeout(resolve, 2500))
            const response = await Axios.put(Config.API + '/rubric/vote', {
                _professorScheduleId,
                _subjectId,
                _questionId: stats.rubric._questionId,
                _studentId: _profileId,
                value: stars,
            })
            const newStats = { ...stats }
            newStats.rubric = response.data.vote.votes.find(
                (voting) => voting._questionId === stats.rubric._questionId
            )
            setStats(newStats)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            setInfoBlock([
                'error',
                error.message ||
                    (error.data && error.data.message) ||
                    'Ups! Ocurrió un error. Intenta nuevamente.',
            ])
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }

    const saveStudent = async (student) => {
        try {
            setStudent(student)
            await Axios.patch(Config.API + '/user/' + student._id, student)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            setInfoBlock([
                'error',
                error.message ||
                    (error.data && error.data.message) ||
                    'Ups! Ocurrió un error. Intenta nuevamente.',
            ])
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }

    if (!ready) {
        return <LoadingPage />
    }

    return (
        <StudentDetailView
            stats={stats}
            student={student}
            onRubric={role === 'professor' ? saveRubric : undefined}
            onEdit={role === 'professor' ? saveStudent : undefined}
            role={role}
        />
    )
}

export default StudentDetailContainer
