import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubjectContext } from '../../../../../context/SubjectContext'
import { MeetingService } from '../../../../../hooks/ApiHooks/useMeetings'
import { UIContext } from '../../../../../context/UIContext'
import { useForm, Validate } from 'react-hook-form'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { useGAEvents } from '../../../../../hooks/useGAEvents'
import { capitalize } from '../../../../../helpers/formatter'

export interface Props {
    service: MeetingService
    placeholder?: string
    validate?: Validate | Record<string, Validate>
}

export const MeetConfig = ({ placeholder, service, validate }: Props) => {
    const { meeting, setMeeting, totalTables } = useContext(SubjectContext)
    const { sendGA } = useGAEvents()
    const form = useForm<{ general: string; rooms: string[] }>({
        mode: 'onBlur',
    })
    const { handleSubmit, register, setValue, formState, errors } = form
    const { setInfoBlock } = useContext(UIContext)
    const { t } = useTranslation(['teamSettings'])

    useEffect(() => {
        switch (meeting?.service) {
            case MeetingService.MEET:
            case MeetingService.TEAMS:
            case MeetingService.JITSI:
            case MeetingService.ZOOM_LIST:
            case MeetingService.WHATSAPP:
                if (meeting.general) setValue('general', meeting.general)
                meeting.rooms?.forEach((room, i) => setValue(`rooms[${i}]`, room))
        }
    }, [meeting, setValue])

    const submit = handleSubmit(async ({ general, rooms }) => {
        try {
            switch (service) {
                case MeetingService.WHATSAPP:
                case MeetingService.JITSI:
                case MeetingService.ZOOM_LIST:
                case MeetingService.MEET:
                case MeetingService.TEAMS:
                    await setMeeting?.(service, general, rooms)
            }

            setInfoBlock(['success', 'Se han guardado los cambios de la reunión'])
        } catch (error) {
            setInfoBlock([
                'error',
                errorHandler(error, 'Ups! Ocurrió un error. Intenta nuevamente.'),
            ])
        } finally {
            sendGA({ category: 'Meetings', action: 'MeetingSaved', label: capitalize(service) })
            setTimeout(() => setInfoBlock([]), 2000)
        }
    })

    const getInputsList = () => {
        if (totalTables) {
            const inputs = []
            for (let i = 0; i < totalTables; i++) {
                //console.log('i', i)
                inputs.push(
                    <div className="link-item" key={i + 1}>
                        <span className="label">
                            {' '}
                            {t('teamSettings:content.virtual-classroom.meet.room-label')} {i + 1}{' '}
                        </span>
                        <div className="input">
                            <input
                                type="text"
                                className="form-input-no-card"
                                name={`rooms[${i}]`}
                                id={`rooms[${i}]`}
                                placeholder={placeholder}
                                ref={register(!validate ? undefined : { validate })}
                            />
                            {errors?.rooms?.[i]?.message && (
                                <span>{errors?.rooms?.[i]?.message}</span>
                            )}
                        </div>
                    </div>
                )
            }
            return inputs
        }
    }

    return (
        <div className="egg-list classroom-links-list">
            <form onSubmit={submit}>
                <div className="section general-links">
                    <div className="link-item">
                        <span className="label">
                            {t('teamSettings:content.virtual-classroom.meet.general-label')}
                        </span>
                        <div className="input">
                            <input
                                type="text"
                                name="general"
                                className="form-input-no-card"
                                id="general"
                                placeholder={placeholder}
                                ref={register(!validate ? undefined : { validate })}
                            />
                            {errors.general?.message && <span>{errors.general?.message}</span>}
                        </div>
                    </div>
                </div>
                <div className="section rooms-links">
                    <span className="title">
                        {t('teamSettings:content.virtual-classroom.meet.room-section-title')}
                    </span>
                    {getInputsList()}
                </div>
                <div className="action-buttons">
                    <button type="submit" className="btn-yellow" disabled={!formState.isValid}>
                        {!formState.isSubmitting ? (
                            t('teamSettings:content.virtual-classroom.meet.save')
                        ) : (
                            <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default MeetConfig
