// API
import React, { useContext, useState } from 'react'
// Context
import { UIContext } from '../../../../context/UIContext'
// Components
import AvatarEgg from '../../../../components/Styled/AvatarEgg'
import Rubric from './Actions/Rubric'
import Attendance from './Actions/Attendance'
import Stats from './Actions/Stats'
import Reassign from './Actions/Reassign'
import Exam from './Actions/Exam'
import { AssignationItem, SubjectContext } from '../../../../context/SubjectContext'
import ManagerContext, { ActionView } from '../../../../context/ManagerContext'
import UserContext from '../../../../context/UserContext'
import Link from '../../../../components/Link'
import AddAction from './Actions/AddAction'
import { useTranslation } from 'react-i18next'
import { BtnCircular, BtnCircularProps } from '../../../../components/Buttons/BtnCircular'
import ConfirmModal from '../../../../components/ConfirmModal'
import { capitalize } from '../../../../helpers/formatter'
import ReactGA from 'react-ga'
import { useTeam } from '../../../Team/Context/TeamContext'

export const ActionType = ({ type, student }: { type: ActionView; student: AssignationItem }) => {
    switch (type) {
        case ActionView.Attendance:
            return <Attendance student={student} />
        case ActionView.Rubric:
            return <Rubric student={student} />
        case ActionView.Stats:
            return <Stats student={student} />
        case ActionView.Exam:
            return <Exam student={student} />
        case ActionView.Reassign:
            return <Reassign student={student} />
        case ActionView.Add:
            return <AddAction student={student} />
        default:
            return <></>
    }
}

const UserItem = (props: UserItemProps) => {
    const { student, isAssigned, hidden, searchAssigned, canDeleted } = props
    const { titleCaseFn } = useContext(UIContext)
    const { actionView } = useContext(ManagerContext)
    const { professorScheduleId, subject } = useContext(SubjectContext)
    const { t } = useTranslation(['teamManager'])

    return (
        <div className="user-item" hidden={hidden}>
            <div className="user-info-block">
                {student?.position && (
                    <div className={`position ${!isAssigned ? 'unassigned' : ''}`}>
                        {student.position}
                    </div>
                )}
                <div className="avatar">
                    {student?._profileId ? (
                        <Link
                            to={`/team/${professorScheduleId}/subject/${subject?.id}/profile/${student?._profileId}`}
                        >
                            <AvatarEgg hasVoted={student?.hasVoted} src={student?.urlImage} />
                        </Link>
                    ) : (
                        <AvatarEgg src={student?.urlImage} />
                    )}
                </div>
                <div className="name-label">
                    {student?._profileId ? (
                        <>
                            <Link
                                className="global-text"
                                to={`/team/${professorScheduleId}/subject/${subject?.id}/profile/${student?._profileId}`}
                            >
                                {titleCaseFn(student?.name)} {titleCaseFn(student?.lastname)}
                            </Link>{' '}
                            {searchAssigned && searchAssigned === 'assigned' ? (
                                <div className="badge assigned">
                                    {t('teamManager:search.badge-team')} {student.tableNumber}
                                </div>
                            ) : searchAssigned === 'unassigned' ? (
                                <div className="badge unassigned">
                                    {t('teamManager:search.badge-unassigned')}
                                </div>
                            ) : null}{' '}
                        </>
                    ) : (
                        <span className="global-text">
                            {titleCaseFn(student?.name)} {titleCaseFn(student?.lastname)}
                        </span>
                    )}
                </div>
            </div>

            {isAssigned && (
                <div className="action-block">
                    <ActionType type={actionView} student={student} />
                </div>
            )}
            <MoreOptions
                student={student}
                canDeleted={canDeleted === undefined ? true : canDeleted}
            />
        </div>
    )
}

export default UserItem

const MoreOptions = ({
    student,
    canDeleted,
}: {
    student: UserItemProps['student']
    canDeleted?: boolean
}) => {
    const { onDelete, userItemMoreOptionState } = useContext(ManagerContext)
    const { hq, course, subject } = useContext(SubjectContext)
    const { role } = useContext(UserContext)
    const [state, setState] = userItemMoreOptionState
    const { t } = useTranslation(['teamManager'])
    const { _profileId, name, lastname } = student
    const displayName = capitalize(name + ' ' + lastname)
    const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false)
    const { team } = useTeam()

    if (!_profileId) return null

    const profileUrl = location.href.replace('manager', `profile/${_profileId}`)

    const isOpen = state === _profileId

    const handleClick = () => {
        if (!isOpen || null) setState(_profileId)
        else setState(null)
    }

    const options: BtnCircularProps['options'] = [
        {
            label: t('teamManager:user-options.profile'),
            onClick: () => (location.href = profileUrl),
        },
        {
            label: t('teamManager:user-options.delete'),
            icon: 'trash-alt',
            onClick: () => {
                setOpenModalDeleteUser(true)
                ReactGA.event({
                    category: 'Team Management',
                    action: 'DeleteStudentClicked',
                    dimension1: 'platform',
                    dimension2: hq?.name.toLowerCase(),
                    dimension3: course?.name.toLowerCase(),
                    dimension4: subject?.name.toLowerCase(),
                    dimension7: role,
                    dimension9: team?.algorithm
                        ? team?.algorithm?.algorithmType
                        : 'no algorithm available',
                })
            },
            props: {
                style: { color: '#f86969', fontWeight: 'bold' },
            },
        },
    ]

    !canDeleted && options.pop() // Remove delete user option

    return (
        <>
            <ConfirmModal
                onClose={() => {
                    setOpenModalDeleteUser(false)
                }}
                onConfirm={() => {
                    setOpenModalDeleteUser(false), onDelete(_profileId, student)
                }}
                open={openModalDeleteUser}
                type={'danger'}
                title={t('user-options.title-modal')}
                contentText={t('user-options.content-modal', { displayName })}
                confirmButtonText={t('user-options.confirm-btn-modal')}
                cancelButtonText={t('user-options.cancel-btn-modal')}
            />

            <BtnCircular
                icon="ellipsis-h"
                style={{
                    marginLeft: '0.5em',
                    width: 32,
                    height: 32,
                    fontSize: 15,
                }}
                isOpen={isOpen}
                onClick={() => handleClick()}
                options={options}
            />
        </>
    )
}

// Interfaces
interface UserItemProps {
    hidden?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    student: AssignationItem // TODO: I should give it a proper interface
    isAssigned?: boolean
    searchAssigned?: string | undefined | null
    canDeleted?: boolean
}
