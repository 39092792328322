import styled from 'styled-components'

export const BtnPulse = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;
    font-size: 100%;
    align-items: center;
    min-width: 150px;
    max-width: 150px;
    height: 38px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    @media screen and (max-width: 768px) {
    }
    &.inactive {
        &:hover {
            background: linear-gradient(to bottom, #ffffff, #eaeaea);
        }
    }
    &.active {
        color: white;
        background-color: #37474f;
    }
    img {
        height: 18px;
        @media screen and (min-width: 900px) {
            margin-right: 6px;
        }
    }
`
