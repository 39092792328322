import { Styles } from 'react-modal'

export enum ROLES {
    SUPERADMIN = 'admin',
    ADMIN = 'director',
    DIRECTOR = 'director',
    COACH = 'professor',
    PROFESSOR = 'tutor',
    STUDENT = 'student',
    TEMPORAL = 'temporal',
}
export const roles = Object.values(ROLES)
export const isProduction = location.origin === 'https://admin.egg.live'
export const isDevelopment = process.env.NODE_ENV === 'development'

export const isRoleAllowed = (minimumRole: ROLES, role?: ROLES) =>
    role && roles.indexOf(role) <= roles.indexOf(minimumRole)

export const defaultModalStyles: Styles = {
    overlay: {
        position: 'fixed',
        inset: 0,
        backgroundColor: 'var(--darkGreyEgg)',
        zIndex: 600001,
    },
    content: {
        inset: '50%',
        border: 0,
        background: 'rgb(255, 255, 255)',
        borderRadius: '8px',
        outline: 'none',
        overflow: 'hidden',
        padding: 20,
        width: 'min-content',
        height: 'min-content',
        transform: 'translate(-50%, -50%)',
    },
}
