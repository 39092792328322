import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NoTeamManage } from './NoTeamManage'
import Styles from './ManageMeeting.module.css'
import { TeamManage } from './TeamManage'
import { useTimeline } from '../../../Hooks/useTimeline'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'

export const ManageMeetingCustomStyles = {
    content: {
        width: '24.125rem',
        height: '29.5rem',
    },
    overlay: {
        backgroundColor: 'rgba(0, 55, 80, 0.98)',
    },
}

export const ManageMeetingModal = () => {
    const { closeModal } = useUIContext()
    const { t } = useTranslation('teamMonitor')
    const { currentEvent, isLoading } = useTimeline()

    if (isLoading) return <p>{t('Loading...')}</p>

    const inTeams = currentEvent?.room === 'teams'

    return (
        <section className={Styles.section}>
            <header className={Styles.header}>
                <span className={Styles.title}>{t('Manage meeting')}</span>
                <button style={{ border: 0, background: 0 }} onClick={closeModal}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                </button>
            </header>
            <hr />
            {inTeams ? <TeamManage /> : <NoTeamManage />}
        </section>
    )
}
