import React from 'react'
import { useTeam } from '../../../Context/TeamContext'
import { StudentItemProps } from '../StudentItem'
import { Attendance } from './Attendance'
import { Rearrange } from './Rearrange'
import { Stats } from './Stats'

export const ActionsSelector = (props: StudentItemProps) => {
    const { actionsView } = useTeam()
    const [actions] = actionsView

    return (
        <div className="action-block" style={{ justifyContent: 'flex-end' }}>
            {actions === 'attendance' && <Attendance {...props} />}
            {actions === 'stats' && <Stats {...props} />}
            {actions === 'rearrange' && <Rearrange {...props} />}
        </div>
    )
}
