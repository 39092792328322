import styled from 'styled-components'
import { colors } from '../../styles/globals/_variables'

export const BtnRoundedShaddow = styled.button`
    display: none;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background-color: white;
    border: 1px solid ${colors.borders};
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: relative;
    @media screen and (min-width: 768px) {
        display: flex;
    }
    .icon {
        font-size: 1.4rem;
    }
    span {
        margin-left: 10px;
        font-weight: bold;
    }
    &:hover {
        cursor: pointer;
    }
    &:disabled {
        cursor: default;
        box-shadow: none;
    }
`
