import styled, { css } from 'styled-components'
import baseTheme from '../../../themes/baseTheme'
import { flex } from '../../../utils/mixins'
import Button from '../../atoms/Button/Button.component'

export const TabMenuContainer = styled.nav`
    background: ${baseTheme.colors['f2f2f2']};
    border-radius: 10px;
    padding: 7px 8px;
`

export const TabMenuContent = styled.div`
    ${flex('center', 'space-evenly')};
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
`

export const TabMenuItem = styled(Button)((props) => {
    return css`
        background: ${props.className === 'selected'
            ? baseTheme.colors['073045']
            : baseTheme.colors['f2f2f2']};
        border: none;
        border-radius: 8px;
        color: ${props.className === 'selected'
            ? baseTheme.colors['ffffff']
            : baseTheme.colors['073045']};
        flex-grow: 1;
        font-family: ${baseTheme.typography.fonts.inter};
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.2px;
        line-height: 18px;
        min-width: 150px;
        text-align: center;
        text-transform: uppercase;

        :hover {
            background: ${props.className === 'selected'
                ? baseTheme.colors['073045']
                : baseTheme.colors['dddddd']};
            border: none;
        }
        :active {
            background: ${props.className === 'selected'
                ? baseTheme.colors['073045']
                : baseTheme.colors['dddddd']};
            color: ${baseTheme.colors['ffffff']};
        }
    `
})

export const TabMenuItemContainer = styled.li`
    ${flex('center', 'space-around')};
    border-right: 1px solid ${baseTheme.colors['c7c7c7']};
    height: 100%;
    padding: 0 5px;
    width: 100%;
    :last-child {
        border-right: none;
    }
`
