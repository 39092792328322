import React, { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { SubjectContext } from '../../../../context/SubjectContext'
import { useTranslation } from 'react-i18next'
import Axios from 'axios'
import Config from '../../../../config'
import { capitalize } from '../../../../helpers/formatter'
import ReactGA from 'react-ga'

const formatDay = (date: Date) => {
    const year = date.getFullYear()
    const month = (1 + date.getMonth()).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return day + '/' + month + '/' + year
}

const initialAttendance = {
    present: 0,
    delay: 0,
    absent: 0,
}

const AttendanceView = () => {
    const { professorScheduleId, subject } = useContext(SubjectContext)
    const [isLoading, setIsLoading] = useState(false)
    const [attendance, setAttendance] = useState(initialAttendance)
    const { t } = useTranslation(['teamManager'])
    const date = new Date()
    const [rotationData, setRotationDate] = useState<InitialDay | null>(null)
    const rotationText = rotationData
        ? t('teamManager:stats-attendance.rotation-info', {
              rotationDay: capitalize(rotationData.day),
              rotationDate: capitalize(rotationData.rotationDate),
              rotationHour: capitalize(rotationData.hour),
              accumulatedRotation: capitalize(rotationData.accumulatedRotation),
          })
        : t('teamManager:stats-attendance.no-rotation')

    const updateAttendance = () => {
        setIsLoading(true)
        Axios.get<GetAttendanceResponse['data']>(
            Config.API +
                '/professorSchedule/' +
                professorScheduleId +
                '/subject/' +
                subject?.id +
                '/attendances/',
            {
                params: { date: formatDay(date) },
            }
        )
            .then(({ data }) => {
                const results = data?.attendances?.reduce(
                    (acc, { status }) => {
                        acc[status] += 1
                        return acc
                    },
                    { ...initialAttendance }
                )
                setAttendance(results)

                if (!data.infoAlgorithm.dateLastRotation) return null
                const resultsDate = data?.infoAlgorithm

                const dateLocal = new Date(resultsDate.dateLastRotation)
                const dateLastAlgorith = Intl.DateTimeFormat(navigator.language, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(dateLocal)
                //fecha local
                const timeLocal =
                    ('0' + dateLocal.getHours()).substr(-2) +
                    ':' +
                    ('0' + dateLocal.getMinutes()).substr(-2)
                //dias desde la ultima rotacion
                const daysLatsAlgorith = Math.floor(
                    (new Date().getTime() - dateLocal.getTime()) / (1000 * 3600 * 24)
                )
                //dd-mm-yyyy  mm-dd-yyyy
                setRotationDate({
                    day: daysLatsAlgorith.toString(),
                    rotationDate: dateLastAlgorith,
                    hour: timeLocal,
                    accumulatedRotation: resultsDate.totalRotations.toString(),
                })
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        updateAttendance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const attendanceStats: AttendanceStats[] = [
        {
            className: 'check',
            icon: ['fas', 'user-check'],
            value: attendance.present,
            label: t('teamManager:stats-attendance.present'),
        },
        {
            className: 'clock',
            icon: ['fas', 'user-clock'],
            value: attendance.delay,
            label: t('teamManager:stats-attendance.late'),
        },
        {
            className: 'slash',
            icon: ['fas', 'user-slash'],
            value: attendance.absent,
            label: t('teamManager:stats-attendance.absent'),
        },
    ]

    return (
        <div>
            <div className="attendance-header">
                <span className="span-title">
                    {' '}
                    <b>{t('teamManager:stats-attendance.blockTitle')}</b>
                </span>
                <span className="span-stats">
                    {' '}
                    <em>{rotationText} </em>{' '}
                </span>
            </div>
            <div className="attendance-view">
                {attendanceStats.map((element) => {
                    const { value, label, className, icon } = element
                    return (
                        <div key={label} className="items-block">
                            <FontAwesomeIcon icon={icon} className={className} />
                            <div className="items-block-spam">
                                <span className="number">{value !== undefined ? value : '-'}</span>
                                <span className="subtitle">{label}</span>
                            </div>
                            <div></div>
                        </div>
                    )
                })}

                <div
                    className="items-buttom"
                    onClick={() => {
                        updateAttendance(),
                            ReactGA.event({
                                category: 'Engagement',
                                action: 'Attendance',
                                label: 'User refreshes meeting attendance ',
                            })
                    }}
                >
                    <FontAwesomeIcon
                        className="icon-style"
                        icon={['fas', 'redo']}
                        spin={isLoading}
                    />
                    <span>{t('teamManager:stats-attendance.reload')}</span>
                </div>
            </div>
        </div>
    )
}

export default AttendanceView

interface GetAttendanceResponse {
    data: {
        infoAlgorithm: {
            dateLastRotation: Date
            totalRotations: number
        }
        attendances: {
            _studentId: string
            status: 'present' | 'delay' | 'absent'
            comment: string
        }[]
    }
    message: string
}

interface AttendanceStats extends FontAwesomeIconProps {
    value: number
    label: string
}
interface InitialDay {
    day: string
    rotationDate: string
    hour: string
    accumulatedRotation: string
}
