import React, { createContext, useContext, useReducer, useCallback, useState } from 'react'
import Config from '../config'
import { AssignationItem, SubjectContext } from './SubjectContext'
import UIContext from './UIContext'
import downloadBlob from '../helpers/downloadBlob'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import Axios from 'axios'
import { VoteTablesFunction } from '../hooks/ApiHooks/useTableVote'
import { AttendanceSaveType } from '../hooks/ApiHooks/useAttendance'
import { useTranslation } from 'react-i18next'
import useLocalStorage from '../hooks/useLocalStorage'
import { SubmitHandler, useForm, UseFormMethods } from 'react-hook-form'
import { errorHandler } from '../helpers/errorHandler'

export enum ActionView {
    Attendance = 'attendance',
    Rubric = 'rubrica',
    Stats = 'stats',
    Exam = 'exam',
    Reassign = 'reassign',
    Delete = 'delete',
    Add = 'add',
}

interface IExamFormData {
    date: string
    title: string
    score: Record<
        string,
        {
            score: string
            absent: boolean
        }
    >
}

interface IExcelExamResponse {
    isValid: boolean
    students: {
        email: string
        dni: string
        isValid: boolean
        lastname: string
        name: string
        note: string
        _profileId: string
        _userId: string
    }[]
    _classId: string
}

const notContextFn = () => {
    throw Error('Not inside Context')
}
const contextOutofContext = {
    search: '' as string,
    actionView: 'attendance' as ActionView,
    examForm: {} as UseFormMethods<IExamFormData>,
    setActionView: notContextFn as (view: ActionView) => void,
    setSearch: notContextFn as React.Dispatch<string>,
    onExamUpload: notContextFn as () => Promise<unknown>,
    onExamDownloadTemplate: notContextFn as () => Promise<unknown>,
    onExamSubmit: notContextFn as (e?: React.BaseSyntheticEvent) => Promise<void>,
    onVoteTable: notContextFn as (...args: Parameters<VoteTablesFunction>) => Promise<unknown>,
    onStatsReport: notContextFn as (
        order: 'name' | 'votes',
        type?: 'desc' | 'asc'
    ) => Promise<unknown>,
    onFacilitatorReport: notContextFn as (
        order: 'lastname' | 'facilitator',
        type?: 'desc' | 'asc'
    ) => Promise<unknown>,
    onAttendance: notContextFn as (...args: Parameters<AttendanceSaveType>) => unknown,
    onReassign: notContextFn as (student: string, table: number | null) => unknown,
    onRubric: notContextFn as (studentId: string, value: number) => unknown,
    onDelete: notContextFn as (id: string, student: AssignationItem) => unknown,
    shouldDisplay: notContextFn as (student: { name?: string; lastname?: string }) => boolean,
    onAssignationReport: notContextFn as (sort: 'name' | 'assignation') => Promise<unknown>,
    userItemMoreOptionState: [null, notContextFn] as [
        string | null,
        React.Dispatch<React.SetStateAction<string | null>>
    ],
}

export type ContextInterface = typeof contextOutofContext

export const ManagerContext = createContext<ContextInterface>(contextOutofContext)
ManagerContext.displayName = 'ManagerContext'

const nonLatinRegexWithNumbers = /[^ @0-9A-Za-z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u02af\u1d00-\u1d25\u1d62-\u1d65\u1d6b-\u1d77\u1d79-\u1d9a\u1e00-\u1eff\u2090-\u2094\u2184-\u2184\u2488-\u2490\u271d-\u271d\u2c60-\u2c7c\u2c7e-\u2c7f\ua722-\ua76f\ua771-\ua787\ua78b-\ua78c\ua7fb-\ua7ff\ufb00-\ufb06]/gi

export const useManagerContextValue = (): ContextInterface => {
    const userItemMoreOptionState = useState<string | null>('aca')
    const { t } = useTranslation(['teamManager'])

    const [search, setSearch] = useReducer(
        (_: string, value: string) => value.replace(nonLatinRegexWithNumbers, ''),
        ''
    )

    const [actionView, setActionView] = useLocalStorage<ActionView>(
        'manager-action-view',
        ActionView.Attendance
    )

    const examForm = useForm<IExamFormData>({
        mode: 'onBlur',
    })

    const {
        subject,
        setVoteTable,
        course,
        setAttendance,
        setAssignation,
        setRubric,
        setMembers,
        algorithmId,
        fetchAssignation,
        fetchUnassigned,
    } = useContext(SubjectContext)

    const { setInfoBlock, toastNotification } = useContext(UIContext)

    const onVoteTable: ContextInterface['onVoteTable'] = useCallback(
        async (...args) => {
            await setVoteTable?.(...args).then(
                (response) => response,
                (error) => {
                    setInfoBlock([
                        'error',
                        error.message ||
                            (error.data && error.data.message) ||
                            'Ups! Ocurrió un error. Intenta nuevamente.',
                    ])
                    setTimeout(() => setInfoBlock([]), 2500)
                }
            )
        },
        [setVoteTable, setInfoBlock]
    )

    const onAssignationReport: ContextInterface['onAssignationReport'] = useCallback(
        async (sort) => {
            const url =
                Config.API + '/algorithm/' + algorithmId + '/students/pdf?orderBy=' + escape(sort)
            const headers = {
                'access-token': JSON.parse(window.localStorage.getItem('token') ?? ''),
            }
            const response = await fetch(url, { method: 'GET', headers })
            const today = dayjs().format('DD/MM/YYYY')
            if (response.status === 200) {
                downloadBlob(
                    await response.blob(),
                    `asignación_${course?.name.replace(/ /g, '-')}_${today.replace(
                        /\//g,
                        ''
                    )}_by-${sort}.pdf`
                )
            } else {
                setInfoBlock(['error', response.statusText])
                setTimeout(() => setInfoBlock([]), 2000)
            }
        },
        [algorithmId, course, setInfoBlock]
    )

    const onStatsReport: ContextInterface['onStatsReport'] = useCallback(
        async (order, type) => {
            if (type === undefined) type = ['name'].includes(order) ? 'asc' : 'desc'
            const response = await fetch(
                Config.API +
                    '/class/' +
                    course?.id +
                    '/exportVoteStats' +
                    '/xls' +
                    '?orderBy=' +
                    escape(order) +
                    '&type=' +
                    escape(type),
                {
                    method: 'GET',
                    headers: {
                        'access-token': JSON.parse(window.localStorage.getItem('token') ?? ''),
                    },
                }
            )
            if (response.status === 200) {
                downloadBlob(
                    await response.blob(),
                    `vote-stats_${course?.name.replace(/ /g, '-')}_${dayjs().format(
                        'DDMMYYYY'
                    )}_${order}-${type}.xlsx`
                )
            } else {
                setInfoBlock(['error', response.statusText])
                setTimeout(() => setInfoBlock([]), 2000)
            }
        },
        [course, setInfoBlock]
    )

    const onFacilitatorReport: ContextInterface['onFacilitatorReport'] = useCallback(
        async (order, type) => {
            if (type === undefined) type = ['lastname'].includes(order) ? 'asc' : 'desc'
            //api/class/:_courseId/facilitatorStats/:format?order=lastname
            const response = await fetch(
                Config.API +
                    '/class/' +
                    course?.id +
                    '/facilitatorStats/xls' +
                    '?order=' +
                    escape(order),
                {
                    method: 'GET',
                    headers: {
                        'access-token': JSON.parse(window.localStorage.getItem('token') ?? ''),
                    },
                }
            )
            if (response.status === 200) {
                downloadBlob(
                    await response.blob(),
                    `facilitator-stats_${course?.name.replace(/ /g, '-')}_${dayjs().format(
                        'DDMMYYYY'
                    )}_${order}-${type}.xls`
                )
            } else {
                setInfoBlock(['error', response.statusText])
                setTimeout(() => setInfoBlock([]), 2000)
            }
        },
        [course, setInfoBlock]
    )

    const onExamDownloadTemplate: ContextInterface['onExamDownloadTemplate'] = useCallback(async () => {
        const response = await fetch(Config.API + '/exam/courses/' + course?.id + '/xlsx', {
            method: 'GET',
            headers: { 'access-token': JSON.parse(window.localStorage.getItem('token') ?? '') },
        })
        if (response.status === 200) {
            downloadBlob(
                await response.blob(),
                t('teamManager:exam.default-filename', {
                    course: course?.name,
                    subject: subject?.name,
                })
            )
        } else {
            setInfoBlock(['error', response.statusText])
            setTimeout(() => setInfoBlock([]), 2000)
        }
    }, [subject, course, setInfoBlock, t])

    const onExamUpload: ContextInterface['onExamUpload'] = useCallback(
        () =>
            new Promise((resolve, reject) => {
                const input = document.createElement('input')
                input.id = 'exam-uploader'
                input.style.display = 'none'
                input.type = 'file'
                input.accept =
                    '.xls,.xlsx,  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
                input.hidden = true
                document.body.appendChild(input)

                let waiting = true

                input.onerror = (error) => {
                    waiting = false
                    document.body.removeChild(input)
                    reject(error)
                }

                input.onchange = async (ev) => {
                    // Let me parse the file array to avoid the `files` as undefined property
                    const event = (ev as unknown) as {
                        target?: { files?: (Blob & { name: string })[] }
                    }
                    if (waiting)
                        try {
                            waiting = false
                            const file = event.target?.files?.[0]
                            if (!file) return
                            const form = new FormData()
                            form.append('title', file.name)
                            form.append('file', file, file.name)

                            const response = await Axios.post<IExcelExamResponse>(
                                `${Config.API}/exam/courses/${course?.id}/excel`,
                                form,
                                {
                                    headers: {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                                    },
                                }
                            )
                            examForm.reset()
                            response.data.students
                                .filter((student) => student.isValid)
                                .forEach((student) => {
                                    if (student.note === '0') {
                                        examForm.setValue(
                                            `score.${student._profileId}.absent`,
                                            true
                                        )
                                        examForm.setValue(`score.${student._profileId}.score`, '')
                                    } else {
                                        examForm.setValue(
                                            `score.${student._profileId}.absent`,
                                            false
                                        )
                                        examForm.setValue(
                                            `score.${student._profileId}.score`,
                                            student.note
                                        )
                                    }
                                })
                            //// You can enter the title of the exam as the filename
                            //examForm.setValue('title', file.name)
                            examForm.trigger()
                            document.body.removeChild(input)
                            resolve(response)
                        } catch (error) {
                            reject(error)
                        }
                }

                window.addEventListener(
                    'focus',
                    () => {
                        setTimeout(() => {
                            if (waiting) {
                                waiting = false
                                reject(new Error(t('teamManager:exam.must-select-file')))
                                document.body.removeChild(input)
                            }
                        }, 1000)
                    },
                    { once: true }
                )

                input.click()
            }),
        [course, examForm, t]
    )

    /**
     * Handler of the Send Exam Button. It communicates with the POST endpoint
     * to create a new exam with a title and a date. After that, it resets the form.
     */
    const onExamSubmit: SubmitHandler<IExamFormData> = useCallback(
        async (data) => {
            try {
                await Axios.post(`${Config.API}/exam/courses/${course?.id}`, {
                    _classId: subject?.id,
                    title: data.title,
                    examDate: dayjs.utc(data.date, '').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
                    notes: Object.entries(data.score).map(([key, data]) => ({
                        // The back consider a 0 as an absent
                        note: !data.absent ? data.score : 0,
                        _profileId: key,
                    })),
                })

                // Resets the form
                const value = examForm.getValues()
                value.date = value.title = ''
                value.score &&
                    Object.keys(value.score).forEach(
                        (student) => (value.score[student] = { score: '', absent: false })
                    )
                examForm.reset(value)

                setInfoBlock([
                    'success',
                    t('teamManager:exam.successful-sended', { title: value.title }),
                ])
            } catch (error) {
                toastNotification(errorHandler(error, t('errors:genericRetry')), 'error')
            } finally {
                setTimeout(() => setInfoBlock([]), 2500)
            }
        },
        [setInfoBlock, subject, course, t, examForm, toastNotification]
    )

    const onError = useCallback(
        (error) => {
            const message =
                error.message ||
                (error.data && error.data.message) ||
                'Ups! Ocurrió un error. Intenta nuevamente.'
            toastNotification(message, 'error')
        },
        [toastNotification]
    )

    const onAttendance: ContextInterface['onAttendance'] = useCallback(
        (...args) => setAttendance?.(...args).catch(onError),
        [setAttendance, onError]
    )

    const onReassign: ContextInterface['onReassign'] = useCallback(
        (...args) =>
            setAssignation?.move(...args).then(() => {
                setInfoBlock(['success', t('teamManager:actions.reassign.success')])
                setTimeout(() => setInfoBlock([]), 1500)
            }, onError),
        [setAssignation, setInfoBlock, onError, t]
    )

    const onRubric: ContextInterface['onRubric'] = useCallback(
        (...args) => setRubric?.(...args).catch(onError),
        [setRubric, onError]
    )

    const onDelete: ContextInterface['onDelete'] = useCallback(
        (id, student: AssignationItem) => {
            const { name, lastname } = student
            return setMembers?.(id, null)
                .then(() => {
                    const message = t('teamManager:actions.delete.message', {
                        name,
                        lastname,
                    })
                    toastNotification(message, 'success', 1)
                })
                .catch(onError)
                .finally(() => {
                    fetchAssignation?.(1)
                    fetchUnassigned?.(1)
                })
        },
        [fetchAssignation, fetchUnassigned, onError, setMembers, t, toastNotification]
    )

    const shouldDisplay: ContextInterface['shouldDisplay'] = useCallback(
        ({ lastname, name }) =>
            `${lastname} ${name} ${lastname}`
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(
                    search
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLocaleLowerCase()
                ),
        [search]
    )

    const value: ContextInterface = {
        onExamSubmit: examForm.handleSubmit(onExamSubmit),
        onExamUpload: onExamUpload,
        onExamDownloadTemplate: onExamDownloadTemplate,
        onStatsReport,
        onFacilitatorReport,
        onVoteTable,
        onReassign,
        onAttendance,
        onRubric,
        onDelete,
        search,
        setSearch,
        actionView,
        setActionView,
        examForm,
        shouldDisplay,
        onAssignationReport,
        userItemMoreOptionState,
    }

    return value
}

export default ManagerContext
