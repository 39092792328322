/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react'

import { getTeam } from '../../../apis/api.signup'
import { useQuery } from 'react-query'
import { useUser } from '../../../context/UserContext'

export const useTeam = () => {
    const lastCourseId = sessionStorage.getItem('last-courseId')
    const { profiles } = useUser()
    const [courseID, setLastCourseId] = useState(lastCourseId)
    const [profileID, setProfileID] = useState<string | null>(null)

    const { data, ...rest } = useQuery({
        queryKey: ['Team', courseID],
        queryFn: () => getTeam(courseID!),
        enabled: !!courseID,
    })

    // Listen for changes in sessionStorage
    useEffect(() => {
        const onChange = () => setLastCourseId(sessionStorage.getItem('last-courseId'))
        window.addEventListener('sessionStorageChange', onChange)
        return () => window.removeEventListener('sessionStorageChange', onChange)
    }, [courseID])

    // Set profileID based on courseID
    useEffect(() => {
        const { _profileId: profileID } =
            profiles.find(({ course: { _id } }) => _id === courseID!) ?? {}

        profileID && setProfileID(profileID)
    }, [courseID, profiles])

    return {
        courseID,
        profileID,
        algorithmID: data?.algorithm?._id,
        hasGeneral:
            (data?.settings?.meeting?.type === 'egg' && data.settings.meeting.data.general) ||
            data?.settings.meeting?.type === 'zoom-hybrid',
        ...data,
        ...rest,
    }
}
