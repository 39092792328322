import React, { useEffect } from 'react'

import FullLayout from '../../layouts/FullLayout'
import { OverviewHeader } from './Components/Overview/OverviewHeader'
import WarningBlock from '../../components/WarningBlock'
import { setNewAccessToken } from '../../apis/apis'
import { useCourses } from '../../hooks/ApiHooks/useCourses'
import { useTeam } from './Context/TeamContext'
import { useUser } from '../../context/UserContext'

const Monitor = React.lazy(() => import('./Components/Monitor/CourseMonitor'))

export const Overview = () => {
    const { team, courseID, mainLinks } = useTeam()
    const { getCourseData } = useCourses()
    const { setToken } = useUser()

    useEffect(() => {
        if (!courseID) return

        sessionStorage.setItem('last-courseId', courseID)

        const course = getCourseData(courseID)
        if (!course) return

        setNewAccessToken(course['access-token'])
        setToken(course['access-token'])
    }, [courseID, getCourseData, setToken])

    if (!team) return null

    return (
        <FullLayout links={mainLinks}>
            <WarningBlock />
            <div
                className="team-detail-container"
                style={{
                    backgroundColor: '#F8F8F8',
                    minHeight: 'inherit',
                }}
            >
                <div className="container overview">
                    <OverviewHeader />
                    <Monitor />
                </div>
            </div>
        </FullLayout>
    )
}
