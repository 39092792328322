import React from 'react'
import Axios from 'axios'
import Config from '../../config'

// https://develop.d3qmp9et8bpuml.amplifyapp.com/#/Rubric-GET-professorSchedule-{_professorScheduleId}-subjects-{_subjectId}-rubrics
export interface IRubricGETResponse {
    students: {
        _studentId: string
        votes: {
            _questionId: string
            quantity: number
            total: number
            average: number
        }[]
    }[]
    questions: {
        _id: string
        name: string
    }[]
}

export interface IRubricPUTResponse {
    vote: {
        _studentId: string
        votes: {
            _questionId: string
            quantity: number
            total: number
            average: number
        }[]
    }
}

export interface RubricItem {
    _questionId: string
    quantity: number
    total: number
    average: number
}

export interface RubricType {
    [_questionId: string]: { [_studentId: string]: RubricItem }
}

export interface QuestionType {
    _id: string
    name: string
}

export interface RubricSaveType {
    (_questionId: string, _studentId: string, value: number): Promise<{
        _studentId: string
        votes: RubricItem[]
    }>
}

export function useRubric(
    /** INPUT  */
    _professorScheduleId: string,
    _subjectId: string,
    // eslint-disable-next-line no-console
    onError = console.error
): [
    /** OUTPUT */
    RubricType | null | /*Case of error */ undefined /*Still loading*/,
    RubricSaveType,
    QuestionType[] | null | /*Case of error */ undefined /*Still loading*/
] {
    const [rubric, setRubric] = React.useState<RubricType | undefined | null>()
    const [questions, setQuestions] = React.useState<QuestionType[] | undefined>()

    React.useEffect(
        function getRubric() {
            if (!_professorScheduleId || !_subjectId) return
            Axios.get<IRubricGETResponse>(
                Config.API +
                    '/professorSchedule/' +
                    _professorScheduleId +
                    '/subject/' +
                    _subjectId +
                    '/rubrics/'
            )
                .then((response) => {
                    setQuestions(response.data.questions)
                    //setQuestions([])

                    // Parse an array of votes to an object where the key is the questionId
                    setRubric(
                        response.data.students.reduce((rubric: RubricType, student) => {
                            rubric[student._studentId] = student.votes.reduce(
                                (votes, vote: RubricItem) => {
                                    votes[vote._questionId] = vote
                                    return votes
                                },
                                {} as Record<string, RubricItem>
                            )
                            return rubric
                        }, {})
                    )
                })
                .catch((error) => {
                    onError(error)
                    setRubric(null)
                })
        },
        [_professorScheduleId, _subjectId, onError]
    )

    const saveRubric: RubricSaveType = async (
        _questionId: string,
        _studentId: string,
        value: number
    ) => {
        return Axios.put<IRubricPUTResponse>(Config.API + '/rubric/vote', {
            _professorScheduleId,
            _subjectId,
            _questionId,
            _studentId,
            value,
        }).then((response) => {
            if (response.data.vote._studentId === _studentId && rubric) {
                const result = response.data.vote.votes.find(
                    (vote) => vote._questionId === _questionId
                )
                if (result) {
                    rubric[_studentId] = {
                        ...rubric[_studentId],
                        [_questionId]: result,
                    }
                    setRubric({ ...rubric })
                }
            }
            return response.data.vote
        })
    }

    return [rubric, saveRubric, questions]
}
