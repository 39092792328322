/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef, useContext } from 'react'
import XLSX from 'xlsx'
import downloadBlob from '../../../../helpers/downloadBlob'
import Pagination from '../../../../components/Pagination'
import { ROLES } from '../../../../constants'
import styled, { css } from 'styled-components'
import { colors } from '../../../../styles/globals/_variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import ToggleBtn from '../../../../components/Buttons/Toggle'
import UserContext from '../../../../context/UserContext'
import Axios from 'axios'
import Config from '../../../../config'
import { SubjectContext } from '../../../../context/SubjectContext'
import { UIContext } from '../../../../context/UIContext'
import ReactGA from 'react-ga'
import { useTeam } from '../../../Team/Context/TeamContext'

interface Item {
    name: string
    lastname: string
    email: string
    start: string
}

export interface Props {
    key: string
    key2: number
    params: Record<string, unknown>
    phead: string
    defaultV: string
    page: number
    limit: number
    changeOne: () => unknown
}

const generateDownloadbleExcel = () => {
    const wb = XLSX.utils.book_new()
    wb.Props = {
        Title: 'estructura carga masiva',
        Subject: 'Egg',
        Author: 'Egg',
        CreatedDate: new Date(),
    }
    wb.SheetNames.push('CargaMasiva')
    const ws_data = [
        ['name', 'lastname', 'email', 'start'],
        ['William', 'Wallace', 'example@mail.com', 'dd/mm/yyyy'],
    ] //a row with 4 columns format for upload
    const ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets['CargaMasiva'] = ws
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
    const s2ab = (s: typeof wbout) => {
        const buf = new ArrayBuffer(s.length) //convert s to arrayBuffer
        const view = new Uint8Array(buf) //create uint8array as viewer
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff //convert to octet
        return buf
    }
    return new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
}

function runTest(itemArray: Item[]) {
    let index = 1
    // eslint-disable-next-line prefer-const
    let arrayError: string[] = []
    let errorRow
    itemArray.forEach((item) => {
        errorRow = 'Fila ' + index + ': |'
        const errorRowCheck = 'Fila ' + index + ': |'

        if (
            !item.name ||
            !/^[a-zA-ZÀ-ÿ-'\u00f1\u00d1\s]+$/i.test(item.name) ||
            item.name.length > 50
        ) {
            errorRow = errorRow + ' name |'
        }
        if (
            !item.lastname ||
            !/^[a-zA-ZÀ-ÿ-'\u00f1\u00d1\s]+$/i.test(item.lastname) ||
            item.lastname.length > 50
        )
            errorRow = errorRow + ' lastname |'
        if (
            !item.email ||
            !/^[a-zA-ZÀ-ÿ0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                item.email
            ) ||
            item.email.length > 50
        )
            errorRow = errorRow + ' email |'
        //de momento dejamos que el campo start que hace referencia  a la fecha de inicio del cursado se opcional pero si va, queda formateado como dd/mm/yyyy .
        if (item.start) {
            if (
                !item.start ||
                !/^(0[1-9]|[12][0-9]|3[01])[/-](0[1-9]|1[012])[/-]\d{4}$/.test(item.start)
            )
                errorRow = errorRow + ' start date |'
        }
        if (errorRow != errorRowCheck) arrayError.push(errorRow)
        index++
    })
    return arrayError
}
function checkDuplicates(arr: any) {
    let resultado = ''
    const duplicateEmails = arr
        .map((e: { [x: string]: any }) => e['email'])
        .map((e: any, i: any, final: string | any[]) => final.indexOf(e) !== i && i)
        .filter((obj: string | number) => arr[obj])
        .map((e: string | number) => arr[e]['email'])

    duplicateEmails.forEach((element: string) => {
        resultado = resultado + ' ' + element + ', '
    })
    return resultado
}

function readExcelFromFile<T>(file: File) {
    return new Promise<T[]>((resolve, reject) => {
        const fileReader = new FileReader()
        if (!file) return

        const admisibleTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
        ]

        if (!admisibleTypes.includes(file.type)) return

        fileReader.readAsArrayBuffer(file)
        fileReader.onload = (e) => {
            const buffeyArray = e.target?.result
            const wordBout = XLSX.read(buffeyArray, { type: 'buffer' })
            const wsName = wordBout.SheetNames[0]
            const wsfinal = wordBout.Sheets[wsName]
            const data: T[] = XLSX.utils.sheet_to_json(wsfinal)
            // eslint-disable-next-line prefer-const
            let resultado: any = [...data]
            let index = 0
            resultado.forEach((element: { start: any }) => {
                if (resultado[index]) {
                    resultado[index].start = ExcelDateToJSDate(element.start)
                }
                index++
            })

            resolve(data)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

function ExcelDateToJSDate(date: any) {
    if (Number.isInteger(date)) {
        const int_length = ('' + date).length
        if (int_length == 5) {
            const fecha = new Date(Math.round((date - 25569) * 86400 * 1000))

            const day = fecha.getUTCDate()
            let day2 = String(day)

            const month = fecha.getUTCMonth() + 1
            let month2 = String(month)

            const year = fecha.getUTCFullYear()
            if (day < 10) {
                day2 = '0' + day
            }
            if (month < 10) {
                month2 = '0' + month
            }

            const resultado = day2 + '/' + month2 + '/' + year

            return resultado
        } else {
            return date
        }
    } else {
        return date
    }
}

const MassiveSave = () => {
    const { course, fetchUnassigned, hq, subject } = useContext(SubjectContext)
    const { setInfoBlock } = useContext(UIContext)
    const { t } = useTranslation(['massiveSave'])
    const { role } = useContext(UserContext)
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const fileInput = useRef<HTMLInputElement>(null)
    const [resultado, setResultado] = useState<Item[]>([])
    const [page, setPage] = useState<number>(1)
    const [maxpage, setMaxpage] = useState(1)
    const [showErrors, setShowErrors] = useState<string[]>()
    const [showDuplicateEmail, setShowDuplicateEmail] = useState<any>()
    const [longError, setLongError] = useState<string>()
    const [inputKey, setInputKey] = useState(Math.random().toString(36))
    const arrayLimit = 200
    //limit is limit per page
    const [tableKey, setTableKey] = useState(0)
    const { team } = useTeam()
    const limit = 10
    const heads = [
        t('massiveSave:name'),
        t('massiveSave:lastname'),
        t('massiveSave:email'),
        t('massiveSave:start'),
    ]

    const switchVisible = async () => {
        setIsVisible(!isVisible)
    }
    //generate a xlsx file with the estructure for the upload
    const guardar = () => {
        downloadBlob(generateDownloadbleExcel(), 'estructura.xlsx')
    }

    const deleteOne = (index: number, mail: string) => {
        try {
            const indexSum = (page - 1) * limit + index
            setTableKey(tableKey + 1)

            const copyUserArray: Item[] = [...resultado]
            copyUserArray.splice(indexSum, 1)

            if (copyUserArray.length <= (page - 1) * limit) {
                if (page != 1) setPage(page - 1)
            }
            setResultado(copyUserArray)
            setInfoBlock(['success', mail + ' ' + t('backStatus.deleteSuccessfully')])
        } catch (error) {
            setInfoBlock(['error', t('errors.deleteError')])
        } finally {
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }
    const guardarUsers = async () => {
        if (resultado.length <= 0) return
        try {
            setIsSubmitting(true)
            await Axios.put(`${Config.API}/class/${course?.id}/loadStudents`, {
                data: resultado,
            })
            setInfoBlock(['success', t('backStatus.succesfully')])

            try {
                await fetchUnassigned?.(1)
                setIsSubmitting(false)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching new unassigned page', error)
                setIsSubmitting(false)
            }

            cleanAll()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            setInfoBlock([
                'error',
                error.message ||
                    (error.data && error.data.message) ||
                    'Ups! Ocurrió un error. Intenta nuevamente.',
            ])
            setIsSubmitting(false)
        } finally {
            setTimeout(() => {
                setInfoBlock([])
                setIsSubmitting(false)
            }, 3000)
        }
    }

    const cleanAll = () => {
        setResultado([])
        setInputKey(Math.random().toString(36))
    }
    const changeOne = (index: number, entry: keyof Item, value: string) => {
        const newArr: Item[] = [...resultado]
        const max = entry === 'start' ? 20 : 50
        if (value.length < max) newArr[index][entry] = value
        setResultado(newArr)
    }

    //upload the xlsx file and run a test per each row
    const readExcel = async (file: File) => {
        setIsSubmitting(true)
        const data = await readExcelFromFile<Item>(file)
        let moreColumns = false

        for (let index = 0; index < data.length; index++) {
            data[index].name = data[index]?.name?.toString() ?? ''
            data[index].lastname = data[index]?.lastname?.toString() ?? ''
            data[index].email = data[index]?.email?.toString() ?? ''
            data[index].start = data[index]?.start?.toString() ?? ''

            if (Object.keys(data[index]).length > 4) {
                moreColumns = true
            }
        }

        if (data.length > 0 && !moreColumns) {
            if (data.length > arrayLimit) {
                setLongError(t('errors.longArray') + (data.length - arrayLimit))
                setResultado(data.slice(0, arrayLimit))
            } else {
                setResultado(data.slice(0, arrayLimit))
            }
        } else if (moreColumns) {
            setInfoBlock(['error', t('errors.moreColumns')])
        } else {
            setInfoBlock(['error', t('errors.emptyFile')])
        }
        setIsSubmitting(false)
        setTimeout(() => {
            setInfoBlock([])
        }, 3000)
    }
    //check the content of the file
    useEffect(() => {
        //the limit set the size of the pages
        setMaxpage(Math.ceil(resultado.length / limit))
        setShowErrors(runTest(resultado))
        setShowDuplicateEmail(checkDuplicates(resultado))
    }, [resultado, page])

    return (
        <ComponentBorder>
            <div className="heading" style={{ marginBottom: '30px' }}>
                <h3>{t('massiveSave:title')}</h3>
                <ToggleBtn
                    inactive={role !== ROLES.ADMIN && role !== ROLES.COACH}
                    on={isVisible}
                    onClick={() => switchVisible()}
                />
            </div>
            {isVisible && (
                <div>
                    {resultado.length <= 0 && (
                        <Card>
                            <Content>
                                <Item className="egg-card">
                                    <FontAwesomeIconStyled
                                        className="icon"
                                        icon={['fal', 'arrow-to-bottom']}
                                    />
                                    <Label>{t('massiveSave:label1')}</Label>
                                    <PanelMS>
                                        <Span className="global-text">
                                            {t('massiveSave:paragraph1')}
                                        </Span>
                                    </PanelMS>
                                    <button
                                        onClick={guardar}
                                        className="btn-yellow btn"
                                        style={{ width: '80%' }}
                                    >
                                        {t('massiveSave:button-download')}
                                    </button>
                                </Item>
                                <Item className="egg-card">
                                    <FontAwesomeIconStyled
                                        className="icon"
                                        icon={['fal', 'th-list']}
                                    />
                                    <Label>{t('massiveSave:label2')}</Label>
                                    <PanelMS>
                                        <Span className="global-text">
                                            {t('massiveSave:paragraph2')}
                                        </Span>
                                    </PanelMS>
                                </Item>
                                <Item className="egg-card">
                                    <FontAwesomeIconStyled
                                        className="icon"
                                        icon={['fal', 'cloud-upload']}
                                    />
                                    <Label>{t('massiveSave:label3')}</Label>
                                    <PanelMS>
                                        <Span className="global-text">
                                            {t('massiveSave:paragraph3')}
                                        </Span>
                                    </PanelMS>
                                    <input
                                        id="file"
                                        key={inputKey || ''}
                                        style={{ display: 'none' }}
                                        type="file"
                                        onChange={(e) => {
                                            e.target.files ? readExcel(e.target.files[0]) : 'alert'
                                        }}
                                        accept=".xls, .xlsx"
                                        ref={fileInput}
                                    />
                                    <button
                                        className="btn-yellow btn"
                                        style={{ width: '80%' }}
                                        onClick={() => {
                                            fileInput.current?.click()
                                            ReactGA.event({
                                                category: 'Onboarding',
                                                action: 'MassiveStudentsUpload',
                                                dimension1: 'platform',
                                                dimension2: hq?.name.toLowerCase(),
                                                dimension3: course?.name.toLowerCase(),
                                                dimension4: subject?.name.toLowerCase(),
                                                dimension7: role,
                                                dimension9: team?.algorithm
                                                    ? team?.algorithm?.algorithmType
                                                    : 'no algorithm available',
                                            })
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? (
                                            <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                        ) : (
                                            t('massiveSave:button-upload')
                                        )}
                                    </button>
                                </Item>
                            </Content>
                        </Card>
                    )}
                    {resultado.length > 0 && (
                        <div>
                            {longError && (
                                <p style={{ color: 'red', fontWeight: 'bold' }}>{longError}</p>
                            )}

                            <p>
                                {
                                    // eslint-disable-next-line no-extra-boolean-cast
                                    t('massiveSave:count-errors').replace(
                                        '{length}',
                                        // eslint-disable-next-line no-extra-boolean-cast
                                        !!showErrors ? showErrors.length.toString() : '0'
                                    )
                                }
                            </p>

                            {showErrors?.slice(0, 3).map((errorInLine, index) => (
                                <div key={'div' + index + errorInLine}>
                                    <p style={{ color: 'red' }} key={'p2' + index + errorInLine}>
                                        {errorInLine}
                                    </p>
                                </div>
                            ))}
                            <hr />
                            {showDuplicateEmail && (
                                <div>
                                    <p style={{ color: 'red' }}>
                                        {t('massiveSave:errors.duplicate-emails')}
                                    </p>
                                    <p>{showDuplicateEmail}</p>
                                </div>
                            )}

                            <Table key={tableKey}>
                                <THead>
                                    <TableTrHead>
                                        <TableTdHead></TableTdHead>
                                        {heads.map((elem, index) => {
                                            return (
                                                <TableTdHead key={'table' + index}>
                                                    {elem}
                                                </TableTdHead>
                                            )
                                        })}
                                    </TableTrHead>
                                </THead>
                                <TableBody>
                                    {resultado
                                        .slice((page - 1) * limit, page * limit)
                                        .map((item, index) => {
                                            return (
                                                <TableTr key={index + page * limit}>
                                                    <TableTd>
                                                        {' '}
                                                        {page * limit + index - limit + 1}
                                                    </TableTd>
                                                    <TDBody key={'name'} {...{ width: '25%' }}>
                                                        <PHead>{heads[index]}</PHead>
                                                        <InputTable
                                                            className="form-input"
                                                            type="text"
                                                            defaultValue={item['name']}
                                                            onChange={(e) =>
                                                                changeOne(
                                                                    page * limit + index - limit,
                                                                    'name',
                                                                    e.target.value
                                                                )
                                                            }
                                                            maxLength={50}
                                                        />
                                                    </TDBody>
                                                    <TDBody key={'lastname'} {...{ width: '25%' }}>
                                                        <PHead>{heads[index]}</PHead>
                                                        <InputTable
                                                            className="form-input"
                                                            type="text"
                                                            defaultValue={item['lastname']}
                                                            onChange={(e) =>
                                                                changeOne(
                                                                    page * limit + index - limit,
                                                                    'lastname',
                                                                    e.target.value
                                                                )
                                                            }
                                                            maxLength={50}
                                                        />
                                                    </TDBody>
                                                    <TDBody key={'email'} {...{ width: '30%' }}>
                                                        <PHead>{heads[index]}</PHead>
                                                        <InputTable
                                                            className="form-input"
                                                            type="text"
                                                            defaultValue={item['email']}
                                                            onChange={(e) =>
                                                                changeOne(
                                                                    page * limit + index - limit,
                                                                    'email',
                                                                    e.target.value
                                                                )
                                                            }
                                                            maxLength={50}
                                                        />
                                                    </TDBody>
                                                    <TDBody key={'start'} {...{ width: '20%' }}>
                                                        <PHead>{heads[index]}</PHead>
                                                        <InputTable
                                                            className="form-input"
                                                            type="text"
                                                            defaultValue={item['start']}
                                                            onChange={(e) =>
                                                                changeOne(
                                                                    page * limit + index - limit,
                                                                    'start',
                                                                    e.target.value
                                                                )
                                                            }
                                                            maxLength={20}
                                                        />
                                                    </TDBody>
                                                    <TDBody key={'delete'} {...{ width: '20%' }}>
                                                        <PHead>{heads[index]}</PHead>
                                                        <DivDelete
                                                            onClick={() =>
                                                                deleteOne(index, item['email'])
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={['fas', 'trash']}
                                                                style={{
                                                                    marginLeft: '20px',
                                                                    fontSize: '1.5rem',
                                                                }}
                                                            />
                                                        </DivDelete>
                                                    </TDBody>
                                                </TableTr>
                                            )
                                        })}
                                </TableBody>
                            </Table>

                            <Pagination
                                page={page}
                                count={maxpage}
                                onChange={(page) => {
                                    setPage(page)
                                }}
                            />

                            <ContentOptions>
                                <ParagraphBtn>
                                    {t('massiveSave:count-students').replace(
                                        '{lenght}',
                                        resultado.length.toString()
                                    )}
                                </ParagraphBtn>
                                <ButtonRigth>
                                    <ButtonsMS
                                        className="btn-outline delete-team-action"
                                        style={{ marginRight: '5px' }}
                                        onClick={cleanAll}
                                    >
                                        {t('buttonsTable.cancel')}
                                    </ButtonsMS>
                                    <ButtonsMS
                                        className="btn-yellow btn"
                                        style={{ marginLeft: '5px' }}
                                        onClick={guardarUsers}
                                        disabled={showErrors?.length != 0 || isSubmitting}
                                    >
                                        {isSubmitting ? (
                                            <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                        ) : (
                                            t('buttonsTable.save')
                                        )}
                                    </ButtonsMS>
                                </ButtonRigth>
                            </ContentOptions>
                        </div>
                    )}
                </div>
            )}
        </ComponentBorder>
    )
}

const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 33%;
    margin: 5px;
    @media screen and (min-width: 768px) {
        margin: 10px;
    }
`

const TDBody = styled.td`
    margin-right: 10px;
    padding: 2px;
    @media (max-width: 768px) {
        border: 2px solid ${colors.lightGreyBg};
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `};
`

const Table = styled.table`
    border-collapse: collapse;
    margin-top: 30px;
    width: 100%;
    @media screen and (min-width: 768px) {
        display: block;
    }
`

const THead = styled.thead`
    @media (max-width: 768px) {
        display: none;
    }
`

const PHead = styled.p`
    @media screen and (min-width: 768px) {
        display: none;
    }
    @media (max-width: 768px) {
        width: 95%;
        margin-right: 12px;
        margin-left: 12px;
        text-transform: capitalize;
    }
`

const InputTable = styled.input`
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: ${colors.lightGreyBg};
    box-shadow: none;
    @media (max-width: 768px) {
        margin-bottom: 1px;
        margin-top: 1px;
    }
    width: 95%;
`
const ComponentBorder = styled.div`
    border-bottom: 1px solid ${colors.separadorLineas};
    padding-bottom: 30px;
    margin-bottom: 30px;
`

const TableBody = styled.tbody`
    td {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
`

const TableTr = styled.tr`
    border-bottom: 1px solid #c7c7c7;
    @media (max-width: 768px) {
        display: flex;
        flex-flow: column;
    }
`

const TableTrHead = styled.tr`
    @media (max-width: 768px) {
        display: flex;
        flex-flow: column;
    }
`

const TableTdHead = styled.td`
    text-transform: capitalize;
    padding-left: 20px;
`
const TableTd = styled.td`
    display: flex;
    margin-top: 20px;
    height: 66px;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    align-items: center;
    align-content: center;
    font-weight: 900;
    @media (max-width: 768px) {
        border-bottom: 1px solid #c7c7c7;
        justify-content: flex-start;
    }
    @media screen and (min-width: 768px) {
        border-right: 1px solid #c7c7c7;
        width: 100;
        justify-content: flex-end;
    }
`
const ParagraphBtn = styled.p`
    font-size: 1.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ButtonRigth = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const ButtonsMS = styled.button`
    width: 200px;
    text-transform: capitalize;
`

const PanelMS = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 200px;
`

const Span = styled.div`
    margin-bottom: 8px;
    text-align: center;
`
const Card = styled.div`
    margin: 20px 0;
    padding: 60px 10px;
    @media screen and (min-width: 768px) {
        paddingtop: 10px;
        paddingbottom: 10px;
    }
`

const Content = styled.div`
    display: flex;
    flex-flow: column nowrap;
    @media screen and (min-width: 768px) {
        flex-flow: row;
    }
`

const ContentOptions = styled.div`
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    flex-flow: column nowrap;
    @media screen and (min-width: 768px) {
        flex-flow: row;
        justify-content: space-between;
    }
`

const Label = styled.h3`
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: capitalize;
`

const FontAwesomeIconStyled = styled(FontAwesomeIcon)({
    marginTop: '10px',
    fontSize: '4rem',
    color: colors.eggColor,
})

const DivDelete = styled.div`
    cursor: pointer;
    &:hover {
        color: rgb(255, 129, 130);
    }
`

export default MassiveSave
