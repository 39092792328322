import React, { HTMLAttributes } from 'react'
import { Tip } from './Tooltip.styles'

enum PlacementName {
    bottomCenter = 'bottomCenter',
    bottomEnd = 'bottomEnd',
    bottomStart = 'bottomStart',
    topCenter = 'topCenter',
    topEnd = 'topEnd',
    topStart = 'topStart',
    rightCenter = 'rightCenter',
}

declare type PlacementType = keyof typeof PlacementName

export interface ITooltipProps extends HTMLAttributes<HTMLSpanElement> {
    placement: PlacementType
    text: string
}

const Tooltip = ({ children, placement, text }: ITooltipProps) => {
    return (
        <Tip placement={placement} text={text}>
            {children}
        </Tip>
    )
}

export default Tooltip
