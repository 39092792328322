// API
import React, { useEffect } from 'react'
// Componentes
import { BottomNotification } from '../components/BottomNotification'
import { ToastRotation } from '../components/ToastRotation'
import { useNotifications } from '../hooks/useNotifications'
import NavBarDesktop from './NavBar/NavBarDesktop'
import { NavBarMobile } from './NavBar/NavBarMobile'
import { MenuProps } from './NavBar/useNavBarItems'

export interface LinkItem {
    active?: boolean
    url: string
    name: string
}

export interface Props {
    children: React.ReactNode
    ruta?: string
    links?: MenuProps[]
    page?: string
    hasSearch?: boolean
    onSearch?: () => unknown
}
const FullLayout = ({ children, links, page }: Props) => {
    const { fetchAllNotification } = useNotifications()

    useEffect(() => {
        fetchAllNotification()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`full-layout ${page}`}>
            {/* Error / success message top page */}
            {/* <InfoBlock /> */}
            <ToastRotation />
            <BottomNotification />
            <NavBarDesktop mainLinks={links} />
            <NavBarMobile mainLinks={links} />
            <main className="main-container">{children}</main>
        </div>
    )
}

export default FullLayout
