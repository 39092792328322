import React, { useContext } from 'react'
import UserItem from './UserItem'
import { TableItem as ITableItem } from '../../../../context/SubjectContext'
import ManagerContext from '../../../../context/ManagerContext'
import { TeamHeader } from './TeamHeader'
import { iGetMentorsProps } from '../TeamManagerContainer'

interface IProps {
    table: ITableItem
    onVote?: () => Promise<unknown>
    onTableMeeting?: () => Promise<unknown>
    showMentorsTeam: (
        team: iGetMentorsProps['team'],
        guide: iGetMentorsProps['guide'],
        algorithm?: iGetMentorsProps['algorithm'] | undefined
    ) => void | ''
}

const TableItem = ({ table, onTableMeeting, showMentorsTeam }: IProps) => {
    const { tableNumber, egged, guide, isMentorTable } = table

    const { shouldDisplay } = useContext(ManagerContext)

    const filteredStudents = table.students.filter(shouldDisplay)

    return (
        <div className="table-item" hidden={filteredStudents.length === 0}>
            <TeamHeader
                teamNumber={tableNumber}
                isProgressive={!!guide || isMentorTable}
                isMentorTeam={isMentorTable}
                guide={guide}
                hasVote={egged}
                onTableMeeting={onTableMeeting}
                showMentors={showMentorsTeam}
            />
            <div className="user-list">
                {table.students.map((student) => (
                    <UserItem
                        isAssigned={true}
                        student={student}
                        key={student._profileId}
                        hidden={!shouldDisplay(student)}
                    />
                ))}
            </div>
        </div>
    )
}

export default TableItem
