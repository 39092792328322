// =============
// STRING FORMAT
// =============

export function capitalize(str: string) {
    if (!str) return ''
    // Regular expression to match every consecutive alphabetic chars or number
    const alphabeticChar = /[0-9A-Z\u00C0-\u00DC]+/gi

    return str.replace(
        alphabeticChar,
        // For every consecutive list of alphabetic chars, make the first to uppercase and return the rest
        (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    )
}

export const capitalizeAll = (text?: string) => `${text}`.split(' ').map(capitalize).join(' ')