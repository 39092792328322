import React, { useState, createContext, useEffect, useContext, useCallback } from 'react'
import { UIContext } from './UIContext'
import { UserContext } from './UserContext'

import { StudentType, TableType } from '../hooks/ApiHooks/useAssignation'
import { useVoteTable, VoteTableFunction } from '../hooks/ApiHooks/useTableVote'
import Axios from 'axios'
import Config from '../config'
import useVote, { VoteFunctionType, ShouldVoteType } from '../hooks/ApiHooks/useVote'
import { useMeetings, Meeting, MeetingService } from '../hooks/ApiHooks/useMeetings'
import MandatoryVote from '../pages/Teams/MandatoryVote/MandatoryVote'
import NotFoundPage from '../pages/Other/NotFoundPage'
import useMyTeam, { IOwnData, ITeamData } from '../hooks/ApiHooks/useMyTeam'
import { useTranslation } from 'react-i18next'
import useLocation from 'wouter/use-location'
import JitsiView from '../components/Jitsi/JitsiView'
import { capitalize } from '../helpers/formatter'
import { useGAEvents } from '../hooks/useGAEvents'
import { GetTeamResponse } from '../apis/api.signup'

export type MainLink = {
    name: string
    url: string
    active?: boolean
}

export interface AssignationItem extends StudentType {
    voted?: boolean
}

export interface TableItem extends TableType {
    egged?: boolean
    students: AssignationItem[]
}

export type ContextInterface = {
    mainLinks: MainLink[]

    teamId: string | undefined
    ownData: IOwnData | undefined
    teamData: ITeamData | undefined
    setTeamData: (data: ITeamData) => void
    fetchMyTeam: () => void
    professorSchedule: { id: string } | undefined
    checkMeeting: boolean
    profile: { id: string } | undefined
    voted: string | null | undefined
    votedTable: boolean | null | undefined
    meeting: Meeting | null | undefined
    shouldVote: ShouldVoteType | null | undefined

    setVoted: VoteFunctionType
    setVoteTable: (value?: boolean) => ReturnType<VoteTableFunction>
    joinMeeting: ReturnType<typeof useMeetings>[2]

    iframeLink: string | undefined
    meetingModalShow: boolean | number
    setMeetingModalShow: (val: boolean) => void
    fetchMeetings: () => void
}

// Create Context Object
export const SubjectStudentContext = createContext<Partial<ContextInterface>>({})

// Create a provider for components to consume and subscribe to changes
export const SubjectStudentContextProvider = (props: {
    professorSchedule: string
    subject: string
    children: React.ReactNode
}) => {
    // Ojo Acá. For students, The route param s is teamId since is used to fetch useMyTeam and previous _subjectId is on teamData -> goal -> _id
    const { professorSchedule: _professorScheduleId, subject: teamId } = props
    const [_profileId, setProfileId] = useState<string>('')
    const [checkMeeting] = useState<boolean>(false)

    const { setInfoBlock } = useContext(UIContext)
    const { profiles, role } = useContext(UserContext)
    const { t } = useTranslation(['teamManager'])
    const { sendGA } = useGAEvents()

    /** Defining the generic onError function */
    const onError = useCallback(
        (error, timeout = 3000) => {
            // eslint-disable-next-line no-console
            console.error(error)
            setInfoBlock([
                'error',
                error.message ||
                    (error.data && error.data.message) ||
                    'Ups! Ocurrió un error. Intenta nuevamente.',
            ])
            setTimeout(() => setInfoBlock([]), timeout)
            throw error
        },
        [setInfoBlock]
    )

    // Fetchs all important information to fill student view.
    const [ownData, teamData, setTeamData, fetchMyTeam] = useMyTeam(teamId)

    // This could be on v2/team -> data - own -> table + POST to vote table.
    const [votedTable, setVoteTable] = useVoteTable(
        teamData?._algorithmId,
        ownData?.table.tableNumber ?? undefined,
        onError,
        (teamData as unknown) as GetTeamResponse['data']
    )

    const [
        meeting,
        ,
        join,
        iframeLink,
        meetingModalShow,
        setMeetingModalShow,
        fetchMeetings,
    ] = useMeetings(
        _professorScheduleId,
        teamData?.goal._id,
        _profileId,
        teamData?.course._id,
        role
    )

    const [voted, setVoted, shouldVote] = useVote(
        _profileId,
        teamData?._algorithmId,
        ownData?.table?.positions?.map((student) => student.profile._id),
        onError,
        {
            hqName: teamData?.hq.name,
            teamName: teamData?.course.name,
            subjectName: teamData?.goal.name,
            role: role,
        }
    )

    // Loops on user profiles to find the actual activeProfile id.
    useEffect(() => {
        if (!teamData?.course?._id) return
        const selectedProfile = profiles?.find(
            (profile) => profile?.course?._id === teamData?.course._id && role === profile.role
        )
        if (selectedProfile && selectedProfile._profileId !== _profileId) {
            setProfileId(selectedProfile._profileId)
        }
    }, [profiles, teamData, role, _profileId])

    // Joins to a meeting and marks present.
    const joinMeeting: typeof join = (...args: unknown[]) =>
        join(...(args as Parameters<typeof join>)).then(async (response) => {
            // console.log(args, 'args meeting')
            // Mark the present to the teacher
            await Axios.post(Config.API + '/profile/present', {
                _professorScheduleId,
                _subjectId: teamData?.goal._id,
            })

            // Send the data to analytics
            teamData &&
                sendGA({
                    category: 'Engagement',
                    action: 'Attendance',
                    dimension2: teamData.hq.name.toLowerCase(),
                    dimension3: teamData.course.name.toLowerCase(),
                    dimension4: teamData.goal.name.toLowerCase(),
                })

            return response
        })

    // Builds header links for all student pages
    const [location] = useLocation()

    const mainLinks = [
        {
            name: t('teamManager:main-links.team'),
            url: `/team/${_professorScheduleId}/subject/${teamData?._id}/`,
            active: !location.includes('content') && !location.includes('collab'),
        },
    ]

    if (ownData && ownData?.docs !== null) {
        mainLinks.push({
            name: t('teamManager:main-links.collab'),
            url: `/team/${_professorScheduleId}/subject/${teamData?._id}/collab/`,
            active: location.includes('collab'),
        })
    }

    const value: Required<ContextInterface> = {
        mainLinks,
        professorSchedule: { id: _professorScheduleId },
        votedTable,
        voted,
        setVoteTable,
        checkMeeting,
        setVoted,
        joinMeeting,
        meeting,
        shouldVote,
        profile: { id: _profileId },
        teamId,
        ownData,
        teamData,
        setTeamData,
        fetchMyTeam,
        iframeLink,
        meetingModalShow,
        setMeetingModalShow,
        fetchMeetings,
    }

    return (
        <SubjectStudentContext.Provider value={value}>
            {teamData === null ? (
                <NotFoundPage />
            ) : shouldVote && shouldVote?.candidates?.length > 1 ? (
                <MandatoryVote
                    candidates={shouldVote.candidates.filter(
                        (student) => student._profileId !== _profileId
                    )}
                    onVote={shouldVote.vote}
                />
            ) : (
                props.children
            )}
            {ownData &&
                teamData &&
                meetingModalShow &&
                meeting?.service === MeetingService.JITSI &&
                meeting.method === 'automatic' && (
                    <JitsiView
                        onClose={() => setMeetingModalShow(false)}
                        roomId={`${teamData.course._id}_table_${ownData.table.tableNumber}`}
                        password={teamData?._algorithmId}
                        roomName={capitalize(
                            `${teamData.course.name} - ${teamData.goal.name} - ${ownData.table.tableNumber}`
                        )}
                        trackingPayload={{
                            role,
                            _algorithmId: teamData._algorithmId,
                            table: ownData.table.tableNumber,
                            _courseId: teamData.course._id,
                            _subjectId: teamData.goal._id,
                            _hqId: teamData.hq._id,
                            _professorScheduleId,
                        }}
                    />
                )}
        </SubjectStudentContext.Provider>
    )
}
