/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react'

import AvatarEgg from '../../../components/Styled/AvatarEgg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIContext } from '../../../context/UIContext'

import { useTranslation } from 'react-i18next'

/**
 *
 * @param {number} x
 * @param {number} digits
 * @param {number} base
 */
const round = (x, digits = 0, base = 10) =>
    Math.round(x * Math.pow(base, digits)) / Math.pow(base, digits)

const StudentStats = ({ stats, sending }) => {
    const { t } = useTranslation(['profile'])

    if (stats) {
        return (
            <div className="stats-block">
                <div className="stat-item">
                    <span className="number">
                        {sending ? (
                            <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                        ) : (
                            round(stats.rubric, 1)
                        )}
                    </span>
                    <div className="label">{t('profile:heading.general-average')}</div>
                </div>
                <div className="stat-item">
                    <span className="number">{stats.votes}</span>
                    <div className="label">{t('profile:heading.partner-points')}</div>
                </div>
                <div className="stat-item">
                    <span className="number">
                        {!stats.timesFacilitator ? '0' : stats.timesFacilitator}
                    </span>
                    <div className="label">{t('profile:heading.times-facilitator')}</div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="stats-block">
                <div className="stat-item">
                    <span className="number">
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                    </span>
                </div>
                <div className="stat-item">
                    <span className="number">
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                    </span>
                </div>
                <div className="stat-item">
                    <span className="number">
                        <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                    </span>
                </div>
            </div>
        )
    }
}

const StudentHeading = (props) => {
    const {
        name,
        lastname,
        photo,
        onBack,
        statusMessage,
        stats,
        onRubric,
        isEditable,
        student,
        onEdit,
        role,
    } = props
    const maxStars = 4
    const [stars, setStars] = useState()
    const [sendingRubric, setSendingRubric] = useState(false)

    const [isStatusEditable, setStatusEditable] = useState(false)
    const [statusMsg, setStatusMsg] = useState(statusMessage)

    const { titleCaseFn } = useContext(UIContext)

    const { t } = useTranslation(['profile'])

    const saveRubric =
        onRubric &&
        (async () => {
            setSendingRubric(true)
            await onRubric(stars)
            setStars()
            setSendingRubric(false)
        })
    return (
        <div className="student-heading-container">
            <div className="navigation">
                <button className="action-link" onClick={() => onBack()}>
                    <FontAwesomeIcon className="icon" icon={['fal', 'chevron-left']} />
                    <span>{t('profile:back')}</span>
                </button>
            </div>
            <div className="student-data responsive">
                <div className="avatar-container">
                    <AvatarEgg size={{ mobile: 170, desktop: 210 }} src={photo} />
                </div>
                <div className="rest-info">
                    <h2>
                        {titleCaseFn(name)} {titleCaseFn(lastname)}
                    </h2>
                    <div className="status-msg-block">
                        {
                            // Hide input if is editable & is empty
                            !isStatusEditable && !statusMsg ? null : (
                                <input
                                    disabled={!isStatusEditable}
                                    className="data status-msg global-text"
                                    value={statusMsg}
                                    onChange={(e) => {
                                        setStatusMsg(e.target.value)
                                    }}
                                    placeholder={
                                        isEditable
                                            ? t('profile:heading.status_msg')
                                            : t('profile:heading.no_status_msg')
                                    }
                                    maxLength={512}
                                />
                            )
                        }

                        {role !== 'student' && !isStatusEditable && (
                            <button
                                onClick={() => {
                                    setStatusEditable(true)
                                }}
                                className="edit-btn"
                            >
                                <FontAwesomeIcon icon={['fal', 'pencil']} />
                            </button>
                        )}

                        {isStatusEditable && (
                            <button
                                onClick={() => {
                                    setStatusEditable(false)
                                    onEdit({ ...student, statusMessage: statusMsg })
                                }}
                                className="edit-btn"
                            >
                                <FontAwesomeIcon icon={['fal', 'save']} />
                            </button>
                        )}
                    </div>

                    {role != 'student' && stats !== null && (
                        <StudentStats stats={stats} sending={sendingRubric} />
                    )}

                    {stats?.rubric && saveRubric && (
                        <div className="qualify-block">
                            <div className="stars-block">
                                {[...Array(maxStars).keys()].map((e) => (
                                    <FontAwesomeIcon
                                        key={e + 1}
                                        className={`star-icon ${e < stars ? 'active' : 'inactive'}`}
                                        icon={[e < stars ? 'fa' : 'fal', 'star']}
                                        onClick={() =>
                                            setStars(e !== 0 ? e + 1 : stars !== 1 ? 1 : undefined)
                                        }
                                    />
                                ))}
                            </div>
                            <div className="action-btn">
                                <button
                                    className="btn-qualify"
                                    disabled={!stars}
                                    onClick={saveRubric}
                                >
                                    {t('profile:qualify')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default StudentHeading
