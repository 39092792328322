import {
    Dispatch,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react'

import Axios from 'axios'
import Config from '../config'
import DayJs from 'dayjs'
import { ROLES } from '../constants'
import ReactGA from 'react-ga'
import firebase from 'firebase/app'
import jwtDecode from 'jwt-decode'
import relativeTime from 'dayjs/plugin/relativeTime'
import { setNewAccessToken } from '../apis/apis'
import storageHelper from '../helpers/storage'
import useLocalStorage from '../hooks/useLocalStorage'

DayJs.extend(relativeTime)

const notContextFn = () => {
    throw Error('Not inside Context')
}
const contextOutOfContext = {
    setRole: notContextFn as (role: ROLES) => void,
    setToken: notContextFn as (token: string) => void,
    fbUser: undefined as firebase.User | null | undefined,
    user: undefined as User | null | undefined,
    profiles: [] as Profile[],
    setUser: notContextFn as (user: Partial<User>) => Promise<IPatchUserResponse>,
    fetchUser: notContextFn as () => void,
    setUserButNotSaveIt: notContextFn as Dispatch<SetStateAction<User | null | undefined>>, //TODO: Deprecate this and handle everything inside this context
    logout: notContextFn as (next?: string) => Promise<void>,
    login: notContextFn as (email: string, password: string, force?: boolean) => Promise<void>,
    loginSuperAdmin: notContextFn as (token: string) => Promise<unknown>,
    loginWithIdToken: notContextFn as (idToken: string) => Promise<boolean>,
    verify: notContextFn as (code: string, next?: string) => Promise<unknown>,
    setProfiles: notContextFn as (profiles: Profile[]) => unknown,
    role: undefined as ROLES | undefined,
    logged: undefined as boolean | undefined,
    token: undefined as string | undefined,
    skipValidation: false as boolean,
    setSkipValidation: notContextFn as (value: boolean) => void,
    handleUploadPhoto: notContextFn as (fileToUpload: Blob, userId: string) => void,
    imageIsLoading: false as boolean,
    setImageIsLoading: notContextFn as (value: boolean) => void,
}

export type ContextInterface = typeof contextOutOfContext

export const UserContext = createContext<ContextInterface>(contextOutOfContext)
UserContext.displayName = 'UserContext'

export const useUserContextValue = () => {
    const [user, setUser] = useState<ContextInterface['user']>()
    const [role, setRole] = useLocalStorage<ROLES | undefined>('role', undefined)
    const [profiles, setProfiles] = useState<Profile[]>([])
    const [fbUser, setFBUser] = useState<ContextInterface['fbUser']>()

    const [token, setToken] = useLocalStorage<string | undefined>('token', undefined)
    const [skipValidation, setSkipValidation] = useState<boolean>(false)
    const [imageIsLoading, setImageIsLoading] = useState<boolean>(false)

    const loginWithIdToken = async (idToken: string) => {
        try {
            setSkipValidation(true)
            const { user } = await firebase.auth().signInWithCustomToken(idToken)
            if (!user) throw Error('User not found')
            setFBUser(user)
            const token = await user?.getIdToken()
            const { data } = await Axios.post<{ token: string }>(`${Config.API}/user/verifyToken`, {
                idToken: token,
            })
            setToken(data.token)
            return true
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            throw error
        }
    }

    const loginSuperAdmin = async (token: string) => {
        try {
            const { user: adminUser } = await firebase.auth().signInWithCustomToken(token)
            const idToken = await adminUser?.getIdToken()
            idToken && (await verifyIdToken(idToken))
        } catch (error) {
            return error
        }
    }

    const verifyIdToken = useCallback(
        async (idToken: string) => {
            const response = await Axios.post(Config.API + '/user/verifyToken', { idToken })
            const token = response.data.token
            setToken(token)
            return token
        },
        [setToken]
    )

    const handleUploadPhoto = async (fileToUpload: Blob, userId: string) => {
        try {
            setImageIsLoading(true)

            const formData = new FormData()
            formData.append('file', fileToUpload)
            const response = await fetch(`${process.env.REACT_APP_S3}/{${userId}`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `${token}`,
                },
            })
            if (!response.ok) throw new Error('Error uploading image')

            const json = await response.json()
            const urlImage = json.thumbnailUrl.split('?AWSAccessKeyId')[0] + `?hash=${Date.now()}`
            await saveUser({ ...user, urlImage })

            //window.location.reload()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error uploading image', error)
        } finally {
            setImageIsLoading(false)
        }
    }

    const logoutWithoutUser: ContextInterface['logout'] = async () => {
        const oldUser = { ...user }
        // Closes intercom
        if ('Intercom' in window) {
            window.Intercom('shutdown')
        }
        try {
            setUser(undefined)
            await firebase.auth().signOut()
            storageHelper.clear()
        } catch (error) {
            setUser(oldUser as User)
            throw error
        }
    }

    const refreshToken = (immediate: boolean) => {
        setNewAccessToken(token ?? null)
        if (fbUser && token) {
            const exp = jwtDecode<Token>(token).exp
            const diff = exp * 1000 - new Date().valueOf() - 10000
            if (diff > 0) {
                const time = DayJs().add(diff, 'milliseconds').fromNow()
                // eslint-disable-next-line no-console
                console.info(`The token will be refreshed ${time}`)
                const timerOut = setTimeout(
                    () => {
                        // eslint-disable-next-line no-console
                        console.info('Refreshing token...')
                        fbUser
                            .getIdToken(true)
                            .then(verifyIdToken)
                            .catch(function (error) {
                                // eslint-disable-next-line no-console
                                console.error(error)
                                logoutWithoutUser()
                            })
                    },
                    immediate ? 1000 : Math.floor(diff)
                )
                return () => clearTimeout(timerOut)
            } else {
                // eslint-disable-next-line no-console
                console.info('Expired token, refreshing it')
                // Esto genera un doble count en loginCount y genera que no se muestren los wizards @TO-DO
                fbUser
                    .getIdToken(true)
                    .then(verifyIdToken)
                    .catch(function () {
                        logoutWithoutUser()
                    })
            }
        } else if (fbUser === null) {
            setToken(undefined)
        }
    }

    useEffect(
        function AssignRole() {
            if (token) {
                const decoded = jwtDecode<Token>(token)
                decoded.role && setRole(decoded.role)
            }
        },
        [setRole, token]
    )

    useLayoutEffect(() => {
        refreshToken(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fbUser, setToken, token, verifyIdToken])

    useLayoutEffect(function AddAuthListener() {
        const unSuscribe = firebase.auth().onAuthStateChanged(setFBUser)
        return unSuscribe
    }, [])

    const saveUser: ContextInterface['setUser'] = async (_user) => {
        if (!user?._id) throw Error('User ID not found')
        const response = await Axios.patch<IPatchUserResponse>(
            Config.API + '/user/' + user._id,
            _user
        )
        setUser({ ...user, ..._user })
        return response.data
    }

    const logout: ContextInterface['logout'] = async () => {
        if (!user) throw Error('Cannot logout. The user is not logged.')
        const oldUser = { ...user }
        try {
            setUser(undefined)
            await firebase.auth().signOut()
            storageHelper.clear()
        } catch (error) {
            setUser(oldUser)
            throw error
        }
    }

    const login: ContextInterface['login'] = async (email, password, force) => {
        if ((user || user === undefined) && !force)
            throw Error('Cannot login. The user is already logged. Please, logout first')
        const { user: newUser } = await firebase.auth().signInWithEmailAndPassword(email, password)
        const idToken = await newUser?.getIdToken()
        idToken && (await verifyIdToken(idToken))

        ReactGA.event({
            category: 'Engagement',
            action: 'Login',
            dimension1: 'platform',
        })
    }

    const verify: ContextInterface['verify'] = async (code) => {
        if (!user) throw Error('Cannot verify. The user is not logged.')
        const response = await Axios.post(`${Config.API}/user/verifyEmail`, { code })
        setUser({ ...user, emailVerified: true })
        firebase.auth().currentUser?.reload()
        return response
    }

    const changeRole: ContextInterface['setRole'] = async (role: ROLES) => {
        if (!user?.roles?.includes(role)) throw Error(`The user does not have the role ${role}`)

        const response = await Axios.post(Config.API + `/user/refreshToken`, { role })
        setToken(response.data.token)
    }

    const fetchUser = useCallback(async () => {
        if (fbUser === undefined || !token) return
        if (fbUser) {
            try {
                const me = await Axios.get(Config.API + `/me`)
                setUser({
                    ...me.data.user,
                    _profileId: me?.data?.activeProfile?._id,
                    roles: me.data.roles,
                    //role: role,
                    emailVerified: fbUser.emailVerified,
                    notificationsCount: me?.data?.notifications?.count,
                    settings: me?.data?.settings,
                })
                setProfiles(me.data.profiles)

                // Updates intercom user data when fetching user
                if ('Intercom' in window) {
                    window.Intercom('update', {
                        email: me.data.user.email,
                        user_id: me.data.user._profileId,
                        name: `${me.data.user.name} ${me.data.user.lastname}`,
                        language_override: me.data.user.language,
                        created_at: me.data.user.createdAt,
                        avatar: me.data.user.urlImage
                            ? {
                                  type: 'avatar',
                                  image_url: me.data.user.urlImage,
                              }
                            : undefined,
                    })
                }

                // i18n.changeLanguage(me.data.user.language);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Comprueba tu conexión a internet.')
                refreshToken(true)
                // eslint-disable-next-line no-console
                console.error('REFRESH TOKEN 3')
                throw error
            }
        } else {
            setUser(null)
            setRole(undefined)
            setProfiles([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fbUser, token, setRole])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])

    useEffect(() => {
        if ('Intercom' in window && user)
            window.Intercom('update', {
                name: `${user.name} ${user.lastname}`,
                email: user.email,
                user_id: user._profileId,
                language_override: user.language,
                avatar: user.urlImage
                    ? {
                          type: 'avatar',
                          image_url: user.urlImage,
                      }
                    : undefined,
            })
    }, [user])

    const value: ContextInterface = {
        user,
        setUser: saveUser,
        fetchUser,
        setUserButNotSaveIt: setUser,
        profiles,
        setProfiles,
        role,
        setRole: changeRole,
        setToken,
        token,
        fbUser,
        logged: user ? true : user === null ? false : undefined,
        logout,
        login,
        verify,
        loginSuperAdmin,
        loginWithIdToken,
        skipValidation,
        setSkipValidation,
        handleUploadPhoto,
        imageIsLoading,
        setImageIsLoading,
    }

    return value
}

export const useUser = () => useContext(UserContext)

export default UserContext

export interface Profile {
    _profileId: string
    role: ROLES
    hq: {
        _id: string
        name: string
    }
    course: {
        _id: string
        name: string
    }
}

export interface User {
    _id?: string
    _profileId?: string
    name?: string
    lastname?: string
    email: string
    phone?: string
    urlImage?: string
    language?: 'es' | 'en' | 'pt'
    dni?: string
    createdAt: string
    address?: {
        street?: string
        numeration?: string
        floor?: string
        apartment?: string
        country?: string
        city?: string
    }
    statusMessage?: string
    birthdate?: string
    //language?: string,
    roles?: ROLES[]
    role?: ROLES
    emailVerified: boolean
    profiles?: Profile[]
    genre?: 'male' | 'female' | 'other'
    notificationsCount?: number | undefined
    settings: {
        mandatoryDataFill?: boolean
        avatarUploadSkipped?: boolean
        askForTermsAndConditions: boolean
    }
    termsAndConditions: boolean
}

export interface Token {
    role?: ROLES
    _profileId?: string
    exp: number
}

export interface IPatchUserResponse {
    _id: string
    email: string
    name: string
    lastname: string
    dni: string
    phone: string
    address: string //verify this
    statusMessage: string
    urlImage: string
    birthdate: string
    language: string
}
