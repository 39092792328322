import Axios from 'axios'
import { useQuery } from 'react-query'
import Config from '../../../config'

export const useProfile = (profileId: string) => {
    const queryKey = ['profile', profileId]
    const queryFn = async () => {
        const { data } = await Axios.get<GetProfileResponse>(`${Config.API}/profile/${profileId}`)
        return data.user
    }
    const { data: profile, isLoading } = useQuery(queryKey, queryFn)
    return { profile, isLoading }
}

interface GetProfileResponse {
    course: { _id: string; name: string }
    hq: { _id: string; name: string }
    user: {
        urlImage: string
        uid: string
        statusMessage: string
        name: string
        lastname: string
        email: string
        active: boolean
        _id: string
    }
    _id: string
}
