// API
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Axios from 'axios'
// Helpers
import useDebounce from '../../hooks/useDebounce'
import Config from '../../config'
// Context
import { WizardContext } from '../../context/WizardContext'
import { UIContext } from '../../context/UIContext'
// Components
import Step2View from './Step2View'

const Step2 = () => {
    const {
        courseSelected,
        maxEnrollStudentSelected,
        setCourseSelected,
        nextStep,
        hqSelected,
        prevStep,
    } = useContext(WizardContext)
    const [isNextStepEnabled, setIsNextStepEnabled] = useState(courseSelected ? true : false)

    const [courseName, setCourseName] = useState(courseSelected ? courseSelected.name : '')
    const [maxEnrollStudents, setMaxEnrollStudents] = useState(
        maxEnrollStudentSelected ? maxEnrollStudentSelected : 50
    )
    const [suggestionCourses, setSuggestionCourses] = useState([])
    const [isSearching, setIsSearching] = useState(false)

    const [error] = useState('')
    const [success] = useState('')

    const [debouncedSearchTerm] = useDebounce(courseName, 400)

    // Sets infoBlock error / success
    const { setInfoBlock } = useContext(UIContext)

    const getCoursesByName = useCallback(
        async (name) => {
            if (!courseSelected) {
                setIsSearching(true)
                try {
                    const response = await Axios.get(
                        Config.API + '/hq/' + hqSelected._id + '/classes?name=' + name
                    )
                    setSuggestionCourses(response.data)
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('error courses by name', error)
                    setInfoBlock([
                        'error',
                        error.data.message
                            ? error.data.message
                            : 'Ups! Ocurrió un error obteniendo resultados recomendados.',
                    ])
                } finally {
                    setIsSearching(false)
                    setTimeout(() => {
                        setInfoBlock([])
                    }, 3000)
                }
            }
        },
        [courseSelected, hqSelected._id, setInfoBlock]
    )

    useEffect(() => {
        if (!hqSelected.isNew) {
            if (debouncedSearchTerm) {
                getCoursesByName(debouncedSearchTerm)
            } else {
                setSuggestionCourses([])
            }
        }
    }, [debouncedSearchTerm, getCoursesByName, hqSelected.isNew])

    const onInputChange = (value) => {
        if (value.length === 0) {
            setCourseSelected(null)
            setIsNextStepEnabled(false)
        } else {
            setIsNextStepEnabled(true)
        }
        setCourseName(value)
    }

    const onClickCourse = (course) => {
        return () => {
            setSuggestionCourses([])
            setCourseName(course.name)
            setCourseSelected({
                _id: course._id,
                name: course.name,
                isNew: false,
                maxEnrollStudents: Number(maxEnrollStudents),
            })
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!courseSelected) {
            setCourseSelected({
                name: courseName,
                isNew: true,
                maxEnrollStudents: Number(maxEnrollStudents),
            })
        }
        nextStep()
    }

    return (
        <Step2View
            onInputChange={onInputChange}
            hqSelected={hqSelected}
            prevStep={prevStep}
            nextStep={nextStep}
            isNextStepEnabled={isNextStepEnabled}
            suggestionCourses={suggestionCourses}
            maxEnrollStudents={maxEnrollStudents}
            setMaxEnrollStudents={setMaxEnrollStudents}
            onClickCourse={onClickCourse}
            getCoursesByName={getCoursesByName}
            courseName={courseName}
            courseSelected={courseSelected}
            setCourseName={setCourseName}
            success={success}
            error={error}
            onSubmit={onSubmit}
            isSearching={isSearching}
        />
    )
}

export default Step2
