import React, { useContext, useState, useEffect } from 'react'
import TeamManagerView from './TeamManagerView'
import LoadingPage from '../../Other/LoadingPage'
// Hooks
import { useTranslation } from 'react-i18next'
import { ROLES } from '../../../constants'
import { UserContext } from '../../../context/UserContext'
import { SubjectContext } from '../../../context/SubjectContext'
import ManagerContext, { useManagerContextValue } from '../../../context/ManagerContext'
import Axios from 'axios'
import Config from '../../../config'
import { useUIContext } from '../../../context/UIContext'
import ReactGA from 'react-ga'
import { useTeam } from '../../Team/Context/TeamContext'

const TeamManagerContainer = () => {
    const { toastNotification } = useUIContext()
    const [ready, setReady] = useState(false)
    const { t } = useTranslation(['teamManager'])

    // Why to import from subjectContext here and in the view?
    const {
        professorScheduleId,
        subject,
        assignation,
        unassigments,
        members,
        course,
        hq,
        algorithmId,
        teamsNumbers,
    } = useContext(SubjectContext)
    const { role } = useContext(UserContext)

    const value = useManagerContextValue()

    useEffect(() => {
        setReady(assignation !== undefined && unassigments !== undefined)
    }, [assignation, unassigments])

    // Search to api handling
    const mentorsSearch = useState<iGetMentorsProps | null>(null)
    const mentorsResult = useState<iGetMentorsReturn | null>(null)
    const [searchResult, setSearchResult] = useState<iSearchParsedResult | null>(null)
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const { searchBox } = useUIContext()
    const { team } = useTeam()

    /** Perform search */
    useEffect(() => {
        if (!searchBox[0]?.query.trim() || isSearching || !teamsNumbers)
            return setSearchResult(null)

        const { type, query } = searchBox[0]

        // Check if valid team number
        const reg = new RegExp('^[0-9]*$')
        const isValidTeam = teamsNumbers.some((team) => team === +query)

        if (type === 'teams' && (!reg.test(query) || !isValidTeam)) {
            searchBox[1](null)
            return toastNotification(t('teamManager:search.error-invalid-team'), 'error')
        }

        setIsSearching(true)

        // Searching people
        if (type === 'people')
            Axios.get<iSearchByPeople['data']>(
                `${Config.API}/v3/course/${course?.id}/students?skip=0&limit=10&search=${query}`
            )
                .then(({ data }) => setSearchResult(parsePeopleSearchResult(data)))
                .catch(() => toastNotification(t('errors:genericRetry'), 'error'))
                .finally(() => {
                    setIsSearching(false)
                    ReactGA.event({
                        category: 'Team Management',
                        action: 'SearchBar',
                        label: 'team member',
                        dimension1: 'platform',
                        dimension2: hq?.name.toLowerCase(),
                        dimension3: course?.name.toLowerCase(),
                        dimension4: subject?.name.toLowerCase(),
                        dimension6: 'Unassign',
                        dimension7: role,
                        dimension9: team?.algorithm
                            ? team?.algorithm?.algorithmType
                            : 'no algorithm available',
                    })
                })

        // Searching teams
        if (type === 'teams')
            Axios.get<iSearchByTeam['data']>(
                `${Config.API}/v2/course/${course?.id}/algorithm/last?tableNumber=${query}`
            )
                .then(({ data }) => setSearchResult(parseTeamSearchResult(data)))
                .catch(() => toastNotification(t('errors:genericRetry'), 'error'))
                .finally(() => {
                    setIsSearching(false)
                    ReactGA.event({
                        category: 'Team Management',
                        action: 'SearchBar',
                        label: 'team',
                        dimension1: 'platform',
                        dimension2: hq?.name.toLowerCase(),
                        dimension3: course?.name.toLowerCase(),
                        dimension4: subject?.name.toLowerCase(),
                        dimension7: role,
                        dimension9: team?.algorithm
                            ? team?.algorithm?.algorithmType
                            : 'no algorithm available',
                    })
                })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchBox[0], assignation, unassigments])

    const getMentors = ({ algorithm, team, guide }: iGetMentorsProps) => {
        mentorsResult[1]({
            team,
            guide,
            mentors: [],
            integration: [],
        })
        const endpoint = `${Config.API}/v2/algorithm/${algorithm}/mentors/${team}`
        Axios.get<iGetMentors['data']>(endpoint)
            .then(({ data }) => {
                mentorsResult[1]({
                    team,
                    guide,
                    mentors: [...data.tableMentors],
                    integration: [...data.integrationMentors],
                })
            })
            .catch(() => toastNotification(t('errors:genericRetry'), 'error'))
    }

    const showMentorsTeam = (
        team: iGetMentorsProps['team'],
        guide: iGetMentorsProps['guide'],
        algorithm: iGetMentorsProps['algorithm'] | undefined = algorithmId
    ) =>
        algorithm &&
        mentorsSearch[1]({
            team,
            guide,
            algorithm,
        })

    /** Searching mentor  */
    useEffect(() => {
        const [searchMentorTeam] = mentorsSearch
        searchMentorTeam && getMentors(searchMentorTeam)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mentorsSearch[0]])

    if (!ready) return <LoadingPage />

    const mainLinks = [
        {
            name: t('teamManager:main-links.room'),
            url: `/admin/overview/${course?.id}`,
        },
        {
            name: t('teamManager:main-links.students'),
            url: `/admin/team/${course?.id}`,
            active: true,
        },
    ]

    // Add Setting menu
    role !== ROLES.PROFESSOR &&
        mainLinks.push({
            name: t('teamManager:main-links.settings'),
            url: `/team/${professorScheduleId}/subject/${subject?.id}/settings`,
        })

    return (
        <ManagerContext.Provider value={value}>
            <TeamManagerView
                mainLinks={mainLinks}
                unassignedStudents={unassigments ?? []}
                teachers={members?.professor}
                hqName={hq && hq.name ? hq.name : ''}
                courseName={course && course.name ? course.name : ''}
                search={{
                    isSearching,
                    searchResult,
                    setSearchResult,
                    mentorsSearch,
                    mentorsResult,
                    showMentorsTeam,
                }}
            />
        </ManagerContext.Provider>
    )
}

export default TeamManagerContainer

const parseTeamSearchResult = (data: iSearchByTeam['data']): iSearchParsedResult => {
    const tableNumber = data.results[0].tableNumber
    return {
        searchType: 'teams',
        team: {
            id: data.course._id,
            type: data.course.type,
            table: data.results[0].tableNumber,
            isMentorTable: !!data.results[0].isMentorTable,
            guide: data.results[0].guide || null,
            module: data.results[0].module || null,
        },
        people: [
            ...data.results[0].students.map((std) => ({
                ...std,
                tableNumber,
            })),
        ],
    }
}

const parsePeopleSearchResult = (data: iSearchByPeople['data']): iSearchParsedResult => ({
    searchType: 'people',
    team: null,
    people: [...data.results],
})

// Interface

export interface iSearchParsedResult {
    searchType: 'people' | 'teams'
    people: iUserSearchResult[]
    team: {
        id: string
        table: number
        type: 'default' | 'progressive'
        isMentorTable: boolean
        guide: number | null
        module: number | null
    } | null
}

export interface iStudentSearchResult {
    data: {
        results: {
            _profileId: string
            name: string
            lastname: string
            email: string
            searchAssigned: 'assigned' | 'unassigned'
            urlImage?: string
            tableNumber?: number
        }[]
        pagination: {
            total: number
        }
    }
    message: string
}

interface iSearchByPeople {
    data: {
        results: iUserSearchResult[]
        pagination: {
            total: number
        }
    }
    message: string
}

interface iSearchByTeam {
    data: {
        hasAlgorithm: boolean
        _algorithmId: string
        course: {
            _id: string
            type: 'default' | 'progressive'
            enabledManualAssign: boolean
            rubricContent: boolean
            uploadExamContent: boolean
        }
        results: {
            tableNumber: number
            isMentorTable: boolean
            facilitator: string
            guide?: number
            module?: number
            students: {
                name: string
                lastname: string
                email: string
                _profileId: string
                urlImage: string
                searchAssigned: 'assigned' | 'unassigned'
            }[]
        }[]
        pagination: {
            totalTables: number
        }
    }
    message: string
}

interface iUserSearchResult {
    name: string
    lastname: string
    email: string
    _profileId: string
    urlImage: string
    searchAssigned: 'assigned' | 'unassigned'
    tableNumber: number
}

interface iGetMentors {
    data: {
        tableMentors: {
            name: string
            lastname: string
            email: string
            _profileId: string
            urlImage: string
        }[]
        integrationMentors: {
            name: string
            lastname: string
            email: string
            _profileId: string
            urlImage: string
        }[]
    }
    message: string
}

export interface iGetMentorsProps {
    algorithm: string
    team: number
    guide: number
}

export interface iGetMentorsReturn {
    team: number
    guide: number
    mentors: {
        name: string
        lastname: string
        email: string
        _profileId: string
        urlImage: string
    }[]
    integration: Record<string, unknown>[]
}
