/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import AvatarEgg from '../../components/Styled/AvatarEgg'
import { ToolTipSmall } from '../../components/Styled/ToolTipSmall'

const Leaders = styled.div`
    align-items: center;
    margin-right: 20px;
    margin-left: 15px;
    margin-top: 20px;
    display: none;
    @media screen and (min-width: 768px) {
        display: flex;
        margin-top: 0;
    }
    .leader-item {
        border: 4px solid #f7f8f8;
        border-radius: 50px;
    }
`

const Leader = styled.div`
    border: 4px solid #f7f8f8;
    border-radius: 50px;
    margin-left: -12px;
    z-index: ${(props) => props.idx + 5 - props.idx * 2};
`

const LeadersBlock = ({ owners }) => {
    const [toolTipShow, setToolTipShow] = useState(undefined)
    const { t } = useTranslation(['teamDashboard'])
    return (
        <Leaders>
            {owners?.slice(0, 3).map((item, index) => {
                return (
                    <Leader
                        // eslint-disable-next-line no-undef
                        onMouseOver={() => setToolTipShow(item.user._id)}
                        onMouseLeave={() => setToolTipShow(undefined)}
                        className="leader-item"
                        style={{ position: 'relative' }}
                        key={item.user._id}
                        idx={index}
                    >
                        <AvatarEgg
                            title={`${item.user.name} ${item.user.lastname}`}
                            src={item.user.urlImage}
                            size={{ mobile: 48, desktop: 54 }}
                            badge={
                                item.profile.role === 'professor'
                                    ? ['fas', 'cog', item.profile.role]
                                    : ['fal', 'chalkboard-teacher', item.profile.role]
                            }
                        />
                        {toolTipShow === item.user._id && (
                            <ToolTipSmall className="toolTips" position={{ top: 60, left: -20 }}>
                                {item.profile.role === 'professor'
                                    ? t('roles.professor')
                                    : t('roles.tutor')}
                            </ToolTipSmall>
                        )}
                    </Leader>
                )
            })}
            {owners?.length > 3 && (
                <Leader lassName="leader-item">
                    <AvatarEgg
                        title={`Plus ${owners?.length - 3} coachs `}
                        size={54}
                        badge={['fal', 'plus']}
                    />
                </Leader>
            )}
        </Leaders>
    )
}

export default LeadersBlock
