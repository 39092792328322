import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BtnCircular } from '../../../components/Buttons/BtnCircular'
import { colors } from '../../../styles/globals/_variables'
import { useTeam } from '../Context/TeamContext'
import Styles from './CoachItem.module.css'

export const CoachItem = (props: CoachItemProps) => {
    const { t } = useTranslation('teamManager')
    const [isOpen, setIsOpen] = useState(false)
    const { team } = useTeam()
    const { displayName, urlImage, _profileId } = props
    const profileUrl = `/team/${team?.professorSchedule._id}/subject/${team?.subject._id}/profile/${_profileId}`
    const size = 62
    return (
        <div className={Styles.coachItem}>
            <div className={Styles.info}>
                <div className={Styles.avatar}>
                    {urlImage ? (
                        <img
                            src={urlImage}
                            alt={displayName}
                            title={displayName}
                            style={{
                                width: size,
                                height: size,
                                borderRadius: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <BtnCircular
                            icon={['fal', 'user-circle']}
                            title={displayName}
                            style={{
                                color: '#dadada',
                                width: size,
                                height: size,
                            }}
                        />
                    )}
                </div>
                <div className={Styles.label} style={{ fontSize: 16, color: colors.darkGreyEgg }}>
                    {displayName}
                </div>
            </div>
            <div className={Styles.options}>
                <BtnCircular
                    icon="ellipsis-h"
                    style={{
                        marginLeft: '0.5em',
                        width: 32,
                        height: 32,
                        fontSize: 15,
                        borderColor: '#dadada',
                        background: '#ffffff',
                    }}
                    isOpen={isOpen}
                    onClick={() => setIsOpen((prev) => !prev)}
                    options={[
                        {
                            label: t('user-options.profile'),
                            onClick: () => (location.href = profileUrl),
                        },
                    ]}
                />
            </div>
        </div>
    )
}

// Interfaces
interface CoachItemProps {
    _profileId: string
    displayName: string
    urlImage?: string
}
