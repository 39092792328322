import { ActionsView, useTeam } from '../../Context/TeamContext'
import { ROLES, isRoleAllowed } from '../../../../constants'
import React, { useEffect } from 'react'

import TabMenu from '../../../../components/DesignSystem/components/molecules/TabMenu/TabMenu.component'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../../context/UserContext'

export const ActionsMenu = () => {
    const { t } = useTranslation('teamManager')
    const { team, actionsView } = useTeam()
    const { role } = useUser()
    const [actions, setActions] = actionsView

    // Disable re arrange only when this flag is false
    const canRearrange = team?.settings?.enabledManualAssign !== false

    const handleKeyPress = (event: KeyboardEvent) => {
        const { target, key } = event
        if (target !== document.body || typeof key !== 'string') return

        const keyToLower = key.toLocaleUpperCase()

        switch (keyToLower) {
            case 'A':
                setActions('attendance')
                break
            case 'S':
                team?.algorithm && setActions('stats')
                break
            case 'R':
                team?.algorithm && canRearrange && setActions('rearrange')
                break
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', (event) => handleKeyPress(event))
        return () => document.removeEventListener('keyup', (event) => handleKeyPress(event))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!team) return null

    const customClassName = (action: string) => (actions === action ? 'selected' : '')

    const actionTitles = {
        attendance: {
            disabled: false,
            shortcut: 'A',
            title: t('switcher.assistance'),
        },
        stats: {
            disabled: !team.algorithm,
            shortcut: 'S',
            title: t('switcher.stats'),
        },
        ...(isRoleAllowed(ROLES.COACH, role) && {
            rearrange: {
                disabled: !canRearrange && !team.algorithm,
                shortcut: 'R',
                title: t('switcher.reassign'),
            },
        }),
    }

    const tabsActions = (action: ActionsView) => ({
        children: actionTitles[action]?.title,
        className: customClassName(action),
        disabled: actionTitles[action]?.disabled,
        onClick: () => setActions(action),
        title: `[${actionTitles[action]?.shortcut}] ${actionTitles[action]?.title}`,
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actionsArray = Object.keys(actionTitles).map((action: any) => tabsActions(action))

    return <TabMenu actions={actionsArray} />
}
