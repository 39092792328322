import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface PaginationProps {
    /** This is the main callback when the user interacts with the component. */
    onChange: (page: number, event: React.MouseEvent) => void

    /** The total number of pages */
    count: number | undefined

    /** The number of pages displayed when there are two ellipsis (the user is in the middle of a long pagination) */
    middles: number

    /** The number of pages displayed when there is at least one ellipsis on the start or the beggining */
    boundary: number

    /** The current page selected */
    page?: number
}

export const Pagination = (props: PaginationProps) => {
    const { onChange, count, middles, boundary } = props

    const [page, setPage] = useState(props.page || 1)
    const changePage = (page: number) => (event: React.MouseEvent) => {
        setPage(page)
        onChange && onChange(page, event)
    }

    useEffect(() => {
        setPage(props.page || 1)
    }, [props.page])

    if (!count) {
        return (
            <div className="pagination" data-testid="pagination">
                <div className="nav-btn first-page-btn">
                    <button disabled>
                        <FontAwesomeIcon className="icon" icon={['fal', 'arrow-circle-left']} />
                    </button>
                </div>
                <div className="nav-btn middle-page-btn">
                    <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
                </div>
                <div className="nav-btn last-page-btn">
                    <button disabled>
                        <FontAwesomeIcon className="icon" icon={['fal', 'arrow-circle-right']} />
                    </button>
                </div>
            </div>
        )
    }

    const buttons = []
    const upperSiblins = Math.ceil((middles - 1) / 2)
    const lowerSiblins = Math.floor((middles - 1) / 2)
    const maximumLen = 2 * boundary + middles + 2
    if (count <= maximumLen) {
        // No ellipsis
        for (let i = 1; i <= count; i++) buttons.push(i)
    } else {
        // Some ellipsis
        if (boundary + 1 >= page - lowerSiblins) {
            //Ellipsis at the beggining
            for (let i = 1; i <= maximumLen - boundary - 1; i++) buttons.push(i)
            buttons.push(null)
            for (let i = count - boundary + 1; i <= count; i++) buttons.push(i)
        } else if (page + upperSiblins + 1 >= count - boundary) {
            //Ellipsis at the end
            for (let i = 1; i <= boundary; i++) buttons.push(i)
            buttons.push(null)
            for (let i = count + boundary - maximumLen + 2; i <= count; i++) buttons.push(i)
        } else {
            //Two ellipsis
            for (let i = 1; i <= boundary; i++) buttons.push(i)
            buttons.push(null)
            for (let i = page - lowerSiblins; i <= page + upperSiblins; i++) buttons.push(i)
            buttons.push(null)
            for (let i = count - boundary + 1; i <= count; i++) buttons.push(i)
        }
    }

    return (
        <div className="pagination">
            <div className="nav-btn first-page-btn">
                <button disabled={page === 1} onClick={changePage(page - 1)}>
                    <FontAwesomeIcon className="icon" icon={['fal', 'arrow-circle-left']} />
                </button>
            </div>
            <div className="nav-btn middle-page-btn">
                {buttons.map((e, i) =>
                    e !== null ? (
                        <button
                            key={e}
                            onClick={changePage(e)}
                            className={e === page ? 'active' : 'inactive'}
                        >
                            {e}
                        </button>
                    ) : (
                        <FontAwesomeIcon key={`ellipsis-${i + 1}`} icon={['fas', 'ellipsis-h']} />
                    )
                )}
            </div>
            <div className="nav-btn last-page-btn">
                <button onClick={changePage(page + 1)} disabled={page === count}>
                    <FontAwesomeIcon className="icon" icon={['fal', 'arrow-circle-right']} />
                </button>
            </div>
        </div>
    )
}

export default Pagination
