import firebase from 'firebase/app'
// import package
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/storage'
import 'firebase/messaging'
import 'firebase/functions'
import 'firebase/database'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    // measurementId: process.env.REACT_APP_GAID
}

const firebaseInit = firebase.initializeApp(firebaseConfig)
export default firebaseInit
