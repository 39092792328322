import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode, useState } from 'react'
import InfoModal, { IInfoModalProps } from '../../../../../components/InfoModal'
import { useUIContext } from '../../../../../context/UIContext'
import Styles from './CardsHeader.module.css'

export const CardsHeader = ({ title, subtitle }: CardsHeaderProps) => {
    if (!title && !subtitle) return null
    return (
        <header className={Styles.header}>
            <div className={Styles.title}>{title}</div>
            {subtitle && <div className={Styles.subtitle}>{subtitle}</div>}
        </header>
    )
}

export const CardInfo = ({ info }: Pick<CardsHeaderProps, 'info'>) => {
    const [showInfo, setShowInfo] = useState(false)

    if (!info) return null

    return (
        <>
            {showInfo && (
                <div className={Styles.cardInfo} onMouseLeave={() => setShowInfo(false)}>
                    {info}
                </div>
            )}
            <button
                className={Styles.showInfo}
                style={{
                    border: '1px solid #f2f2f2',
                    borderRadius: '5px',
                    fontSize: '12px',
                    padding: '5px 6px',
                }}
                onMouseEnter={() => setShowInfo(true)}
                data-testid="btn-info"
            >
                <FontAwesomeIcon icon={['fas', 'circle-info']} />
            </button>
        </>
    )
}

export const CardsExpand = ({
    content,
    enabled,
}: {
    content?: IInfoModalProps
    enabled?: boolean
}) => {
    const { setModal } = useUIContext()

    return (
        <>
            <button
                disabled={!enabled}
                onClick={() => setModal({ content: <InfoModal {...content} /> })}
                style={{
                    background: '0px center',
                    border: '1px solid #F2F2F2',
                    borderRadius: '5px',
                    color: 'inherit',
                    cursor: enabled ? 'pointer' : 'not-allowed',
                    fontSize: '14px',
                    height: '26px',
                    minWidth: 'unset',
                    padding: '4px',
                    position: 'absolute',
                    right: '58px',
                    top: '25px',
                    width: '26px',
                }}
            >
                <FontAwesomeIcon icon={['fas', 'arrow-up-right-and-arrow-down-left-from-center']} />
            </button>
        </>
    )
}

export interface CardsHeaderProps {
    expand?: {
        enabled?: boolean
        content?: IInfoModalProps
    }
    info?: ReactNode
    subtitle?: ReactNode
    title: ReactNode
}
