import { getGeneralMeetToken, getTeamMeetToken } from '../../../apis/api.hca'

import { Team } from '../Components/TeamHeader'
import { capitalize } from '../../../helpers/formatter'
import { useTeam } from '../Context/TeamContext'
import { useUIContext } from '../../../context/UIContext'
import { useUser } from '../../../context/UserContext'

const { REACT_APP_VIDEO_SDK_URL } = process.env

export const useOpenMeet = () => {
    const { team, algorithmID } = useTeam()
    const { token: accessToken, user, profiles } = useUser()
    const { toastError } = useUIContext()

    const { name, lastname } = user ?? {}
    const userName = capitalize(`${name} ${lastname}`)

    const openTeamMeet = async ({
        number,
        isMentorTeam,
    }: Partial<Pick<Team, 'number' | 'isMentorTeam'>>) => {
        if (!team || !user || !team.settings?.meeting) return

        const { meeting } = team.settings
        try {
            let meetUrl: string | null = null

            switch (meeting.type) {
                case 'zoom-sdk':
                case 'zoom-sdk-auto':
                case 'egg': {
                    const profile = profiles.find(({ course }) => course._id === team.course._id)
                    if (!profile) return
                    const { _profileId } = profile
                    const _classId = team.course._id

                    const { token: meetToken, topic } = await getTeamMeetToken({
                        tableNumber: number,
                        tableType: isMentorTeam ? 'm' : 't',
                        algorithmId: algorithmID,
                        _classId,
                        _profileId,
                    })

                    meetUrl = encodeURI(
                        `${REACT_APP_VIDEO_SDK_URL}/meeting?topic=${topic}&zoomToken=${meetToken}&widgetToken=${accessToken}&name=${userName}&courseName=${team.teamName}&_classId=${_classId}`
                    )
                    break
                }
            }

            if (meetUrl) window.open(meetUrl, '_blank')
        } catch (error) {
            toastError(error)
        }
    }

    const openGeneralMeet = async () => {
        if (!team || !user || !team.settings?.meeting) return

        const { meeting } = team.settings

        try {
            let meetUrl: string | null = null

            switch (meeting.type) {
                case 'egg': {
                    const profile = profiles.find(({ course }) => course._id === team.course._id)
                    if (!profile) return
                    const { _profileId } = profile
                    const _classId = team.course._id

                    const { token: meetToken, topic } = await getGeneralMeetToken({
                        _classId,
                        _profileId,
                    })

                    meetUrl = encodeURI(
                        `${REACT_APP_VIDEO_SDK_URL}/meeting?topic=${topic}&zoomToken=${meetToken}&widgetToken=${accessToken}&name=${userName}&courseName=${team.teamName}&_classId=${_classId}`
                    )
                    break
                }
                case 'zoom-hybrid':
                    meetUrl = meeting.url[0].zoomURL
                    break
                default:
                    meetUrl = meeting.url ?? null
            }

            if (meetUrl) window.open(meetUrl, '_blank')
        } catch (error) {
            toastError(error)
        }
    }

    return { openTeamMeet, openGeneralMeet }
}
