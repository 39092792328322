import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LoadingPage from '../../../../Other/LoadingPage'
import { getValuationCsv } from '../../../api-reports'
import { useCsvReport } from '../../../Hooks/useCsvReport'
import { useValuation } from '../../../Hooks/useValuation'
import { colors, PropsReports } from '../../../Reports'
import { ChartComponent } from '../ChartComponent'
import { ReportContainer } from '../ReportContainer'

export const ValuationReports = ({ pieChartOptions }: PropsReports) => {
    const { t } = useTranslation('teamReports')
    const { getFilteredReport } = useCsvReport()
    const { valuation, isLoading } = useValuation()

    const { csatBarChart, pieChart, scoreBarChart } = valuation || {}

    const { yellow, green, red, grey, lightBlue, eggBlue } = colors

    const transformCSATData = () => {
        if (!pieChart) return null
        const header = ['reference', 'total']

        const rows = pieChart?.slice(1).map((row) => {
            const status = t(row[0] as string)
            const total = row[1] as number
            return [status, total]
        })

        return [header, ...rows]
    }

    const transformLineData = csatBarChart
        ? csatBarChart.map((row, index) => {
              if (index === 0) {
                  return [
                      row[0],
                      row[row.length - 1],
                      {
                          role: 'annotation',
                          type: 'string',
                          calc: 'stringify',
                      },
                  ]
              } else {
                  return [row[0], row[row.length - 1], `${row[row.length - 1]}%`]
              }
          })
        : null
    const transformScoreData = () => {
        if (!scoreBarChart) return null
        const columnNames = ['instance', '5 ⭐', '4 ⭐', '3 ⭐', '2 ⭐', '1 ⭐', 'totals']

        const header = columnNames.flatMap((name, index) =>
            index === 0
                ? [name]
                : [
                      name,
                      {
                          role: 'annotation',
                          type: name === 'totals' ? 'number' : 'string',
                          calc: 'stringify',
                      },
                  ]
        )

        const transformedData = (scoreBarChart as DataEntry[]).slice(1).map((row: DataEntry) => {
            const [instance, five, four, three, two, one] = row
            const numberRow = row.slice(1).map((value) => value as number)
            const totals = numberRow.reduce((acc, value) => acc + value, 0)
            const maxHeight = getMaxValuePlusPercent(scoreBarChart)

            return [
                instance,
                five,
                five,
                four,
                four,
                three,
                three,
                two,
                two,
                one,
                one,
                maxHeight - totals,
                totals,
            ]
        })
        return [header, ...transformedData]
    }

    const scoreData = transformScoreData()

    if (isLoading) return <LoadingPage />

    return (
        <AssessmentReportsStyled>
            <ReportContainer
                header={{
                    title: 'Customer Satisfaction Score (CSAT)',
                    onClick: () => getFilteredReport(getValuationCsv, 'csat'),
                    disabled: !pieChart && !csatBarChart && !scoreBarChart,
                }}
                content={
                    <>
                        <ChartComponent
                            chartType="PieChart"
                            data={transformCSATData()}
                            width={pieChartOptions?.width}
                            options={{
                                title: 'CSAT acumulado',
                                titleTextStyle: {
                                    fontSize: 14,
                                },
                                pieSliceTextStyle: {
                                    fontSize: 12,
                                },
                                colors: [yellow, lightBlue, red],
                                chartArea: {
                                    left: 20,
                                    top: 40,
                                    right: 20,
                                    bottom: 60,
                                },
                                legend: { position: 'bottom' },
                            }}
                        />
                        <ChartComponent
                            chartType="ColumnChart"
                            data={transformLineData}
                            options={{
                                title: 'CSAT por encuentro',
                                titleTextStyle: {
                                    fontSize: 14,
                                },
                                legend: { position: 'none' },
                                vAxis: {
                                    viewWindowMode: 'pretty',
                                    viewWindow: {
                                        max: 100,
                                        min: 0,
                                    },
                                },
                                bar: { groupWidth: '50' },
                                chartArea: {
                                    left: 50,
                                    top: 50,
                                    right: 20,
                                    bottom: 60,
                                    width: '100%',
                                    height: '100%',
                                },
                                colors: [eggBlue],
                                hAxis: {
                                    slantedText: true,
                                },
                            }}
                        />
                    </>
                }
                className="row-display"
            />
            <ReportContainer
                header={{
                    title: 'Score',
                }}
                content={
                    <ChartComponent
                        chartType="ColumnChart"
                        data={scoreData}
                        height={'500px'}
                        options={{
                            legend: { position: 'top' },
                            isStacked: true,
                            colors: [yellow, green, lightBlue, grey, red, 'transparent'],
                            bar: { groupWidth: '50' },
                            chartArea: {
                                left: 70,
                                top: 70,
                                right: 40,
                                bottom: 70,
                            },
                            series: {
                                5: {
                                    visibleInLegend: false,
                                    annotations: {
                                        textStyle: {
                                            fontSize: 16,
                                            bold: true,
                                            color: 'black',
                                        },
                                    },
                                },
                            },
                            theme: {
                                annotations: {
                                    textStyle: { fontSize: 14 },
                                },
                            },
                            vAxis: {
                                title: t('Score'),
                                viewWindow: {
                                    max: scoreBarChart && getMaxValuePlusPercent(scoreBarChart),
                                },
                            },
                            hAxis: {
                                slantedText: true,
                            },
                        }}
                    />
                }
            />
        </AssessmentReportsStyled>
    )
}

export const getMaxValuePlusPercent = (data: (string | number)[][]) => {
    const totals = data.slice(1).map((arr) => {
        const total = arr.reduce(
            (sum: number, val: string | number) => (typeof val === 'number' ? sum + val : sum),
            0
        )
        return total
    })

    const maxVal = Math.max(...totals)

    return maxVal * 1.2
}

type DataEntry = [string, number, number, number, number, number]

const AssessmentReportsStyled = styled.section`
    & > .chart > .row-display {
        display: grid;
        grid-template-columns: 25% 74%;
        gap: 1em;
        & > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            & > * {
                border-bottom: none;
            }
        }
    }
    .table-container {
        .google-visualization-table,
        .google-visualization-table > * {
            width: 100%;
        }
        .header-row,
        .table-row {
            height: 50px;
        }
        .header-row {
            background-color: lightGray;
        }
        .table-row {
            background-color: white;
        }
        .table-cell {
            text-align: center;
        }
        .table-cell:last-child {
            text-align: left;
        }
    }
`
