import React from 'react'
import StudentImportView from './StudentImportView'

const StudentImportContainer = () => {
    return (
        <div>
            <StudentImportView />
        </div>
    )
}

export default StudentImportContainer
