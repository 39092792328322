/**
 * Documentation: docs/services/socket-service.md
 *                docs/actions.md
 */

import React, { createContext, useContext, useEffect, useRef } from 'react'
import { io, Socket } from 'socket.io-client'
import { useActions } from '../hooks/useActions'
import { ActionsMap } from '../interfaces/actions'
import UserContext from './UserContext'

const debug = process.env.NODE_ENV === 'development' ? true : false

const Context = createContext<Partial<ContextTypes>>({})
Context.displayName = 'WebSocketContext'

const server: string = process.env.REACT_APP_WEBSOCKET + ''

export const SocketContextProvider = ({ children }: { children: React.ReactNode }) => {
    const socket = useRef<Socket | null>(null)
    const { ActionsDispatch } = useActions()
    const { token } = useContext(UserContext)

    const emit = (eventName: string, payload?: Record<string, unknown>) => {
        if (!socket.current) throw '❗ Socket connection not available.'
        return socket.current.emit(eventName, payload)
    }
    const volatileEmit = (eventName: string, payload?: Record<string, unknown>) => {
        if (!socket.current) throw '❗ Socket connection not available.'
        return socket.current.volatile.emit(eventName, payload)
    }

    // Initializing socket connection
    useEffect(() => {
        if (!token) return
        // Create connection
        socket.current = io(server, {
            query: {
                token,
            },
        })

        socket.current.on('connect', () => {
            debug &&
                // eslint-disable-next-line no-console
                console.log(
                    `✅ Connecting to socket server ${server} with socketID ${socket.current?.id}`
                )
        })

        socket.current.on('disconnect', (reason, details) => {
            // eslint-disable-next-line no-console
            console.error(`❗ Disconnect from socket server`, { reason, details })
        })

        // On recibe events from server
        socket.current.onAny((event: keyof ActionsMap, payload) => ActionsDispatch(event, payload))

        return () => {
            socket.current?.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    return <Context.Provider value={{ emit, volatileEmit }}>{children}</Context.Provider>
}

export const useSocket = () => useContext(Context)

// Interfaces
interface ContextTypes {
    isAvailable: boolean
    emit: (eventName: string, payload?: Record<string, unknown>) => Socket
    volatileEmit: (eventName: string, payload?: Record<string, unknown>) => Socket
}
