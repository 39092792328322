import React, { useContext } from 'react'
import ManagerContext, { ActionView } from '../../../../context/ManagerContext'
import ExamHeader from './Headers/ExamHeader'
import ReportHeaderDropdown from './Headers/ReportHeaderDropdown'
import { useTranslation } from 'react-i18next'
import { AttendanceReport } from './Headers/AttendanceReport'
import UserContext from '../../../../context/UserContext'
import { SubjectContext } from '../../../../context/SubjectContext'
import ReactGA from 'react-ga'
import { useTeam } from '../../../Team/Context/TeamContext'

const ActionHeader = () => {
    const { actionView, onStatsReport, onFacilitatorReport } = useContext(ManagerContext)
    const { role } = useContext(UserContext)
    const { hq, course, subject } = useContext(SubjectContext)
    const { t } = useTranslation(['teamManager'])
    const { team } = useTeam()

    switch (actionView) {
        case ActionView.Attendance:
            return <AttendanceReport />

        case ActionView.Stats:
            return (
                <div className="manager-action-header">
                    <ReportHeaderDropdown
                        label={t('actions.attendance.report-recognizing')}
                        icon={['far', 'file-download']}
                        className="floating-right-action-button-container"
                    >
                        <div className="report-dropdown">
                            <div
                                className="report-item action-link"
                                onClick={() => {
                                    onStatsReport('votes')
                                    ReactGA.event({
                                        category: 'Reporting',
                                        action: 'RecognitionsReporteDownload',
                                        dimension1: 'platform',
                                        dimension2: hq?.name.toLowerCase(),
                                        dimension3: course?.name.toLowerCase(),
                                        dimension4: subject?.name.toLowerCase(),
                                        dimension7: role,
                                        dimension9: team?.algorithm
                                            ? team?.algorithm?.algorithmType
                                            : 'no algorithm available',
                                    })
                                }}
                            >
                                {t('actions.attendance.order-recognition')}
                            </div>
                            <div
                                className="report-item action-link"
                                onClick={() => {
                                    onStatsReport('name')
                                    ReactGA.event({
                                        category: 'Reporting',
                                        action: 'RecognitionsReporteDownload',
                                        dimension1: 'platform',
                                        dimension2: hq?.name.toLowerCase(),
                                        dimension3: course?.name.toLowerCase(),
                                        dimension4: subject?.name.toLowerCase(),
                                        dimension7: role,
                                        dimension9: team?.algorithm
                                            ? team?.algorithm?.algorithmType
                                            : 'no algorithm available',
                                    })
                                }}
                            >
                                {t('actions.attendance.order-alphabetically')}
                            </div>
                        </div>
                    </ReportHeaderDropdown>
                    <ReportHeaderDropdown
                        label={t('actions.attendance.report-facilitator')}
                        icon={['far', 'file-download']}
                        className="floating-right-action-button-container"
                    >
                        <div className="report-dropdown">
                            <div
                                className="report-item action-link"
                                onClick={() => {
                                    onFacilitatorReport('facilitator')
                                    ReactGA.event({
                                        category: 'Reporting',
                                        action: 'FacilitatorsReportDownload',
                                        dimension1: 'platform',
                                        dimension2: hq?.name.toLowerCase(),
                                        dimension3: course?.name.toLowerCase(),
                                        dimension4: subject?.name.toLowerCase(),
                                        dimension7: role,
                                        dimension9: team?.algorithm
                                            ? team?.algorithm?.algorithmType
                                            : 'no algorithm available',
                                    })
                                }}
                            >
                                {t('actions.attendance.order-facilitator')}
                            </div>
                            <div
                                className="report-item action-link"
                                onClick={() => {
                                    onFacilitatorReport('lastname')
                                    ReactGA.event({
                                        category: 'Reporting',
                                        action: 'FacilitatorsReportDownload',
                                        dimension1: 'platform',
                                        dimension2: hq?.name.toLowerCase(),
                                        dimension3: course?.name.toLowerCase(),
                                        dimension4: subject?.name.toLowerCase(),
                                        dimension7: role,
                                        dimension9: team?.algorithm
                                            ? team?.algorithm?.algorithmType
                                            : 'no algorithm available',
                                    })
                                }}
                            >
                                {t('actions.attendance.order-alphabetically')}
                            </div>
                        </div>
                    </ReportHeaderDropdown>
                </div>
            )
        case ActionView.Exam:
            return <ExamHeader />
        default:
            return <div className="manager-action-header" />
    }
}

export default ActionHeader
