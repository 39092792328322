import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useTranslation } from 'react-i18next'
import Accordion from '../../../components/DesignSystem/components/molecules/Accordion/Accordion.component'
import Pagination from '../../../components/Pagination'
import Config from '../../../config'
import { useTeam } from '../Context/TeamContext'
import { Rotate } from './Overview/Rotate'
import TeamHeader from './TeamHeader'
import { StudentList } from './TeamItem'

const { TEAMS_BY_PAGE } = Config

export const TeamsList = () => {
    const { t } = useTranslation(['teamManager', 'teamMonitor'])
    const { team, setPagination } = useTeam()

    if (!team) return null
    const {
        teams,
        stats: { students },
    } = team
    const totalPages = Math.ceil(team.pagination.totalTeams / TEAMS_BY_PAGE)
    if (!students.total)
        return (
            <div className="content empty">
                <p className="global-text">{t('There are no users loaded.')}</p>
            </div>
        )
    if (!students.assigned)
        return (
            <div className="content empty">
                <p className="global-text">{t('empty.no-tables')}</p>
                <Rotate />
            </div>
        )

    // When have student enrolled & assigned
    return (
        <div>
            <div className="heading">
                {/* <h3 style={{ color: '#1F394C', fontSize: 20, fontWeight: 800 }}>
                    {t('titles.teams')}
                </h3> */}
            </div>
            <div>
                {teams.map(
                    ({ isExpertMentorTeam, isMentorTeam, guide, module, description, ...team }) => {
                        const icon: IconProp = ['fas', 'users']
                        const subtitle = isMentorTeam
                            ? isExpertMentorTeam
                                ? `${t('Expert mentors')}`
                                : `${t('search.mentors')}`
                            : undefined
                        const title = `${t('teamsList.team')} ${team.number}`
                        const contentTitle = `${guide ? `${t('teamsList.guide')} ${guide}` : ''} ${
                            module
                                ? guide
                                    ? ` / ${t('teamsList.module')} ${module}`
                                    : `${t('teamsList.module')} ${module}`
                                : ''
                        } ${
                            description ? (guide || module ? ` / ${description}` : description) : ''
                        }`

                        return (
                            <Accordion
                                key={team.number}
                                header={{ contentTitle, icon, subtitle, title }}
                                headerChildren={
                                    <TeamHeader
                                        isMentorTeam={isMentorTeam}
                                        isExpertMentorTeam={isExpertMentorTeam}
                                        guide={guide}
                                        {...team}
                                    />
                                }
                            >
                                <StudentList teamNumber={team.number} />
                            </Accordion>
                        )
                    }
                )}
            </div>
            {totalPages > 1 && (
                <div className="pagination-container">
                    <Pagination
                        onChange={(page) => setPagination((prev) => ({ ...prev, teams: page }))}
                        count={totalPages}
                        boundary={2}
                        middles={5}
                    />
                </div>
            )}
        </div>
    )
}
