import React, { useContext, useState, useEffect, useCallback } from 'react'
import TeamCollabView from './TeamCollabView'
import { SubjectStudentContext } from '../../../context/SubjectStudentContext'
import Axios from 'axios'
import Config from '../../../config'
import CollabContextProvider from '../../../context/CollabContext'

const TeamCollabContainer = () => {
    const { teamId, mainLinks } = useContext(SubjectStudentContext)

    const [ready, setReady] = useState<boolean>(false)
    // We need to give `ownDocs` a proper interface!
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [ownDocs, setOwnDocs] = useState<any>(undefined)
    const [isUnsubscribing, setIsUnsubscribing] = useState<boolean | string>(false)
    const { setSelectedDoc } = useContext(CollabContextProvider)

    const getTeamData = useCallback(async () => {
        try {
            // preventing getting teamId as undefined
            if (!teamId) return
            const res2 = await Axios.get(Config.API + `/v2/team/${teamId}/documents`)
            // console.log(res2, 'get documents by team id')
            setOwnDocs(res2.data.documents)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        } finally {
            setReady(true)
        }
    }, [teamId])

    useEffect(() => {
        getTeamData()
    }, [getTeamData])

    useEffect(function clearSelectedDoc() {
        setSelectedDoc({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const unsubscribeDoc = async (docId: string, deleteForAll: boolean) => {
        if (window.confirm('¿Desea eliminar este documento?')) {
            setIsUnsubscribing(docId)
            await Axios.delete(
                Config.API + `/v2/document/${docId}${deleteForAll ? `?removeAll=true` : ''}`
            )
            await getTeamData()
            setIsUnsubscribing(false)
        }
    }

    return (
        <TeamCollabView
            mainLinks={mainLinks}
            collabDocs={ownDocs}
            ready={ready}
            unsubscribeDoc={unsubscribeDoc}
            isUnsubscribing={isUnsubscribing}
            setSelectedDoc={setSelectedDoc}
        />
    )
}

export default TeamCollabContainer
