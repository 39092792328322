import styled from 'styled-components'
import { colors } from '../../styles/globals/_variables'

type IProps = {
    position?: {
        top: number
        left: number
    }
    size?: {
        width: number
    }
}

export const ToolTipSmall = styled.div<IProps>`
    text-transform: capitalize;
    text-align: center;
    position: absolute;
    //width: ${({ size }) => (size ? size?.width + 'px' : '100px')};
    width: auto;
    min-width: 100px;
    min-height: 20px; /*min height of DIV should be set to at least 2x the width of the arrow*/
    background-color: ${colors.darkGreyEgg};
    color: white;
    padding: 10px;
    top: ${({ position }) => (position ? position?.top + 'px' : '40px')};
    left: ${({ position }) => (position ? position?.left + 'px' : '0px')};
    word-wrap: break-word;
    -moz-border-radius: 5px; /*add some nice CSS3 round corners*/
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 2em;
    z-index: 99;

    :after {
        /*arrow added to uparrowdiv DIV*/
        content: '';
        display: block;
        position: absolute;
        top: -10px; /*should be set to -border-width x 2 */
        left: 45%;
        width: 0;
        height: 0;
        border-color: transparent transparent ${colors.darkGreyEgg} transparent; /*border color should be same as div div background color*/
        border-style: solid;
        border-width: 5px;
    }
`
