// API
import React, { useEffect, useState, useContext, ChangeEvent } from 'react'
import { useLocation } from 'wouter'
import Axios from 'axios'
// Context
import { UIContext } from '../../../context/UIContext'
// Components
import TeamSettingsView from './TeamSettingsView'
import { SubjectContext } from '../../../context/SubjectContext'
import LoadingPage from '../../Other/LoadingPage'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(utc)
dayjs.extend(advancedFormat)
// Hooks
import { useTranslation } from 'react-i18next'
import UserContext from '../../../context/UserContext'
import { ROLES } from '../../../constants'
import { CourseOptions } from '../../../hooks/ApiHooks/useCourse'
import { errorHandler } from '../../../helpers/errorHandler'
import ReactGA from 'react-ga'
import { useTeam } from '../../Team/Context/TeamContext'

export interface IDay {
    name: string
    idx: number
    checked: boolean
    start: string | undefined
    end: string | undefined
}

const TeamSettingsContainer = () => {
    const { t } = useTranslation(['teamManager', 'teamSettings', 'errors'])
    const { team } = useTeam()
    const { role } = useContext(UserContext)

    const {
        professorScheduleId,
        subject,
        schedules,
        setAssignation,
        setSchedules,
        course,
        hq,
        meeting,
        setMeeting,
        assignation,
        options,
        setCourse,
        setSubject,
    } = useContext(SubjectContext)

    const [, setLocation] = useLocation()

    // REMEMBER This should be rehidrated from the backend.
    // I must show wich mode is active to the user
    //const [algorithmModeSelected, setAlgorithmModeSelected] = useState ('auto')
    const [days, setDays] = useState<IDay[]>([])
    const [daysBackup, setDaysBackup] = useState<IDay[]>([])
    const [ready, setReady] = useState<boolean>(false)
    const [spinnerCourse, setSpinnerCourse] = useState(false)
    const [spinnerSubject, setSpinnerSubject] = useState(false)

    const onErrorGeneric = (error: { message?: string; data?: { message?: string } } | unknown) => {
        // eslint-disable-next-line no-console
        console.error(error)
        setInfoBlock(['error', errorHandler(error) || t('errors:generic')])
        setTimeout(() => setInfoBlock([]), 3000)
    }

    useEffect(() => {
        if (!schedules) return
        const days = [
            t('teamSettings:content.days.monday'),
            t('teamSettings:content.days.tuesday'),
            t('teamSettings:content.days.wednesday'),
            t('teamSettings:content.days.thursday'),
            t('teamSettings:content.days.friday'),
            t('teamSettings:content.days.saturday'),
            t('teamSettings:content.days.sunday'),
        ].map((name, i, days) => {
            const idx = (i + 1) % days.length
            const finded = schedules.find((schedule) => dayjs(schedule[0]).day() === idx)
            return {
                name,
                idx,
                checked: !!finded,
                start: finded ? dayjs(finded[0]).format('HH:mm') : undefined,
                end: finded ? dayjs(finded[1]).format('HH:mm') : undefined,
            }
        })
        setDays(days)
        if (daysBackup.length == 0) setDaysBackup(days)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedules, t])

    // Sets infoBlock error / success
    const { setInfoBlock } = useContext(UIContext)

    const onChangeCheckbox = (idx: IDay['idx']) => {
        const daysTemp: Array<IDay> = []
        days.forEach((day, index) => {
            if (day.idx === idx) {
                if (!day.checked)
                    daysTemp.push({
                        ...day,
                        checked: !day.checked,
                        start: daysBackup[index]?.start,
                        end: daysBackup[index]?.end,
                    })
                else daysTemp.push({ ...day, checked: !day.checked })
            } else {
                daysTemp.push(day)
            }
        })

        setDays([...daysTemp])
    }

    /**
     *
     * @param {string} time Time of the schedule (format HH:MM)
     * @param {number} idx Index to Change
     * @param {'start' | 'end'} type
     */
    const onChangeClassTime = (idx: IDay['idx'], type: 'start' | 'end') => (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        const day = days.find((e) => e.idx === idx)
        day && (day[type] = e.target.value)
        setDays([...days])
        setDaysBackup([...days])
    }
    const onCourseChange = async (coursePatch: string) => {
        if (!setCourse) return
        setSpinnerCourse(true)
        try {
            await setCourse(coursePatch)
            setInfoBlock(['success', t('teamSettings:content.edit-succes')])
        } catch (error) {
            errorHandler(error)
            setInfoBlock(['error', errorHandler(error) || t('errors:generic')])
            setTimeout(() => setInfoBlock([]), 3000)
        } finally {
            setTimeout(() => setInfoBlock([]), 3000)
            setSpinnerCourse(false)
        }
    }
    const onSubjectChange = async (subjectPatch: string) => {
        if (!setSubject) return
        setSpinnerSubject(true)
        try {
            await setSubject(subjectPatch)
            setInfoBlock(['success', t('teamSettings:content.edit-succes')])
        } catch (error) {
            setInfoBlock(['error', errorHandler(error) || t('errors:generic')])
            setTimeout(() => setInfoBlock([]), 3000)
        } finally {
            setTimeout(() => setInfoBlock([]), 3000)
            setSpinnerSubject(false)
        }
    }

    // Algorithm can be launched to maintain positions (and only generate a new voting)
    // or to shuffle students between tables
    const launchAlgorithm = async (launchType: 'repeated' | 'shuffle') => {
        try {
            await setAssignation?.launch(undefined, launchType)
            setInfoBlock(['success', t('teamSettings:content.algorithm.launch-success')])
            setTimeout(() => setInfoBlock([]), 2500)
        } catch (error) {
            onErrorGeneric(error)
        }
    }

    useEffect(() => {
        setReady(!!schedules && !!subject)
    }, [schedules, subject])

    if (!ready) return <LoadingPage />

    const onDeleteThisTeam = async () => {
        const res = window.confirm(t('teamSettings:content.delete-confirm'))
        if (res) {
            try {
                await Axios.delete(
                    `${process.env.REACT_APP_API}/professorSchedule/${professorScheduleId}/subject/${subject?.id}`
                )
                setLocation('/')
            } catch (error) {
                setInfoBlock(['error', t('teamSettings:content.delete-error')])
            }
        }
    }

    const onSave = async () => {
        await onSaveSchedule()
    }

    const onSaveSchedule = async () => {
        // This needs to be parsed to the API Schema.
        const newSchedule = days
            ?.filter((day) => day.checked)
            .map((day) => [
                dayjs(dayjs().format('YYYY-MM-DD') + ' ' + day.start, 'HH:mm')
                    .day(day.idx)
                    .utc()
                    .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
                dayjs(dayjs().format('YYYY-MM-DD') + ' ' + day.end, 'HH:mm')
                    .day(day.idx)
                    .utc()
                    .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
            ]) as [string, string][]

        ReactGA.event({
            category: 'Schedule Settings',
            action: 'TimetableSaved',
            dimension1: 'platform',
            dimension2: hq?.name.toLowerCase(),
            dimension3: course?.name.toLowerCase(),
            dimension4: subject?.name.toLowerCase(),
            dimension7: role,
            dimension9: team?.algorithm ? team?.algorithm?.algorithmType : 'no algorithm available',
        })

        await setSchedules?.(newSchedule)
            .then(() => {
                setInfoBlock(['success', t('teamSettings:content.schedule.success')])
                setTimeout(() => setInfoBlock([]), 2500)
            })
            .catch((error) => {
                setInfoBlock(['error', error.data.message || onErrorGeneric])
                setTimeout(() => setInfoBlock([]), 2500)
            })
    }

    const mainLinks = [
        {
            name: t('teamManager:main-links.room'),
            url: `/admin/overview/${course?.id}`,
            active: false,
        },
        {
            name: t('teamManager:main-links.students'),
            url: `/admin/team/${course?.id}`,
        },
    ]

    if (role && [ROLES.DIRECTOR].includes(role) && options?.[CourseOptions.DisplayStats])
        mainLinks.push({
            name: t('teamManager:main-links.stats'),
            url: `/team/${professorScheduleId}/subject/${subject?.id}/hca`,
        })

    mainLinks.push({
        name: t('teamManager:main-links.settings'),
        url: `/team/${professorScheduleId}/subject/${subject?.id}/settings`,
        active: true,
    })

    return (
        <TeamSettingsView
            mainLinks={mainLinks}
            courseName={course?.name ?? ''}
            hqName={hq && hq.name ? hq.name : ''}
            subjectName={subject?.name ?? ''}
            onLaunchAlgorithm={launchAlgorithm}
            days={days}
            onChangeCheckbox={onChangeCheckbox}
            onChangeClassTime={onChangeClassTime}
            onCourseChange={onCourseChange}
            onSubjectChange={onSubjectChange}
            spinnerCourse={spinnerCourse}
            spinnerSubject={spinnerSubject}
            setMeetingService={setMeeting}
            meeting={meeting}
            assignation={assignation ?? []}
            onTeamDelete={onDeleteThisTeam}
            disabledSave={false}
            onSave={onSave}
            //onCancel={() => {}}
        />
    )
}

export default TeamSettingsContainer
