import AccordionHeader, {
    IAccordionHeaderProps,
} from '../AccordionHeader/AccordionHeader.component'
import AccordionItem, { IAccordionItemProps } from '../AccordionItem/AccordionItem.component'
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'

const Accordion = ({
    children,
    controlledCollapsed = false,
    setControlledCollapsed,
    header,
    headerChildren,
    items,
    notCollapsed = false,
    footer,
    styles,
}: IAccordionProps) => {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const toggleCollapsed = () => {
        setIsCollapsed((prev) => !prev)
        setControlledCollapsed && setControlledCollapsed(() => false)
    }

    useEffect(() => {
        notCollapsed && setIsCollapsed(() => false)
    }, [notCollapsed])

    useEffect(() => {
        setControlledCollapsed && setControlledCollapsed(isCollapsed)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        controlledCollapsed && setIsCollapsed(true)
    }, [controlledCollapsed])

    return (
        <div
            style={{
                marginBottom: 10,
                border: '1px solid #f2f2f2',
                borderRadius: 10,
                background: '#fff',
                ...styles,
            }}
        >
            <AccordionHeader
                {...header}
                toggleCollapsed={toggleCollapsed}
                isCollapsed={isCollapsed}
            >
                {headerChildren}
            </AccordionHeader>
            {!isCollapsed && (
                <div>
                    {items?.map((item) => (
                        <AccordionItem key={item.itemNumber} {...item} />
                    ))}
                    {children}
                </div>
            )}
            {!isCollapsed && footer && footer}
        </div>
    )
}

export default Accordion

export interface IAccordionProps {
    children: ReactNode
    controlledCollapsed?: boolean
    setControlledCollapsed?: Dispatch<SetStateAction<boolean>>
    footer?: ReactNode
    header: Partial<IAccordionHeaderProps>
    headerChildren?: ReactNode
    items?: IAccordionItemProps[]
    notCollapsed?: boolean
    styles?: React.CSSProperties
}
