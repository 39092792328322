import React, { useContext } from 'react'
import { UIContext } from '../context/UIContext'
import styled from 'styled-components'

// TODO cambiar a props.
const InfoBlock = () => {
    /* Context success / error messages top page */
    const { infoBlock } = useContext(UIContext)
    return (
        <Info
            className={
                infoBlock[0] === 'success'
                    ? 'info-block show success'
                    : infoBlock[0] === 'error'
                    ? 'info-block show error'
                    : 'info-block'
            }
        >
            {infoBlock[1]}
        </Info>
    )
}
export default InfoBlock

const Info = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    padding: 37px 0;
    width: 100%;
    transition: all 0.3s ease;
    transform: translateY(-100%);
    text-align: center;
    color: white;
    font-weight: bold;
    z-index: 1000;
    &.show {
        transform: translateY(0%);
        transition: all 0.3s ease;
    }
    &.success {
        background: rgba(139, 237, 154, 0.9);
    }
    &.error {
        background: rgba(253, 115, 115, 0.9);
    }
`
