import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTeam } from '../Context/TeamContext'
import { ActionsMenu } from './Actions/ActionsMenu'
import { SearchBox } from './SearchBox'

export const StickySection = () => {
    const { t } = useTranslation('teamManager')
    const { searchOptions, isSearching } = useTeam()
    return (
        <section className="sticky" style={{ backgroundColor: '#F8F8F8' }}>
            <SearchBox
                options={searchOptions}
                optionsTitle={t('search.option-title')}
                isBusy={isSearching}
            />

            <ActionsMenu />
        </section>
    )
}
