import React, { useContext } from 'react'
import { CourseResponse } from './EnrollContainer'
import MainLayout from '../../layouts/MainLayout'
import enroll_img from '../../assets/images/join_team_img.png'
import { UIContext } from '../../context/UIContext'
import { useLocation } from 'wouter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

interface ILogedProps {
    course: CourseResponse
    onSubmit?: () => void
    onEnrollAsNew?: () => void
    userName?: string | null
}

export const EnrollViewLoged = ({ course, userName, onSubmit, onEnrollAsNew }: ILogedProps) => {
    const { t } = useTranslation(['enroll'])
    const [, setLocation] = useLocation()
    const { titleCaseFn } = useContext(UIContext)

    return (
        <MainLayout ruta={course?.hq.name}>
            <div className="enroll-page-container">
                <img src={enroll_img} alt="" />
                <span className="hello-badge">
                    {t('main.hello')} {userName ? titleCaseFn(userName) : ''}!
                </span>
                <h3 className="text-lighter">
                    {t('main.join')}
                    <span className="text-strong"> {course.course.name}</span>
                    {t('main.of')}
                    <span className="text-strong"> {course.hq.name}</span>?
                </h3>

                <button className="btn-yellow" onClick={() => onSubmit?.()}>
                    {t('buttons.btn-join')}
                </button>
                <button className="btn-outline" onClick={() => setLocation('/')}>
                    {t('buttons.btn-later')}
                </button>
                <button className="action-link-enroll" onClick={() => onEnrollAsNew?.()}>
                    {t('buttons.btn-new-user')}
                    <FontAwesomeIcon className="icon" icon={['fas', 'arrow-right']} />
                </button>
            </div>
        </MainLayout>
    )
}

export default EnrollViewLoged
