// API
import React, { useEffect, useState, useContext } from 'react'
import Axios from 'axios'
// Helpers
import Config from '../../config'
import { WizardContext } from '../../context/WizardContext'
import useDebounce from '../../hooks/useDebounce'
// Context
import { UIContext } from '../../context/UIContext'
// Components
import Step1View from './Step1View'

const Step1 = () => {
    const { hqSelected, setHqSelected, nextStep } = useContext(WizardContext)
    const [isNextStepEnabled, setIsNextStepEnabled] = useState(false)
    const [isSearching, setIsSearching] = useState(false)

    const [hqName, setHqName] = useState('')
    const [recommendedHqs, setRecommendedHqs] = useState([])

    const [debouncedSearchTerm] = useDebounce(hqName, 400)

    // Sets infoBlock error / success
    const { setInfoBlock } = useContext(UIContext)

    useEffect(() => {
        if (debouncedSearchTerm) {
            const getHqsByName = async (name) => {
                if (!hqSelected) {
                    setIsSearching(true)
                    try {
                        const response = await Axios.get(Config.API + '/hq?search=' + name)
                        // console.log(response,'res hqname')
                        setRecommendedHqs(response.data.hqs)
                    } catch (error) {
                        setInfoBlock([
                            'error',
                            error.data.message
                                ? error.data.message
                                : 'Ups! Ocurrió un error obteniendo resultados recomendados.',
                        ])
                    } finally {
                        setIsSearching(false)
                        setTimeout(() => {
                            setInfoBlock([])
                        }, 3000)
                    }
                }
            }
            getHqsByName(debouncedSearchTerm)
        } else {
            setRecommendedHqs([])
        }
    }, [debouncedSearchTerm, hqSelected, setInfoBlock])

    const onInputChange = (value) => {
        if (value.length === 0) {
            setHqSelected(null)
            setIsNextStepEnabled(false)
        } else {
            setIsNextStepEnabled(true)
        }
        setHqName(value)
    }

    const onClickHq = (hq) => {
        return () => {
            setRecommendedHqs([])
            setHqName(hq.name)
            setHqSelected({ _id: hq._id, name: hq.name, isNew: false })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!hqSelected) {
            setHqSelected({ name: hqName, isNew: true })
        }
        nextStep()
    }

    return (
        <Step1View
            onInputChange={onInputChange}
            hqName={hqName}
            hqSelected={hqSelected}
            setHqName={setHqName}
            recommendedHqs={recommendedHqs}
            onClickHq={onClickHq}
            onSubmit={onSubmit}
            nextStep={nextStep}
            isNextStepEnabled={isNextStepEnabled}
            isSearching={isSearching}
        />
    )
}

export default Step1
