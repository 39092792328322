import React, { useRef } from 'react'

export interface Props
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'ref' | 'onChange'> {
    value: string
    onChange: (newValue: string) => unknown
    size: number
    className: string
    validation: RegExp
}

export interface InputAttributes
    extends Pick<
        React.InputHTMLAttributes<HTMLInputElement>,
        'value' | 'onClick' | 'onFocus' | 'onKeyDown' | 'onChange'
    > {
    ref: (input: HTMLInputElement | null) => void
}

const padValue = (value: string, length: number) => {
    while (value.length < length) {
        value += ' '
    }
    return value.substr(0, length)
}

export const Pincode = (props: Props) => {
    const { value, size, onChange, className, validation, ...rest } = props
    const length = size
    const val = padValue(value, length)

    const inputs = useRef<(HTMLInputElement | undefined)[]>(Array.from({ length }))
    const inputRefSetters = useRef<InputAttributes['ref'][]>(Array.from({ length }))

    const inputProps: InputAttributes[] = []
    for (let i = 0; i < length; i++) {
        const ref = (inputRefSetters.current[i] =
            inputRefSetters.current[i] ||
            ((input) => {
                inputs.current[i] = input || undefined
            }))
        const digitValue = val[i] === ' ' ? '' : val[i]

        inputProps.push({
            ...rest,
            ref,
            value: digitValue,
            onClick: ({ currentTarget }) => {
                window.requestAnimationFrame(() => {
                    currentTarget.select()
                })
            },
            onFocus: ({ currentTarget }) => {
                currentTarget.select()
            },
            onKeyDown: (e) => {
                switch (e.key) {
                    case 'Backspace':
                        e.preventDefault()
                        if (!digitValue) {
                            if (i > 0) {
                                // this digit is empty, so backspace removes the previous digit
                                // and focuses it
                                onChange(val.substring(0, i - 1) + ' ' + val.substring(i))
                                const previousInput = inputs.current[i - 1]
                                if (previousInput) {
                                    previousInput.focus()
                                }
                            }
                        } else {
                            // this digit is not empty, so backspace removes that digit
                            onChange(val.substring(0, i) + ' ' + val.substring(i + 1))
                        }
                        break
                    case 'ArrowUp':
                    case 'ArrowLeft':
                        e.preventDefault()
                        if (i > 0) {
                            const previousInput = inputs.current[i - 1]
                            if (previousInput) {
                                previousInput.focus()
                                window.requestAnimationFrame(() => {
                                    previousInput.select()
                                })
                            }
                        }
                        break
                    case 'ArrowDown':
                    case 'ArrowRight':
                        e.preventDefault()
                        if (i + 1 < length) {
                            const nextInput = inputs.current[i + 1]
                            if (nextInput) {
                                nextInput.focus()
                                window.requestAnimationFrame(() => {
                                    nextInput.select()
                                })
                            }
                        }
                        break
                    default:
                        if (e.key.length === 1 && !(e.metaKey || e.altKey || e.ctrlKey)) {
                            e.preventDefault()
                            if (validation.test(e.key)) {
                                onChange(val.substring(0, i) + e.key + val.substring(i + 1))
                                if (i + 1 < length) {
                                    const nextInput = inputs.current[i + 1]
                                    if (nextInput) {
                                        nextInput.focus()
                                        window.requestAnimationFrame(() => {
                                            nextInput.select()
                                        })
                                    }
                                } else {
                                    const currentTarget = e.currentTarget
                                    window.requestAnimationFrame(() => {
                                        currentTarget.select()
                                    })
                                }
                            }
                        }
                }
            },
            onChange: (e) => {
                const v = e.target.value
                    .split('')
                    .filter((c) => validation.test(c))
                    .join('')
                onChange((val.substring(0, i) + v + val.substring(i + v.length)).substr(0, length))
                if (i < length - 1) {
                    const nextInput =
                        inputs.current[i + v.length < length ? i + v.length : length - 1]
                    if (nextInput) {
                        nextInput.focus()
                        window.requestAnimationFrame(() => {
                            nextInput.select()
                        })
                    }
                }
            },
        })
    }

    return (
        <div className={className}>
            {inputProps.map((prop, i) => (
                <input key={i} {...prop} />
            ))}
        </div>
    )
}

export default Pincode
