const storageHelper = {
    setUser: (user) => {
        const { name, lastname, email, urlImage, token, role, phone, _id } = user
        const userToStore = {
            _id,
            name,
            lastname,
            email,
            urlImage,
            token,
            role,
            phone,
        }
        window.localStorage.setItem('user', JSON.stringify(userToStore))
    },
    setToken: (token) => {
        if (!token) {
            return false
        }
        window.localStorage.setItem('token', token)
        return token
    },
    getToken: () => {
        return window.localStorage.getItem('token')
    },
    setRole: (role) => {
        if (!role) {
            return false
        }
        window.localStorage.setItem('role', role)
        return role
    },
    getRole: () => {
        return window.localStorage.getItem('role')
    },
    setItem: (itemName, value) => {
        window.localStorage.setItem(itemName, value)
    },
    getItem: (itemName) => {
        return window.localStorage.getItem(itemName)
    },
    getUser: () => {
        try {
            return JSON.parse(window.localStorage.getItem('user'))
        } catch (error) {
            //@TODO handle when crash
        }
    },
    clear: () => {
        window.localStorage.clear()
    },
}

export default storageHelper
