import styled from 'styled-components'

export const DoubleTabStyled = styled.div`
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    width: 100%; /* Set to 100% width */
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
    height: 42px;
    padding: 3px;
    background-color: #f1f1f1;
    gap: 3px;
    .tab {
        flex: 1; /* Make tabs evenly distributed */
        padding: 10px 20px;
        border: none;
        background-color: #f1f1f1;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: #333;
        outline: none;
        text-align: center; /* Center align text */
        border-radius: 8px;
    }

    .tab:not(.active):hover {
        background-color: #e1e1e1;
    }

    .tab.active {
        background-color: #fff;
        color: #000;
        /* box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1); */
    }
`
