import { useEffect, useState, useContext } from 'react'
import Axios from 'axios'
import Config from '../../config'
import { TableType } from './useAssignation'
import { AttendanceStatus } from './useAttendance'
import { useLocation } from 'wouter'
import { UIContext } from '../../context/UIContext'
// export enum AttendanceState {
//     PRESENT = 'present',
//     ABSENT = 'absent',
//     DELAY = 'delay',
//     UNSET = 'unset',
// }

export interface IOwnData {
    assistance: {
        timesAbsent: number
        timesDelay: number
        timesPresent: number
    }
    docs?: {
        createdAt: string
        description: string
        document: string
        isOwner: boolean
        title: string
        _id: string
    }[]
    isAssigned: boolean
    isWizardCompleted: boolean
    messages: string[]
    shouldVote: boolean
    stats: {
        cooperationIndex: number
        rubricResult: number
        timesFacilitator: number
        votes: number
    }
    table: {
        facilitator: string
        tableNumber: number
        positions: UserType[]
        guide?: number
        module?: number
    }
    attending: AttendanceStatus
}

export interface ITeamData {
    course: {
        mandatoryVote: boolean
        defaultShouldCheckPresents: boolean
        name: string
        _id: string
    }
    facilitators: UserType[]
    goal: {
        name: string
        _id: string
    }
    hq: {
        name: string
        _id: string
    }
    nextClass: {
        day: number
        labelDay: string
        start: string
        subject: {
            name: string
        }
        attendancesRegistrationTime?: {
            attendanceDelayTime?: number // Minutes
            attendancesEndTime?: number // Minutes
            attendancesStartTime?: number // Minutes
        }
    }
    owners: UserType[]
    rankReputation: UserType[]
    stats: {
        cooperationIndex: number
    }
    settings: {
        isVirtual: boolean
        rubricContent: boolean
        attachmentContent: boolean
    }
    tables: TableType[] | null
    _algorithmId: string
    [arg: string]: unknown
}

type UserType = {
    profile: {
        role: string
        _id: string
    }
    user: {
        _id: string
        urlImage: string
        name: string
        lastname: string
        email: string
    }
}

interface GETMyTeam {
    own: IOwnData
    team: ITeamData
}

const useMyTeam = (
    teamId: string | undefined
): [
    // eslint-disable-next-line no-undef
    typeof ownData,
    // eslint-disable-next-line no-undef
    typeof teamData,
    // eslint-disable-next-line no-undef
    typeof setTeamData,
    // eslint-disable-next-line no-undef
    typeof fetchMyTeam
] => {
    const [ownData, setOwnData] = useState<IOwnData>()
    const [teamData, setTeamData] = useState<ITeamData>()
    const [, setLocation] = useLocation()
    const { setInfoBlock } = useContext(UIContext)
    useEffect(() => {
        if (!teamId) return
        fetchMyTeam()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamId])

    const fetchMyTeam = async () => {
        try {
            const res = await Axios.get<GETMyTeam>(Config.API + `/v2/team/${teamId}`, {
                timeout: 3000,
            })

            setOwnData(res.data.own)
            setTeamData(res.data.team)
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('error getting my team data', e)
            setInfoBlock(['error', e?.data?.errorMessage ? e?.data.errorMessage : e?.data?.message])
            setTimeout(() => setInfoBlock([]), 3000)
            setLocation('/')
        }
    }

    return [ownData, teamData, setTeamData, fetchMyTeam]
}

export default useMyTeam
