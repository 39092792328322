import React, { useContext, useEffect } from 'react'
import JitsiView from '../../../components/Jitsi/JitsiView'
import UserContext from '../../../context/UserContext'
import { useTeam } from '../Context/TeamContext'

export const MeetLauncher = () => {
    const { team, meeting } = useTeam()
    const [meet, setMeet] = meeting
    const { role } = useContext(UserContext)

    const isJitsiMeet = meet?.includes('meet.jit.si')
    const roomID = meet?.replace('https://meet.jit.si/', '')

    /** open meet in window if they are not Jitsi  */
    useEffect(() => {
        if (meet && !isJitsiMeet) {
            window.open(`${meet}`, '_blank')
            setMeet(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isJitsiMeet, meet])

    if (!team || !meet) return null

    if (isJitsiMeet)
        return (
            <JitsiView
                onClose={() => setMeet(null)}
                roomId={roomID}
                password={team.algorithm?._id}
                roomName={`${team.teamName} > ${team.subject.name}`}
                trackingPayload={{
                    role,
                    _algorithmId: team.algorithm?._id,
                    table: 'unknown',
                    _courseId: team.course._id,
                    _subjectId: team.subject._id,
                    _hqId: team.hq._id,
                    _professorScheduleId: team.professorSchedule._id,
                }}
            />
        )
    else return null
}
