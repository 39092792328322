import React from 'react'
import Axios from 'axios'
import Config from '../../config'

const formatDay = (date: Date) => {
    const year = date.getFullYear()
    const month = (1 + date.getMonth()).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return day + '/' + month + '/' + year
}

/**
 * Enum for the tri-state of the attendance of a student.
 * @readonly
 * @enum {'delay' | 'present' | 'absent'}
 */
export enum AttendanceStatus {
    DELAY = 'delay',
    PRESENT = 'present',
    ABSENT = 'absent',
    UNSET = 'unset',
}

export type AttendanceItem = {
    status: AttendanceStatus
    comment: string
}
export type AttendanceType = { [studentId: string]: AttendanceItem }

export type SummaryType = { [status in AttendanceStatus]: number }

export type AttendanceSaveType = (
    _studentId: string,
    status: AttendanceStatus,
    justification: string
) => Promise<{ _id: string; status: AttendanceStatus }>

export function useAttendance(
    /** INPUT */
    _professorScheduleId: string,
    _subjectId: string,
    date = new Date(),
    // eslint-disable-next-line no-console
    onError = console.error
): [
    /** OUTPUT */
    AttendanceType | null | /*Case of error */ undefined /*Still loading*/,
    SummaryType | null | /*Case of error */ undefined /*Still loading*/,
    AttendanceSaveType
] {
    const [attendance, setAttendance] = React.useState<AttendanceType | null | undefined>()
    const [summary, setSummary] = React.useState<SummaryType | null | undefined>()

    React.useEffect(
        function getAttendance() {
            if (!_professorScheduleId || !_subjectId || !date) return
            Axios.get(
                Config.API +
                    '/professorSchedule/' +
                    _professorScheduleId +
                    '/subject/' +
                    _subjectId +
                    '/attendances/',
                {
                    params: { date: formatDay(date) }, // @TO-DO Borrar esto.
                }
            )
                .then((response) => {
                    // Create a map where the key is the studentId and the value is the object {status, comments}
                    const attendance = response.data.attendances.reduce(
                        (
                            result: AttendanceType,
                            current: { _studentId: string } & AttendanceItem
                        ) => {
                            const { status, comment, _studentId } = current
                            result[_studentId] = { status, comment }
                            return result
                        },
                        {}
                    )
                    setAttendance(attendance)

                    // Create the summary, counting the number of status
                    const summary: SummaryType = {
                        [AttendanceStatus.ABSENT]: 0,
                        [AttendanceStatus.DELAY]: 0,
                        [AttendanceStatus.PRESENT]: 0,
                        [AttendanceStatus.UNSET]: 0,
                    }
                    response.data.attendances.forEach(
                        (student: { _studentId: string } & AttendanceItem) => {
                            summary[student.status] += 1
                        }
                    )
                    setSummary(summary)
                })
                .catch((error) => {
                    onError && onError(error)
                    setAttendance(null)
                    setSummary(null)
                })
        },
        [_professorScheduleId, _subjectId, date, onError]
    )

    /**
     * Change the state of the attendance of a single student
     * @param {string} userId The id of the user to change
     * @param {AttendanceStatus} attendance State to change
     * @param {string} justification The justification (typically used in ABSENT)
     */
    const saveAttendance: AttendanceSaveType = async (_studentId, status, justification) => {
        return Axios.put(Config.API + '/attendance/', {
            _professorScheduleId,
            _subjectId,
            _studentId,
            status,
            date: formatDay(date), //FORMAT YYYY-MM-DD  @TO-DO Borrar esto.
            comment: justification,
        }).then((response) => {
            if (response.data.status !== status) throw Error('Ha ocurrido un error')

            if (attendance) {
                attendance[_studentId] = { status, comment: justification }
                setAttendance({ ...attendance })
                const summary: SummaryType = {
                    [AttendanceStatus.ABSENT]: 0,
                    [AttendanceStatus.DELAY]: 0,
                    [AttendanceStatus.PRESENT]: 0,
                    [AttendanceStatus.UNSET]: 0,
                }

                Object.keys(attendance).forEach((studentId) => {
                    summary[attendance[studentId].status] += 1
                })
                setSummary(summary)
            }
            return response.data
        })
    }

    return [attendance, summary, saveAttendance]
}
