import Axios from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Config from '../../../config'
import UIContext from '../../../context/UIContext'
import LoadingPage from '../../Other/LoadingPage'
import NotFoundPage from '../../Other/NotFoundPage'
import TeamProfileView from './TeamProfileView'

interface IProps {
    profile: string
}

interface IGETProfileEndpoint {
    course: { _id: string; name: string }
    hq: { _id: string; name: string }
    user: {
        urlImage: string
        uid: string
        statusMessage: string
        name: string
        lastname: string
        email: string
        active: boolean
        _id: string
    }
    _id: string
}

const TeamProfileContainer = ({ profile }: IProps) => {
    const [user, setUser] = useState<IGETProfileEndpoint['user'] | null>()
    const { setInfoBlock } = useContext(UIContext)

    const fetchStudent = useCallback(async () => {
        if (!profile) return
        try {
            const response = await Axios.get<IGETProfileEndpoint>(
                `${Config.API}/profile/${profile}`
            )
            const { user } = response.data
            setUser(user)
        } catch (error) {
            setUser(null)
            setInfoBlock(['error', 'Ups! Ocurrió un error al obtener los datos del alumno.'])
        } finally {
            setTimeout(() => setInfoBlock([]), 3000)
        }
    }, [profile, setInfoBlock])

    useEffect(() => {
        fetchStudent()
    }, [fetchStudent])

    if (user === undefined) {
        return <LoadingPage />
    }

    if (user === null) {
        return <NotFoundPage />
    }

    return (
        <TeamProfileView
            name={`${user.name} ${user.lastname}`}
            email={user.email}
            photo={user.urlImage}
            status={user.statusMessage}
        />
    )
}

export default TeamProfileContainer
