import React, { useCallback, useEffect } from 'react'
import Axios from 'axios'
import Config from '../../config'
import { useGAEvents } from '../useGAEvents'

// Response from https://develop.d3qmp9et8bpuml.amplifyapp.com/#/Vote-GET-vote-status-%7B_profileId%7D
export interface GETStatusVoteResponse {
    vote: {
        _profileFromId: string
        _profileToId: string
        createdAt: number
    }
    shouldVote?: boolean
    table?: {
        //_algorithmId: string,
        votes: number
        facilitator: string
        positions: {
            number: number
            _profileId: string
            _userId: {
                _id: string
                lastname: string
                name: string
                urlImage: string
            }
        }[]
    }
    _algorithmId: string
}

export interface POSTVoteResponse {
    vote: {
        _id: string
        _profileFromId: string
        _profileToId: string
        _algorithmId: string
    }
    quantityVotes: number
}

// Response from https://develop.d3qmp9et8bpuml.amplifyapp.com/#/Vote-POST-vote
export type VoteFunctionType = (to?: string) => Promise<POSTVoteResponse> //TODO: Append onDeleteReturnType

export type ShouldVoteType = {
    candidates: Exclude<GETStatusVoteResponse['table'], undefined>['positions']
    _algorithmId: string
    vote: (id_or_position: string | number | null) => Promise<POSTVoteResponse>
}

export function useVote(
    /** INPUT */
    profile: string | undefined,
    algorithm: string | undefined,
    votedList?: string[],
    // eslint-disable-next-line no-console
    onError = console.error
): [
    /** OUTPUT */
    string | undefined | null,
    VoteFunctionType, // TODO: It should be able to change votes
    ShouldVoteType | undefined | null
] {
    const [voted, setVoted] = React.useState<string | undefined | null>()
    const [shouldVote, setShouldVote] = React.useState<ShouldVoteType | undefined | null>()
    const { sendGA } = useGAEvents()

    const getCurrentVotes = useCallback(async () => {
        try {
            if (!profile || !algorithm) return
            const response = await Axios.get<GETStatusVoteResponse>(
                Config.API + '/vote/status/' + profile
            )
            setVoted(response.data?.vote?._profileToId ?? null)

            //Check and initialize the should vote property
            if (response.data.shouldVote && response.data.table) {
                const candidates = response.data.table.positions
                const _algorithmIdToVote = response.data._algorithmId

                // The action of vote in the shouldVote
                const vote = async (id: number | string | null) => {
                    if (!_algorithmIdToVote) throw Error('No previous algorithm found.')
                    const to =
                        id !== null
                            ? typeof id === 'string'
                                ? id
                                : candidates.find(
                                      ({ number, _profileId }) =>
                                          number === id && profile !== _profileId
                                  )?._profileId
                            : null
                    if (to === undefined) throw Error("Couldn't determine vote")
                    const response = await Axios.post<POSTVoteResponse>(
                        `${Config.API}/vote/`,
                        to !== null
                            ? {
                                  _profileFromId: profile,
                                  _profileToId: to,
                                  _algorithmId: _algorithmIdToVote,
                              }
                            : {
                                  _profileFromId: profile,
                                  type: 'blank',
                                  _algorithmId: _algorithmIdToVote,
                              }
                    )

                    sendGA({ category: 'Engagement', action: 'Vote' })

                    setShouldVote(null)
                    if (_algorithmIdToVote === algorithm) {
                        //I have to set the voted profile if the algorithm is the current one
                        setVoted(to)
                    }
                    return response.data
                }
                setShouldVote({
                    _algorithmId: _algorithmIdToVote,
                    candidates,
                    vote,
                })
            } else {
                setShouldVote(null)
            }
        } catch (error) {
            onError(error)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, algorithm, onError])

    useEffect(() => {
        getCurrentVotes()
    }, [getCurrentVotes])

    const vote: VoteFunctionType = async (to?: string) => {
        if (!profile) throw Error('ProfileId not started')

        if (to && votedList && !votedList.includes(to)) throw Error(`Can't vote to ${to}`)
        const response = await Axios.post<POSTVoteResponse>(`${Config.API}/vote`, {
            _profileFromId: profile,
            _profileToId: to,
            type: to ? undefined : 'blank',
            _algorithmId: algorithm,
        })

        setVoted(to)
        //setShouldVote(null)

        sendGA({ category: 'Engagement', action: 'Vote' })

        return response.data
    }

    return [voted, vote, shouldVote]
}

export default useVote
