import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import AvatarEgg from '../../../../components/AvatarEgg'
import { capitalize } from '../../../../helpers/formatter'
import { iGetMentorsProps, iGetMentorsReturn } from '../TeamManagerContainer'
import Styles from './ModalMentors.module.css'

export const ModalMentors = ({ mentorsSearch, mentorsResults }: iProps) => {
    const { t } = useTranslation(['teamManager'])
    const [search, setSearch] = mentorsSearch
    const [mentors, setMentors] = mentorsResults
    return (
        <Modal isOpen={!!mentors} style={customStyles} overlayClassName={Styles.overlay}>
            <section className={Styles.container}>
                <header className={Styles.header}>
                    <div className={Styles.headerIcon}>
                        <FontAwesomeIcon icon={['fas', 'graduation-cap']} />
                    </div>
                    <div className={Styles.teamTitle}>
                        <h3>{t('teamManager:search.mentors')}</h3>
                        <h3 className={Styles.guide}>
                            {`${t('teamManager:search.badge-team')} ${search?.team} - ${t(
                                'teamManager:search.guide'
                            )} ${search?.guide}`}
                        </h3>
                    </div>
                    <button
                        className={Styles.btnCloseModal}
                        onClick={() => {
                            setSearch(null)
                            setMentors(null)
                        }}
                    >
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </button>
                </header>
                <main className={Styles.content}>
                    <div className={Styles.mentorList}>
                        {mentors &&
                            mentors.mentors.map((item) => (
                                <MentorItem mentor={item} key={item._profileId} />
                            ))}
                    </div>
                </main>
            </section>
        </Modal>
    )
}

const MentorItem = ({ mentor, title }: { mentor: iMentor; title?: string }) => {
    const { name, lastname, urlImage } = mentor
    const displayName = `${capitalize(name || '')} ${capitalize(lastname || '')}`

    return (
        <div className={Styles.mentoItem}>
            <AvatarEgg src={urlImage} />
            <div className={Styles.mentoName}>
                <div>{displayName}</div>
                {title && <div className={Styles.mentoTitle}>{title}</div>}
            </div>
        </div>
    )
}

type iMentor = iGetMentorsReturn['mentors'][0]

interface iProps {
    mentorsSearch: [
        iGetMentorsProps | null,
        React.Dispatch<React.SetStateAction<iGetMentorsProps | null>>
    ]
    mentorsResults: [
        iGetMentorsReturn | null,
        React.Dispatch<React.SetStateAction<iGetMentorsReturn | null>>
    ]
}

const customStyles = {
    content: {
        overflow: 'hidden',
        padding: '10px',
        maxWidth: '1200px',
        margin: 'auto',
    },
    overlay: {
        zIndex: 1,
    },
}
