/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'

// Context
import UserContext from '../../../context/UserContext'
import { useUIContext } from '../../../context/UIContext'
import { SubjectStudentContext } from '../../../context/SubjectStudentContext'

// Hooks
import { AttendanceStatus } from '../../../hooks/ApiHooks/useAttendance'
import { useNotifications } from '../../../hooks/useNotifications'

// Assets
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

//import OnboardingWizard from './OnboardingWizard/OnboardingWizard';

// Componentes
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WideLayout from '../../../layouts/WideLayout'
import EggCard from '../../../components/Styled/EggCard'
import ModalEgg from '../../../components/ModalEgg'
import { BtnAttend } from '../HeadingStudent'

// Micro styled components
import HeadingStudent from '../HeadingStudent'
import FacilitatorsBlock from '../../../components/Styled/FacilitatorsBlock'
import InteractiveTable from '../../../components/Styled/InteractiveTable'
import MessagesBlock from '../../../components/Styled/MessagesBlock'
import ReputationBlock from '../../../components/Styled/ReputationBlock'
import AssistanceBlock from '../../../components/Styled/AssistanceBlock'
import { useTranslation } from 'react-i18next'

dayjs.extend(isBetween)
dayjs.extend(isToday)

const TeamDetailView = (props) => {
    const {
        mainLinks,
        onMeeting,
        professorScheduleId,
        subjectId,
        onVoteTable,
        votedTable,
        ownData,
        teamData,
        profile,
        voted,
        vote,
        unassigned,
        attendance,
        markAttendance,
        iframeLink,
        meetingModalShow,
        setMeetingModalShow,
    } = props

    const { sendNotification } = useNotifications()
    const { meeting } = useContext(SubjectStudentContext)
    const { titleCaseFn } = useUIContext()
    const { user } = useContext(UserContext)
    const [emptyState, setEmptyState] = useState({})
    const { t, i18n } = useTranslation(['teamDetailStudent', 'errors'])
    // View Awareness
    const now = dayjs().valueOf()
    const start = dayjs(teamData?.nextClass?.start).valueOf()
    const end = dayjs(teamData?.nextClass?.end).valueOf()
    const presentBeforeStart = dayjs(teamData?.nextClass?.start)
        .subtract(teamData?.nextClass?.attendancesRegistrationTime.attendancesStartTime, 'minutes')
        .valueOf()
    const startPlusTolerance = dayjs(teamData?.nextClass?.start)
        .add(teamData?.nextClass?.attendancesRegistrationTime.attendancesEndTime, 'minutes')
        .valueOf()

    const isEarly = () => now < start
    const isToday = () => dayjs(start).isToday()
    const isInRange = () => dayjs(now).isBetween(presentBeforeStart, startPlusTolerance)
    const classAlreadyStarted = () => dayjs(now).isBetween(startPlusTolerance, end)
    const meetingEnabled = () => dayjs(now).isBetween(presentBeforeStart, end)
    // Wait for unassigned to be updated and get current empty state case

    const getUnassignedCase = () => {
        if (unassigned) {
            if (teamData.settings.isVirtual) {
                // Si hay próxima clase config
                if (teamData.nextClass) {
                    if (isEarly() && isToday() && !isInRange()) {
                        /**  Si todavía no comienza */
                        setEmptyState({
                            title: t('teamDetailStudent:attendance.early-day-noRange.title'),
                            msg: t('teamDetailStudent:attendance.early-day-noRange.description', {
                                timeMeet: dayjs(teamData?.nextClass?.start)
                                    .locale(i18n.language)
                                    .fromNow(),
                                timeMeetRange: dayjs(teamData?.nextClass.start)
                                    .subtract(
                                        teamData?.nextClass?.attendancesRegistrationTime
                                            ?.attendancesStartTime,
                                        'minutes'
                                    )
                                    .format('HH:mm'),
                            }),
                        })
                    } else if (isToday() && isInRange()) {
                        /** Si ya comenzó pero está dentro del limite de tolerancia  */
                        if (teamData.course.defaultShouldCheckPresents) {
                            setEmptyState({
                                title: t('teamDetailStudent:attendance.early-day-range.title'),
                                msg: t('teamDetailStudent:attendance.early-day-range.description', {
                                    present: teamData.course.defaultShouldCheckPresents
                                        ? t('teamDetailStudent:attendance.early-day-range.present')
                                        : '',
                                    timeMeet: dayjs(teamData?.nextClass?.start).format('HH:mm'),
                                    timeMeetRange: dayjs(teamData?.nextClass?.start)
                                        .add(
                                            teamData?.nextClass?.attendancesRegistrationTime
                                                .attendancesEndTime,
                                            'minutes'
                                        )
                                        .format('HH:mm'),
                                }),
                                refresh: false,
                                setPresent: true,
                            })
                        } else {
                            setEmptyState({
                                title: t('teamDetailStudent:attendance.else-early-day-range.title'),
                                msg:
                                    t(
                                        'teamDetailStudent:attendance.else-early-day-range.description',
                                        {
                                            present: teamData.course.defaultShouldCheckPresents
                                                ? t(
                                                      'teamDetailStudent:attendance.else-early-day-range.present'
                                                  )
                                                : '',
                                        }
                                    ) + dayjs(teamData?.nextClass?.start).format('HH:mm'),
                                refresh: false,
                                setPresent: true,
                            })
                        }
                    } else if (isToday() && classAlreadyStarted()) {
                        /** Si ya comenzó pero todavía no termina  */
                        setEmptyState({
                            title: t('teamDetailStudent:attendance.day-late.title'),
                            msg: t('teamDetailStudent:attendance.day-late.description'),
                            notificateCoach: true,
                        })
                    } else {
                        /**  Si es otro día  */
                        setEmptyState({
                            title: t('teamDetailStudent:attendance.another-day.title'),
                            msg:
                                t('teamDetailStudent:attendance.another-day.description') +
                                dayjs(teamData?.nextClass?.start)
                                    .locale(i18n.language)
                                    .format('dddd HH:mm'),
                        })
                    }
                } else {
                    /**  Si NO hay próxima clase configurada  */
                    setEmptyState({
                        title: t('teamDetailStudent:attendance.no-day-set.title'),
                        msg: t('teamDetailStudent:attendance.no-day-set.description'),
                    })
                }
            } else {
                setEmptyState({
                    title: t('teamDetailStudent:attendance.no-day-set-secondary.title'),
                    msg: t('teamDetailStudent:attendance.no-day-set-secondary.description'),
                })
            }
        }
    }

    // Wait for unassigned to be updated and get current empty state case
    useEffect(() => {
        getUnassignedCase()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unassigned, teamData, t, i18n])

    const getAttendanceElms = () => {
        switch (ownData?.attending) {
            case AttendanceStatus.UNSET:
                return (
                    <BtnAttend
                        attendance={attendance}
                        onClick={markAttendance}
                        title={t('teamDetailStudent:attendance.in-time')}
                    >
                        <div>
                            <FontAwesomeIcon className="icon" icon={['fal', 'hand-paper']} />
                            <span>{t('teamDetailStudent:attendance.in-time')}</span>
                        </div>
                    </BtnAttend>
                )
            case AttendanceStatus.PRESENT:
                return (
                    <BtnAttend
                        disabled
                        attendance={attendance}
                        onClick={markAttendance}
                        title={t('teamDetailStudent:attendance.here')}
                    >
                        <div>
                            <FontAwesomeIcon className="icon" icon={['fal', 'check-circle']} />
                            <span>{t('teamDetailStudent:attendance.in-time')}</span>
                        </div>
                    </BtnAttend>
                )
            case AttendanceStatus.DELAY:
                return (
                    <BtnAttend
                        disabled
                        attendance={attendance}
                        onClick={markAttendance}
                        title={t('teamDetailStudent:attendance.here')}
                    >
                        <div>
                            <FontAwesomeIcon className="icon" icon={['fal', 'clock']} />
                            <span>{t('teamDetailStudent:attendance.delay')}</span>
                        </div>
                    </BtnAttend>
                )
            case AttendanceStatus.ABSENT:
                return (
                    <BtnAttend
                        disabled
                        attendance={attendance}
                        onClick={markAttendance}
                        title={t('teamDetailStudent:attendance.here')}
                    >
                        <div>
                            <FontAwesomeIcon className="icon" icon={['fal', 'times-circle']} />
                            <span>{t('teamDetailStudent:attendance.absent')}</span>
                        </div>
                    </BtnAttend>
                )
        }
    }
    return (
        <WideLayout
            ruta={`${titleCaseFn(teamData?.hq.name)} > ${titleCaseFn(teamData?.course.name)}`}
            links={mainLinks}
        >
            <HeadingStudent
                progress={ownData?.stats.cooperationIndex}
                goal={teamData?.goal.name}
                stats={ownData?.stats}
                owners={teamData?.owners}
                meeting={meeting}
                onMeeting={onMeeting}
                isVirtual={teamData?.settings.isVirtual}
                attendance={attendance}
                markAttendance={markAttendance}
                rubricEnabled={teamData?.settings?.rubricContent}
                tableNumber={ownData?.table?.tableNumber}
                unassigned={unassigned}
                enableMeetingUnassigned={teamData?.settings?.enableMeetingUnassigned}
                meetingEnabled={meetingEnabled}
            />

            {unassigned && (
                <EggCard>
                    <div className="content-no-assignation">
                        <div className="table-empty-msg">
                            <div>
                                <FontAwesomeIcon className="empty-icon" icon={['fal', 'clock']} />
                            </div>
                            <h3 style={{ textAlign: 'center', marginBottom: '-5px' }}>
                                {emptyState?.title}
                            </h3>
                            <p className="global-text">{emptyState?.msg}</p>
                            <div
                                style={{
                                    margin: '0 auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {emptyState?.setPresent && getAttendanceElms()}
                                {/* Definir con el back que el usuario pueda mandar una alerta cuando está NO asignado*/}
                                {emptyState?.notificateCoach && (
                                    <button
                                        className="btn-yellow"
                                        onClick={() =>
                                            sendNotification(
                                                t(
                                                    'teamDetailStudent:attendance.new-join-request.title'
                                                ),
                                                `${t(
                                                    'teamDetailStudent:attendance.new-join-request.description',
                                                    {
                                                        user: `${user.name} ${user.lastname}`,
                                                        team: `${teamData.course.name}`,
                                                        hqName: `${teamData.hq.name}`,
                                                    }
                                                )}`,
                                                { _professorScheduleId: professorScheduleId }
                                            )
                                        }
                                    >
                                        {t('teamDetailStudent:notification.give-notice')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </EggCard>
            )}

            {!unassigned && JSON.stringify(ownData?.table) !== '{}' && (
                <div className="table">
                    <InteractiveTable
                        toolbar
                        table={ownData?.table}
                        tableLabel
                        profileId={profile?.id}
                        onVoteTable={onVoteTable}
                        votedTable={votedTable}
                        showStdVoteBtn={true}
                        voted={voted}
                        vote={vote}
                        sendAlert={sendNotification}
                        meeting={meeting}
                        onMeeting={onMeeting}
                        isVirtual={teamData?.settings.isVirtual}
                        meetingEnabled={meetingEnabled}
                        ownDataMeeting={teamData?.meeting}
                    />
                </div>
            )}

            {ownData && (
                <TwoCols>
                    <EggCard colChildren>
                        <FacilitatorsBlock
                            facilitators={teamData?.facilitators}
                            titleCaseFn={titleCaseFn}
                            ps={professorScheduleId}
                            s={subjectId}
                        />
                    </EggCard>
                    <EggCard colChildren>
                        <ReputationBlock
                            rank={teamData?.rankReputation}
                            ps={professorScheduleId}
                            s={subjectId}
                        />
                    </EggCard>
                </TwoCols>
            )}

            <TwoCols>
                <EggCard colChildren>
                    <MessagesBlock />
                </EggCard>
                <EggCard colChildren>
                    <AssistanceBlock assistance={ownData?.assistance} />
                </EggCard>
            </TwoCols>

            {meetingModalShow && iframeLink && (
                <ModalEgg
                    modalShow={meetingModalShow}
                    modalId="modal-jitsi"
                    closeModal={() => setMeetingModalShow(false)}
                >
                    <iframe
                        allow="camera; microphone"
                        className="iframe-modal-jitsi"
                        src={iframeLink}
                        // eslint-disable-next-line react/no-unknown-property
                        framebBorder="0"
                        width="100%"
                        height="100%"
                    />
                    <button
                        onClick={() => window.open(iframeLink, '_blank', 'noopener noreferrer')}
                    >
                        <FontAwesomeIcon className="empty-icon" icon={['fal', 'link']} />
                        {t('teamDetailStudent:notification.new-window')}
                    </button>
                </ModalEgg>
            )}
        </WideLayout>
    )
}

export default TeamDetailView

const TwoCols = styled.div`
    display: flex;
    margin: 30px 0;
    flex-direction: column;
    /* flex:1 1 0; */
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`
