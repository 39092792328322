import React, { useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useReactMediaRecorder } from 'react-media-recorder'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../context/UIContext'

const VideoStyles: React.CSSProperties = {
    borderRadius: '8px',
    width: '20rem',
    marginBottom: '1rem',
}

export const Recorder = (props: Props) => {
    const { t } = useTranslation('teamMonitor')
    const { file, setFile } = useUIContext()

    const { status, startRecording, stopRecording, mediaBlobUrl, ...rest } = useReactMediaRecorder({
        video: true,
        onStop: (_, file) => setFile(file),
        onStart: () => setFile(null),
        ...props,
    })

    return (
        <div>
            {file ? (
                <video style={VideoStyles} src={mediaBlobUrl} autoPlay controls />
            ) : (
                <VideoPreview {...rest} />
            )}

            {status === 'acquiring_media' && (
                <button className="accent" disabled>
                    {t('Loading...')}
                </button>
            )}

            {status === 'idle' && (
                <button onClick={startRecording} className="accent">
                    <FontAwesomeIcon icon={['fas', 'circle']} />
                    {t('Record')}
                </button>
            )}
            {status === 'recording' && (
                <button onClick={stopRecording} className="white">
                    <FontAwesomeIcon icon={['fas', 'stop']} />
                    {t('Stop recording')}
                </button>
            )}
            {status === 'stopped' && (
                <button onClick={startRecording} className="white">
                    <FontAwesomeIcon icon={['fas', 'rotate-right']} />
                    {t('Record again')}
                </button>
            )}
        </div>
    )
}

const VideoPreview = ({
    previewStream,
}: Pick<ReturnType<typeof useReactMediaRecorder>, 'previewStream'>) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        if (videoRef.current) videoRef.current.srcObject = previewStream
    }, [previewStream])

    if (!previewStream) return <VideoPreviewSkeleton />

    return <video style={VideoStyles} autoPlay ref={videoRef} />
}

const VideoPreviewSkeleton = () => (
    <div
        style={{
            ...VideoStyles,
            backgroundColor: 'black',
            height: '157px',
        }}
    />
)

type Props = Partial<Parameters<typeof useReactMediaRecorder>[0]>
