// API
import React, { useState } from 'react'
import { SubmitHandler, UseFormMethods } from 'react-hook-form'
import { useAuth0 } from '@auth0/auth0-react'

// Components
import NoHeaderLayout from '../../layouts/NoHeaderLayout'
// Hooks
import { useTranslation } from 'react-i18next'
// Assets
import EggLogo from '../../assets/images/egg_shaddow.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { version } from '../../../package.json'
import { LoginFormField } from './LoginContainer'

interface IProps {
    onSubmit: SubmitHandler<LoginFormField>
    onRecover?: () => unknown
    onSignup?: () => unknown
    form: UseFormMethods<LoginFormField>
}

const LoginView = (props: IProps) => {
    const { handleSubmit, register, errors, formState } = props.form

    const { onSubmit } = props
    const { t, i18n } = useTranslation(['login'])
    const [lang, setLang] = useState(i18n.language.toUpperCase())

    const switchLang = () => {
        if (lang === 'ES') {
            i18n.changeLanguage('en')
            setLang('EN')
        } else {
            i18n.changeLanguage('es')
            setLang('ES')
        }
    }

    return (
        <NoHeaderLayout>
            <div className="login-pages">
                <div className="lang-switch">
                    <button onClick={switchLang}>
                        <FontAwesomeIcon className="icon" icon={['fal', 'globe-americas']} />
                        <span>{lang}</span>
                    </button>
                </div>
                <div className="container">
                    <div className="onboarding-card responsive">
                        <div className="onboarding-form">
                            <img
                                src={EggLogo}
                                style={{ width: '70px', margin: '0 auto 20px auto' }}
                                alt=""
                            />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <input
                                        type="email"
                                        name="email"
                                        defaultValue={process.env.REACT_APP_LOGIN_U ?? ''}
                                        placeholder={t('login:email')}
                                        className="form-input"
                                        autoComplete="username"
                                        ref={register({
                                            required: 'Campo requerido',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'E-mail inválido',
                                            },
                                        })}
                                    />
                                    {errors.email?.message && (
                                        <div className="input-error-msg">
                                            {errors.email?.message}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <input
                                        type="password"
                                        name="password"
                                        defaultValue={process.env.REACT_APP_LOGIN_P ?? ''}
                                        placeholder={t('login:password')}
                                        className="form-input"
                                        autoComplete="current-password"
                                        ref={register({
                                            required: 'Campo requerido',
                                        })}
                                    />
                                    {errors.password?.message && (
                                        <div className="input-error-msg">
                                            {errors.password.message}
                                        </div>
                                    )}
                                </div>
                                <button
                                    className="btn-yellow"
                                    type="submit"
                                    style={{ marginBottom: '10px' }}
                                >
                                    {formState.isSubmitting ? (
                                        <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                    ) : (
                                        t('login:login')
                                    )}
                                </button>
                            </form>
                            <div className="alt-links">
                                <Auth0LoginButton />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="version">version {version}</div>
            </div>
        </NoHeaderLayout>
    )
}

export default LoginView

const Auth0LoginButton = () => {
    const { loginWithRedirect } = useAuth0()

    return (
        <button onClick={() => loginWithRedirect()} className="forgot-psw-link">
            SSO
        </button>
    )
}
