import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MutationStatus } from 'react-query'
import Styles from './SpreadMessage.module.css'
import { Tabs } from '../../../../../components/Buttons/Tabs'
import { TextMessage } from './Mode/TextMessage'
import { UploadVideo } from './Mode/UploadVideo'
import { useContent } from '../../../../../hooks/useContent'
import { useTeam } from '../../../Hooks/useTeam'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'

export const SpreadMessageModal = () => {
    const { t } = useTranslation('teamMonitor')
    const { closeModal } = useUIContext()

    const [status, setStatus] = useState<MutationStatus>('idle')

    return (
        <section className={Styles.section}>
            <header className={Styles.header}>
                <span className={Styles.title}>{t('Spread message')}</span>
                <button className={Styles.closeButton} onClick={closeModal}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                </button>
            </header>
            <main className={Styles.main}>
                {status === 'idle' ? (
                    <SendContentIdle onSend={setStatus} />
                ) : status === 'error' ? (
                    <SendContentError onConfirm={() => setStatus('idle')} />
                ) : (
                    <SendContentSuccess onConfirm={() => setStatus('idle')} />
                )}
            </main>
        </section>
    )
}

const SendContentIdle = ({ onSend }: { onSend?: (status: MutationStatus) => void }) => {
    const { t } = useTranslation('teamMonitor')
    const { courseID } = useTeam()
    const { isLoading, sendContent } = useContent()
    const [type, setType] = useState<Type>('video')

    const srcState = useState('')
    const [src] = srcState

    const messageState = useState('')
    const [message, setMessage] = messageState

    if (!courseID) return null

    const disabled = (!src && !message) || isLoading

    const tabs = (['video', 'text'] as const).map((value) => ({
        label: t(value),
        value,
    }))

    return (
        <>
            <Tabs tabs={tabs} defaultSelected={type} onChange={setType} />
            <hr />
            <form
                onSubmit={(event) => {
                    event.preventDefault()

                    if (!src && !message) return

                    sendContent({
                        filter: {
                            type: 'any',
                            to: [courseID],
                        },
                        payload: type === 'video' ? { type, src } : { type, message },
                    })
                        .then(() => onSend?.('success'))
                        .catch(() => onSend?.('error'))
                    setMessage('')
                }}
            >
                <div className={Styles.selectedTab}>
                    {type === 'video' ? (
                        <UploadVideo state={srcState} />
                    ) : (
                        <TextMessage state={messageState} />
                    )}
                </div>

                <button className="primary" disabled={disabled}>
                    {t('Send now')}
                </button>
            </form>
        </>
    )
}

const SendContentSuccess = ({ onConfirm }: { onConfirm?: () => void }) => {
    const { t } = useTranslation('teamMonitor')

    return (
        <>
            <div className="flex column text-center" style={{ padding: '4em 0' }}>
                <FontAwesomeIcon
                    icon={['fas', 'check-circle']}
                    style={{ color: '#00D1A0', fontSize: '3em' }}
                />
                <p>{t('Diffusion message was sent successfully.')}</p>
            </div>
            <button className="white" onClick={onConfirm}>
                {t('Got it')}
            </button>
        </>
    )
}

const SendContentError = ({ onConfirm }: { onConfirm?: () => void }) => {
    const { t } = useTranslation('teamMonitor')

    return (
        <>
            <div className="flex column text-center" style={{ padding: '4em 0' }}>
                <FontAwesomeIcon
                    icon={['fas', 'exclamation-triangle']}
                    style={{ color: '#FF647C', fontSize: '3em' }}
                />
                <p>{t('Error sending diffusion message. Please try again.')}</p>
            </div>
            <button className="accent" onClick={onConfirm}>
                {t('Got it')}
            </button>
        </>
    )
}

export const SpreadMessageModalStyles: {
    content: React.CSSProperties
    overlay: React.CSSProperties
} = {
    content: {
        width: 'min-content',
        height: 'min-content',
        minWidth: '24.125rem',
        minHeight: '385px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 55, 80, 0.98)',
    },
}

type Type = 'video' | 'text'
