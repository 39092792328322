import React from 'react'
import Axios from 'axios'
import Config from '../../config'

export type NamedType = { id: string; name: string }

export type ScheduleItem = [string, string]

export type ScheduleSaveType = (days: ScheduleItem[]) => Promise<string>

export function useSchedule(
    /** INPUT */
    _professorScheduleId: string,
    _subjectId: string,
    // eslint-disable-next-line no-console
    onError = console.error
) {
    const [schedule, setSchedule] = React.useState<ScheduleItem[] | undefined | null>()
    const [hq, setHq] = React.useState<{ id: string; name: string } | undefined>()
    const [course, setCourse] = React.useState<{ id: string; name: string } | undefined>()
    const [subject, setSubject] = React.useState<{ id: string; name: string } | undefined>()
    const [teamId, setTeamId] = React.useState<string | undefined>()

    React.useEffect(
        function getSchedule() {
            Axios.get(
                Config.API + '/professorSchedule/' + _professorScheduleId + '/subject/' + _subjectId
            )
                .then((response) => {
                    const { subject, course, _teamId } = response.data.professorSchedule
                    //const schedules = subject.schedules.map(({start, end}: {start: string, end: string}) => [start, end])
                    const schedules = subject.schedules.map(
                        ({ start, end }: { start: string; end: string }) => [start, end]
                    )
                    setSchedule(schedules)
                    setHq({ id: course.hq._id, name: course.hq.name })
                    setCourse({ id: course._id, name: course.name })
                    setSubject({ id: subject._subjectId, name: subject.name })
                    setTeamId(_teamId)
                })
                .catch((error) => {
                    setSchedule(null)
                    onError && onError(error)
                })
        },
        [_professorScheduleId, _subjectId, onError]
    )

    const saveCourse = async (courseName: string) => {
        if (!course?.id) return
        const response = await Axios.patch(`${Config.API}/class/${course.id}`, { name: courseName })
        setCourse({ id: course.id, name: courseName })
        return response.data
    }
    const saveSubject = async (subjectName: string) => {
        if (!subject?.id) return
        const response = await Axios.patch(Config.API + '/subject/' + subject.id, {
            _professorScheduleId,
            name: subjectName,
        })
        setSubject({ id: subject.id, name: subjectName })
        return response.data
    }

    const saveSchedules: ScheduleSaveType = async (days) => {
        const response = await Axios.put(
            Config.API + '/professorSchedule/' + _professorScheduleId + '/subject/' + _subjectId,
            {
                schedules: days.map((day) => ({ start: day[0], end: day[1] })),
            }
        )
        // Ensure that today's classes comes first
        setSchedule(days)
        return response.data._professorScheduleId
    }

    return [schedule, saveSchedules, hq, course, subject, teamId, saveCourse, saveSubject] as const
}
