import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Axios from 'axios'
import UIContext from '../../context/UIContext'
import { useUser } from '../../context/UserContext'
import Config from '../../config'
import { AWS } from '../../infrastructure/api-aws'
import { capitalize } from '../../helpers/formatter'
import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons'
import { colors } from '../../styles/globals/_variables'

const AvatarEgg = (props: AvatarEggProps) => {
    const { toastError } = useContext(UIContext)
    const { t } = useTranslation('profile')
    const { src, title, isEditable = false, size, isExternal, badge, hasVoted } = props
    const fileInput = useRef<HTMLInputElement>(null)
    const { token, user, setUser } = useUser()
    const [userImage, setUserImage] = useState('')
    const [isAWSUpdating, setIsAWSUpdating] = useState(false)
    const [isImageLoading, setIsImageLoading] = useState(false)
    const { urlImage } = user || {}
    const displayName = isExternal ? title : capitalize(user?.name + ' ' + user?.lastname)

    const genericError = t('Error uploading image, try again later.')
    const maxImageSizeMB = 4.3
    const availableImageTypes = ['image/jpeg', 'image/png', 'image/jpg']

    const handleFileSelect = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
        const fileToUpload = files && files[0]

        if (!fileToUpload) return

        if (fileToUpload.size / 1024 / 1024 > maxImageSizeMB) {
            toastError(
                t('The image is too large. The maximum size is {{size}}MB.', {
                    size: maxImageSizeMB,
                })
            )
            return
        }

        if (!availableImageTypes.includes(fileToUpload.type)) {
            toastError(t('The image must be in JPG, JPEG or PNG format.'))
            return
        }
        setIsAWSUpdating(true)

        const formData = new FormData()
        formData.append('file', fileToUpload)

        handleUploadPhoto(formData)
    }

    const handleUploadPhoto = async (formData: FormData) => {
        if (!user?._id || !token) return
        try {
            const urlImage = await AWS.uploadImage({
                formData,
                userId: user._id,
                token,
            })
            if (!urlImage) return toastError(genericError)
            setIsImageLoading(true)

            await Axios.patch<DefaultUserDTO>(
                `${Config.API_SIGNUP}/v1/users/updateImageUrl`,
                {
                    urlImage,
                },
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token') ?? '')}`,
                    },
                }
            )
                .then(({ data }) => {
                    setUser({ urlImage: data.urlImage })
                })
                .catch((error) => toastError(error))
                .finally(() => setIsImageLoading(false))
        } catch (error) {
            toastError(genericError)
        } finally {
            formData.delete('file')
            setIsAWSUpdating(false)
        }
    }

    useEffect(() => {
        setUserImage(isExternal ? src || '' : urlImage || '')
    }, [urlImage, src, user, isExternal])

    return (
        <AvatarEggStyled {...{ src: userImage, size, badge }} className="avatar">
            <input
                style={{ display: 'none' }}
                type="file"
                onChange={(event) => handleFileSelect(event)}
                ref={fileInput}
                accept={availableImageTypes.join(',')}
            />
            <div className="main">
                {isImageLoading ? (
                    <FontAwesomeIcon className="btn-icon" icon={['far', 'spinner']} spin />
                ) : (isExternal && !src) || !userImage ? (
                    <span className="gravatar">
                        {displayName
                            ?.split(' ')
                            .map((word: string) => word.slice(0, 1))
                            .join('')
                            .toUpperCase()
                            .substring(0, 2)}
                    </span>
                ) : (
                    <img
                        src={src ?? userImage}
                        alt={title}
                        className={isAWSUpdating || isImageLoading ? 'opacity' : ''}
                    />
                )}
                {isEditable &&
                    (!isAWSUpdating && !isImageLoading ? (
                        <button
                            className="overlay"
                            onClick={() => fileInput.current?.click()}
                            aria-label="Upload profile image"
                        >
                            <FontAwesomeIcon className="btn-icon" icon={['far', 'pen-to-square']} />
                        </button>
                    ) : (
                        <div className="overlay permanent">
                            <FontAwesomeIcon className="btn-icon" icon={['far', 'spinner']} spin />
                        </div>
                    ))}
            </div>
            {badge && (
                <div className="badge">
                    <FontAwesomeIcon className="icon" icon={[badge[0], badge[1]]} />
                </div>
            )}
            {hasVoted && (
                <div title={t('vote.already-voted')} className="voted">
                    <FontAwesomeIcon className="icon" icon={['fas', 'egg']} />
                </div>
            )}
        </AvatarEggStyled>
    )
}

export default AvatarEgg

interface AvatarEggProps {
    src?: string
    title?: string
    size?: { mobile: number; desktop: number }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style?: Record<string, any>
    isEditable?: boolean
    disabled?: boolean
    isExternal?: boolean
    badge?: [IconPrefix, IconName, string]
    hasVoted?: boolean
}

interface DefaultUserDTO {
    id: string
    userId: string
    email: string
    displayNameOrName: string
    name: string | null
    lastname: string | null
    displayName?: string
    dateBirth: string | null
    dni: string | null
    gender: string | null
    phone: string | null
    urlImage?: string
    isVerified: boolean
    country: string | null
    countryId: number
    provinceId: number | null
    tz: string | null
    referrer?: string
    createdAt: string
    complete: boolean
}

export const AvatarEggStyled = styled.div<{
    src?: AvatarEggProps['src']
    size: AvatarEggProps['size']
    badge: AvatarEggProps['badge']
}>`
    .main {
        background-color: ${colors.white};
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: ${({ src }) => (src ? 'none' : `1px solid ${colors.borders}`)};
        border-radius: 100%;
        width: ${({ size }) => (size ? `${size.desktop}px` : '110px')};
        height: ${({ size }) => (size ? `${size.desktop}px` : '110px')};
        @media (max-width: 768px) {
            border-radius: 0;
            width: ${({ size }) => (size ? `${size.mobile}px` : '100px')};
            height: ${({ size }) => (size ? `${size.mobile}px` : '100px')};
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${colors.white};
            text-align: center;
            font-size: 12px;
            border: none;
            margin: 0;
            font-size: 0px;
            background-color: rgba(0, 0, 0, 0);

            &.permanent {
                font-size: 30px;
                background-color: rgba(0, 0, 0, 0.7);
            }
            &:hover {
                cursor: pointer;
                -webkit-transition: background-color 150ms linear;
                -ms-transition: background-color 150ms linear;
                transition: background-color 150ms linear;
                font-size: 30px;
                background-color: rgba(0, 0, 0, 0.7);
            }
            @media (max-width: 768px) {
                font-size: 12px;
                top: 70%;
                left: 70%;
                width: 26px;
                height: 26px;
                padding: 5px;
                border-radius: 100%;
                opacity: 1;
                background-color: ${colors.white};
                color: ${colors.darkGreyEgg};

                &.permanent {
                    background: -${colors.white};
                    color: ${colors.darkGreyEgg};
                    opacity: 1;
                    font-size: 12px;
                }
                &:hover {
                    background-color: ${colors.white};
                    font-size: 12px;
                }
            }
        }

        span.gravatar {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: ${({ size }) => (size ? size.desktop * 0.305 + 'px' : '32px')};
            font-weight: bold;
            letter-spacing: 0.05rem;
            border: ${({ src }) => (src ? 'none' : `1px solid ${colors.lightGreyBg}`)};
            border-radius: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            transition: opacity 0.3s ease; // Suaviza la transición de opacidad
            border: ${({ src }) => (src ? 'none' : `1px solid ${colors.lightGreyBg}`)};
            &.opacity {
                opacity: 0.7;
            }
            @media (max-width: 768px) {
                border-radius: 100%;
            }
        }
        &:hover:not([disabled]) {
            color: ${colors.darkGreyEgg};
        }
    }
    .badge {
        /* border:4px solid white; */
        border-radius: 26px;
        position: absolute;
        bottom: 0px;
        right: -5px;
        height: ${({ size }) =>
            size?.desktop && size?.desktop * 0.4 > 28 ? `${size?.desktop * 0.4}px` : '28px'};
        width: ${({ size }) =>
            size?.desktop && size?.desktop * 0.4 > 28 ? `${size?.desktop * 0.4}px` : '28px'};
        background-color: ${colors.eggColor};
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
        .icon {
            font-size: 0.8rem;
        }
    }
    .voted {
        position: absolute;
        bottom: -5px;
        right: -5px;
        height: ${({ size }) =>
            size?.desktop && size?.desktop * 0.4 > 28 ? `${size?.desktop * 0.4}px` : '28px'};
        width: ${({ size }) =>
            size?.desktop && size?.desktop * 0.4 > 28 ? `${size?.desktop * 0.4}px` : '28px'};
        /* background:red; */
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
            font-size: 1.4rem;
        }
        .icon path {
            color: ${colors.eggColor};
            stroke: white;
            stroke-width: 6rem;
        }
    }
`
