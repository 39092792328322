import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ROLES } from '../../../../constants'
import ManagerContext, { ActionView } from '../../../../context/ManagerContext'
import { SubjectContext } from '../../../../context/SubjectContext'
import UserContext from '../../../../context/UserContext'
import ReportHeaderDropdown from './Headers/ReportHeaderDropdown'
import ReactGA from 'react-ga'
import { useTeam } from '../../../Team/Context/TeamContext'

export const ActionsMenu = () => {
    const { actionView, setActionView, onAssignationReport } = useContext(ManagerContext)
    const { joinMeeting: onMeeting, hq, course, subject } = useContext(SubjectContext)
    const { role } = useContext(UserContext)
    const { team } = useTeam()

    const { t } = useTranslation(['teamManager'])
    return (
        <div className="block-actions">
            <div onClick={() => onMeeting?.()} className="action-link">
                <FontAwesomeIcon className="icon" icon={['fal', 'video']} />
                <span>{t('teamManager:heading.virtualClass')}</span>
            </div>
            <div className="action-link">
                <ReportHeaderDropdown
                    label={t('teamManager:heading.report.label')}
                    icon={['far', 'file-download']}
                    className="action-link"
                >
                    <div className="report-dropdown">
                        <div
                            className="report-item action-link"
                            onClick={() => {
                                onAssignationReport('name')
                                ReactGA.event({
                                    category: 'Reporting',
                                    action: 'TeamReportDownloaded',
                                    dimension1: 'platform',
                                    dimension2: hq?.name.toLowerCase(),
                                    dimension3: course?.name.toLowerCase(),
                                    dimension4: subject?.name.toLowerCase(),
                                    dimension7: role,
                                    dimension9: team?.algorithm
                                        ? team?.algorithm?.algorithmType
                                        : 'no algorithm available',
                                })
                            }}
                        >
                            {t('teamManager:heading.report.order-by-name')}
                        </div>
                        <div
                            className="report-item action-link"
                            onClick={() => {
                                onAssignationReport('assignation')
                                ReactGA.event({
                                    category: 'Reporting',
                                    action: 'TeamReportDownloaded',
                                    dimension1: 'platform',
                                    dimension2: hq?.name.toLowerCase(),
                                    dimension3: course?.name.toLowerCase(),
                                    dimension4: subject?.name.toLowerCase(),
                                    dimension7: role,
                                    dimension9: team?.algorithm
                                        ? team?.algorithm?.algorithmType
                                        : 'no algorithm available',
                                })
                            }}
                        >
                            {t('teamManager:heading.report.order-by-team')}
                        </div>
                    </div>
                </ReportHeaderDropdown>
            </div>
            {role === ROLES.DIRECTOR && (
                <button
                    className="action-link"
                    id="add-btn"
                    style={{ width: 'auto' }}
                    onClick={() =>
                        setActionView(
                            actionView === ActionView.Add ? ActionView.Attendance : ActionView.Add
                        )
                    }
                >
                    <FontAwesomeIcon
                        className="icon"
                        icon={[actionView !== ActionView.Add ? 'fas' : 'fal', 'plus-circle']}
                    />
                </button>
            )}
        </div>
    )
}
