const defaultMessage = 'Whoops! An error occurred. Please try again.'

/** Return a message in error, or fallback message */
export const errorHandler = (error: unknown, message = defaultMessage): string => {
    if (!error) return defaultMessage
    const err = error as APIError
    return err.data?.message || err.message || message || defaultMessage
}

type APIError = {
    message?: string
    data?: {
        message?: string
    }
}
