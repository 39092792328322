import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import ManagerContext from '../../../../../context/ManagerContext'
import dayjs from 'dayjs'
import UIContext from '../../../../../context/UIContext'
import { useTranslation } from 'react-i18next'

const ExamHeader = () => {
    const { onExamDownloadTemplate, onExamUpload, onExamSubmit, examForm } = useContext(
        ManagerContext
    )

    const [downloading, setDownloading] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)

    const { setInfoBlock } = useContext(UIContext)

    const { formState, register, errors, unregister } = examForm

    const { t } = useTranslation(['teamManager'])

    const handleUpload = () => {
        setUploading(true)
        onExamUpload()
            .then(() => setInfoBlock(['success', 'Se han cargado las notas.']))
            .catch((error) => setInfoBlock(['error', error.message]))
            .finally(() => {
                setTimeout(() => setInfoBlock([]), 2000)
                setUploading(false)
            })
    }

    const handleDownload = () => {
        setDownloading(true)
        onExamDownloadTemplate().finally(() => {
            setDownloading(false)
        })
    }

    React.useEffect(() => {
        return () => unregister(['date', 'title'])
    }, [unregister])

    return (
        <div className="manager-action-header">
            <input
                className={`exam-date ${errors.title ? 'error' : ''}`}
                name="title"
                placeholder={t('teamManager:exam.exam-title-placeholder')}
                required
                ref={register({
                    required: 'Requerido',
                })}
            />
            <input
                className={`exam-date ${errors.date ? 'error' : ''}`}
                name="date"
                type="date"
                max={dayjs().format('YYYY-MM-DD')}
                ref={register({
                    required: 'Requerido',
                })}
            />

            <div className="separator" />

            <div onClick={handleDownload} className="action-link">
                <FontAwesomeIcon
                    className="icon"
                    icon={!downloading ? ['far', 'file-download'] : 'spinner'}
                    spin={downloading}
                />
                <span>{t('teamManager:exam.download-template-label')}</span>
            </div>

            <div onClick={handleUpload} className="action-link">
                <FontAwesomeIcon
                    className="icon"
                    icon={!uploading ? ['far', 'file-upload'] : 'spinner'}
                    spin={uploading}
                />
                <span>{t('teamManager:exam.upload-template-label')}</span>
            </div>

            <div
                onClick={
                    formState.isValid
                        ? !formState.isSubmitting
                            ? onExamSubmit
                            : undefined
                        : () => examForm.trigger()
                }
                className={`action-link ${formState.isValid ? 'en' : 'dis'}abled`}
            >
                <FontAwesomeIcon
                    className="icon"
                    icon={!formState.isSubmitting ? ['far', 'file-check'] : 'spinner'}
                    spin={formState.isSubmitting}
                />
                <span>{t('teamManager:exam.save-exam-label')}</span>
            </div>
        </div>
    )
}

export default ExamHeader
