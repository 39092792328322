import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React, { HTMLAttributes, ImgHTMLAttributes } from 'react'
import baseTheme from '../../../themes/baseTheme'
import { ButtonContainer, ButtonIcon, ButtonImage } from './Button.styles'

const Button = ({ children, icon, image, imageProps, ...rest }: IButtonProps) => {
    return (
        <ButtonContainer {...rest}>
            {!image && icon && <ButtonIcon icon={icon} />}
            {!icon && image && <ButtonImage src={image} {...imageProps} />}
            {children && (image || icon) && <div style={{ marginRight: 10 }} />}
            {children && <div>{children}</div>}
        </ButtonContainer>
    )
}

export default Button

export interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
    backgroundColor?: string
    borderColor?: string
    disabled?: boolean
    icon?: IconProp
    image?: string
    imageProps?: ImgHTMLAttributes<HTMLImageElement>
    type?: 'button' | 'submit' | 'reset' | undefined
}

export const OutlineButton = (props: IButtonProps) => (
    <Button
        backgroundColor={baseTheme.colors['ffffff']}
        borderColor={baseTheme.colors['eaeaea']}
        color={baseTheme.colors['003750']}
        style={{
            textTransform: 'capitalize',
        }}
        {...props}
    />
)
