import React, { useState } from 'react'

import { DoubleTabStyled } from './TabsStyled'

interface Props<T> {
    tabs: {
        label: string
        value: T
    }[]
    defaultSelected?: T
    onChange: (value: T) => void
}

export const Tabs = <T extends string>({ tabs, defaultSelected, onChange }: Props<T>) => {
    const [activeTab, setActiveTab] = useState(defaultSelected ?? tabs[0].value)

    const handleOnChange = (value: T) => {
        setActiveTab(value)
        onChange(value)
    }

    return (
        <DoubleTabStyled className="toggle-button">
            {tabs.map(({ label, value }) => (
                <button
                    key={label}
                    className={`tab ${activeTab === value ? 'active' : ''}`}
                    onClick={() => handleOnChange(value)}
                >
                    {label}
                </button>
            ))}
        </DoubleTabStyled>
    )
}
