import styled from 'styled-components'
import baseTheme from '../../../themes/baseTheme'
import { flex } from '../../../utils/mixins'
import Button from '../../atoms/Button/Button.component'

export const AccordionItemContainer = styled.div`
    ${flex('center', 'space-between')};
    border-bottom: 1px solid ${baseTheme.colors['eaeaea']};
    padding: 14px 0;
`

export const AccordionItemContentContainer = styled.div`
    ${flex('center', 'flex-start')};
`

export const AccordionItemMoreButton = styled(Button)`
    background: white;
    border-radius: 50%;
    border: 1px solid ${baseTheme.colors['eaeaea']};
    color: ${baseTheme.colors['37474f']};
    font-family: ${baseTheme.typography.fonts['font-awesome-6-pro']};
    font-size: 16px;
    height: 32px;
    letter-spacing: 0px;
    line-height: 16px;
    text-align: center;
    width: 32px;
`

export const AccordionItemNumber = styled.h3`
    color: ${baseTheme.colors['37474f']};
    font-family: ${baseTheme.typography.fonts.inter};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 21px;
    text-align: left;
`

export const AccordionItemOptionsContainer = styled.div`
    ${flex('center', 'flex-end')};
`

export const AccordionItemSubtitle = styled.h3`
    color: ${baseTheme.colors['073045']};
    font-family: ${baseTheme.typography.fonts.inter};
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 14px;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
`

export const AccordionItemTitle = styled.h2`
    color: ${baseTheme.colors['073045']};
    font-family: ${baseTheme.typography.fonts.inter};
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 26px;
    margin: 0;
    text-align: left;
    text-transform: capitalize;
`
