import { ActiveMeeting } from './ActiveMeeting'
import { FinishMeetingButton } from '../../FinishMeetingButton'
import { MoveToGeneralButton } from './MoveToGeneral.button'
import { MoveToTeamsButton } from './MoveToTeams.button'

export const TeamManage = () => (
    <main>
        <ActiveMeeting />
        <MoveToTeamsButton keepsTeam={false} />
        <MoveToGeneralButton />
        <hr style={{ margin: 0 }} />
        <FinishMeetingButton />
    </main>
)
