import { SpreadMessageModal, SpreadMessageModalStyles } from './SpreadMessage.modal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'

export const SpreadMessageButton = () => {
    const { t } = useTranslation('teamMonitor')
    const { setModal } = useUIContext()

    const openModal = () =>
        setModal({
            content: <SpreadMessageModal />,
            customStyles: SpreadMessageModalStyles,
        })

    return (
        <button className="white" onClick={openModal}>
            <FontAwesomeIcon icon={['fas', 'megaphone']} />
            {t('Spread message')}
        </button>
    )
}
