import Axios from 'axios'
import Config from '../config'

const downloadBlob = (blob: Blob, filename?: string) => {
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    filename && link.setAttribute('download', filename)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode?.removeChild(link)
}

export default downloadBlob

export const createLinkFromURL = (url: string, fileName: string) => {
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.click()
    link.remove()
}

export const createCsvFile = (data: unknown[], fileName: string) => {
    const csv = data.join('\n')
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    createLinkFromURL(url, fileName)
}

export const downloadFile = (url: string, fileName: string, baseURL = `${location.origin}/`) =>
    Axios.get(url, {
        responseType: 'blob',
        baseURL,
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        createLinkFromURL(url, fileName)
    })

export const downloadReport = (url: string, fileName: string) =>
    downloadFile(url, fileName, Config.API)
