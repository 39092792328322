// API
import React from 'react'
// Componentes
import InfoBlock from '../components/InfoBlockTopPage'
import styled from 'styled-components'
import NavBarDesktop from './NavBar/NavBarDesktop'
import { NavBarMobile } from './NavBar/NavBarMobile'

export interface LinkItem {
    active?: boolean
    url: string
    name: string
}

export interface Props {
    children: React.ReactNode
    ruta?: string
    links?: LinkItem[]
    page?: string
}

const WideLayout = ({ children }: Props) => {
    return (
        <Layout>
            <InfoBlock />
            <NavBarDesktop />
            <NavBarMobile />
            <main>{children}</main>
        </Layout>
    )
}

export default WideLayout

const Layout = styled.div`
    width: 100% !important;
    max-width: 100%;
    padding: 0;
    background-color: #f7f8f8;
    main {
        min-height: 600px;
        max-width: 1300px;
        margin: 0 auto;
        padding: 40px 20px;
        overflow-x: hidden;
        @media screen and (min-width: 1300) {
            padding: 40px 0;
        }
    }
`
