import React from 'react'
import { toViewDate } from '../../../../helpers/formatter/dateFormatters'
import { useCohorts } from '../../Hooks/useCohorts'
import { useReports } from '../../Context/ReportsContext'
import { useTranslation } from 'react-i18next'
import Select, { MultiValue } from 'react-select'

export const CohortsSelect = () => {
    const { t } = useTranslation('teamReports')
    const { optionsSelected, setOptionsSelected } = useReports()
    const { cohorts } = useCohorts()
    const options = cohorts?.map((cohort) => {
        return { label: toViewDate(cohort), value: toViewDate(cohort) }
    })
    return (
        <div className="cohort-header">
            <Select
                onChange={(values) => setOptionsSelected(values)}
                defaultValue={optionsSelected}
                isMulti
                isSearchable
                options={options}
                placeholder={t('All Cohorts')}
                noOptionsMessage={() => t('No options')}
            ></Select>
        </div>
    )
}

export type SelectedOptions = MultiValue<{
    value: string
    label: string
}> | null
