import { Countdown } from '../../Countdown'
import DayJS from '../../../../../helpers/DayJS'
import Styles from './ManageMeeting.module.css'
import { useQueryClient } from 'react-query'
import { useTeam } from '../../../Context/TeamContext'
import { useTimeline } from '../../../Hooks/useTimeline'
import { useTranslation } from 'react-i18next'

export const ActiveMeeting = () => {
    const { t } = useTranslation('teamMonitor')
    const { courseID } = useTeam()
    const { nextEvent } = useTimeline()
    const queryClient = useQueryClient()

    return (
        <div className={Styles.activeMeeting}>
            <p>{t("There's a team's meeting in progress")}</p>
            {nextEvent && (
                <>
                    <p>{t('Time remaining')}</p>{' '}
                    <div className={Styles.countdown}>
                        <Countdown
                            key={`${nextEvent.room}-${nextEvent.date}`}
                            duration={DayJS(nextEvent.date).diff(DayJS(), 'milliseconds')}
                            onFinish={() =>
                                queryClient.invalidateQueries(['Team', 'Timeline', courseID])
                            }
                        />
                    </div>
                </>
            )}
        </div>
    )
}
