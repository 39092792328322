import ReactSelect, { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select'

import { ComponentProps } from 'react'

export const Select = (props: Props) => (
    <ReactSelect {...props} unstyled styles={customSelectStyles as never} />
)

const customSelectStyles: StylesConfig<
    {
        value: string
        label: string
    },
    true,
    GroupBase<{
        value: string
        label: string
    }>
> = {
    control: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        background: 'white',
        border: '1px solid #f2f2f2',
        width: '100%',
        minWidth: '140px',
        minHeight: '40px',
        borderRadius: '0.5em',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
        color: 'black',
        padding: '1em',
        fontWeight: 600,
    }),
    valueContainer: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        gap: '1em',
    }),
    menu: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        padding: '0 20px',
        background: 'white',
        borderRadius: '12px',
        cursor: 'pointer',
        position: 'absolute',
        width: 'fit-content',
        minWidth: '140px',
        marginTop: '0.5em',
    }),
    menuList: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        padding: '10px 0',
        maxHeight: '200px',
    }),
    option: (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        padding: '10px 0',
        borderBottom: '1px solid #f2f2f2',
        cursor: 'pointer',
    }),
}

type Props = Omit<ComponentProps<typeof ReactSelect>, 'styles' | 'unstyled'>
