/* eslint-disable react/prop-types */
// API
import React from 'react'
// Components
import { Formik, Form, Field, ErrorMessage } from 'formik'
// Assets
import EggLogo from '../../assets/images/egg_shaddow.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RecoverView = ({ onSubmit, validate, recovered, onCancel }) => {
    return (
        <div className="login-pages">
            <div className="container">
                <div className="onboarding-card responsive">
                    <div className="onboarding-img"></div>
                    <div className="onboarding-form">
                        <img src={EggLogo} style={{ width: '70px', margin: '0 auto' }} alt="" />
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            validate={validate}
                            onSubmit={onSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div>
                                        <p className="global-text">
                                            Ingresa tu dirección de e-mail para recuperar tu
                                            contraseña.
                                        </p>
                                        <Field
                                            type="email"
                                            name="email"
                                            placeholder="E-mail"
                                            className="form-input"
                                            autoComplete="username"
                                        />
                                        <ErrorMessage
                                            name="email"
                                            render={(msg) => (
                                                <div className="input-error-msg">{msg}</div>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <button
                                            className="btn-yellow"
                                            type="submit"
                                            disabled={isSubmitting || recovered}
                                        >
                                            {isSubmitting ? (
                                                <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                            ) : recovered ? (
                                                'Comprueba tu e-mail'
                                            ) : (
                                                'Recuperar contraseña'
                                            )}
                                        </button>
                                        <div className="alt-links">
                                            <button onClick={onCancel} className="forgot-psw-link">
                                                Iniciar sesión
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecoverView
