import Button, { IButtonProps } from '../../atoms/Button/Button.component'
import styled, { css } from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import baseTheme from '../../../themes/baseTheme'
import { flex } from '../../../utils/mixins'

export const AccordionHeaderActions = styled.div`
    ${flex('center', 'flex-end')};
`

export const AccordionHeaderCollapseButton = styled(Button)<IAccordionHeaderCollapseButtonProps>(
    ({ isCollapsed }) => {
        const collapsed = css`
            background: ${baseTheme.colors['ffffff']};
            color: ${baseTheme.colors['37474f']};
        `
        const expanded = css`
            background: ${baseTheme.colors['2b75ff']};
            color: ${baseTheme.colors['ffffff']};
        `

        return css`
            border: 1px solid ${baseTheme.colors['eaeaea']};
            border-radius: 50%;
            font-family: ${baseTheme.typography.fonts['font-awesome-6-pro']};
            font-size: 16px;
            height: 32px;
            letter-spacing: 0px;
            line-height: 16px;
            text-align: center;
            width: 32px;
            ${isCollapsed ? collapsed : expanded}
        `
    }
)

export const AccordionHeaderContainer = styled.div<IAccordionHeaderCollapseButtonProps>(
    ({ isCollapsed }) => {
        return css`
            ${flex('center', 'space-between')};
            background: ${baseTheme.colors['ffffff']};
            border-radius: ${isCollapsed ? '10px' : '10px 10px 0 0'};
            color: ${baseTheme.colors['003750']};
            padding: 25px 40px;
        `
    }
)

export const AccordionHeaderContentContainer = styled.div`
    ${flex('center', 'space-between')};
`

export const AccordionHeaderContent = styled.div`
    ${flex('center', 'space-between')};
`

export const AccordionHeaderContentIcon = styled(FontAwesomeIcon)`
    font-size: 12px;
    margin-right: 6px;
`

export const AccordionHeaderIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    margin-right: 18px;
`

export const AccordionHeaderContentTitle = styled.h3`
    color: ${baseTheme.colors['003750']};
    font-family: ${baseTheme.typography.fonts['font-awesome-6-pro']};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 49px;
    margin: 0 20px 0 0;
    max-width: 600px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
`

export const AccordionHeaderSubtitle = styled.h3`
    color: ${baseTheme.colors['37474f']};
    font-family: ${baseTheme.typography.fonts.inter};
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 40px;
    margin: 0 20px 0 0;
    text-align: left;
    text-transform: capitalize;
`

export const AccordionHeaderTitle = styled.h2`
    color: ${baseTheme.colors['37474f']};
    font-family: ${baseTheme.typography.fonts.inter};
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 49px;
    margin: 0 20px 0 0;
    text-align: left;
    text-transform: capitalize;
`

interface IAccordionHeaderCollapseButtonProps extends IButtonProps {
    isCollapsed: boolean
}
