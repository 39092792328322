import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useNetworkInfo from '../../hooks/useNetworkInfo'

const LoadingPage = () => {
    const [online] = useNetworkInfo()

    if (!online)
        return (
            <div className="loading-page-container">
                <h2>No estás conectado</h2>
            </div>
        )

    return (
        <div className="loading-page-container">
            <FontAwesomeIcon icon={['fa', 'spinner']} spin size="2x" />
        </div>
    )
}

export default LoadingPage
