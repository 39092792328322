import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { colors } from '../styles/globals/_variables'
import EggCard from './Styled/EggCard'
import { useTranslation } from 'react-i18next'

// Context
import UserContext from '../context/UserContext'
import { useUIContext } from '../context/UIContext'

// Interface

// Hooks
import { NotificationDTO, useCustomProps, useNotifications } from '../hooks/useNotifications'

const Notifications = () => {
    const { t } = useTranslation(['notifications'])
    const { user } = useContext(UserContext)
    const { notifications, rotationInfo, infoBlock } = useUIContext()
    const { closeAllNotificationPopups } = useNotifications()

    if (!user) return null // For close popup's when user logout.

    const toShow = notifications
        .filter(
            ({ isClosed, status, type }) => !isClosed && status === 'unread' && type === 'alert'
        )
        .slice(0, 4)

    return (
        <>
            <NotificationContainer
                style={rotationInfo?.inCourse || infoBlock?.length > 0 ? { bottom: 100 } : {}}
            >
                {!!toShow.length && (
                    <button onClick={() => closeAllNotificationPopups()} className="hideAll">
                        {t('hideAll')}
                    </button>
                )}
                {toShow.map((item, index) => (
                    <NotificationCard data={item} key={`${item._id}-${index}`} />
                ))}
            </NotificationContainer>
        </>
    )
}

export const NotificationCard = ({ data }: { data: NotificationDTO }) => {
    const [isHide, setIsHide] = useState(false)
    const { closeNotificationPopup } = useNotifications()
    const { notification, onClick } = useCustomProps(data)
    const { title, message } = notification

    const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()
        closeNotificationPopup([notification])
        setIsHide(true)
    }

    if (isHide) return null
    return (
        <NotificationItem glass={false}>
            <div
                onClick={onClick}
                style={{ cursor: onClick && 'pointer', display: 'flex', justifyContent: 'center' }}
            >
                <span className="type">
                    <FontAwesomeIcon icon={['fas', 'bell']} />
                </span>
                <span className="body">{title || message}</span>
            </div>
            <Close onClick={(event) => handleClose(event)} className="close">
                <FontAwesomeIcon className="icon" icon={['fas', 'times-circle']} />
            </Close>
        </NotificationItem>
    )
}

export default Notifications

const NotificationContainer = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    opacity: 0.8;
    z-index: 1;

    .hideAll {
        width: max-content;
        margin-left: auto;
        background: 0;
        border: 0;
        cursor: pointer;
        background-color: #ffffff82;
        color: rgb(55, 71, 79);
        border-radius: 4px;
    }
`

const NotificationItem = styled(EggCard)`
    position: relative;
    border-radius: 10px;
    border: 1px solid ${colors.borders};
    min-height: 80px;
    padding: 20px 30px 20px 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 5px 0;
    min-width: 30ch;
    .type {
        padding-right: 15px;
        font-size: 2rem;
        color: ${colors.darkGreyEgg};
    }
    .body {
        font-weight: bold;
        margin: auto;
    }
    .my-node {
        background: red;
    }
    .my-node-enter {
        opacity: 0;
    }
    .my-node-enter-active {
        opacity: 1;
        transition: opacity 200ms;
    }
    .my-node-exit {
        opacity: 1;
    }
    .my-node-exit-active {
        opacity: 0;
        transition: opacity 200ms;
    }
`

const Close = styled.button`
    background: none;
    border: none;
    width: 20px;
    min-width: unset;
    height: 20px;
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 5px;
    margin: 0;
    padding: 0;
    .icon {
        font-size: 1.5rem;
        color: ${colors.eggColor};
    }
`
