import styled, { css } from 'styled-components'
import baseTheme from '../../../themes/baseTheme'
import { IButtonProps } from './Button.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { flex } from '../../../utils/mixins'

export const ButtonContainer = styled.button<IButtonProps>(
    ({ backgroundColor, borderColor, color }) => {
        return css`
            ${flex('center', 'center')};
            background: ${backgroundColor ? backgroundColor : baseTheme.colors['2b75ff']};
            border-radius: 8px;
            border: 1px solid ${borderColor ? borderColor : baseTheme.colors['2b75ff']};
            color: ${color ? color : baseTheme.colors['ffffff']};
            cursor: pointer;
            font-family: ${baseTheme.typography.fonts.inter};
            font-size: 13px;
            font-weight: bold;
            line-height: 16px;
            padding: 8px 15px;
            text-decoration: none;
            user-select: none;

            :disabled {
                background: ${baseTheme.colors['f2f2f2']};
                border: 1px solid ${baseTheme.colors['eaeaea']};
                color: ${baseTheme.colors['37474f']};
            }
            :hover {
                background: ${baseTheme.colors['f2f2f2']};
                border: 1px solid ${baseTheme.colors['eaeaea']};
            }
        `
    }
)

export const ButtonIcon = styled(FontAwesomeIcon)`
    font-family: ${baseTheme.typography.fonts['font-awesome-6-pro']};
    font-size: 15px;
    line-height: 30px;
`

export const ButtonImage = styled.img`
    ${flex('center', 'center')}
    height: inherit;
    transform: scale(0.45);
    width: inherit;
`
