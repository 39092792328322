import React, { ReactNode } from 'react'
import {
    TabMenuContainer,
    TabMenuContent,
    TabMenuItem,
    TabMenuItemContainer,
} from './TabMenu.styles'

interface ITabMenuItem {
    children: ReactNode
    className: string
    onClick: () => void
    title: string
}

interface ITabMenuProps {
    actions: ITabMenuItem[]
}

export interface ActionTitles {
    [key: string]: {
        disabled: boolean
        shortcut: string
        title: string
        component: React.ReactNode
    }
}

const TabMenu = ({ actions }: ITabMenuProps) => {
    const renderMenuItem = (action: ITabMenuItem) => (
        <TabMenuItemContainer key={action.title}>
            <TabMenuItem className={action.className} onClick={action.onClick} title={action.title}>
                {action.children}
            </TabMenuItem>
        </TabMenuItemContainer>
    )

    return (
        <TabMenuContainer className="tab-menu">
            <TabMenuContent>{actions.map((action) => renderMenuItem(action))}</TabMenuContent>
        </TabMenuContainer>
    )
}

export default TabMenu
