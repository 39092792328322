/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'

// Assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from './Link'
import { Trans } from 'react-i18next'

const LinkBlockMsg = (props) => {
    const { id, icon, text, title, linkName, linkRef } = props

    return (
        <div className="link-block-msg" id={id}>
            <div className="content">
                <div className="icon-container">
                    <FontAwesomeIcon icon={['fal', `${icon}`]} className="icon" />
                </div>
                <div className="texts">
                    <span className="title">{title}</span>
                    <p className="global-text">
                        <Trans i18nKey={text} />
                    </p>
                </div>
            </div>
            {linkName && (
                <div className="link">
                    <Link to={linkRef}>{linkName}</Link>
                    <FontAwesomeIcon icon={['fa', `arrow-right`]} className="icon" />
                </div>
            )}
        </div>
    )
}

export default LinkBlockMsg

LinkBlockMsg.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}
