import { useEffect, useReducer } from 'react'

export type NetworkType = 'slow-2g' | '2g' | '3g' | '4g'

export const connection =
    // This is needed for compatibility issues.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigator.connection || navigator.mozConnection || navigator.webkitConnection

export type NetworkInfo = [
    connected: boolean,
    effectiveType: NetworkType | undefined,
    downloadLink: number | undefined
]

const reducer = () =>
    [navigator.onLine, connection?.effectiveType, connection?.downlink] as NetworkInfo

const useNetworkInfo = (): NetworkInfo => {
    const [status, refresh] = useReducer(reducer, reducer())

    useEffect(() => {
        connection?.addEventListener('change', refresh)
        window.addEventListener('online', refresh)
        window.addEventListener('offline', refresh)
        return () => {
            connection?.addEventListener('change', refresh)
            window.removeEventListener('online', refresh)
            window.removeEventListener('offline', refresh)
        }
    }, [])

    return status
}

export default useNetworkInfo
