import React, { useContext } from 'react'
import { FillInfoContext } from './FillInfoContext'
import UserInfo from './UserInfo'
import AvatarUpload from './AvatarUpload'
import MainLayout from '../../../layouts/MainLayout'
import { Redirect } from 'wouter'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {skipFillInfo} boolean
 * @returns void
 */
const SignupFillInfoContainer = () => {
    const { t } = useTranslation(['completeProfile'])
    const { step } = useContext(FillInfoContext)

    const getView = () => {
        switch (step) {
            case 1:
                return <UserInfo />
            case 2:
                return <AvatarUpload />
            default:
                return <Redirect to={'/'} />
        }
    }

    return <MainLayout ruta={t('completeProfile:route')}>{getView()}</MainLayout>
}

export default SignupFillInfoContainer
