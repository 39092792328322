// utils/GoogleAnalytics.js
import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'wouter'

const isGAEnabled = process.env.NODE_ENV === 'production' || process.env.GA_ENABLED

if (isGAEnabled) {
    ReactGA.initialize('UA-141662578-1')
}

export const RouteTracker = () => {
    const [location] = useLocation()

    useEffect(() => {
        if (!isGAEnabled) return
        ReactGA.set({
            page: location,
            location: `${window.location.origin}${location}`,
        })
        ReactGA.pageview(location)
    }, [location])

    return null
}

// Map of the custom events with the structure `key: [category, action, label]`
const eventMap = {
    login: ['engagement', 'login', 'User Login'],
    vote: ['engagement', 'vote', 'user performe a vote'],
    present: ['engagement', 'attendance', 'user is present'],
    tableVote: ['engagement', 'vote-table', 'user vote an entire table'],
    algorithmLaunched: ['engagement', 'reorder', 'user reorder a workgroup'],
    meeting: ['engagement', 'join-meeting', 'user joins a meeting'],
}
type Events = keyof typeof eventMap
type EventArgs = Omit<ReactGA.EventArgs, 'category' | 'action'>
function event(event: Events, options?: EventArgs): void
function event(category: string, action: string, options?: EventArgs): void
function event(...args: unknown[]) {
    if (!isGAEnabled) return

    // Assume that the first argument is harcoded event
    const event = args[0] as keyof typeof eventMap

    if (eventMap?.[event]) {
        // It is a hardcoded event
        const options = args[1] as EventArgs
        const [category, action, label] = eventMap[event]
        ReactGA.event({ category, action, label, ...options })
    } else {
        //It isn't a hardcoded event
        const [category, action, options] = args as [string, string, EventArgs]
        ReactGA.event({ category, action, ...options })
    }
}

export const GA = {
    RouteTracker,
    event,
}

export default GA
