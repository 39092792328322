import React, { useEffect } from 'react'
import { ChartWrapperOptions } from 'react-google-charts'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useLocation from 'wouter/use-location'
import HeaderBanner from '../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'
import WarningBlock from '../../components/WarningBlock'
import { useUser } from '../../context/UserContext'
import { capitalize } from '../../helpers/formatter'
import { useGAEvents } from '../../hooks/useGAEvents'
import FullLayout from '../../layouts/FullLayout'
import LoadingPage from '../Other/LoadingPage'
import { useTeam } from './Context/TeamContext'
import { useCohorts } from './Hooks/useCohorts'
import { ButtonExport } from './Reports/Components/ButtonExport'
import { NoData } from './Reports/Components/ChartComponent'
import { CohortsSelect } from './Reports/Components/CohortsSelect'
import { Menu } from './Reports/Components/Menu'
import { toUtcViewDate } from '../../helpers/formatter/dateFormatters'

export const Reports = () => {
    const { t } = useTranslation('teamReports')
    const [, setLocation] = useLocation()
    const { role } = useUser()
    const { team, courseID, mainLinks } = useTeam()
    const { sendGA } = useGAEvents()
    const { cohorts, lastMigrationDate, isLoading } = useCohorts()

    const formattedDate = toUtcViewDate(lastMigrationDate, 'HH:hh')

    useEffect(() => {
        sendGA({ category: 'Reporting', action: 'ViewReportSection' })
    }, [team, role, sendGA])

    useEffect(() => {
        sessionStorage.setItem('last-courseId', courseID || '')
    }, [courseID])

    if (!team) return null

    const {
        hq: { name: hqName },
        teamName,
    } = team

    if (isLoading) return <LoadingPage />

    return (
        <FullLayout links={mainLinks} ruta={`${capitalize(hqName)} > ${capitalize(teamName)}`}>
            <WarningBlock />
            <ReportStyled className="container">
                <HeaderBanner
                    title={team?.teamName}
                    subtitle={t('reports')}
                    subActions={
                        <ButtonExport
                            onClick={() => setLocation(`./${courseID}/pdf`, { replace: false })}
                            label={t('Open PDF version')}
                            disabled={!cohorts}
                            type="outlined"
                        />
                    }
                />
                <div className="span-stats">
                    {t('Last updated')} {formattedDate} hs
                </div>
                {!cohorts ? (
                    <NoData text={`You don't have cohorts yet`} />
                ) : (
                    <>
                        <CohortsSelect />
                        <Menu />
                    </>
                )}
            </ReportStyled>
        </FullLayout>
    )
}

export default Reports

export const ReportStyled = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 0;
    h2 {
        margin: 0;
    }
    & .loading-page-container {
        height: 6em;
    }
    & .cohort-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

export const colors = {
    green: '#15C0AF',
    grey: '#383A40',
    lightGrey: '#D8DADB',
    lightBlue: '#00A3FF',
    violet: '#BF03E2',
    red: '#FF647C',
    yellow: '#ffcd00',
    blue: '#1A4B62',
    eggBlue: '#003750',
}

export type PropsReports = {
    barChartOptions?: {
        width?: string
        height?: string
        options?: ChartWrapperOptions['options']
    }
    pieChartOptions?: {
        width?: string
        height?: string
        options?: ChartWrapperOptions['options']
    }
}
