import { Link, useLocation } from 'wouter'
import { useAuth0 } from '@auth0/auth0-react'
import { getIdTokenFromAuth0 } from '../../../apis/api.signup'
import { useQuery } from 'react-query'
import { FullPageMessage } from '../../../components/FullPageMessage'
import { useTranslation } from 'react-i18next'

const Auth0Callback = () => {
    const { t } = useTranslation('login')
    const [, setLocation] = useLocation()
    const { getAccessTokenSilently, user } = useAuth0()

    const { status } = useQuery(
        ['Auth0', 'Get credential', user?.sub],
        async () => {
            const email = user?.email ?? extractEmailFromSub(user?.sub)
            if (!email) throw new Error('Authentication error: No email found')
            const accessToken = await getAccessTokenSilently()
            return await getIdTokenFromAuth0({ accessToken, email })
        },
        {
            onSuccess: (idToken) => setLocation(`/login/superadmin/?token=${idToken}`),
            enabled: !!user,
            staleTime: 0,
            retry: 2,
        }
    )

    const message =
        status === 'error' ? (
            <div style={{ textAlign: 'center' }}>
                <h4>{t('Error logging in, please try again.')}</h4>
                <br />
                <Link href="/login">{t('Try again')}</Link>
            </div>
        ) : (
            t('Please wait while you enter the platform...')
        )

    return <FullPageMessage message={message} />
}

export default Auth0Callback

// Extract email from token sub. Ej: "sub": "sub": "oidc|Provider-integration|user@mail.com"
export const extractEmailFromSub = (token?: string) => {
    const email = token?.split('|').pop()
    // Check if email is valid
    if (!email || !email.includes('@')) return null
    return email
}
