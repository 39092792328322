// API
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'wouter'
// Context
import { UserContext } from '../../context/UserContext'
import { UIContext } from '../../context/UIContext'
// Components
import LoginView from './LoginView'
import { useForm } from 'react-hook-form'
import useSearchParams from '../../hooks/useSearchParams'
import PasswordManageContainer from '../Password/PasswordManageContainer'
import { useTranslation } from 'react-i18next'
// Assets

export interface LoginFormField {
    email: string
    password: string
}

const LoginContainer = () => {
    const { login } = useContext(UserContext)
    const [, setLocation] = useLocation()

    const { toastNotification } = useContext(UIContext)

    const { t } = useTranslation('login')

    const [nextLogin] = useSearchParams('onLogin')
    const [nextSignup] = useSearchParams('onSignup')
    const [needRecover, setNeedRecover] = useState(false)

    //If the user is known, you could pre-fill this field by a queryParam
    const [searchUser] = useSearchParams('user')

    const form = useForm<LoginFormField>({
        // This is a workaround to not wait for the component to mount
        // https://github.com/react-hook-form/react-hook-form/issues/2578
        shouldUnregister: false,
    })
    const { setValue } = form

    useEffect(() => {
        searchUser && setValue('email', searchUser, { shouldDirty: true, shouldValidate: true })
    }, [searchUser, setValue])

    const onSubmit = async (values: LoginFormField) => {
        form.clearErrors()
        try {
            await login(values.email, values.password, true)
            setLocation(nextLogin ?? '/')
        } catch (error) {
            //@ts-expect-error remove warning
            const code = error?.code

            switch (code) {
                case 'auth/internal-error':
                case 'auth/user-not-found': {
                    form.setError('email', { message: t('User does not exist') })
                    break
                }
                case 'auth/wrong-password': {
                    form.setError('password', {
                        message: 'La contraseña para este usuario es inválida.',
                    })
                    break
                }
                default:
                    toastNotification(
                        //@ts-expect-error remove warning
                        error.message ||
                            //@ts-expect-error remove warning
                            error.data?.message ||
                            'Ups! Ocurrió un error. Intenta nuevamente.',
                        'error'
                    )
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }

    if (needRecover) return <PasswordManageContainer onCancel={() => setNeedRecover(false)} />

    return (
        <LoginView
            form={form}
            onSubmit={onSubmit}
            onSignup={() => setLocation(nextSignup ?? '/signup/professor')}
            onRecover={() => setNeedRecover(true)}
        />
    )
}

export default LoginContainer
