// API
import React, { useState, useContext } from 'react'
// Helpers and libs
// Context
import { UIContext } from '../../context/UIContext'
// Components
import VerifyView from './VerifyView'
import Axios from 'axios'
import Config from '../../config'
import { UserContext } from '../../context/UserContext'
import { useTranslation } from 'react-i18next'

const EmailVerifyContainer = () => {
    const { t } = useTranslation(['verify', 'errors'])

    const { verify, logout } = useContext(UserContext)

    // Sets infoBlock error / success
    const { setInfoBlock } = useContext(UIContext)
    const [canResend, setCanResend] = useState(true)

    const delayBetweenSendings = 3

    const onSignout = () => {
        logout?.('/login')
    }

    const onResend = async () => {
        try {
            if (canResend) {
                const response = await Axios.post(`${Config.API}/user/sendVerificationEmail`)
                setCanResend(false)
                setTimeout(() => setCanResend(true), delayBetweenSendings * 60 * 1000)
                setInfoBlock(['success', t('verify:email-sended')])
                setTimeout(() => setInfoBlock([]), 3000)
                return response
            } else {
                setInfoBlock(['success', t('verify:email-sended-retry')])
                setTimeout(() => setInfoBlock([]), 3000)
            }
        } catch (error) {
            setInfoBlock([
                'error',
                error.message || error.data?.message || t('errors:genericRetry'),
            ])
            setTimeout(() => setInfoBlock([]), 3000)
        }
    }

    const onValidate = async (code: string) => {
        try {
            const response = await verify(code)
            //setLocation('/')
            setInfoBlock(['success', t('verify:email-verified')])
            return response
        } catch (error) {
            setInfoBlock([
                'error',
                error.message || (error.data && error.data.message) || t('errors:genericRetry'),
            ])
        } finally {
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }

    return <VerifyView onValidate={onValidate} onSignout={onSignout} onResend={onResend} />
}

export default EmailVerifyContainer
