// I will allow `any` on this file since almost all errors has that interface.
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import MainLayout from './layouts/MainLayout'
import Img from './assets/images/img_teams_create.png'
import { withTranslation, WithTranslation } from 'react-i18next'

export const defaultState = {
    hasError: false,
    title: 'Error!',
    message: 'Ha ocurrido un error',
    route: 'Ups...',
    button: {
        action: window.location.reload,
        label: 'Refrescar',
    },
}

export type ErrorType = Omit<typeof defaultState, 'hasError'>

class ErrorBoundary extends React.Component<
    Partial<typeof defaultState> & WithTranslation,
    typeof defaultState & WithTranslation
> {
    constructor(props: any) {
        super(props)
        this.state = { ...this.state, ...defaultState }
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        if (typeof error === 'object') {
            return {
                ...defaultState,
                ...error,
                hasError: true,
            }
        } else {
            return {
                ...defaultState,
                hasError: true,
            }
        }
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        // eslint-disable-next-line no-console
        console.error(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <MainLayout ruta={this.state.route}>
                    <div className="teams-create-container">
                        <div className="content">
                            <img src={Img} alt="" />
                            <h3>{this.state.title || this.props.t}</h3>
                            <p className="global-text">{this.props.t('error-page')}</p>
                            <button className="btn-yellow" onClick={() => window.location.reload()}>
                                {this.props.t('error-page-try-again')}
                            </button>
                        </div>
                    </div>
                </MainLayout>
            )
        }
        return this.props.children
    }
}

export default withTranslation('errors')(ErrorBoundary)
