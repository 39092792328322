const colors = {
    '000000': '#000000',
    '003750': '#003750',
    '00a3ff': '#00A3FF',
    '073045': '#073045',
    '0c374e': '#0C374E',
    '105df9': '#105DF9',
    '15c1b0': '#15C1B0',
    '1f394c': '#1F394C',
    '2b75ff': '#2B75FF',
    '37474f': '#37474F',
    707070: '#707070',
    a3a3a3: '#A3A3A3',
    bf03e2: '#BF03E2',
    c7c7c7: '#C7C7C7',
    dadada: '#DADADA',
    dddddd: '#DDDDDD',
    eaeaea: '#EAEAEA',
    ececec: '#ECECEC',
    f2f2f2: '#F2F2F2',
    f8f8f8: '#F8F8F8',
    ff647c: '#FF647C',
    ff8900: '#FF8900',
    ffba00: '#FFBA00',
    ffcd00: '#FFCD00',
    ffffff: '#FFFFFF',
}

const typography = {
    fonts: {
        inter: 'Inter',
        'font-awesome-6-pro': 'Font Awesome 6 Pro',
    },
}

const baseTheme = {
    colors,
    typography,
}

export default baseTheme
