// API
import React from 'react'
// Components
import NoHeaderLayout from '../../layouts/NoHeaderLayout'
import Link from '../../components/Link'
// Assets

const EmailVerifyView = () => {
    return (
        <NoHeaderLayout>
            <div className="login-pages">
                <div className="container">
                    <div className="onboarding-card responsive">
                        <div className="onboarding-img"></div>
                        <div className="onboarding-form">
                            <div className="info">
                                <span className="step-title">¡Bienvenido a Egg!</span>
                                <p className="global-text">
                                    Quedaste inscripto en el equipo. Inicia sesión para poder ver tu
                                    equipo.
                                </p>
                                <Link to="/" className="forgot-psw-link">
                                    Iniciar sesión
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NoHeaderLayout>
    )
}

export default EmailVerifyView
