/* eslint-disable react/prop-types */
import React from 'react'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

Modal.setAppElement('#root')

const ModalEgg = (props) => {
    const { modalShow, closeModal, modalId } = props

    return (
        <Modal
            isOpen={modalShow}
            // style={customStyles}
            className={'modal ' + modalId}
            overlayClassName="overlayModal"
        >
            <FontAwesomeIcon
                className="close-modal-btn"
                onClick={() => closeModal()}
                icon={['fas', 'times-circle']}
            />
            {props.children}
        </Modal>
    )
}

export default ModalEgg
