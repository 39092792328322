import { useQuery } from 'react-query'
import { getPulsesGraph, getPulsesRanking } from '../api-reports'
import { useReports } from '../Context/ReportsContext'

export const usePulses = () => {
    const { reportQuery, enabled, staleTime } = useReports()
    const { courseId, cohorts, ...restQuery } = reportQuery
    const queryKeyPulses = [
        courseId,
        'Reports',
        'Pulses',
        'Graph',
        `Cohorts: ${cohorts?.join()}`,
        restQuery,
    ]
    const { data: pulses, isLoading: isPulsesLoading } = useQuery(
        queryKeyPulses,
        () => getPulsesGraph(reportQuery),
        { enabled, staleTime }
    )

    const queryKeyRanking = [
        courseId,
        'Reports',
        'Ranking',
        `Cohorts: ${cohorts?.join()}`,
        restQuery,
    ]
    const { data: ranking, isLoading: isRankingLoading } = useQuery(
        queryKeyRanking,
        () => getPulsesRanking(reportQuery),
        { enabled, staleTime }
    )

    const columnsWidth = ranking?.reduce(
        (accu, item) => {
            const positionLength = `${item.position}`.length
            if (positionLength > accu.positionLength) accu.positionLength = positionLength
            const pulsesLength = `${item.pulsesIn}`.length
            if (pulsesLength > accu.pulsesLength) accu.pulsesLength = pulsesLength
            return accu
        },
        { positionLength: 0, pulsesLength: 0 }
    )

    return { pulses, isPulsesLoading, ranking, isRankingLoading, columnsWidth }
}
