import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import AvatarEgg from '../../../components/AvatarEgg'
import ConfirmModal from '../../../components/ConfirmModal'
import { useUIContext } from '../../../context/UIContext'
import {
    changeHelpRequestStatus,
    GetMentorsResponse,
    getProfileDetails,
    updateMentors,
} from '../../../apis/api.signup'
import { useTeam } from '../Context/TeamContext'
import Styles from './ModalMentors.module.css'
import { useArrange } from './StudentItem/Actions/Rearrange'
import NoMentorsImg from '../../../assets/images/icons/no-mentors.svg'

export const ModalMentors = () => {
    const { toastError } = useUIContext()
    const { courseID } = useTeam()
    const { rearrange } = useArrange()
    const { t } = useTranslation('teamManager')
    const { mentorsSearch } = useTeam()
    const [search, setSearch] = mentorsSearch
    const [mentorSelected, setMentorSelected] = useState<null | Mentor>(null)

    const { data, status } = useQuery(
        [courseID, 'Team', 'Team Mentors', search?.team],
        () => search && updateMentors(search),
        {
            enabled: !!search,
            onError: (error) => toastError(error),
        }
    )

    const noMentors = !data?.mentors.length && !data?.integrations.length
    const twoColumns = !!data?.mentors.length && !!data?.integrations.length

    const mentorAssign = () => {
        if (!courseID || !search || !mentorSelected) return
        getProfileDetails(courseID, mentorSelected._profileId)
            .then((student) => {
                rearrange(student, mentorSelected.tableNumber, +search.team)
            })
            .finally(() => {
                setSearch(null)
                setMentorSelected(null)
            })
            .catch((error) => toastError(error))
    }
    const teamNumber = `${t('search.badge-team')} ${data?.teamNumber}`
    const teamGuide = data?.guide ? ` - ${t('search.guide')} ${data.guide}` : ''
    const displayName = mentorSelected?.displayName

    if (mentorSelected)
        return (
            <ConfirmModal
                open={!!mentorSelected}
                title={t('modal-mentor.assign.title', { displayName })}
                contentText={t('modal-mentor.assign.contentText', { displayName, teamNumber })}
                confirmButtonText={t('modal-mentor.assign.assign')}
                cancelButtonText={t('modal-mentor.assign.cancel')}
                onClose={() => setMentorSelected(null)}
                onConfirm={mentorAssign}
            />
        )
    else
        return (
            <Modal isOpen={!!search} style={customStyles} overlayClassName={Styles.overlay}>
                <section className={Styles.container}>
                    <header className={Styles.header}>
                        <div className={Styles.headerIcon}>
                            <FontAwesomeIcon icon={['fas', 'graduation-cap']} />
                        </div>
                        <div className={Styles.teamTitle}>
                            <h3>{t('search.mentors')}</h3>
                            <h3 className={Styles.guide}>
                                {teamNumber} {teamGuide}
                            </h3>
                        </div>
                        <button className={Styles.btnCloseModal} onClick={() => setSearch(null)}>
                            <FontAwesomeIcon icon={['fas', 'xmark']} />
                        </button>
                    </header>
                    <main
                        className={Styles.content}
                        style={{
                            gridTemplateColumns: twoColumns ? '3fr 1fr' : '1fr',
                        }}
                    >
                        {status === 'loading' && (
                            <div className={Styles.noMentors}>{t('Loading data...')}</div>
                        )}
                        {status === 'success' &&
                            (noMentors ? (
                                <NoMentorsAvailable {...data} />
                            ) : (
                                <>
                                    <div className={Styles.mentorList}>
                                        {data?.mentors.map((item) => (
                                            <MentorItem
                                                mentor={item}
                                                key={item._profileId}
                                                title={`${t('tableItem.table')} ${
                                                    item.tableNumber
                                                }`}
                                                onClick={() => setMentorSelected(item)}
                                            />
                                        ))}
                                    </div>

                                    <div className={Styles.integrationList}>
                                        {!!data.integrations.length &&
                                            data.integrations.map((item) => (
                                                <MentorItem
                                                    mentor={item}
                                                    key={item._profileId}
                                                    title={`${t('mentor.mentor')} - ${t(
                                                        'tableItem.table'
                                                    )} ${item.tableNumber}`}
                                                    onClick={() => setMentorSelected(item)}
                                                />
                                            ))}
                                    </div>
                                </>
                            ))}
                    </main>
                </section>
            </Modal>
        )
}

const NoMentorsAvailable = ({ _helpRequestId, action }: NoMentorsModalProps) => {
    const { t } = useTranslation('teamManager')
    const { toastError } = useUIContext()
    const queryClient = useQueryClient()
    const {
        courseID,
        mentorsSearch: [search, setSearch],
    } = useTeam()

    const key = [courseID, 'Team', 'Team Mentors', search?.team]

    const { mutate: sendProfesor } = useMutation(
        key,
        () => changeHelpRequestStatus(_helpRequestId ?? ''),
        {
            onMutate: () => {
                const snapshot = queryClient.getQueryData<GetMentorsResponse['data']>(key)
                queryClient.setQueryData<typeof snapshot>(key, (prev) =>
                    prev ? { ...prev, _helpRequestId: undefined } : prev
                )
                setSearch(null)
            },
            onError: toastError,
        }
    )

    return (
        <div className={Styles.noMentors}>
            <div style={{ textAlign: 'center' }}>
                <img src={NoMentorsImg} />
                <p style={{ fontSize: '20px', width: '50ch', margin: '3em 0' }}>
                    {t('modal-mentor.no-available.text')}
                </p>
                <button
                    onClick={() => sendProfesor()}
                    disabled={action !== 'close'}
                    className="btn-yellow btn btn-primary-color"
                    style={{ padding: '5px 25px' }}
                >
                    {t('modal-mentor.no-available.send-proffesor')}
                </button>
            </div>
        </div>
    )
}

const MentorItem = ({
    mentor,
    title,
    onClick,
}: {
    mentor: Mentor
    title?: string
    onClick?: () => void
}) => {
    const { displayName, urlImage } = mentor

    return (
        <div className={Styles.mentorItem} onClick={onClick}>
            <AvatarEgg src={urlImage} />
            <div className={Styles.mentorName}>
                <div>{displayName}</div>
                {title && <div className={Styles.mentorTitle}>{title}</div>}
            </div>
        </div>
    )
}

// eslint-disable-next-line no-undef
type Mentor = Awaited<ReturnType<typeof updateMentors>>['mentors'][0]

type NoMentorsModalProps = Pick<GetMentorsResponse['data'], '_helpRequestId' | 'action'>

const customStyles = {
    content: {
        overflow: 'hidden',
        padding: '10px',
        maxWidth: '1200px',
        margin: 'auto',
    },
    overlay: {
        zIndex: 1,
    },
}
