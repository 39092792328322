/* eslint-disable no-irregular-whitespace */
import { version } from '../../package.json'

const versionStyle = `
    color: unset;
    text-shadow:
        3px 3px 0 rgb(217,31,38),
        6px 6px 0 rgb(226,91,14),
        9px 9px 0 rgb(245,221,8),
        12px 12px 0 rgb(5,148,68),
        15px 15px 0 rgb(2,135,206),
        18px 18px 0 rgb(4,77,145),
        21px 21px 0 rgb(42,21,113);
`

const eggAdminStyle = `
    color: unset;
`

const printVersion = () => {
    // eslint-disable-next-line no-console
    console.log(
        `
%c           %c╓%c@%c@%c╣%c▒%c╢%c@%c@%c╖
%c        %c,%c@%c▒%c▒%c▒▒▒▒▒▒▒▒▒%c@%c,
%c       %c@%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c@
%c     %c╓%c▒%c▒%c▒▒▒▒▒▒▒▒▒▒▒%c▒%c▒%c▒%c▒▒▒%c╗
%c    %c╔%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒%c▒%c▌%c▒%c▒▒▒▒▒%c@
%c   %c║%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒%c▓%c▒%c▒%c▒▒▒▒▒▒%c@%c  %c  ___              _      _       _      
%c  %c╔%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c█%c▄%c▒%c▒▒▒▒▒▒▒%c@%c %c | __|__ _ __ _   /_\\  __| |_ __ (_)_ _  
%c %c┌%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c▒%c▓%c▌%c▒%c▒▒▒▒▒▒▒%c╕%c%c | _|/ _\` / _\` | / _ \\/ _\` | '  \\| | ' \\ 
%c %c╣%c▒%c▒▒▒▒▒▒▒▒▒▒%c▒%c▒%c▒%c▒%c▀%c▓%c█%c▒%c▒%c▒▒▒▒▒▒▒%c▒%c%c |___\\__, \\__, |/_/ \\_\\__,_|_|_|_|_|_||_|
%c %c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c▒%c▀%c█%c▒%c▒%c▒▒▒▒▒▒%c[%c %c   |___/|___/         
%c║%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c▒%c▌%c▒%c▒▒▒▒▒▒▒%c╢
%c╢%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c█%c▌%c▒%c▒▒▒▒▒▒▒▒
%c╢▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c▒%c▒%c▒%c▒▒▒▒▒▒▒▒▒
%c║▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c▒%c▒%c▒%c▒▒▒▒▒▒▒▒▒%c╣
%c]%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c▒▒▒▒▒▒▒▒▒▒▒▒%c╛
%c %c╢%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c╢%c               %cversion ${version}
%c  %c╣%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c╢
%c   %c╙%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c╝
%c     %c╙%c╣%c▒%c▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒%c▒%c╜
%c        %c"%c╙%c╩%c╣%c▒%c▒▒▒▒▒▒%c╢%cÑ%c╝%c"`,

        'color: rgb(128, 128, 128)',
        'color: rgb(176, 158, 79)',
        'color: rgb(209, 178, 46)',
        'color: rgb(234, 194, 21)',
        'color: rgb(248, 203, 7)',
        'color: rgb(255, 207, 0)',
        'color: rgb(250, 204, 5)',
        'color: rgb(237, 196, 18)',
        'color: rgb(215, 182, 40)',
        'color: rgb(183, 162, 72)',
        'color: rgb(128, 128, 128)',
        'color: rgb(159, 147, 96)',
        'color: rgb(222, 186, 33)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(234, 194, 21)',
        'color: rgb(173, 156, 82)',
        'color: rgb(128, 128, 128)',
        'color: rgb(227, 189, 28)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(240, 198, 15)',
        'color: rgb(128, 128, 128)',
        'color: rgb(186, 164, 69)',
        'color: rgb(255, 207, 1)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(233, 191, 4)',
        'color: rgb(220, 181, 7)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(207, 177, 48)',
        'color: rgb(128, 128, 128)',
        'color: rgb(214, 181, 41)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(247, 201, 2)',
        'color: rgb(113, 103, 28)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(234, 194, 21)',
        'color: rgb(128, 128, 128)',
        'color: rgb(221, 186, 34)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(107, 99, 29)',
        'color: rgb(232, 190, 5)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(240, 197, 15)',
        'color: rgb(128, 128, 128)',
        eggAdminStyle,

        'color: rgb(128, 128, 128)',
        'color: rgb(206, 176, 49)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(71, 73, 37)',
        'color: rgb(187, 157, 14)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(232, 192, 23)',
        'color: rgb(128, 128, 128)',
        eggAdminStyle,

        'color: rgb(128, 128, 128)',
        'color: rgb(162, 149, 93)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(254, 206, 0)',
        'color: rgb(140, 123, 23)',
        'color: rgb(169, 144, 17)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(194, 169, 61)',
        'color: rgb(128, 128, 128)',
        eggAdminStyle,

        'color: rgb(128, 128, 127)',
        'color: rgb(245, 201, 10)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(232, 190, 5)',
        'color: rgb(220, 181, 7)',
        'color: rgb(187, 158, 13)',
        'color: rgb(172, 146, 17)',
        'color: rgb(158, 136, 19)',
        'color: rgb(130, 115, 25)',
        'color: rgb(77, 77, 35)',
        'color: rgb(232, 190, 5)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(128, 128, 128)',
        eggAdminStyle,

        'color: rgb(167, 152, 88)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(232, 190, 5)',
        'color: rgb(162, 139, 19)',
        'color: rgb(102, 95, 30)',
        'color: rgb(248, 202, 1)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(199, 172, 56)',
        'color: rgb(128, 128, 128)',
        eggAdminStyle,

        'color: rgb(218, 184, 37)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(186, 157, 14)',
        'color: rgb(125, 112, 26)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(248, 203, 7)',
        'color: rgb(246, 202, 9)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(89, 86, 33)',
        'color: rgb(174, 148, 16)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(253, 205, 2)',
        'color: rgb(219, 181, 7)',
        'color: rgb(208, 172, 9)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(198, 166, 11)',
        'color: rgb(232, 190, 5)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(254, 206, 1)',
        'color: rgb(180, 160, 75)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(251, 204, 1)',
        'color: rgb(213, 181, 42)',
        'color: rgb(128, 128, 127)',
        'color: rgb(241, 198, 14)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(252, 205, 3)',
        'color: rgb(128, 128, 128)',

        versionStyle,

        'color: rgb(128, 128, 128)',
        'color: rgb(245, 200, 11)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(253, 206, 2)',
        'color: rgb(128, 128, 128)',
        'color: rgb(214, 182, 41)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(231, 192, 24)',
        'color: rgb(128, 128, 128)',
        'color: rgb(209, 178, 46)',
        'color: rgb(252, 205, 3)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(220, 185, 35)',
        'color: rgb(128, 128, 128)',
        'color: rgb(188, 165, 67)',
        'color: rgb(213, 181, 42)',
        'color: rgb(233, 194, 22)',
        'color: rgb(249, 203, 6)',
        'color: rgb(255, 207, 0)',
        'color: rgb(255, 207, 0)',
        'color: rgb(252, 205, 3)',
        'color: rgb(237, 196, 18)',
        'color: rgb(218, 184, 37)',
        'color: rgb(194, 169, 61)'
    )
}

export default printVersion
