import DayJS from '../DayJS'

export const toViewDate = (date: string): string => DayJS(date).format('DD/MM/YYYY')

export const toApiDate = (date: string): string => date.split('/').reverse().join('-')

export const toLocaleUtcViewDate = (date: string): string => DayJS(date).utc().format('DD/MM/YYYY')

export const toUtcViewDate = (date?: string, format?: string): string => {
    const dayjs = DayJS(date)
    const localTimezone = DayJS.tz.guess()
    return dayjs.isValid()
        ? dayjs
              .utc()
              .tz(localTimezone)
              .format(format ?? 'DD/MM/YYYY')
        : ''
}

export const parseHourWidthMinutes = (hour: number, minutes: number) => {
    const localTimezone = DayJS.tz.guess()
    const time = DayJS().utc().hour(hour).minute(minutes).second(0).tz(localTimezone)
    return time.format('H:mm')
}

export const generateTime = (from: number, to: number, fromMinutes: number, toMinutes: number) => ({
    start: parseHourWidthMinutes(from, fromMinutes),
    end: parseHourWidthMinutes(to, toMinutes),
})
