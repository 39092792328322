/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors } from '../../styles/globals/_variables'

const Btn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: ${colors.darkGreyEgg};
    border: 1px solid ${colors.borders};
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: bold;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
    cursor: pointer;
    min-width: 150px;
    .icon {
        margin-right: 8px;
        color: ${colors.darkGreyEgg};
    }
    ${(props) =>
        props.active &&
        css`
            transition: all 0.1s ease-in-out;
            background-color: ${colors.eggColor};
            color: ${colors.darkGreyEgg};
            border: 1px solid ${colors.eggColor};
            .icon {
                color: white;
            }
        `}
    ${(props) =>
        props.whoIvoted &&
        css`
            background-color: ${colors.eggColor} !important;
            color: ${colors.darkGreyEgg} !important;
            border: 1px solid ${colors.eggColor};
            .icon {
                color: white;
            }
            &:disabled {
                .icon {
                    color: white !important;
                }
            }
        `}
    &:hover {
        transition: all 0.1s ease-in-out;
        background-color: ${colors.eggColor};
        color: ${colors.darkGreyEgg};
        border: 1px solid ${colors.eggColor};
        .icon {
            color: white;
        }
    }
    &:disabled {
        pointer-events: none;
        background-color: ${colors.lightGreyBg};
        color: ${colors.mediumGreyBg};
        .icon {
            color: ${colors.mediumGreyBg};
        }
        border: 1px solid ${colors.borders};
        box-shadow: none;
    }
    visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`

const BtnSmall = (props) => {
    return (
        <Btn {...props}>
            {props.icon && <FontAwesomeIcon className="icon" icon={props.icon} />}
            {!props.icon && (
                <FontAwesomeIcon className="icon" icon={[props.whoIvoted ? 'fas' : 'fal', 'egg']} />
            )}
            {props.children}
        </Btn>
    )
}

export default BtnSmall
