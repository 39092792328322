import React from 'react'
import DataRevisionView from './DataRevisionView'

const DataRevisionContainer = () => {
    return (
        <div>
            <DataRevisionView students={students} />
        </div>
    )
}

export default DataRevisionContainer

const students = [
    {
        name: 'juan',
        lastname: 'perez',
        _profileId: '123',
        urlImage: 'http://urlimagen.com',
        isFacilitator: true,
    },
    {
        name: 'marta',
        lastname: 'sanchez',
        _profileId: '12341',
        urlImage: 'http://urlimagen.com',
        isFacilitator: false,
    },
    {
        name: 'marta',
        lastname: 'sanchez',
        _profileId: '14123',
        urlImage: 'http://urlimagen.com',
        isFacilitator: false,
    },
    {
        name: 'marta',
        lastname: 'sanchez',
        _profileId: '12412',
        urlImage: 'http://urlimagen.com',
        isFacilitator: false,
    },
    {
        name: 'marta',
        lastname: 'sanchez',
        _profileId: '123412',
        urlImage: 'http://urlimagen.com',
        isFacilitator: false,
    },
    {
        name: 'marta',
        lastname: 'sanchez',
        _profileId: '12314',
        urlImage: 'http://urlimagen.com',
        isFacilitator: false,
    },
]
