import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../context/UIContext'

export const ToastRotation = () => {
    const { t } = useTranslation('teamSettings')
    const {
        rotationInfo: { inCourse, message, title },
    } = useUIContext()

    if (!inCourse) return null

    return (
        <section>
            <div className="toast-rotation-container">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon
                        icon={['fal', 'arrows-rotate']}
                        style={{ fontSize: 30, color: '#FFFFFF', padding: 20 }}
                    />

                    <label
                        htmlFor="label_toast_rotation"
                        style={{ display: 'inline-grid', color: 'white', marginTop: -3 }}
                    >
                        <span style={{ fontSize: 14, fontWeight: 'bold' }}>
                            {title ?? t('RotateRestore.rotationInCourse')}
                        </span>
                        {message && (
                            <span id="label_toast_rotation" style={{ fontSize: 13, marginTop: 3 }}>
                                {message}
                            </span>
                        )}
                    </label>
                </div>

                <div className="animationContainer">
                    <FontAwesomeIcon
                        icon={['fas', 'circle']}
                        style={{ fontSize: 7, color: '#FFFFFF', margin: 3 }}
                        className="animacionCarga"
                    />
                    <FontAwesomeIcon
                        icon={['fas', 'circle']}
                        style={{
                            fontSize: 7,
                            color: '#FFFFFF',
                            margin: 3,
                            animationDelay: '0.1s',
                        }}
                        className="animacionCarga"
                    />

                    <FontAwesomeIcon
                        icon={['fas', 'circle']}
                        style={{
                            fontSize: 7,
                            color: '#FFFFFF',
                            margin: 3,
                            animationDelay: '0.2s',
                        }}
                        className="animacionCarga"
                    />
                </div>
            </div>
        </section>
    )
}
