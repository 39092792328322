import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../styles/globals/_variables'

interface WindowProps {
    children: React.ReactNode
    width?: string
    height?: string
    onClose?: () => void
}

const Container = styled.div<WindowProps & { maximized: boolean }>`
    position: fixed;
    padding: 4px;
    bottom: var(--inset);
    right: var(--inset);
    margin: auto;
    top: ${(props) => (props.maximized ? 'var(--inset)' : 'unset')};
    left: ${(props) => (props.maximized ? 'var(--inset)' : 'unset')};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.darkGreyEgg};
    z-index: 10000;
    &:hover {
        .topbar {
            display: flex;
        }
    }
    .content {
        width: ${(props) => (props.maximized ? '100%' : props.width)};
        height: ${(props) => (props.maximized ? 'calc(100%)' : props.height)};
        overflow: hidden;
        border-radius: 6px;
    }
    .topbar {
        padding-right: 10px;
        padding-bottom: 1px;
        display: ${(props) => (props.maximized ? 'flex' : 'none')};
        background-color: ${colors.darkGreyEgg};
        //cursor: move;
        width: 100%;
        font-size: 1.4rem;
        height: 26px;
        align-items: center;
        flex-direction: row-reverse;
        .action {
            border: none;
            background-color: transparent;
            color: white;
            cursor: pointer;
        }
    }
`

const Window = (props: WindowProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [maximized, setMaximized] = useState(true)

    //useEffect(() => {
    //    const element = ref.current
    //    if (!element) return
    //    let initial: {x: number, y: number} | undefined = undefined
    //    const mouseDown = (e: MouseEvent) => initial = {x: e.clientX, y: e.clientY}
    //    const mouseUp = () => initial = undefined

    //    const onDrag = (e: MouseEvent) => {
    //        if (!initial) return
    //        e.preventDefault()
    //        element.style.transform = `translate3D(${e.clientX-initial.x}px, ${e.clientY-initial.y}px, 0px)`
    //    }
    //    element.addEventListener('mousedown', mouseDown)
    //    element.addEventListener('mouseup', mouseUp)
    //    element.addEventListener('mousemove', onDrag)

    //})

    return (
        <Container
            ref={ref}
            {...props}
            maximized={maximized}
            style={{ '--inset': '40px' } as React.CSSProperties}
        >
            <div className="topbar">
                <button className="action close" onClick={() => props.onClose?.()}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                </button>
                <button className="action close" onClick={() => setMaximized((s) => !s)}>
                    <FontAwesomeIcon
                        icon={['fal', maximized ? 'window-minimize' : 'window-maximize']}
                    />
                </button>
            </div>
            <div className="content">{props.children}</div>
        </Container>
    )
}

Window.defaultProps = {
    width: '25vw',
    height: '25vh',
}

export default Window
