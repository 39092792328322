import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const AvatarContainer = styled.div`
    align-items: flex-end;
    display: flex;
    margin: 0 20px 0 0;
    padding: 0 5px 0 20px;
    position: relative;
`

export const AvatarImage = styled.img`
    border-radius: 50%;
    height: 62px;
    object-fit: cover;
    width: 62px;
`

export const AvatarDefaultImage = styled(FontAwesomeIcon)`
    border-radius: 50%;
    color: #dadada;
    height: 62px;
    object-fit: cover;
    width: 62px;
`

export const BadgeContainer = styled.div`
    margin-left: -12px;
`
