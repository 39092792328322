import React from 'react'
import styled from 'styled-components'
import AvatarEgg from './AvatarEgg'
import StrongUnit from '../Text/StrongUnit'
import { useTranslation } from 'react-i18next'
import { colors } from '../../styles/globals/_variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'wouter'

interface ReputationBlockProps {
    rank?: {
        profile: {
            _id: string
            totalVotes: number
        }
        user: {
            name: string
            lastname: string
            _id: string
            urlImage: string
        }
    }[]
    ps: string
    s: string
}

const ReputationBlock = ({ rank, ps, s }: ReputationBlockProps) => {
    const { t } = useTranslation(['teamDetailStudent'])

    return (
        <Block>
            <div className="content">
                <h4>{t('reputation.title')}</h4>
                {rank && rank.length >= 1 && (
                    <div className="avatars-block">
                        {rank?.map((item) => {
                            return (
                                <div
                                    title={`${item.user.name} ${item.user.lastname}`}
                                    className="avatar-container"
                                    key={item.user._id}
                                >
                                    <Link
                                        key={item.profile._id}
                                        title={`${item.user.name} ${item.user.lastname}`}
                                        to={`/profile/${item.profile._id}/from/${ps}/subject/${s}`}
                                    >
                                        <AvatarEgg src={item.user.urlImage} size={55} />
                                    </Link>
                                    <div className="value">
                                        <StrongUnit>{item.profile.totalVotes}</StrongUnit>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
                {rank && rank.length < 1 && (
                    <div className="no-info">
                        <FontAwesomeIcon
                            style={{ marginRight: '5px' }}
                            icon={['fal', 'exclamation-circle']}
                        />
                        {t('reputation.not-available')}
                    </div>
                )}
            </div>
        </Block>
    )
}

export default ReputationBlock


const Block = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .content {
        h4 {
            text-transform: uppercase;
            font-size: 1rem;
            letter-spacing: 0.05rem;
        }
        .avatars-block {
            display: flex;
            flex: 1 1 0;
            margin-top: 15px;
            .avatar-container {
                margin: 10px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                @media screen and (min-width: 768px) {
                    margin: 10px 39px;
                    flex-direction: row;
                }
                .value {
                    margin-top: 10px;
                    @media screen and (min-width: 768px) {
                        margin-top: 0px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .no-info {
            margin-top: 10px;
            background-color: ${colors.lightGreyBg};
            border-radius: 30px;
            display: inline-block;
            padding: 10px 25px;
            border: 1px solid ${colors.borders};
        }
    }
`