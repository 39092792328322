import { useState } from 'react'
//import { useLocation } from "wouter"

/** Beware using this as it doesn't keep in sync with the search by calling another function, instead is just a setter */
// eslint-disable-next-line no-undef
const useSearchParam = (param: string): [typeof state, typeof setter] => {
    const [state, setState] = useState<string | null>(
        new URLSearchParams(window.location.search).get(param)
    )

    const setter = (value: string | null) => {
        const state = new URLSearchParams(window.location.search)
        value === null ? state.delete(param) : state.set(param, value)
        window.history.replaceState({}, '', window.location.pathname + '?' + state)
        setState(value)
    }

    return [state, setter]
}

export default useSearchParam
