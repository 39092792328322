import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { useTeam } from '../pages/Team/Context/TeamContext'
import { useGAEvents } from '../hooks/useGAEvents'

export const WarningBlock = () => {
    const { t } = useTranslation(['teamSettings'])
    const { team } = useTeam()
    const [, setLocation] = useLocation()

    const { sendGA } = useGAEvents()

    if (!team || team.settings.ableLaunchAlgorithm.able) return null

    const goAdvanceSettingsWithGA = () => {
        sendGA({ category: 'Team Settings', action: 'AlertCompleteBasicSettings' })
        setLocation(`/admin/settings/${team?.course._id}`)
    }

    return (
        <Info className={'info-block show '}>
            <FontAwesomeIcon
                icon={['fas', 'exclamation-triangle']}
                style={{ marginRight: '10px' }}
            />
            {t('teamSettings:warning-block.description')} <span></span>
            <u onClick={() => goAdvanceSettingsWithGA()} style={{ cursor: 'pointer' }}>
                {t('teamSettings:warning-block.link')}
            </u>
        </Info>
    )
}
export default WarningBlock

const Info = styled.div`
    position: sticky;
    padding: 20px;
    top: -1px;
    text-align: center;
    color: white;
    font-size: 1rem;
    z-index: 2;
    background: rgba(255, 139, 0, 1);
    margin-bottom: 20px;
`
