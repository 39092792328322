import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { colors } from '../styles/globals/_variables'

const PrevArrow = styled.div`
    font-size: 25px;
    color: #e0e0e0;
    z-index: 10000;
    left: 0;
    top: 25%;
    &:hover {
        color: ${colors.darkGreyEgg};
    }
`

const NextArrow = styled(PrevArrow)`
    left: auto;
    right: 0;
    &:hover {
        color: ${colors.darkGreyEgg};
    }
`

// Today Facilitators Slider Setting. Placed on Student view.
export const TFSliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    dots: false,
    variableWidth: false,
    arrows: true,
    prevArrow: (
        <PrevArrow>
            <FontAwesomeIcon className="" icon={['fal', 'chevron-left']} />
        </PrevArrow>
    ),
    nextArrow: (
        <NextArrow>
            <FontAwesomeIcon className="" icon={['fal', 'chevron-right']} />
        </NextArrow>
    ),
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
            },
        },
    ],
}

// Today Facilitators Slider Setting. Placed on Student view.
export const MessagesliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    dots: false,
    variableWidth: false,
    arrows: true,
    prevArrow: (
        <PrevArrow>
            <FontAwesomeIcon className="" icon={['fal', 'chevron-left']} />
        </PrevArrow>
    ),
    nextArrow: (
        <NextArrow>
            <FontAwesomeIcon className="" icon={['fal', 'chevron-right']} />
        </NextArrow>
    ),
    // responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         infinite: true,
    //         dots: true
    //       }
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         dots: true,
    //       }
    //     }
    //   ]
}
