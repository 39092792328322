export const colors = {
    borders: '#EEEEEE',
    danger: '#ff6673',
    darkGreyEgg: '#073045',
    darkGreyEggHover: '#05202e',
    eggColor: '#FFCD00',
    lightGreyBg: '#F8F8F8',
    mediumGreyBg: '#D2D2D2',
    paragraphColor: '#37474F',
    primaryColor: '#296efa',
    primaryColorAlt: '#427CA6',
    primaryColorHover: '#105df9',
    active: '##00A3FF',
    salmon: '#FF647C',
    white: '#ffffff',
    separadorLineas: '#EAEAEA',
}
