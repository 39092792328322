import React, { useState, useEffect, useContext } from 'react'
import FullLayout, { Props as FullLayoutProps } from '../../../layouts/FullLayout'
import { useTranslation } from 'react-i18next'
import { UIContext } from '../../../context/UIContext'
import TeamInfo from './components/TeamInfo'
import AlgorithmLaunchButton, {
    Props as AlgorithmLaunchButtonProps,
} from './components/AlgorithmLaunchButton'
import SchedulesList, { Props as SchedulesListProps } from './components/SchedulesList'
import { Props as MeetingServiceSelectorProps } from './components/MeetingConfigBlock/MeetingServiceSelector'
import { Meeting } from '../../../hooks/ApiHooks/useMeetings'
import { Props as SettingsActionButonsProps } from './components/SettingsActionButtons'
import { Props as TeamInfoProps } from './components/TeamInfo'
import { TableItem } from '../../../context/SubjectStudentContext'
import MeetingConfigBlock from './components/MeetingConfigBlock'
import AdvanceConfiguration from './components/AdvanceConfiguration'
import { colors } from '../../../styles/globals/_variables'
import styled from 'styled-components'
import Massiveviev from './components/MassiveSave'
import UserContext from '../../../context/UserContext'
import { SubjectContext } from '../../../context/SubjectContext'
import ReactGA from 'react-ga'
import { useTeam } from '../../Team/Context/TeamContext'

export interface Props {
    mainLinks?: FullLayoutProps['links']
    courseName: string
    hqName: string
    subjectName: string
    onLaunchAlgorithm: AlgorithmLaunchButtonProps['onLaunch']
    days: SchedulesListProps['days']
    onChangeCheckbox: SchedulesListProps['onChangeCheckbox']
    onChangeClassTime: SchedulesListProps['onChangeClassTime']
    setMeetingService?: MeetingServiceSelectorProps['onChange']
    onCourseChange: TeamInfoProps['onCourseChange']
    onSubjectChange: TeamInfoProps['onSubjectChange']
    spinnerCourse: TeamInfoProps['spinnerCourse']
    spinnerSubject: TeamInfoProps['spinnerSubject']
    //onCreateMeeting: ZoomConfigProps['createMeeting'],
    //onLoadCsv: ZoomConfigProps['loadCsv'],
    meeting: Meeting | null | undefined
    assignation: TableItem[]
    onTeamDelete: SettingsActionButonsProps['onDelete']
    disabledSave: SettingsActionButonsProps['disabledSave']
    onSave: SettingsActionButonsProps['onSave']
    onCancel?: SettingsActionButonsProps['onCancel']
}

const TeamSettingsView = (props: Props) => {
    const {
        mainLinks,
        courseName,
        hqName,
        subjectName,
        onLaunchAlgorithm,
        days,
        onChangeCheckbox,
        onChangeClassTime,
        onTeamDelete,
        onSave,
        onCourseChange,
        onSubjectChange,
        spinnerCourse,
        spinnerSubject,
    } = props

    const { t } = useTranslation(['teamSettings'])
    const { titleCaseFn } = useContext(UIContext)
    const { role } = useContext(UserContext)
    const { hq, course, subject } = useContext(SubjectContext)
    const [validSchedule, setValidSchedule] = useState(false)
    const { team } = useTeam()

    useEffect(() => {
        setValidSchedule(days.every((day) => !day.checked || (!!day.start && !!day.end)))
    }, [days])

    return (
        <FullLayout links={mainLinks} ruta={`${titleCaseFn(hqName)} > ${titleCaseFn(courseName)}`}>
            <div className="container">
                <div className="team-settings-container">
                    <div className="heading">
                        <div className="block-title">
                            <h2>{t('teamSettings:heading.title')}</h2>
                        </div>
                    </div>

                    <div className="content">
                        <div>
                            <Massiveviev></Massiveviev>
                        </div>
                        <TeamInfo
                            course={titleCaseFn(courseName)}
                            subject={titleCaseFn(subjectName)}
                            onCourseChange={onCourseChange}
                            onSubjectChange={onSubjectChange}
                            spinnerCourse={spinnerCourse}
                            spinnerSubject={spinnerSubject}
                        />
                        <AlgorithmLaunchButton onLaunch={onLaunchAlgorithm} />
                        <SchedulesList
                            days={days}
                            onChangeCheckbox={onChangeCheckbox}
                            onChangeClassTime={onChangeClassTime}
                            onTeamDelete={onTeamDelete}
                            validSchedule={validSchedule}
                            onSave={onSave}
                        />
                        <MeetingConfigBlock />
                        <AdvanceConfiguration />
                    </div>
                </div>
            </div>
            <BottomBlock>
                <div className="container">
                    <div className="actions-container">
                        {onTeamDelete && (
                            <button
                                className="btn-outline delete-team-action"
                                onClick={() => {
                                    onTeamDelete()
                                    ReactGA.event({
                                        category: 'Team Settings',
                                        action: 'DeleteTeam',
                                        dimension1: 'platform',
                                        dimension2: hq?.name.toLowerCase(),
                                        dimension3: course?.name.toLowerCase(),
                                        dimension4: subject?.name.toLowerCase(),
                                        dimension7: role,
                                        dimension9: team?.algorithm
                                            ? team?.algorithm?.algorithmType
                                            : 'no algorithm available',
                                    })
                                }}
                            >
                                {t('teamSettings:content.delete')}
                            </button>
                        )}
                    </div>
                </div>
            </BottomBlock>
        </FullLayout>
    )
}

export default TeamSettingsView

const BottomBlock = styled.div`
    background-color: ${colors.lightGreyBg};
    .container {
        padding: 40px 60px;
    }
`
