import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useUIContext } from '../context/UIContext'
import Styles from './BottomNotification.module.css'

export const BottomNotification = () => {
    const {
        infoBlock: [type, message],
        setInfoBlock,
    } = useUIContext()

    if (!type) return null

    const icon = type === 'success' ? 'check' : 'times-circle'

    return (
        <div className={`${Styles.bottomNotification} ${Styles[type]}`}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon
                    icon={['fal', icon]}
                    style={{ fontSize: 30, color: '#FFFFFF', padding: 20 }}
                />

                <label
                    style={{
                        display: 'inline-grid',
                        color: 'white',
                        marginTop: -3,
                    }}
                >
                    {message && <span style={{ fontSize: 14, fontWeight: 'bold' }}>{message}</span>}
                </label>
            </div>

            <div className={Styles.closeSection}>
                <FontAwesomeIcon
                    icon={['fal', 'xmark']}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setInfoBlock([])}
                />
            </div>
        </div>
    )
}
