/* eslint-disable react/prop-types */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BtnCircular } from '../../../../../components/Buttons/BtnCircular'
import EggGiven from './icons/stats-given-recognition.svg'
import EggReceived from './icons/stats-received-recognition.svg'
import TimesFacilitator from './icons/stats-times-facilitator.svg'

const Stats = ({ student }) => {
    const { t } = useTranslation('teamManager')
    if (!student.stats) return null

    const { votes, timesFacilitator } = student.stats
    const { received, given } = votes

    return (
        <div className="user-action-stats">
            <StatIcon
                value={received}
                image={EggReceived}
                toolTips={t('stats.recognitions.received')}
            />
            <StatIcon value={given} image={EggGiven} toolTips={t('stats.recognitions.given')} />
            <StatIcon
                value={timesFacilitator}
                image={TimesFacilitator}
                toolTips={t('stats.times-facilitator')}
            />
        </div>
    )
}

export default Stats

const StatIcon = (props) => {
    if (props.value === undefined) return null
    return (
        <div className="stat-unit">
            <img src={props.image} alt={props.toolTips} style={{ width: 30, height: 30 }} />
            <BtnCircular
                toolTips={props.toolTips}
                icon={props.icon}
                style={{
                    border: 0,
                    width: 'auto',
                    height: 'auto',
                    marginLeft: 7,
                    fontSize: 'unset',
                }}
            >
                {props.value}
            </BtnCircular>
        </div>
    )
}
