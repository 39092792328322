import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import Config from '../../config'
import { useTranslation } from 'react-i18next'
import { SubjectContext } from '../../context/SubjectContext'
import UIContext from '../../context/UIContext'
import useDebounce from '../../hooks/useDebounce'
import BtnSmall from '../Buttons/BtnSmall'
import AvatarEgg from '../../components/Styled/AvatarEgg'
import { ROLES } from '../../constants'
import Pagination from '../Pagination'

export interface Props {
    open: boolean
    onClose?: () => void
}

export const WizardModalAddStudent = ({ open, onClose }: Props) => {
    const { t } = useTranslation(['teamDeashboard'])
    const { members, course, hq, setMembers } = useContext(SubjectContext)
    const { titleCaseFn } = useContext(UIContext)
    const [filterData, setFilterData] = useState<string>()
    const [filterResultData, setFilterResultData] = useState<
        {
            urlImage?: string
            name?: string
            lastname?: string
            email?: string
            belong?: boolean
            _id: string
        }[]
    >()
    // const enrollStudent= ( id_student: any) =>{ console.log()}
    const [debounce] = useDebounce(filterData, 1000)
    const [page, setPage] = useState<number>(1)
    const [limit] = useState<number>(10)
    const [maxpage, setMaxpage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [spinner, setSpinner] = useState(false)
    useEffect(() => {
        //console.log("hacer algo cuando cambie deboucne " + debounce);
        //peticion back
        // console.log(members?.student);
        if (!hq?.id) {
            return
        }
        setSpinner(true)
        //if (!debounce){ return}
        Axios.get(`${Config.API}/hq/${hq?.id}/students`, {
            params: {
                limit: limit,
                skip: limit * (page - 1),
                search: debounce,
            },
        }).then(
            (response) => {
                //Acá debería asignar las hqs que el usuario puede seleccionar.
                //recorro el array para agregar el atributo belong si pertenece al curso
                setMaxpage(Math.ceil(response.data.totalCount / limit))
                setTotalCount(response.data.totalCount)

                for (let i = 0; i < response.data.students.length; i++) {
                    let local = false
                    if (members?.student) {
                        for (let j = 0; j < members?.student.length; j++) {
                            if (members?.student[j]._id === response.data.students[i]._id) {
                                local = true
                            }
                        }
                    }
                    if (local === true) {
                        response.data.students[i].belong = true
                    } else {
                        response.data.students[i].belong = false
                    }
                }
                //console.log(response.data.students)
                setFilterResultData(response.data.students)
                setSpinner(false)
            },
            () => {
                //setInfoBlock(['error', error.message || (error.data && error.data.message) || 'Ups! Ocurrió un error. Intenta nuevamente.'])
                //setTimeout( () => {setInfoBlock([])}, 3000)
            }
        )
    }, [debounce, hq, members, limit, page])

    const onAddStudent = (user: string) => async (event: Event) => {
        event.preventDefault()
        await setMembers?.(user, ROLES.STUDENT)
    }

    return (
        <Modal
            isOpen={open}
            className="wizard-modal"
            overlayClassName="wizard-overlay"
            onRequestClose={onClose}
        >
            <div className="heading">
                <FontAwesomeIcon className="close-icon" icon={['fal', 'times']} onClick={onClose} />
                <div className="padded">
                    <h3 className="title">{titleCaseFn(course?.name)}</h3>
                </div>
            </div>
            <div className="content">
                <div className="form">
                    <div className="form-group">
                        <input
                            className="form-input"
                            placeholder={'Buscar por nombre, apellido o email'}
                            onChange={(e) => setFilterData(e.target.value)}
                            maxLength={50}
                        />

                        {spinner && (
                            <FontAwesomeIcon
                                icon="spinner"
                                spin
                                className="spinner"
                                style={{ position: 'absolute', top: '25px', right: '40px' }}
                            />
                        )}
                    </div>

                    <div className="form-group textarea">
                        <span className="block-label">
                            <h4>Usuarios</h4>
                        </span>

                        <div className="scroll-div">
                            <table width="100%" className="scroll-table-students">
                                <thead>
                                    <tr className="left-align-table">
                                        <th>img</th>
                                        <th>name</th>
                                        {/*<th>id</th>*/}
                                        <th>mail</th>
                                        <th>add</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterResultData?.map((element) => {
                                        return (
                                            <tr key={element.urlImage}>
                                                <td>
                                                    <AvatarEgg
                                                        isEditable={false}
                                                        src={element.urlImage}
                                                        size={{ mobile: 32, desktop: 40 }}
                                                    />
                                                </td>
                                                <td>
                                                    {titleCaseFn(
                                                        `${element.name} ${element.lastname}`
                                                    )}
                                                </td>
                                                {/*<td>{element._id}</td>*/}
                                                <td>{element.email}</td>
                                                <td>
                                                    <BtnSmall
                                                        active={element.belong}
                                                        onClick={onAddStudent(element._id)}
                                                    >
                                                        +++
                                                    </BtnSmall>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            page={page}
                            count={maxpage}
                            onChange={(page) => {
                                setPage(page)
                            }}
                        />
                    </div>

                    <span className="counter">
                        <b>Resultados: {totalCount}</b>
                    </span>
                    <div className="action-buttons">
                        <button className="btn-outline cancel" onClick={onClose}>
                            {t('teamDashboard:modal.dismiss')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default WizardModalAddStudent
