import React from 'react'
import Chart, {
    ChartWrapperOptions,
    GoogleChartWrapperChartType,
    ReactGoogleChartEvent,
} from 'react-google-charts'
import { useTranslation } from 'react-i18next'
import LoadingPage from '../../../Other/LoadingPage'

export const ChartComponent = ({ chartType, data, options, width, height, chartEvents }: props) => {
    if (!data) return <NoData />
    return (
        <div style={{ borderRadius: '5px', overflow: 'hidden' }}>
            <Chart
                chartType={chartType}
                className="chart"
                width={width || '100%'}
                height={height || '300px'}
                loader={<LoadingPage />}
                errorElement={<ErrorElement />}
                data={data}
                options={options}
                chartEvents={chartEvents}
            />
        </div>
    )
}

type props = {
    chartType: GoogleChartWrapperChartType
    data: ChartData | null
    options: ChartWrapperOptions['options']
    chartEvents?: ReactGoogleChartEvent[]
    width?: string
    height?: string
}

export type ChartData = (
    | string
    | number
    | null
    | { role: string; sourceColumn?: number; type?: string; calc?: string }
)[][]

export const NoData = ({ text }: { text?: string }) => {
    const { t } = useTranslation('teamReports')
    return <p>{t(`${text ?? 'There is no data for the selected dates'}`)}</p>
}

const ErrorElement = () => {
    const { t } = useTranslation('teamReports')
    return <p>{t('There was a problem, please contact support')}</p>
}
