/* eslint-disable react/prop-types */
import React from 'react'
import InfoBlock from '../components/InfoBlockTopPage'

const NoHeaderLayout = ({ children }) => {
    return (
        <div className="main-layout">
            {/* Error / success message top page */}
            <InfoBlock />
            <main>{children}</main>
        </div>
    )
}

export default NoHeaderLayout
