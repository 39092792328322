import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { IDay } from '../TeamSettingsContainer'
import ToggleBtn from '../../../../components/Buttons/Toggle'
import SettingsActionButons from './SettingsActionButtons'
import styled from 'styled-components'
import { colors } from '../../../../styles/globals/_variables'

// Safari polyfill to show input time placeholders.
// Allow the require since the interface has not been defined.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const [supportsTime, TimePolyfill] = [
    require('time-input-polyfill/supportsTime'),
    require('time-input-polyfill'),
]

export interface Props {
    days: IDay[]
    onChangeCheckbox: (dayIdx: IDay['idx']) => unknown
    onChangeClassTime: (
        dayIdx: IDay['idx'],
        type: 'start' | 'end'
    ) => ChangeEventHandler<HTMLInputElement>
    onTeamDelete?: () => unknown
    validSchedule: boolean
    onSave?: () => unknown
}

const ComponentBorder = styled.div`
    border-bottom: 1px solid ${colors.separadorLineas};
    padding-bottom: 30px;
`

export const SchedulesList = ({
    days,
    onChangeCheckbox,
    onChangeClassTime,
    onTeamDelete,
    validSchedule,
    onSave,
}: Props) => {
    const { t } = useTranslation(['teamSettings'])
    const [show, setShow] = useState<boolean>(false)

    //Safari polyfill to show input time placeholders
    useEffect(() => {
        if (!supportsTime) {
            // Converting to an array for IE support
            const $$inputs = [].slice.call(document.querySelectorAll('input[type="time"]'))
            $$inputs.forEach(function ($input) {
                new TimePolyfill($input)
            })
        }
    }, [])

    return (
        <ComponentBorder>
            <div className="course-hours">
                <div className="egg-list recomendations-list days-list">
                    <div className="schedules-heading">
                        <h3 className="block-title-classroom">
                            {t('teamSettings:content.schedule.header')}
                        </h3>
                        <ToggleBtn on={show} onClick={() => setShow((prev) => !prev)} />
                    </div>

                    {show && (
                        <>
                            <span className="block-label">
                                {t('teamSettings:content.schedule.title')}
                            </span>
                            <span className="block-desc">
                                {t('teamSettings:content.schedule.desc')}
                            </span>
                        </>
                    )}
                    {show &&
                        days.map((day) => (
                            <div key={day.idx} className="list-item">
                                <div className="label-and-switch-container">
                                    <div className="day-label">
                                        <span className="label">{day.name}</span>
                                    </div>
                                    <div
                                        className="day-switch"
                                        onClick={() => onChangeCheckbox(day.idx)}
                                    >
                                        {day.checked ? (
                                            <FontAwesomeIcon
                                                className="box checked"
                                                icon={['fas', 'toggle-on']}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="box unchecked"
                                                icon={['fal', 'toggle-off']}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="time-start time-input">
                                    <label>
                                        <span className="label">
                                            {t('teamSettings:content.schedule.start')}
                                        </span>
                                        <input
                                            type="time"
                                            id="day.name"
                                            defaultValue={day.start}
                                            name="appt"
                                            disabled={day.checked ? false : true}
                                            onChange={onChangeClassTime(day.idx, 'start')}
                                        />
                                    </label>
                                </div>
                                <div className="time-end time-input">
                                    <label>
                                        <span className="label">
                                            {t('teamSettings:content.schedule.end')}
                                        </span>
                                        <input
                                            type="time"
                                            id="day.name"
                                            defaultValue={day.end}
                                            name="appt"
                                            disabled={day.checked ? false : true}
                                            onChange={onChangeClassTime(day.idx, 'end')}
                                        />
                                    </label>
                                </div>
                            </div>
                        ))}
                    {show && (
                        <SettingsActionButons
                            onDelete={onTeamDelete}
                            disabledSave={!validSchedule}
                            onSave={onSave}
                            onCancel={() => window.location.reload()} // TODO: Make the cancel function
                        />
                    )}
                </div>
            </div>
        </ComponentBorder>
    )
}

export default SchedulesList
