// We should used namespaces in the first place. Now we should simply ignore it.
/* eslint-disable @typescript-eslint/no-namespace */
import React, { useEffect, useCallback, useContext } from 'react'
import Config from '../../config'
import Axios from 'axios'
import mobileCheck from '../../helpers/mobileCheck'
import { ROLES } from '../../constants'
import { UIContext } from '../../context/UIContext'
import { errorHandler } from '../../helpers/errorHandler'
export enum MeetingService {
    ZOOM = 'zoom',
    ZOOM_LIST = 'zoom_list',
    MEET = 'meet',
    TEAMS = 'teams',
    WHATSAPP = 'whatsapp',
    JITSI = 'jitsi',
    ZOOM_BY_TABLE = 'zoom-by-table',
}

export declare namespace Meet {
    export interface Meeting {
        service: MeetingService.MEET
        method: 'manual'
        general?: string
        rooms?: string[]
        room?: string
    }
    export interface GETResponseType {
        meetingType: MeetingService.MEET
        generalMeet: string
        meetings?: string[]
        meeting?: string
    }
}
export declare namespace Teams {
    export interface Meeting extends Omit<Meet.Meeting, 'service'> {
        service: MeetingService.TEAMS
    }
    export interface GETResponseType extends Omit<Meet.GETResponseType, 'meetingType'> {
        meetingType: MeetingService.TEAMS
    }
}

export declare namespace Jitsi {
    export interface Meeting extends Omit<Meet.Meeting, 'service' | 'method'> {
        service: MeetingService.JITSI
        method: 'automatic' | 'manual'
    }
    export interface GETResponseType extends Omit<Meet.GETResponseType, 'meetingType'> {
        meetingType: MeetingService.JITSI
    }
}

export declare namespace ZoomList {
    export interface Meeting extends Omit<Meet.Meeting, 'service'> {
        service: MeetingService.ZOOM_LIST
    }
    export interface GETResponseType extends Omit<Meet.GETResponseType, 'meetingType'> {
        meetingType: MeetingService.ZOOM_LIST
    }
}

export declare namespace Whatsapp {
    export interface Meeting extends Omit<Meet.Meeting, 'service'> {
        service: MeetingService.WHATSAPP
    }
    export interface GETResponseType extends Omit<Meet.GETResponseType, 'meetingType'> {
        meetingType: MeetingService.WHATSAPP
    }
}

export declare namespace Zoom {
    interface GETResponseType {
        meetingType: MeetingService.ZOOM
        meetings: BaseMeeting[]
        generalMeet: string
        method: 'manual'
    }
    interface Account {
        id: string
        email: string
    }

    interface BaseMeeting {
        uuid?: string
        id?: number | null
        agenda?: string
        host_id?: string
        topic?: string
        type?: number
        start_time?: string
        duration?: number
        timezone?: string
        created_at?: string
        join_url?: string
        settings?: Record<string, unknown>
        status?: 'waiting'
    }
    export interface Meeting extends BaseMeeting {
        service: MeetingService.ZOOM
        method: 'manual'
        general?: string
        rooms?: string[] | undefined | null
    }
    export type ScheduleType = {
        day: 0 | 1 | 2 | 3 | 4 | 5 | 6
        start: string
    }
    export enum DeviceType {
        Desktop = 'desktop',
        Android = 'android',
        Iphone = 'iphone',
    }
    export type POSTResponseType = {
        _id: string
        zoom: {
            uuid: string
            id: number
        }
    }
}

export declare namespace ZoomByTable {
    export interface Meeting extends Omit<Meet.Meeting, 'service'> {
        service: MeetingService.ZOOM_BY_TABLE
    }

    export interface GETResponseType extends Omit<Meet.GETResponseType, 'meetingType'> {
        meetingType: MeetingService.ZOOM_BY_TABLE
    }
}

export type Meeting =
    | Meet.Meeting
    | Teams.Meeting
    | Whatsapp.Meeting
    | Zoom.Meeting
    | Jitsi.Meeting
    | ZoomList.Meeting
    | ZoomByTable.Meeting

export type GETResponseType =
    | Meet.GETResponseType
    | Teams.GETResponseType
    | Whatsapp.GETResponseType
    | Zoom.GETResponseType
    | Jitsi.GETResponseType
    | ZoomList.GETResponseType
    | ZoomByTable.GETResponseType

interface PATCHResponseType {
    _courseId: string
}

const safeOpenLink = (url: string) => {
    if (url) {
        if (!url.match(/^[a-zA-Z]+:\/\//)) {
            url = 'https://' + url
        }
        window.open(url, '_blank', 'noopener noreferrer')
    }
}

export function useMeetings(
    /** INPUT */
    _professorScheduleId: string | undefined,
    _subjectId: string | undefined,
    _profileId: string | undefined, // TODO: Why the get needs _profileId
    _courseId: string | undefined,
    role: string | undefined,
    // eslint-disable-next-line no-console
    onError: (reason?: unknown) => void = console.error
): [
    Meeting | null | undefined,
    // This functions are defined inside the useMeetings function
    // eslint-disable-next-line no-undef
    typeof meetingGenerator,
    // eslint-disable-next-line no-undef
    typeof meetingJoiner,
    // Workaround to show modal when meeting type is zoom_list
    string | undefined,
    boolean | number,
    (val: boolean) => void,
    () => void,
    () => void // Exporting Fetch meeting to be called after socket event on teamdetailstudentcontainer
] {
    const [meeting, setMeeting] = React.useState<Meeting | undefined | null>(undefined)
    const [zoomAccounts, setZoomAccount] = React.useState<Zoom.Account[]>([])

    const [iframeLink, setiframeLink] = React.useState<string | undefined>(undefined)
    const { setInfoBlock } = useContext(UIContext)
    const [meetingModalShow, setMeetingModalShow] = React.useState<boolean | number>(false)

    const openInModal = (url: string) => {
        setiframeLink(url)
        setMeetingModalShow(true)
    }

    async function clearMeeting(): Promise<undefined> {
        if (role !== ROLES.STUDENT) {
            try {
                if (!_courseId) return
                await Axios.patch<PATCHResponseType>(Config.API + '/class/' + _courseId, {
                    config: {},
                })
                setMeeting(undefined)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
            return
        }
    }

    useEffect(() => {
        // Si no hay courseid return, si el rol es student no hacer este request ya que el student no la necesita.
        if (!_courseId || role === ROLES.STUDENT) return
        Axios.get(`${Config.API}/class/${_courseId}/config`).then((response) => {
            //console.log('response', response)
            setZoomAccount(response.data.zoomAccounts)
        })
    }, [_courseId, role])

    const fetchMeetings = useCallback(async () => {
        try {
            if (!_profileId) return
            await Axios.get(`${Config.API}/profile/${_profileId}/meetings/`, {
                params: {
                    _professorScheduleId,
                    _subjectId,
                },
            })
                .then((response) => {
                    if (response.data.meetingType === 'zoom-by-table') {
                        const {
                            generalMeet,
                            meetings,
                            meeting,
                            meetingType,
                            method,
                        } = response.data
                        setMeeting({
                            service: meetingType,
                            method: method || 'manual',
                            general: generalMeet || '',
                            rooms: meetings,
                            room: meeting,
                        })
                    }

                    if (response?.status === 200) {
                        switch (response.data.meetingType) {
                            case MeetingService.JITSI:
                            case MeetingService.ZOOM_LIST:
                            case MeetingService.WHATSAPP:
                            case MeetingService.TEAMS:
                            case MeetingService.MEET: {
                                const {
                                    generalMeet,
                                    meetings,
                                    meeting,
                                    meetingType,
                                    method,
                                } = response.data
                                setMeeting({
                                    service: meetingType,
                                    method: method || 'manual',
                                    general: generalMeet,
                                    rooms: meetings,
                                    room: meeting,
                                })
                                break
                            }
                            case MeetingService.ZOOM: {
                                const { generalMeet, method } = response.data

                                switch (method) {
                                    case 'manual':
                                        setMeeting({
                                            service: MeetingService.ZOOM,
                                            method: 'manual',
                                            general: generalMeet,
                                        })
                                        break
                                }
                                break
                            }
                            default: {
                                if (response.data.meetingType !== 'zoom-by-table') {
                                    setMeeting(undefined)
                                }

                                break
                            }
                        }
                    }
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error(errorHandler(error))
                })
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            setMeeting(null)
            onError(error)
        }
    }, [onError, setMeeting, _profileId, _professorScheduleId, _subjectId])

    useEffect(() => {
        fetchMeetings()
    }, [fetchMeetings])

    /**
     * It sets the service and create the corresponding meeting.
     * @param service The type of service to set
     * @param general Meet only. Link to the main meeting
     * @param rooms Meet only. Array of links corresponding to every table (table 1 with index 0)
     * @param schedules Zoom only. Schedules for the meeting
     * @return
     */
    async function meetingGenerator(
        service:
            | MeetingService.MEET
            | MeetingService.TEAMS
            | MeetingService.JITSI
            | MeetingService.WHATSAPP
            | MeetingService.ZOOM_LIST,
        general?: string,
        rooms?: string[]
    ): Promise<PATCHResponseType>
    async function meetingGenerator(
        service: MeetingService.ZOOM,
        method: 'manual',
        general?: string
    ): Promise<undefined>
    //async function meetingGenerator(service: MeetingService, ...args: [string?, string[]?] | ['manual' | 'automatic', Zoom.ScheduleType?]): Promise<PATCHResponseType | Zoom.POSTResponseType['zoom'] | undefined>
    async function meetingGenerator(
        service: MeetingService,
        ...args: unknown[]
    ): Promise<PATCHResponseType | Zoom.POSTResponseType['zoom'] | undefined> {
        switch (service) {
            case MeetingService.WHATSAPP:
            case MeetingService.ZOOM_LIST:
            case MeetingService.TEAMS:
            case MeetingService.JITSI:
            case MeetingService.MEET: {
                let [general, rooms] = args as [string?, string[]?]
                let method = 'manual'

                if (MeetingService.JITSI && general === 'automatic')
                    [method, general, rooms] = ['automatic', '', []]

                setMeeting({ service, general, rooms, method } as Meeting)
                const responseMeet = await Axios.patch<PATCHResponseType>(
                    Config.API + '/class/' + _courseId,
                    {
                        config: {
                            meetingType: service,
                            method,
                            data: {
                                generalMeet: general ?? '',
                                meetings: rooms ?? [],
                            },
                        },
                    }
                )
                return responseMeet.data
            }
            case MeetingService.ZOOM: {
                const method = args[0] as 'manual'
                switch (method) {
                    case 'manual': {
                        const general = (args[1] as string) ?? ''
                        setMeeting({
                            service: MeetingService.ZOOM,
                            method,
                            general,
                        })
                        await Axios.patch<PATCHResponseType>(Config.API + '/class/' + _courseId, {
                            config: {
                                meetingType: service,
                                method,
                                data: { generalMeet: general },
                            },
                        })
                        return
                    }
                }
            }
        }
    }
    meetingGenerator.zoomAccounts = zoomAccounts

    /**
     *
     * @param service The type of service to set
     * @param link Meet only. The link
     * @param id Zoom only.
     * @param device Zoom only.
     */
    async function meetingJoiner(link?: string): Promise<unknown>
    async function meetingJoiner(table?: number): Promise<unknown>
    async function meetingJoiner(
        id?: Zoom.Meeting['id'],
        device?: Zoom.DeviceType
    ): Promise<unknown>
    async function meetingJoiner(...args: unknown[]): Promise<unknown> {
        if (meeting?.service === MeetingService.JITSI && meeting.method === 'automatic') {
            const table = args[0]
            setMeetingModalShow(typeof table === 'number' ? table : true)
            return true
        }

        switch (meeting?.service) {
            case MeetingService.WHATSAPP:
            case MeetingService.TEAMS:
            case MeetingService.ZOOM_LIST: {
                const [link_or_table] = args as [(string | number)?]
                if (role === ROLES.STUDENT) {
                    if (typeof link_or_table === 'string') {
                        openInModal(link_or_table)
                    } else if (link_or_table && meeting?.rooms) {
                        openInModal(meeting.rooms?.[link_or_table - 1])
                    } else if (link_or_table && meeting?.room) {
                        openInModal(meeting.room)
                    } else if (meeting?.general) {
                        openInModal(meeting.general)
                    } else {
                        setInfoBlock(['error', 'No se ha encontrado un link!'])
                        setTimeout(() => setInfoBlock([]), 1500)
                        return false
                    }
                    return true
                } else {
                    if (typeof link_or_table === 'string') {
                        safeOpenLink(link_or_table)
                    } else if (link_or_table && meeting?.rooms) {
                        safeOpenLink(meeting.rooms?.[link_or_table - 1])
                    } else if (link_or_table && meeting?.room) {
                        safeOpenLink(meeting.room)
                    } else if (meeting?.general) {
                        safeOpenLink(meeting.general)
                    } else {
                        setInfoBlock(['error', 'No se ha encontrado un link!'])
                        setTimeout(() => setInfoBlock([]), 1500)
                        return false
                    }
                    return true
                }
            }
            case MeetingService.JITSI:
            case MeetingService.MEET:
            case MeetingService.ZOOM_BY_TABLE: {
                const [link_or_table] = args as [(string | number)?]
                if (typeof link_or_table === 'string') {
                    safeOpenLink(link_or_table)
                } else if (link_or_table && meeting?.rooms) {
                    safeOpenLink(meeting.rooms?.[link_or_table - 1])
                } else if (link_or_table && meeting?.room) {
                    safeOpenLink(meeting.room)
                } else if (meeting?.general) {
                    safeOpenLink(meeting.general)
                } else {
                    setInfoBlock(['error', 'No se ha encontrado un link!'])
                    setTimeout(() => setInfoBlock([]), 1500)
                    return false
                }
                return true
            }
            case MeetingService.ZOOM:
                switch (meeting?.method) {
                    case 'manual': {
                        if (meeting?.general) {
                            if (mobileCheck()) {
                                //window.location.replace(`intent://zoom.us/j/${id}#Intent;scheme=https;package=us.zoom.videomeetings;end`)
                                window.location.replace(
                                    `zoomus://zoom.us/join?confno=${meeting.general}&stype=99`
                                )
                                //window.location.replace(`intent://zoom.us/join?confno=${id}&stype=99;scheme=zoomus;package=us.zoom.videomeetings;end`)
                            } else {
                                window.location.replace(
                                    `zoommtg://zoom.us/join?confno=${meeting.general}&stype=99`
                                )
                            }
                            return false
                        }
                        return false
                    }
                }
        }
    }

    return [
        meeting,
        meetingGenerator,
        meetingJoiner,
        iframeLink,
        meetingModalShow,
        setMeetingModalShow,
        fetchMeetings,
        clearMeeting,
    ]
}

export interface HookUseMeeting {
    meeting: Meeting | null | undefined
    setMeeting: (
        service:
            | MeetingService.MEET
            | MeetingService.TEAMS
            | MeetingService.JITSI
            | MeetingService.WHATSAPP
            | MeetingService.ZOOM_LIST,
        general?: string | undefined,
        rooms?: string[] | undefined
    ) => Promise<PATCHResponseType>
}
