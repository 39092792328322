import { useQuery } from 'react-query'
import { getParticipationGraph } from '../api-reports'
import { useReports } from '../Context/ReportsContext'

export const useParticipation = () => {
    const { reportQuery, enabled, staleTime } = useReports()
    const { courseId, cohorts, ...restQuery } = reportQuery
    const queryKey = [
        courseId,
        'Reports',
        'Participation',
        'Graph',
        `Cohorts: ${cohorts?.join()}`,
        restQuery,
    ]
    const { data: participation, ...rest } = useQuery(
        queryKey,
        () => getParticipationGraph(reportQuery),
        { enabled, staleTime }
    )

    return { participation, ...rest }
}
