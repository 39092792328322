import React from 'react'
import { useTranslation } from 'react-i18next'

export interface Props {
    onDelete?: () => unknown
    onCancel?: () => unknown
    onSave?: () => unknown
    disabledSave: boolean
}

export const SettingsActionButons = ({ onCancel, onSave, disabledSave }: Props) => {
    const { t } = useTranslation(['teamSettings'])

    return (
        <div className="page-actions responsive">
            <div className="actions-container">
                {onCancel && (
                    <button className="btn-outline" onClick={onCancel}>
                        {t('teamSettings:content.cancel')}
                    </button>
                )}
                {onSave && (
                    <button className="btn-yellow" disabled={disabledSave} onClick={onSave}>
                        {t('teamSettings:content.save')}
                    </button>
                )}
            </div>
        </div>
    )
}

export default SettingsActionButons
