import React, { useEffect } from 'react'
import FullLayout from '../../layouts/FullLayout'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
require('dayjs/locale/es')
dayjs.locale('es') // without this line it didn't work
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Hooks
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from '../../styles/globals/_variables'
import { BtnRoundedShaddow } from '../../components/Buttons/BtnRoundedShaddow'

// Interfaces
import { NotificationDTO, useCustomProps, useNotifications } from '../../hooks/useNotifications'
import { capitalize } from '../../helpers/formatter'
import { IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { BtnIcon } from '../../components/Buttons/BtnCircular'
import HeaderBanner from '../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'

export const NotificationView = () => {
    const {
        notifications,
        deleteAllNotifications,
        fetchAllNotification,
        closeAllNotificationPopups,
    } = useNotifications()
    const notificationsCount = notifications.filter((item) => item.status !== 'deleted').length

    useEffect(() => {
        !notifications.length && fetchAllNotification()
        closeAllNotificationPopups()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { t } = useTranslation(['notifications', 'profile'])

    return (
        <FullLayout ruta={t('title')} page="notifications">
            <div className="container">
                <HeaderBanner title={t('title')} />
                <div className="notification-center-page">
                    {notificationsCount ? (
                        <>
                            <div className="mark-all-read">
                                <BtnRoundedShaddow
                                    onClick={() => deleteAllNotifications()}
                                    style={{ color: colors.darkGreyEgg }}
                                >
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={['fas', 'trash']}
                                        style={{ fontSize: '1rem' }}
                                    />
                                    <span>{t('deleteAll')}</span>
                                </BtnRoundedShaddow>
                            </div>
                            <div className="notification-center-container">
                                {notifications.map((notification, index) => (
                                    <NotificationItem
                                        data={notification}
                                        key={`${index}-${notification._id}`}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className="no-notif-msg">{t('notifications:empty')}</div>
                    )}
                </div>
            </div>
        </FullLayout>
    )
}

export default NotificationView

export interface iProps {
    notification: NotificationDTO
    key: string
}

const NotificationItem = ({ data }: { data: NotificationDTO }) => {
    const { t, i18n } = useTranslation(['notifications'])
    const { toggleReadNotification } = useNotifications()
    const { notification, onClick } = useCustomProps(data)
    const { message, status } = notification

    const iconVariant: IconPrefix = status === 'unread' ? 'fas' : 'far'
    const tips = status === 'unread' ? t('markAsRead') : t('markAsUnread')

    return (
        <Card status={status}>
            <div className="item">
                <div
                    className="icon"
                    onClick={(evt) => {
                        evt.stopPropagation()
                        toggleReadNotification(notification)
                    }}
                >
                    <BtnIcon icon={[iconVariant, 'user-headset']} toolTips={tips} />
                </div>
                <div className="content" onClick={onClick}>
                    <div>
                        <span className="message">{message}</span>
                    </div>
                    <div className="date">
                        <span>
                            {capitalize(
                                dayjs(notification.createdAt).locale(i18n.language).fromNow()
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </Card>
    )
}

const Card = styled.div<{ status: NotificationDTO['status'] }>`
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    font-weight: ${({ status }) => (status === 'unread' ? 'bold' : 'normal')};
    text-align: center;
    margin: 10px auto;
    padding: 20px 15px;
    max-width: 940px;

    &:hover {
        background-color: ${colors.lightGreyBg};
    }

    & .content {
        text-align: left;
        padding-left: 15px;
        margin: auto 0;
        flex-grow: 2;
    }

    & .item {
        display: flex;
    }

    & .date {
        font-size: 0.9em;
        margin-top: 0.5em;
        color: grey;
        font-weight: normal;
    }
`
