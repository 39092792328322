import React, { useState, useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { SubjectContext } from '../../../../context/SubjectContext'
import { CourseOptions } from '../../../../hooks/ApiHooks/useCourse'
import UserContext from '../../../../context/UserContext'
import ToggleBtn from '../../../../components/Buttons/Toggle'
import ConfirmModal from '../../../../components/ConfirmModal'
import ReactGA from 'react-ga'
import { useTeam } from '../../../Team/Context/TeamContext'

export interface Props {
    onLaunch?: (type: 'repeated' | 'shuffle') => Promise<void>
}

export const AlgorithmLaunchButton = ({ onLaunch }: Props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const { options, setOptions, hq, course, subject } = useContext(SubjectContext)
    const { role } = useContext(UserContext)
    const { t } = useTranslation(['teamSettings'])
    const [algoType, setAlgoType] = useState<'repeated' | 'shuffle'>('shuffle')
    const [confirmAlgorithmModal, setConfirmAlgorithmModal] = useState(false)
    const { team } = useTeam()

    const algorithmLaunch = async () => {
        try {
            setLoading(true)
            await onLaunch?.(algoType)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            throw error
        }
    }

    // Refresh switch option when options are finally fetched
    useEffect(() => {
        setAlgoType(options?.[CourseOptions.RepeatAlgorithm] ? 'repeated' : 'shuffle')
    }, [options])

    // It sets local algoType to launchAlgorithm with new value
    // Sets db option to persist change
    const handleAlgoSwitch = () => {
        setAlgoType((prev) => (prev === 'shuffle' ? 'repeated' : 'shuffle'))
        setOptions?.(CourseOptions.RepeatAlgorithm, algoType === 'repeated' ? false : true)
    }

    const reassignWithPresents = options?.[CourseOptions.ReassignPresents]

    return (
        <div className="algorithm-launch responsive">
            <div className="bottom">
                <div>
                    <ToggleBtn on={algoType === 'repeated'} onClick={() => handleAlgoSwitch()} />
                    <h5>{t('teamSettings:content.algorithm.switch')}</h5>
                </div>
            </div>
            <div className="top">
                <div className="info responsive">
                    <div className="icon">
                        <FontAwesomeIcon icon={['fal', 'redo']} />
                    </div>
                    <div className="text">
                        <h3 className="text-white">
                            {t(
                                `teamSettings:content.algorithm.${
                                    !reassignWithPresents ? 'label' : 'label-with-presents'
                                }`
                            )}
                        </h3>
                    </div>
                </div>
                <ConfirmModal
                    onClose={() => {
                        setConfirmAlgorithmModal(false)
                    }}
                    onConfirm={() => {
                        setConfirmAlgorithmModal(false), algorithmLaunch()
                    }}
                    open={confirmAlgorithmModal}
                    type={'warning'}
                    title={t('teamSettings:content.algorithm.title-modal')}
                    contentText={t('teamSettings:content.algorithm.confirm')}
                    confirmButtonText={t('teamSettings:content.algorithm.confirm-btn-modal')}
                    cancelButtonText={t('teamSettings:content.algorithm.cancel-btn-modal')}
                />
                <button
                    className="btn-yellow"
                    onClick={() => {
                        setConfirmAlgorithmModal(true)
                        ReactGA.event({
                            category: 'Team Management',
                            action: 'RotateStudents',
                            dimension1: 'platform',
                            dimension2: hq?.name.toLowerCase(),
                            dimension3: course?.name.toLowerCase(),
                            dimension4: subject?.name.toLowerCase(),
                            dimension7: role,
                            dimension9: team?.algorithm
                                ? team?.algorithm?.algorithmType
                                : 'no algorithm available',
                        })
                    }}
                >
                    {!loading ? (
                        t('teamSettings:content.algorithm.button')
                    ) : (
                        <span>
                            <FontAwesomeIcon className="icon" icon={['fas', 'spinner']} spin />
                        </span>
                    )}
                </button>
            </div>
        </div>
    )
}

export default AlgorithmLaunchButton
