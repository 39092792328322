/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react'
import firebase from 'firebase/app'
import PasswordManageView from './PasswordManageView'
import { UIContext } from '../../context/UIContext'
import NoHeaderLayout from '../../layouts/NoHeaderLayout'

const PasswordManageContainer = (props) => {
    const { onCancel } = props
    const [, setReady] = useState(false)
    const [recovered, setRecovered] = useState(false)

    // Sets infoBlock error / success
    const { setInfoBlock } = useContext(UIContext)

    const validate = (values) => {
        const errors = {}
        if (!values.email) {
            errors.email = 'Campo obligatorio'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Formato de email incorrecto'
        }
        return errors
    }

    const onSubmit = async (values, { setSubmitting }) => {
        const { email } = values
        setReady(false)
        setSubmitting(true)
        try {
            await firebase.auth().sendPasswordResetEmail(email)
            setRecovered(true)
            setInfoBlock(['success', 'Enviado correctamente. Revisá tu email para continuar'])
        } catch (error) {
            setInfoBlock(['error', 'Ups! Ocurrió un error. Intenta nuevamente.'])
            // eslint-disable-next-line no-console
            console.error('error', error)
        } finally {
            setReady(true)
            setSubmitting(false)

            /* Deletes and hide message after 5 seconds */
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }

    return (
        <NoHeaderLayout>
            <PasswordManageView
                onCancel={onCancel}
                onSubmit={onSubmit}
                validate={validate}
                recovered={recovered}
            />
        </NoHeaderLayout>
    )
}

export default PasswordManageContainer
