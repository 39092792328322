import React, { useContext } from 'react'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import { WizardContext } from '../../context/WizardContext'
import MainLayout from '../../layouts/MainLayout'
import { useTranslation } from 'react-i18next'
import NotFoundPage from '../Other/NotFoundPage'

const WizardContainer = () => {
    const { step } = useContext(WizardContext)
    const { t } = useTranslation(['wizard'])

    let content = null
    let route = ''

    switch (step) {
        case 1:
            content = <Step1 />
            route = t('wizard:step1.route')
            break
        case 2:
            content = <Step2 />
            route = t('wizard:step2.route')
            break
        case 3:
            content = <Step3 />
            route = t('wizard:step3.route')
            break
        case 4:
            content = <Step4 />
            route = t('wizard:step4.route')
            break
        default:
            return <NotFoundPage />
    }

    return (
        <MainLayout ruta={route}>
            <div className="wizard-container">{content}</div>
        </MainLayout>
    )
}

export default WizardContainer
