import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubjectContext } from '../../../../../context/SubjectContext'
import UserContext from '../../../../../context/UserContext'
import { MeetingService } from '../../../../../hooks/ApiHooks/useMeetings'
import { useUIContext } from '../../../../../context/UIContext'
import { errorHandler } from '../../../../../helpers/errorHandler'
import ReactGA from 'react-ga'
import { useTeam } from '../../../../Team/Context/TeamContext'

export const ZoomManualConfig = () => {
    const { t } = useTranslation(['teamSettings'])
    const { toastNotification } = useUIContext()
    const { setMeeting, meeting, hq, course, subject } = useContext(SubjectContext)
    const { role } = useContext(UserContext)
    const [step, setStep] = React.useState<undefined | 0 | 1 | 1.5 | 2>(undefined)
    const [general, setGeneral] = React.useState<string>()
    const { team } = useTeam()

    useEffect(() => {
        if (
            step === undefined &&
            meeting?.service === MeetingService.ZOOM &&
            meeting.method === 'manual'
        ) {
            if (meeting?.general) {
                setStep(2)
            } else {
                setStep(0)
            }
        }
    }, [meeting, step])

    const onCreateMeeting = async () => {
        try {
            await setMeeting?.(MeetingService.ZOOM, 'manual', general)
            toastNotification(
                t('teamSettings:content.virtual-classroom.zoom-manual.onCreatedMeeting')
            )
        } catch (error) {
            toastNotification(
                errorHandler(error, 'Ups! Ocurrió un error. Intenta nuevamente.'),
                'error'
            )

            throw error
        }
    }

    const createMeeting = async () => {
        try {
            if (step !== 1) return
            setStep(1.5)
            await onCreateMeeting()
            setStep(2)
        } catch (error) {
            setStep(1)
        }
    }

    const formatId = (str?: string, lenghts = [3, 4, 4], separator = ' ') => {
        if (!str) return ''

        // Accumulated version of the lenghts (default by [0,3,7,11])
        const acum = lenghts.reduce<number[]>(
            (acum, value) => [...acum, (acum.slice(-1)[0] ?? 0) + value],
            [0]
        )

        // Split the str into the given lengths.
        const result = [...lenghts, undefined].reduce<string[]>((result, length, i) => {
            const start = acum[i]
            const segment = str.substr(start, length)
            if (segment) result.push(segment)
            return result
        }, [])
        return result.join(separator)
    }

    const onChangeInputGeneral: React.InputHTMLAttributes<HTMLInputElement>['onChange'] = (
        event
    ) => {
        const numbers = event.target.value.replace(/[^0-9]/gi, '')
        const capped = numbers.substr(0, 11)
        setGeneral(capped)
    }

    if (meeting?.service !== MeetingService.ZOOM) return null
    if (meeting.method !== 'manual') return null

    switch (step) {
        case undefined:
            return (
                <div className="zoom-config manual">
                    <FontAwesomeIcon icon={['fas', 'spinner']} spin size="3x" />
                </div>
            )

        case 0:
            return (
                <div className="zoom-config step0 manual">
                    <FontAwesomeIcon className="icon" icon={['fal', 'comment-plus']} />
                    <h4 className="title">
                        {t('teamSettings:content.virtual-classroom.zoom-manual.steps.0.header')}
                    </h4>
                    <span className="global-text">
                        {t('teamSettings:content.virtual-classroom.zoom-manual.steps.0.desc')}
                    </span>
                    <button className="btn-yellow" onClick={() => setStep(1)}>
                        {t(
                            'teamSettings:content.virtual-classroom.zoom-manual.steps.0.next-button-label'
                        )}
                    </button>
                </div>
            )

        case 1:
        case 1.5:
            return (
                <div className="zoom-config step1 manual">
                    <FontAwesomeIcon className="icon" icon={['fal', 'comment-plus']} />
                    <h4 className="title">
                        {t('teamSettings:content.virtual-classroom.zoom-manual.steps.1.header')}
                    </h4>
                    <span className="global-text">
                        {t('teamSettings:content.virtual-classroom.zoom-manual.steps.1.desc')}
                    </span>
                    <div className="selector responsive">
                        <input
                            className="form-input"
                            placeholder="Ej: 935 5371 3070"
                            value={formatId(general)}
                            onChange={onChangeInputGeneral}
                        />
                        <button
                            className="btn-yellow"
                            onClick={() => {
                                createMeeting()
                                ReactGA.event({
                                    category: 'Meetings',
                                    action: 'MeetingSaved',
                                    label: 'Zoom',
                                    dimension1: 'platform',
                                    dimension2: hq?.name.toLowerCase(),
                                    dimension3: course?.name.toLowerCase(),
                                    dimension4: subject?.name.toLowerCase(),
                                    dimension6: 'Save',
                                    dimension7: role,
                                    dimension9: team?.algorithm
                                        ? team?.algorithm?.algorithmType
                                        : 'no algorithm available',
                                })
                            }}
                            disabled={!general || 10 > general.length || general.length > 11}
                        >
                            {step === 1 ? (
                                t(
                                    'teamSettings:content.virtual-classroom.zoom-manual.steps.1.next-button-label'
                                )
                            ) : (
                                <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                            )}
                        </button>
                    </div>
                </div>
            )

        case 2:
            return (
                <div className="zoom-config step2 manual">
                    <h3 className="title">
                        {t('teamSettings:content.virtual-classroom.zoom-manual.steps.2.header')}
                    </h3>
                    <span className="description">
                        {t('teamSettings:content.virtual-classroom.zoom-manual.steps.2.desc')}
                        <b> {formatId(meeting.general || '')}</b>
                    </span>
                    <div className="action-buttons">
                        <button className="btn-yellow" onClick={() => setStep(1)}>
                            {t(
                                'teamSettings:content.virtual-classroom.zoom-manual.steps.2.prev-button-label'
                            )}
                        </button>
                    </div>
                </div>
            )
    }
}

export default ZoomManualConfig
