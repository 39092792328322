import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import baseTheme from '../../../themes/baseTheme'
import { IBadgeProps } from './Badge.component'

export const BadgeIcon = styled(FontAwesomeIcon)<IBadgeProps>(({ backgroundColor, fontSize }) => {
    return css`
        align-items: center;
        background-color: ${backgroundColor ? backgroundColor : baseTheme.colors['ff8900']};
        border-radius: 50%;
        border: 3px solid ${baseTheme.colors['ffffff']};
        color: ${baseTheme.colors['ffffff']};
        display: flex;
        font-size: ${fontSize ? fontSize : '13px'};
        height: 28;
        padding: 4px;
        width: 28;
    `
})
