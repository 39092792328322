import { ApiHCA } from './api.hca'
import { ApiReports } from '../pages/Team/api-reports'
import { ApiSignUp } from './api.signup'
import Axios from 'axios'

export const setNewAccessToken = (token: string | null) => {
    if (!token) return removeToken()
    if (`Bearer ${token}` === ApiSignUp.defaults.headers.common.Authorization) return

    ApiSignUp.defaults.headers.common.Authorization = `Bearer ${token}`
    ApiHCA.defaults.headers.common.Authorization = `Bearer ${token}`
    ApiReports.defaults.headers.common.Authorization = `Bearer ${token}`
    Axios.defaults.headers.common.Authorization = `${token}`

    localStorage.setItem('token', token)
}

const removeToken = () => {
    ApiSignUp.defaults.headers.common.Authorization = false
    ApiHCA.defaults.headers.common.Authorization = false
    ApiReports.defaults.headers.common.Authorization = false
    Axios.defaults.headers.common.Authorization = false

    localStorage.removeItem('token')
}
