import { useQuery } from 'react-query'
import { getNPSGraph } from '../api-reports'
import { useReports } from '../Context/ReportsContext'

export const useSurveys = () => {
    const { reportQuery, enabled, staleTime } = useReports()
    const { courseId, cohorts, ...restQuery } = reportQuery

    const queryKey = [
        courseId,
        'Reports',
        'NPS',
        ' Graph',
        `Cohorts: ${cohorts?.join()}`,
        restQuery,
    ]
    const { data: graphNPS, ...rest } = useQuery(queryKey, () => getNPSGraph(reportQuery), {
        enabled,
        staleTime,
    })

    return { graphNPS, ...rest }
}
