/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react'
import { UIContext } from '../../../context/UIContext'
import { useTranslation } from 'react-i18next'
import Img from '../../../assets/images/join_team_img.png'

const MainLayout = React.lazy(() => import('../../../layouts/MainLayout'))
const WizardModal = React.lazy(() => import('../../../components/WizardModal/Wizard'))

const TeamsCreateView = ({ user }) => {
    const { t } = useTranslation(['teamDashboard'])
    const { titleCaseFn } = useContext(UIContext)
    const [modal, setModal] = useState(false)

    return (
        <MainLayout
            ruta={t('teamDashboard:no-teams.title').replace('$(name)', titleCaseFn(user.name))}
        >
            <div className="teams-create-container">
                <div className="content">
                    <img src={Img} alt="" />
                    <h3>
                        {t('teamDashboard:no-teams.title').replace(
                            '$(name)',
                            titleCaseFn(user.name)
                        )}
                    </h3>
                    <p className="global-text">{t('teamDashboard:no-teams.desc')}</p>
                    <button className="btn-yellow" onClick={() => setModal(true)}>
                        {t('teamDashboard:no-teams.create')}
                    </button>
                </div>
            </div>
            <WizardModal open={modal} onClose={() => setModal(false)} />
        </MainLayout>
    )
}

export default TeamsCreateView
