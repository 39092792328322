import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ManageMeetingCustomStyles } from './ManageMeeting.modal'
import { MoveToGeneralModal } from './MoveToGeneral.modal'
import { useTeam } from '../../../Hooks/useTeam'
import { useTimeline } from '../../../Hooks/useTimeline'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'

export const MoveToGeneralButton = () => {
    const { t } = useTranslation('teamMonitor')
    const { setModal } = useUIContext()
    const { nextEvent } = useTimeline()
    const { courseID, hasGeneral } = useTeam()

    if (!courseID) return null

    return (
        <button
            className="primary"
            disabled={nextEvent?.room === 'teams' || !hasGeneral}
            style={{ fontSize: nextEvent ? '0.9rem' : '1rem' }}
            onClick={() =>
                setModal({
                    content: <MoveToGeneralModal />,
                    customStyles: ManageMeetingCustomStyles,
                })
            }
        >
            <FontAwesomeIcon icon={['fas', 'circle-arrow-left']} />
            {nextEvent ? t("Move to general's room now") : t("Move to general's room")}
        </button>
    )
}
