import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import ManagerContext from '../../../../../context/ManagerContext'
import { AssignationItem } from '../../../../../context/SubjectContext'

const Rubric = ({ student }: { student: AssignationItem }) => {
    const maxStars = 4
    const [stars, setStars] = useState<number | undefined>()
    const [sending, setSending] = useState(false)
    const { t } = useTranslation(['teamManager'])
    const { onRubric } = useContext(ManagerContext)

    // Check if everythings is OK
    const onClick = async () => {
        if (stars === undefined) return
        try {
            setSending(true)
            await onRubric(student._profileId, stars)
        } finally {
            setStars(undefined)
            setSending(false)
        }
    }

    return (
        <div className="user-action-rubrica">
            <div className="average-block">
                {!sending ? (
                    <span>
                        {student.rubric !== undefined
                            ? Math.round((student.rubric.average * 3 - 2) * 10) / 10
                            : '-'}
                    </span>
                ) : (
                    <span>
                        <FontAwesomeIcon className="icon" icon={['fas', 'spinner']} spin />
                    </span>
                )}
            </div>
            <div className="stars-block">
                {[...Array(maxStars)].map((_, e) => (
                    <FontAwesomeIcon
                        key={e + 1}
                        className={`star-icon ${stars && e < stars ? 'active' : 'inactive'}`}
                        icon={[stars && e < stars ? 'fas' : 'fal', 'star']}
                        onClick={() => setStars(e !== 0 ? e + 1 : stars !== 1 ? 1 : undefined)}
                    />
                ))}
            </div>
            <div className="qualify-block">
                <button className="btn-qualify" disabled={!stars && !sending} onClick={onClick}>
                    {t('teamManager:actions.rubric.qualify')}
                </button>
            </div>
        </div>
    )
}

export default Rubric
