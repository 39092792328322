import { ConfirmMeetingChange } from './Overview/ManageMeeting/ConfirmMeetingChange'
import DayJS from '../../../helpers/DayJS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ManageMeetingCustomStyles } from './Overview/ManageMeeting/ManageMeeting.modal'
import i18n from '../../../i18n'
import { useTeam } from '../Hooks/useTeam'
import { useTimeline } from '../Hooks/useTimeline'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../context/UIContext'

export const FinishMeetingButton = () => {
    const { courseID } = useTeam()
    const { t } = useTranslation('teamMonitor')
    const { newTimelineEvent } = useTimeline()
    const { setModal } = useUIContext()

    if (!courseID) return null

    const duration = [1, 'minute'] as const
    const diff = DayJS()
        .add(...duration)
        .diff(DayJS())
    const time = DayJS.duration(diff).locale(i18n.resolvedLanguage).humanize(true)

    return (
        <button
            className="accent"
            onClick={() =>
                setModal({
                    content: (
                        <ConfirmMeetingChange
                            icon={
                                <FontAwesomeIcon
                                    icon={['far', 'power-off']}
                                    style={{ color: '#FF647C' }}
                                />
                            }
                            text={t('The current meetings will be ended {{ time }}.', { time })}
                            onConfirm={() =>
                                newTimelineEvent({
                                    courseID,
                                    room: null,
                                    date: DayJS()
                                        .add(...duration)
                                        .toISOString(),
                                }).then(() => setModal({ content: null }))
                            }
                        />
                    ),
                    customStyles: ManageMeetingCustomStyles,
                })
            }
        >
            <FontAwesomeIcon icon={['far', 'power-off']} />
            {t('End all meetings')}
        </button>
    )
}
