import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../context/UserContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvailableLngs } from '../i18n'

export interface Props {
    setDropdownShow: (dropdownShow: boolean) => unknown
}

const Switch = ({ setDropdownShow }: Props) => {
    const { i18n } = useTranslation(['profile', 'header', 'teamDashboard'])
    const { setUser } = useContext(UserContext)

    const switchLang = async (lang: AvailableLngs) => {
        i18n.changeLanguage(lang)
        setDropdownShow(false)
        await setUser({ language: lang })
    }

    return (
        <ul className="ul-especial">
            <li className={i18n.language === 'en' ? 'active' : ''}>
                <button onClick={() => switchLang('en')}>
                    <label>English </label>
                    <FontAwesomeIcon className="icon" icon={['fal', 'check-circle']} />
                </button>
            </li>
            <li className={i18n.language === 'es' ? 'active' : ''}>
                <button onClick={() => switchLang('es')}>
                    <label>Español </label>
                    <FontAwesomeIcon className="icon" icon={['fal', 'check-circle']} />
                </button>
            </li>
            <li className={i18n.language === 'pt' ? 'active' : ''}>
                <button onClick={() => switchLang('pt')}>
                    <label>Português </label>
                    <FontAwesomeIcon className="icon" icon={['fal', 'check-circle']} />
                </button>
            </li>
        </ul>
    )
}

export default Switch
