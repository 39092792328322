import React, { useContext } from 'react'
import MainLayout from '../../../layouts/MainLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import AvatarEgg from '../../../components/Styled/AvatarEgg'
import UIContext from '../../../context/UIContext'
import styled from 'styled-components'

export interface TeamProfileViewProps {
    name: string
    email: string
    status: string
    photo: string | undefined
}

const StyledParagraph = styled.p({
    fontSize: '1.1rem',
})

const TeamProfileView = (props: TeamProfileViewProps) => {
    const { name, email, photo, status } = props
    const { t } = useTranslation(['profile'])
    const { titleCaseFn } = useContext(UIContext)

    return (
        <MainLayout ruta={name}>
            <div className="student-detail-container">
                <div className="student-heading-container">
                    <div className="navigation">
                        <button className="action-link" onClick={() => window.history.back()}>
                            <FontAwesomeIcon className="icon" icon={['fal', 'chevron-left']} />
                            <span>{t('profile:back')}</span>
                        </button>
                    </div>

                    <div className="student-data responsive">
                        <div className="avatar-container">
                            <AvatarEgg size={210} src={photo} />
                        </div>
                        <div className="rest-info">
                            <h2>{titleCaseFn(name)}</h2>
                            {status !== undefined && status && (
                                <div className="status-msg-block">
                                    <div className="data status-msg global-text">{status}</div>
                                </div>
                            )}
                            <StyledParagraph>{email}</StyledParagraph>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default TeamProfileView
