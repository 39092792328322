import { useEffect, useState } from 'react'

import DayJS from '../../../helpers/DayJS'

export const Countdown = ({ duration, onFinish }: Props) => {
    const [time, setTime] = useState<number | null>(duration)

    useEffect(() => {
        if (!time) return

        const interval = setInterval(() => setTime((prev) => (prev ? prev - 1000 : prev)), 1000)

        if (time <= 0) {
            clearInterval(interval)
            onFinish?.()
            setTime(null)
        }

        return () => clearInterval(interval)
    }, [onFinish, time])

    if (!time || time <= 0) return <b>00:00</b>

    const format = time > 3_600_000 ? 'HH:mm:ss' : 'mm:ss'

    return <b>{DayJS(time).format(format)}</b>
}

interface Props {
    duration: number
    onFinish?: () => void
}
