import React, { useContext, useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { AttendanceStatus } from '../../../../../hooks/ApiHooks/useAttendance'
import { AssignationItem, SubjectContext } from '../../../../../context/SubjectContext'
import ManagerContext from '../../../../../context/ManagerContext'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { BtnIcon } from '../../../../../components/Buttons/BtnCircular'
import ReactGA from 'react-ga'
interface AttendanceIconProps {
    sending?: AttendanceStatus
    attendanceType?: AttendanceStatus
    icon: IconName
    active?: boolean
    onSave: () => unknown
}
export const AttendanceIcon = ({
    sending,
    attendanceType,
    icon,
    active,
    onSave,
}: AttendanceIconProps) => {
    return sending === attendanceType ? (
        <FontAwesomeIcon className="icon saving" icon={['fas', 'spinner']} spin />
    ) : (
        <button
            className={`${attendanceType} ${active ? ' active' : ''}`}
            disabled={sending !== undefined}
            onClick={onSave}
        >
            <FontAwesomeIcon
                className={`icon ${attendanceType}`}
                icon={[active ? 'fas' : 'fal', icon]}
            />
        </button>
    )
}

const Attendance = ({ student }: { student: AssignationItem }) => {
    // TODO: Why is onJustify isn't used?
    const { _profileId } = student
    const { attendance: completeAttendance } = useContext(SubjectContext)
    const attendance = useMemo(() => completeAttendance?.(_profileId), [
        completeAttendance,
        _profileId,
    ])
    const [justification, setJustification] = useState(attendance?.comment || '')
    const [sendingJustification, setSendingJustification] = useState<boolean>(false)
    const [sendingAttendance, setSendingAttendance] = useState<AttendanceStatus | undefined>(
        undefined
    )
    const { t } = useTranslation(['teamManager'])
    const { onAttendance } = useContext(ManagerContext)

    const onJustificationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setJustification(event.target.value)
    }

    const onSaveAttendance = async (attendance: AttendanceStatus) => {
        try {
            setSendingAttendance(attendance) // The second argument contains the AttendanceStatus
            await onAttendance(_profileId, attendance, justification)
        } finally {
            setSendingAttendance(undefined)
        }
    }

    const onSaveJustification = async (justification: string) => {
        if (!attendance) return
        try {
            setSendingJustification(true) // The second argument contains the AttendanceStatus
            ReactGA.event({
                category: 'Engagement',
                action: ' Send Comments',
                label: 'Send comments for a specific student',
            })
            onAttendance && (await onAttendance(_profileId, attendance?.status, justification))
        } finally {
            setSendingJustification(false)
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'Enter':
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore Deselect the element
                event.target.blur()
                event.preventDefault()
                onSaveJustification(justification)
                break
            default:
                return
        }
    }

    return (
        <div className="user-action-attendance">
            <div className="icons-block">
                <AttendanceIcon
                    attendanceType={AttendanceStatus.PRESENT}
                    sending={sendingAttendance}
                    icon={'user-check'}
                    active={attendance && attendance.status === AttendanceStatus.PRESENT}
                    onSave={() => onSaveAttendance(AttendanceStatus.PRESENT)}
                />
                <AttendanceIcon
                    attendanceType={AttendanceStatus.DELAY}
                    sending={sendingAttendance}
                    icon={'user-clock'}
                    active={attendance && attendance.status === AttendanceStatus.DELAY}
                    onSave={() => onSaveAttendance(AttendanceStatus.DELAY)}
                />
                <AttendanceIcon
                    attendanceType={AttendanceStatus.ABSENT}
                    sending={sendingAttendance}
                    icon={'user-slash'}
                    active={attendance && attendance.status === AttendanceStatus.ABSENT}
                    onSave={() => onSaveAttendance(AttendanceStatus.ABSENT)}
                />
            </div>
            <div className="justify-block">
                <input
                    type="text"
                    className="form-input-no-card"
                    placeholder={t('teamManager:actions.attendance.justify')}
                    value={justification}
                    onChange={onJustificationChange}
                    onKeyDown={handleKeyDown}
                />
                <BtnIcon
                    toolTips={t('teamManager:actions.attendance.justify')}
                    icon={!sendingJustification ? ['fal', 'paper-plane'] : ['fas', 'spinner']}
                    iconOver="paper-plane"
                    onClick={() => onSaveJustification(justification)}
                    isActive={sendingJustification}
                    iconProps={{
                        icon: !sendingJustification ? ['fal', 'paper-plane'] : ['fas', 'spinner'],
                        spin: sendingJustification,
                    }}
                />
            </div>
        </div>
    )
}

export default Attendance
