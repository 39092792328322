import { Dispatch, SetStateAction } from 'react'

import styled from 'styled-components'

const MAX_LENGTH = 80

export const TextMessage = ({ state: [message, setMessage] }: Props) => (
    <TextMessageStyled>
        <StyledTextArea
            value={message}
            maxLength={MAX_LENGTH}
            onChange={({ target: { value } }) => setMessage(value)}
            onKeyDown={(event) => {
                if (!event.shiftKey && event.key === 'Enter') {
                    event.preventDefault()
                    const { form } = event.currentTarget
                    if (form)
                        form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
                }
            }}
        />
        <span className="char-count">
            {message.length}/{MAX_LENGTH}
        </span>
    </TextMessageStyled>
)

interface Props {
    state: [string, Dispatch<SetStateAction<string>>]
}

const TextMessageStyled = styled.div`
    position: relative;
    height: 171px;
    .char-count {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
`

const StyledTextArea = styled.textarea`
    flex: 1;
    width: 100%;
    height: 100%;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    resize: none;
    background-color: #f4f4f4;
    border-radius: 0.5rem;
    padding-bottom: 15px;
    &:focus {
        outline: none;
    }
`
