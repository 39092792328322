import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonExport } from './ButtonExport'

export const ReportContainer = ({
    header,
    content,
    className,
}: {
    header: {
        title: string
        values?: number
        onClick?: () => void
        disabled?: boolean
    }
    // eslint-disable-next-line no-undef
    content: JSX.Element
    className?: string
}) => {
    const { t } = useTranslation('teamReports')
    const { title, values, disabled, onClick } = header
    return (
        <div className="chart">
            <header className="report-header">
                <h4>
                    {t(title)} {values ?? ''}
                </h4>
                {onClick && disabled !== undefined && (
                    <ButtonExport onClick={onClick} disabled={disabled} />
                )}
            </header>
            <div className={className ?? 'report-display'}>{content}</div>
        </div>
    )
}
