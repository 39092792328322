import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
// Libraries
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useLocation } from 'wouter'

// Components
import AvatarEgg from '../../components/Styled/AvatarEgg'
import { useUser } from '../../context/UserContext'
import { useGAEvents } from '../../hooks/useGAEvents'
import { useIntercom } from '../../hooks/useIntercom'
import { useAuth0 } from '@auth0/auth0-react'

import eggLogo from '../../assets/images/egg_no_shadow.svg'
import intercomLogo from '../../assets/images/intercom_btn.png'

export const useNavBarItems = (mainLinks: MenuProps[]) => {
    const { logout: logoutFromAuth0 } = useAuth0()

    const { t } = useTranslation('navbar')
    const [location, setLocation] = useLocation()
    const queryClient = useQueryClient()

    const { user } = useUser()
    const { openChat, Client } = useIntercom()
    const { logout } = useUser()
    const { sendGA } = useGAEvents()

    const [activeItem, setActiveItem] = useState<ItemMenuProps>()
    const [visibleMenu, setVisibleMenu] = useState(false)

    const states = useMemo(
        () => ({
            activeItem,
            setActiveItem,
            visibleMenu,
            setVisibleMenu,
        }),
        [activeItem, visibleMenu]
    )

    const isActive = useCallback(
        (label: string, url?: string) =>
            activeItem?.label === label || (!!url && !!location.includes(url)),
        [activeItem?.label, location]
    )

    const isInCourse = location.includes('/admin')

    const topMenu: MenuProps[] = [
        {
            logo: eggLogo,
            name: t('Home'),
            url: '/dashboard',
        },
    ]

    const bottomMenu: MenuProps[] = [
        {
            activeIcon: ['fal', 'users'],
            icon: ['fal', 'users'],
            name: t('My profile'),
            url: '/profile',
            activeSvgIcon: <AvatarEgg size={{ mobile: 25, desktop: 30 }} />,
            svgIcon: <AvatarEgg size={{ mobile: 25, desktop: 30 }} />,
        },
        {
            activeIcon: ['far', 'bell'],
            icon: ['far', 'bell'],
            name: t('Notifications'),
            url: '/notifications',
            onClick: () => {
                sendGA({ category: 'Team Management', action: 'CheckNotifications' })
                setLocation('/notifications')
            },
            notifications: user?.notificationsCount ?? 0,
        },
        {
            activeIcon: ['fas', 'message-question'],
            icon: ['far', 'message-question'],
            svgIcon: <img src={intercomLogo} width={20} alt="Intercom Button" />,
            hidden: !Client,
            name: t('Help Center'),
            onClick: openChat,
            neverActiveMobile: true,
        },
        {
            activeIcon: ['fal', 'arrow-right-from-bracket'],
            icon: ['fal', 'arrow-right-from-bracket'],
            name: t('Log out'),
            onClick: () => {
                logout().then(() => {
                    queryClient.invalidateQueries()
                    setLocation('/')
                })
                logoutFromAuth0({ logoutParams: { returnTo: window.location.origin } })
            },
            neverActiveMobile: true,
        },
    ]

    const menuItems = (menu: MenuProps[]): ItemMenuProps[] =>
        menu.map(({ name: pageName, url, onClick, ...rest }) => {
            return {
                label: pageName,
                onClick: onClick ?? (url ? () => setLocation(url) : undefined),
                active: isActive(pageName, url),
                url,
                ...rest,
            }
        })

    useEffect(() => {
        const allMenuItems = [
            ...menuItems(topMenu),
            ...menuItems(bottomMenu),
            ...menuItems(mainLinks),
        ]
        allMenuItems.forEach((item) => {
            if (item.url?.includes(location)) {
                setActiveItem(item)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return {
        topMenuItems: menuItems(topMenu),
        bottomMenuItems: menuItems(bottomMenu),
        courseMenuItems: mainLinks && isInCourse ? menuItems(mainLinks) : [],
        states,
    }
}

export default useNavBarItems

interface MenuBaseProps {
    logo?: string
    activeIcon?: IconProp
    icon?: IconProp
    hidden?: boolean
    svgIcon?: ReactNode
    activeSvgIcon?: ReactNode
    onClick?: () => void
    neverActiveMobile?: boolean
    url?: string
    active?: boolean
    notifications?: number
}

export type ItemMenuProps = MenuBaseProps & {
    label: string
}

export type MenuProps = MenuBaseProps & {
    name: string
}
