import React from 'react'
import TimesFacilitator from '../../../../../assets/images/icons/medal_black.svg'
import PulseReceived from '../../../../../assets/images/icons/pulses_received.svg'
import PulseGranted from '../../../../../assets/images/icons/pulses_granted.svg'

import { StudentItemProps } from '../StudentItem'
import { useTranslation } from 'react-i18next'
import { BtnCircular, BtnCircularProps } from '../../../../../components/Buttons/BtnCircular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Stats = ({ stats, isExpertMentor }: StudentItemProps) => {
    const { t } = useTranslation('teamManager')
    if (!stats) return null

    const { votes, timesFacilitator } = stats
    const { given, received } = votes

    return (
        <div className="user-action-stats">
            <StatIcon
                value={received}
                image={PulseReceived}
                toolTips={t('stats.recognitions.received')}
                size={'25px'}
            />
            <StatIcon
                value={given}
                image={PulseGranted}
                toolTips={t('stats.recognitions.given')}
                size={'25px'}
            />
            {!isExpertMentor && (
                <>
                    <StatIcon
                        value={timesFacilitator}
                        image={TimesFacilitator}
                        toolTips={t('stats.times-facilitator')}
                        size={'25px'}
                    />
                    {/* {algorithmType !== 'hca1' && (
                        <StatIcon
                            value={timesMentor}
                            icon={['fas', 'graduation-cap']}
                            toolTips={t('stats.times-mentor')}
                            size={'25px'}
                        />
                    )} */}
                </>
            )}
        </div>
    )
}

const StatIcon = (props: {
    value?: number | string
    image?: string
    toolTips: string
    icon?: BtnCircularProps['icon']
    size?: string
}) => {
    if (props.value === undefined) return null
    return (
        <div className="stat-unit vl">
            {props.image && (
                <img
                    src={props.image}
                    alt={props.toolTips}
                    style={{
                        height: props.size ? props.size : 'auto',
                    }}
                />
            )}
            {props.icon && (
                <FontAwesomeIcon
                    icon={props.icon}
                    style={{
                        height: props.size ? props.size : 'auto',
                    }}
                />
            )}
            <BtnCircular
                toolTips={props.toolTips}
                style={{
                    border: 0,
                    width: 'auto',
                    marginLeft: 7,
                    fontSize: 'unset',
                }}
            >
                <span style={{ minWidth: 40, justifyContent: 'center', display: 'flex' }}>
                    {props.value}
                </span>
            </BtnCircular>
        </div>
    )
}
