import React, { useEffect, useContext } from 'react'
import Config from '../../config'
import Axios from 'axios'
import { useLocation } from 'wouter'
import { UIContext } from '../../context/UIContext'
import { UserContext } from '../../context/UserContext'

import { ROLES } from '../../constants'
export interface IUserSignup {
    name: string
    lastname: string
    email: string
    password: string
    password_confirmation: string
}

export interface POSTEnrollResponse {
    _profileId?: string
    role?: ROLES
    course?: {
        _id: string
        name: string
    }
    token?: string
    user?: {
        _id: string
    }
}

export interface ICourse {
    course: { id: string; name: string }
    hq: { id: string; name: string }
}
export function useEnroll(
    mode: string,
    token: string,
    // eslint-disable-next-line no-console
    onError = console.error
): [
    ICourse | null | undefined,
    // Already enrolled | Can Enroll
    undefined | ((user?: IUserSignup) => void),
    (user?: IUserSignup) => void //Force enroll
] {
    const [course, setCourse] = React.useState<ICourse | undefined | null>()
    const [canEnroll, setCanEnroll] = React.useState<boolean | undefined>()
    const { setInfoBlock } = useContext(UIContext)

    const { user: currentUser, setUserButNotSaveIt, setToken, login, logout } = useContext(
        UserContext
    )

    const [, setLocation] = useLocation()

    useEffect(
        function getCourse() {
            if (!token) return
            Axios.get(Config.API + '/user/enroll/token/' + token + '/short')
                .then((response) => {
                    setCourse(response.data)
                    setCanEnroll(!response.data.isEnrolled)
                })
                .catch((error) => {
                    onError?.(error)
                    setCourse(null)
                })
        },
        [token, onError]
    )

    const enroll = async (user?: IUserSignup) => {
        try {
            if (!user) {
                // Type short is a flag to support short links instead of the previus long long long token
                const { data } = await Axios.post<POSTEnrollResponse>(
                    Config.API + '/user/enroll/' + mode,
                    {
                        token: token,
                        type: 'short',
                    }
                )
                data.token && setToken(data.token)
                //data.role && setRole(data.role);
                data.user &&
                    currentUser &&
                    setUserButNotSaveIt({ ...currentUser, _id: data.user._id })
                setLocation('/dashboard?rtt=false')
            } else {
                // Enroll New user
                // eslint-disable-next-line no-console
                await logout().catch(console.error)
                //DANGER HERE pisa la comprobacion de password_confirmation
                user.password_confirmation = user.password
                // Type short is a flag to support short links instead of the previus long long long token
                await Axios.post(Config.API + '/user/signup/' + mode, {
                    ...user,
                    token: token,
                    type: 'short',
                })
                await login(user.email, user.password, true)
                setLocation('/signup/verify')
            }
        } catch (error: { code: string; message: string } | unknown) {
            // eslint-disable-next-line no-console
            console.error(error)
            //@ts-expect-error code is not in the interface
            setInfoBlock(['error', error?.message ? error?.message : 'Ha ocurrido un error!'])
            setTimeout(() => setInfoBlock([]), 1500)
        }
    }

    return [course, canEnroll === false ? undefined : enroll, enroll]
}
