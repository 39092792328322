import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StudentImportContainer = () => {
    return (
        <MainLayout>
            <div className="student-import-container">
                <div className="header">
                    <h2>Carga masiva</h2>
                </div>
                <div className="content">
                    <div className="steps-container responsive">
                        <div className="step">
                            <FontAwesomeIcon className="icon" icon={['fal', 'arrow-to-bottom']} />
                            <span className="title">PASO 1</span>
                            <p>Descarga la plantilla modelo a tu dispositivo</p>
                            <button className="btn-yellow">Descargar</button>
                        </div>
                        <div className="step">
                            <FontAwesomeIcon className="icon" icon={['fal', 'th-list']} />
                            <span className="title">PASO 2</span>
                            <p>
                                Abre la plantilla descargada y rellena los campos con nombre,
                                apellido y número de documento de los alumnos que desees cargar.
                            </p>
                        </div>
                        <div className="step">
                            <FontAwesomeIcon className="icon" icon={['fal', 'cloud-upload']} />
                            <span className="title">PASO 3</span>
                            <p>
                                Presiona sobre le botón “cargar” y selecciona la plantilla
                                previamente descargada y modificada.
                            </p>
                            <button className="btn-yellow">Cargar</button>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default StudentImportContainer
