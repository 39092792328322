import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/globals/_variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StrongUnit from '../Text/StrongUnit'
import { useTranslation } from 'react-i18next'
import { ToolTipSmall } from '../../components/Styled/ToolTipSmall'
import MedalBlack from '../../assets/images/icons/medal_black.svg'
import PulsesGranted from '../../assets/images/icons/pulses_granted.svg'
import PulsesReceived from '../../assets/images/icons/pulses_received.svg'

const Stats = styled.div`
    display: flex;
    .stats-items {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        border-right: 1px solid ${colors.borders};
        &:last-child {
            border-right: none;
        }
        .icon {
            color: ${colors.eggColor};
            font-size: 1.8rem;
            margin-right: 7px;
        }
        .value {
            font-weight: bold;
            font-size: 2.5rem;
        }
    }
`

interface IStats {
    stats: {
        rubricResult: number
        votes: number
        timesFacilitator: number
        givenVotes: number
    }
    rubricEnabled: boolean
}

const StatsBlock = ({ stats, rubricEnabled }: IStats) => {
    const { t } = useTranslation(['teamDashboard'])
    const [statsTips, setStatsTips] = useState(false)
    const [facilitatorTips, SetfacilitatorTips] = useState(false)
    const [voteTips, setVoteTips] = useState(false)
    const [voteReceivedTips, setVoteReceivedTips] = useState(false)

    return (
        <Stats>
            {rubricEnabled && (
                <div className="stats-items">
                    <FontAwesomeIcon
                        onMouseOver={() => setStatsTips(true)}
                        onMouseLeave={() => setStatsTips(false)}
                        className="icon"
                        icon={['fas', 'analytics']}
                    />
                    <StrongUnit>
                        {stats?.rubricResult ? Math.round(30 * stats?.rubricResult - 20) / 10 : '-'}
                    </StrongUnit>
                    {statsTips && <ToolTipSmall> {t('header.stats')} </ToolTipSmall>}
                </div>
            )}
            <div className="stats-items">
                <img
                    onMouseOver={() => setVoteTips(true)}
                    onMouseLeave={() => setVoteTips(false)}
                    className="icon"
                    src={PulsesReceived}
                />
                <StrongUnit>{stats?.votes ? stats?.votes : '-'}</StrongUnit>
                {voteTips && (
                    <ToolTipSmall position={{ top: 40, left: -5 }}>
                        {' '}
                        {t('header.votes')}{' '}
                    </ToolTipSmall>
                )}
            </div>
            <div className="stats-items">
                <img
                    onMouseOver={() => setVoteReceivedTips(true)}
                    onMouseLeave={() => setVoteReceivedTips(false)}
                    className="icon"
                    src={PulsesGranted}
                />
                <StrongUnit>{stats?.givenVotes ? stats?.givenVotes : '-'}</StrongUnit>
                {voteReceivedTips && (
                    <ToolTipSmall position={{ top: 40, left: -5 }}>
                        {' '}
                        {t('header.votes-granted')}{' '}
                    </ToolTipSmall>
                )}
            </div>
            <div className="stats-items">
                <img
                    onMouseOver={() => SetfacilitatorTips(true)}
                    onMouseLeave={() => SetfacilitatorTips(false)}
                    className="icon"
                    src={MedalBlack}
                />
                <StrongUnit>{stats?.timesFacilitator ? stats?.timesFacilitator : '-'}</StrongUnit>
                {facilitatorTips && <ToolTipSmall> {t('header.facilitator')} </ToolTipSmall>}
            </div>
        </Stats>
    )
}

export default StatsBlock
