import React, { useState } from 'react'
//import { useTranslation } from "react-i18next"
//import { ROLES } from '../../../constants';
//import { SubjectContext } from "../../../context/SubjectContext"
//import UIContext from '../../../context/UIContext';
import MainLayout from '../../../layouts/MainLayout'
import { WizardModalAddStudent } from '../../../components/WizardModalAddUser/WizardModalAddStudent'

// Assets
//import Img from '../../../assets/images/join_team_img.png';

const AddStudentContainter = () => {
    const [modal, setModal] = useState(false)

    return (
        <MainLayout>
            <button className="btn-yellow" onClick={() => setModal(true)}>
                agregar
            </button>
            <WizardModalAddStudent open={modal} onClose={() => setModal(false)} />
        </MainLayout>
    )
}
export default AddStudentContainter
