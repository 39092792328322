import React, {useEffect,useState} from 'react'
import MainLayout from '../../layouts/MainLayout'
import Img from '../../assets/images/img_teams_create.png'
import Link from '../../components/Link'
import LoadingPage from '../Other/LoadingPage'
import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {
    const [spinner, setSpinner] = useState(true);
    const { t } = useTranslation(['errors'])
    useEffect(()=>{
        setTimeout(()=>{setSpinner(false)}, 3000);
    },[])

    return (
        <div>
            {spinner && <LoadingPage></LoadingPage>}
            {   !spinner && 
                <MainLayout ruta={`404`}>
                    <div className="teams-create-container">
                        <div className="content">
                            <img src={Img} alt="" />
                            <h3>{t('errors:error-page-not-found.title')}</h3>
                            <p className="global-text">{t('errors:error-page-not-found.msg')}</p>
                            <Link style={{ display: 'inline-block' }} to="/" className="btn-yellow">
                            {t('errors:error-page-not-found.link')}
                            </Link>
                        </div>
                    </div>
                </MainLayout>
            }
        </div>
        
    )
}

export default NotFoundPage
