// API
import React, { useEffect, useState, useContext, useCallback } from 'react'
import Axios from 'axios'
// Helpers
import useDebounce from '../../hooks/useDebounce'
import Config from '../../config'
// Context
import { WizardContext } from '../../context/WizardContext'
import { UIContext } from '../../context/UIContext'
// Components
import Step3View from './Step3View'

const Step3 = () => {
    const { subjectSelected, setSubjectSelected, nextStep } = useContext(WizardContext)
    const [isNextStepEnabled, setIsNextStepEnabled] = useState(subjectSelected ? true : false)

    const [subjectName, setSubjectName] = useState(subjectSelected ? subjectSelected.name : '')
    const [suggestedSubjects, setSuggestedSubjects] = useState([])
    const [isSearching, setIsSearching] = useState(false)

    const [debouncedSearchTerm] = useDebounce(subjectName, 400)

    // Sets infoBlock error / success
    const { setInfoBlock } = useContext(UIContext)

    const getSubjectByName = useCallback(
        async (name) => {
            if (!subjectSelected) {
                setIsSearching(true)
                try {
                    const response = await Axios.get(Config.API + '/subject?name=' + name)
                    setSuggestedSubjects(response.data)
                    // console.log(response, 'res')
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('eerror subject by name', error)
                    setInfoBlock([
                        'error',
                        error.data.message
                            ? error.data.message
                            : 'Ups! Ocurrió un error obteniendo resultados recomendados.',
                    ])
                } finally {
                    setIsSearching(false)
                    setTimeout(() => {
                        setInfoBlock([])
                    }, 3000)
                }
            }
        },
        [subjectSelected, setInfoBlock]
    )

    useEffect(() => {
        if (debouncedSearchTerm) {
            getSubjectByName(debouncedSearchTerm)
        } else {
            setSuggestedSubjects([])
        }
    }, [debouncedSearchTerm, getSubjectByName])

    const onInputChange = (value) => {
        if (value.length === 0) {
            setSubjectSelected(null)
            setIsNextStepEnabled(false)
        } else {
            setIsNextStepEnabled(true)
        }
        setSubjectName(value)
    }

    const onClickSubject = (subject) => {
        return () => {
            setSuggestedSubjects([])
            setSubjectName(subject.name)
            setSubjectSelected({
                isNew: subject.temporal ? true : false,
                _id: subject._id,
                name: subject.name,
            })
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!subjectSelected) {
            const newSubject = {
                isNew: true,
                name: subjectName,
            }
            setSubjectSelected(newSubject)
        }
        nextStep()
    }

    return (
        <Step3View
            onInputChange={onInputChange}
            suggestedSubjects={suggestedSubjects}
            onClickSubject={onClickSubject}
            subjectName={subjectName}
            subjectSelected={subjectSelected}
            isNextStepEnabled={isNextStepEnabled}
            onSubmit={onSubmit}
            isSearching={isSearching}
        />
    )
}

export default Step3
