// It's easy to remove the prop-types validation. But we should deprecate all typescript components.
/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import WideLayout from '../../../layouts/WideLayout'
import styled from 'styled-components'
import InteractiveTable from '../../../components/Styled/InteractiveTable'
import HeadingStudent from '../HeadingStudent'
import { useTranslation } from 'react-i18next'
import { UIContext } from '../../../context/UIContext'

const TeamManagerStudentView = (props) => {
    const { mainLinks, teamData, ownData, onMeeting, meeting } = props

    const { t } = useTranslation(['teamDetailStudent'])
    const { titleCaseFn } = useContext(UIContext)

    return (
        <WideLayout
            links={mainLinks}
            ruta={`${titleCaseFn(teamData?.hq.name)} > ${titleCaseFn(teamData?.course.name)}`}
        >
            <HeadingStudent
                progress={teamData?.stats.cooperationIndex}
                goal={teamData?.goal.name}
                stats={teamData?.stats}
                owners={teamData?.owners}
                meeting={meeting}
                onMeeting={onMeeting}
                page={'team'}
                tableNumber={ownData?.table?.tableNumber}
            />

            {teamData?.tables.map((item, index) => {
                return (
                    <Table idx={index} key={item.tableNumber}>
                        <h3>{t('table.title', { number: item.tableNumber })}</h3>
                        <InteractiveTable
                            key={index}
                            tableLabel={false}
                            table={item}
                            toolbar={false}
                            showStdVoteBtn={false}
                            voteOff={true}
                        />
                    </Table>
                )
            })}
        </WideLayout>
    )
}

export default TeamManagerStudentView

const Table = styled.div`
    margin: ${(props) => (props.idx === 0 ? '20px 0 50px' : '50px 0')};
    h3 {
        margin-bottom: 20px;
    }
`
