import Axios from 'axios'

const { REACT_APP_AWS_IMAGE_UPLOAD } = process.env

if (!REACT_APP_AWS_IMAGE_UPLOAD)
    throw new Error(
        'REACT_APP_AWS_IMAGE_UPLOAD to be declared on your system environments variables'
    )

const baseURL = `${REACT_APP_AWS_IMAGE_UPLOAD}`
const ApiAWS = Axios.create({
    baseURL,
})

export class AWS {
    static uploadImage = ({ formData, userId, token }: PostUpload): Promise<string | void> =>
        ApiAWS.post<UploadImage>(`/upload/${userId}`, formData, {
            headers: { Authorization: token },
        }).then(({ data }) => data.thumbnailUrl.split('?')[0])
}

export type PostUpload = {
    formData: FormData
    userId: string
    token: string
}

interface UploadImage {
    id: string
    mimeType: string
    originalKey: string
    thumbnailKey: string
    bucket: string
    fileName: string
    originalUrl: string
    thumbnailUrl: string
    originalSize: number
    stage: string
    secretKey: string
}
