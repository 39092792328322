import React, { useEffect, useState, useContext } from 'react'
import Axios from 'axios'

// Components
import TeamDetailStudentView from './TeamDetailStudentView'
import LoadingPage from '../../Other/LoadingPage'

// Context
import { SubjectStudentContext } from '../../../context/SubjectStudentContext'
import { useUIContext, useSubscription } from '../../../context/UIContext'
import { useSocket } from '../../../context/SocketContext'

// Hooks
import { AttendanceStatus } from '../../../hooks/ApiHooks/useAttendance'

// API
import Config from '../../../config'
import { useTranslation } from 'react-i18next'

const TeamDetailStudentContainer = () => {
    const { t } = useTranslation(['teamDetailStudent'])
    const { toastNotification } = useUIContext()
    // State
    const [ready, setReady] = useState<boolean>(false)
    const [unassignated, setUnassignated] = useState<boolean | undefined>(false)
    const [attendance, setAttendance] = useState<AttendanceStatus>(AttendanceStatus.UNSET)
    const { emit } = useSocket()

    const {
        mainLinks,
        professorSchedule,
        setVoteTable,
        votedTable,
        joinMeeting,
        meeting,
        teamId,
        teamData,
        ownData,
        profile,
        voted,
        setVoted,
        fetchMyTeam,
        iframeLink,
        meetingModalShow,
        setMeetingModalShow,
        fetchMeetings,
    } = useContext(SubjectStudentContext)

    // Do this when team has changed
    useSubscription('ui-team-has-updated', () => {
        // markAttendance() // TODO @german checkear.
        toastNotification(t('notification.team-changed'))
        fetchMyTeam?.()
        fetchMeetings?.()
    })

    // Force to update course ID by socket
    useEffect(() => {
        teamData?.course._id &&
            emit?.('user-confirm-attendance', {
                _tableNumber: ownData?.table.tableNumber || null,
                _courseId: teamData?.course._id,
            })
    }, [teamData?.course._id, emit, ownData?.table.tableNumber])

    // Set assignation based on isAssigned property from api
    useEffect(() => {
        if (ownData && teamData) {
            !ownData.isAssigned ? setUnassignated(true) : setUnassignated(false)
            ownData?.attending && setAttendance(ownData.attending)
        }
        if (teamData?.isFirstVisitTeam) {
            try {
                Axios.put(Config.API + `/v2/team/${teamId}/visited`)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('error en la actualización del team -> isFirstVisitTeam')
            }
        }
    }, [ownData, teamData, teamId])

    useEffect(() => {
        setReady(teamId !== undefined && (!!ownData || !!unassignated)) // agregar o quitar ownData !== undefined
    }, [teamId, ownData, unassignated])

    const onVoteTable = setVoteTable

    const onMeeting = (table: number) =>
        joinMeeting?.(table).catch((error) => {
            toastNotification(
                error?.message || error?.data?.message || 'Ha ocurrido un error',
                'error'
            )
        })

    const vote: typeof setVoted =
        setVoted &&
        ((...args) =>
            setVoted(...args).then(
                (response) => response,
                (error) => {
                    toastNotification(
                        error.message ||
                            (error.data && error.data.message) ||
                            'Ups! Ocurrió un error. Intenta nuevamente.',
                        'error'
                    )
                    return error
                }
            ))

    const markAttendance = () => {
        if (window.confirm(t('notification.attendance-confirmation'))) {
            const url = `${Config.API}/profile/present`

            Axios.post(url, {
                _professorScheduleId: professorSchedule?.id,
                _subjectId: teamData?.goal?._id,
            })
                .then(() => {
                    emit?.('user-confirm-attendance', {
                        _tableNumber: ownData?.table.tableNumber || null,
                        _courseId: teamData?.course._id,
                    })
                    fetchMyTeam?.()
                })
                .catch((error) => {
                    const errorMessage =
                        'Error al marcar presente, por favor comunicate el profesor'
                    toastNotification(error.data.message || errorMessage, 'error')
                    // eslint-disable-next-line no-console
                    console.error(errorMessage, error)
                })
        }
    }

    if (!ready) {
        return <LoadingPage />
    }

    return (
        <TeamDetailStudentView
            mainLinks={mainLinks}
            profile={profile}
            professorScheduleId={professorSchedule?.id}
            subjectId={teamData?.goal._id}
            // Meeting
            onMeeting={onMeeting}
            meeting={meeting}
            // Vote Table
            onVoteTable={onVoteTable}
            votedTable={votedTable}
            // Voted student
            voted={voted}
            vote={vote}
            // Fetched data
            unassigned={unassignated}
            ownData={ownData}
            teamData={teamData}
            // Files
            // Attendance
            markAttendance={markAttendance}
            attendance={attendance}
            // Meeting when is zoom_link
            iframeLink={iframeLink}
            meetingModalShow={meetingModalShow}
            setMeetingModalShow={setMeetingModalShow}
        />
    )
}

export default TeamDetailStudentContainer
