import React from 'react'
import { useMeetings, MeetingService } from '../../../../../hooks/ApiHooks/useMeetings'
import { meet, zoom, whatsapp, teams, jitsi } from '../../../../../assets/images/meeting-services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import ToggleBtn from '../../../../../components/Buttons/Toggle'
export interface Props {
    meeting?: ReturnType<typeof useMeetings>[0]
    onChange?: ReturnType<typeof useMeetings>[1]
    clearMeeting?: ReturnType<typeof useMeetings>[7]
    show: boolean
    setShow: (show: boolean) => unknown
}

export const MeetingServiceSelector = ({
    onChange,
    meeting,
    show,
    setShow,
    clearMeeting,
}: Props) => {
    const { t } = useTranslation(['teamSettings'])

    const toogleShow = () => {
        if (show && !!meeting) {
            if (window.confirm(t('teamSettings:content.virtual-classroom.disabled-meeting'))) {
                setShow(!show)
                clearMeeting?.()
            }
        } else {
            setShow(!show)
        }
    }

    return (
        <div className="classroom-config">
            <div className="classroom-heading">
                <h3 className="block-title-classroom" style={{ marginBottom: '20px' }}>
                    {t('teamSettings:content.virtual-classroom.header.header')}
                </h3>
                <ToggleBtn on={show} onClick={() => toogleShow()} />
            </div>

            <span className="block-label">
                {t('teamSettings:content.virtual-classroom.header.title')}
            </span>
            <span className="block-desc">
                {t('teamSettings:content.virtual-classroom.header.desc')}
            </span>
            {show && (
                <div className="service-switch-block">
                    <MeetingServiceSelectorButton
                        onClick={() => {
                            onChange?.(MeetingService.JITSI, 'automatic')
                        }}
                        selected={
                            meeting?.service === MeetingService.JITSI &&
                            meeting.method === 'automatic'
                        }
                        type={'jitsi-auto'}
                        label={t('teamSettings:content.virtual-classroom.methods.automatic')}
                    />
                    <MeetingServiceSelectorButton
                        onClick={() => onChange?.(MeetingService.ZOOM, 'manual')}
                        selected={
                            meeting?.service === MeetingService.ZOOM && meeting.method === 'manual'
                        }
                        type={'zoom-manual'}
                        label={t('teamSettings:content.virtual-classroom.methods.zoom')}
                    />
                    <MeetingServiceSelectorButton
                        onClick={() => onChange?.(MeetingService.MEET)}
                        selected={meeting?.service === MeetingService.MEET}
                        type={'meet'}
                        label={t('teamSettings:content.virtual-classroom.methods.google-meet')}
                    />
                    <MeetingServiceSelectorButton
                        onClick={() => onChange?.(MeetingService.JITSI)}
                        selected={
                            meeting?.service === MeetingService.JITSI && meeting.method === 'manual'
                        }
                        type={'jitsi'}
                        label={t('teamSettings:content.virtual-classroom.methods.jitsi')}
                    />
                    <MeetingServiceSelectorButton
                        onClick={() => onChange?.(MeetingService.TEAMS)}
                        selected={meeting?.service === MeetingService.TEAMS}
                        type={'teams'}
                        label={t('teamSettings:content.virtual-classroom.methods.microsoft-teams')}
                    />
                    <MeetingServiceSelectorButton
                        onClick={() => onChange?.(MeetingService.WHATSAPP)}
                        selected={meeting?.service === MeetingService.WHATSAPP}
                        type={'whatsapp'}
                        label={t('teamSettings:content.virtual-classroom.methods.whatsapp')}
                    />
                </div>
            )}
        </div>
    )
}

interface ButtonProps {
    onClick?: () => unknown
    selected?: boolean
    type:
        | 'meet'
        | 'zoom-auto'
        | 'zoom-manual'
        | 'teams'
        | 'whatsapp'
        | 'jitsi'
        | 'zoom_list'
        | 'jitsi-auto'
    label?: string
}
const MeetingServiceSelectorButton = ({ onClick, selected, type, label }: ButtonProps) => {
    const getIcon = React.useCallback((t: ButtonProps['type']) => {
        switch (t) {
            case 'teams':
                return teams
            case 'jitsi-auto':
            case 'jitsi':
                return jitsi
            case 'meet':
                return meet
            case 'zoom_list':
                return zoom
            case 'zoom-auto':
            case 'zoom-manual':
                return zoom
            case 'whatsapp':
                return whatsapp
        }
    }, [])

    return (
        <div onClick={onClick} className={`egg-card service-item ${selected ? '' : 'in'}active`}>
            <FontAwesomeIcon
                className={`power ${selected ? 'active' : ''}`}
                icon={['fas', 'power-off']}
            />
            <img src={getIcon(type)} alt={type} />
            {label && <span>{label}</span>}
        </div>
    )
}

export default MeetingServiceSelector
