/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'

const Unit = styled.span`
    font-weight: bold;
    font-size: 2rem;
    ${(props) =>
        props.margin &&
        css`
            margin: props.margin;
        `}
`

const StrongUnit = (props) => {
    return <Unit>{props.children}</Unit>
}

export default StrongUnit
