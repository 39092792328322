import React, { useContext } from 'react'
import { CourseResponse } from './EnrollContainer'
import MainLayout from '../../layouts/MainLayout'
import { useLocation } from 'wouter'
import enroll_img from '../../assets/images/join_team_img.png'
import { UIContext } from '../../context/UIContext'

interface IAlreadyEnrolled {
    course: CourseResponse
    userName?: string
}
export const AlreadyEnrolled = ({ course, userName }: IAlreadyEnrolled) => {
    const [, setLocation] = useLocation()
    const { titleCaseFn } = useContext(UIContext)

    return (
        <MainLayout ruta={course.hq.name}>
            <div className="enroll-page-container">
                <img src={enroll_img} alt="" />
                <span className="hello-badge">¡Hola {userName ? titleCaseFn(userName) : ''}!</span>
                <h3>Ya estás registrado en el equipo {course.course.name}</h3>
                <button className="btn-yellow" onClick={() => setLocation('/')}>
                    Volver
                </button>
            </div>
        </MainLayout>
    )
}
