/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getGeneralMeetings } from '../apis/api.hca'
import { useQuery } from 'react-query'
import { useTeam } from '../pages/Team/Hooks/useTeam'

export const useGeneralMeetings = () => {
    const { courseID, profileID } = useTeam()

    const queryKey = ['Team', 'General Meetings', courseID]

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn: () => getGeneralMeetings(profileID!),
        enabled: !!profileID,
    })

    return {
        ...data,
        ...rest,
    }
}
