import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import ManagerContext from '../../../../../context/ManagerContext'
import { UserContext } from '../../../../../context/UserContext'
import { SubjectContext } from '../../../../../context/SubjectContext'
import { AssignationItem } from '../../../../../context/SubjectContext'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'
import { useTeam } from '../../../../Team/Context/TeamContext'

export interface SearchStudent extends AssignationItem {
    searchAssigned?: string | undefined
}

const Reassign = ({ student }: { student: SearchStudent }) => {
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isValid },
    } = useForm({
        mode: 'onChange',
    })

    const { unassigned, teamsNumbers, hq, course, subject } = useContext(SubjectContext)
    const { role } = useContext(UserContext)
    const { team } = useTeam()

    const { onReassign } = useContext(ManagerContext)
    const onSubmit = handleSubmit(({ table }) => onReassign(student._profileId, Number(table)))

    const { t } = useTranslation(['teamManager'])
    const requiredField = t('actions.required-field')

    /**
     *
     * @param profileId
     * @returns true if student is unassigned or false if student is not unassigned
     */
    const isUnassigned = (_profileId: string) => {
        return unassigned?.find((unnassigned) => unnassigned?._profileId === _profileId)
            ? true
            : false
    }

    return (
        <div className="user-action-reassign">
            <form className="user-action-reassign" onSubmit={onSubmit}>
                <input
                    name="table"
                    type="number"
                    min={teamsNumbers?.[0]}
                    max={teamsNumbers?.[teamsNumbers.length - 1]}
                    className="form-input-no-card"
                    placeholder={t('actions.reassign.input-label')}
                    ref={register({
                        required: requiredField,
                    })}
                />
                <button
                    type="submit"
                    onClick={() => {
                        ReactGA.event({
                            category: 'Team Management',
                            action: 'StudentAssigned',
                            dimension1: 'platform',
                            dimension2: hq?.name.toLowerCase(),
                            dimension3: course?.name.toLowerCase(),
                            dimension4: subject?.name.toLowerCase(),
                            dimension6: 'Assign',
                            dimension7: role,
                            dimension9: team?.algorithm
                                ? team?.algorithm?.algorithmType
                                : 'no algorithm available',
                        })
                    }}
                    className="btn-reassign"
                    disabled={!isValid}
                >
                    {!isSubmitting
                        ? t('actions.reassign.assign-btn')
                        : t('actions.reassign.sending')}
                </button>
            </form>

            <button
                className="btn-reassign"
                onClick={() => {
                    onReassign(student._profileId, null)
                    ReactGA.event({
                        category: 'Team Management',
                        action: 'StutendUnassigned',
                        dimension1: 'platform',
                        dimension2: hq?.name.toLowerCase(),
                        dimension3: course?.name.toLowerCase(),
                        dimension4: subject?.name.toLowerCase(),
                        dimension6: 'Unassign',
                        dimension7: role,
                        dimension9: team?.algorithm
                            ? team?.algorithm?.algorithmType
                            : 'no algorithm available',
                    })
                }}
                disabled={isUnassigned(student._profileId)}
            >
                {t('actions.reassign.unassign-btn')}
            </button>
        </div>
    )
}

export default Reassign
