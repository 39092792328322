import './firebase'
import './fontawesome/library'

import { QueryClient, QueryClientProvider } from 'react-query'
import { UIContext, useUIContextValue } from './context/UIContext'
import { UserContext, useUserContextValue } from './context/UserContext'
import { defaultModalStyles, isDevelopment } from './constants'

import AppRouter from './router/AppRouter'
import { Auth0Provider } from '@auth0/auth0-react'
import Axios from 'axios'
import DayJS from 'dayjs'
import ErrorBoundary from './ErrorBoundary'
import GA from './router/GoogleAnalytics'
import Notifications from './components/Notifications'
import React from 'react'
import ReactModal from 'react-modal'
import { ReactQueryDevtools } from 'react-query/devtools'
import { SocketContextProvider } from './context/SocketContext'
import interceptorsHelper from './helpers/interceptors'
import { isProduction } from './constants'
import printVersion from './helpers/versionPrinter'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useIntercom } from './hooks/useIntercom'

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE } = process.env

if (!REACT_APP_AUTH0_DOMAIN || !REACT_APP_AUTH0_CLIENT_ID || !REACT_APP_AUTH0_AUDIENCE)
    throw new Error('Auth0 credentials not found')

DayJS.extend(updateLocale)

require('dayjs/locale/en')
require('dayjs/locale/es')
require('dayjs/locale/pt')

interceptorsHelper.addInterceptors(Axios)

!isDevelopment && printVersion()

const queryClient = new QueryClient(
    isDevelopment
        ? {
              defaultOptions: {
                  queries: {
                      refetchOnWindowFocus: false,
                      staleTime: 1000 * 60 * 5,
                      cacheTime: 1000 * 60 * 60 * 36, // 36 hours
                  },
              },
          }
        : undefined
)

;(function () {
    const originalSetItem = sessionStorage.setItem
    const originalRemoveItem = sessionStorage.removeItem

    // Custom event for session storage changes
    const sessionStorageChangeEvent = new Event('sessionStorageChange')

    // Override setItem
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sessionStorage.setItem = function (key, value) {
        originalSetItem.apply(this, arguments)
        window.dispatchEvent(sessionStorageChangeEvent)
    }

    // Override removeItem
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sessionStorage.removeItem = function (key) {
        originalRemoveItem.apply(this, arguments)
        window.dispatchEvent(sessionStorageChangeEvent)
    }
})()

const App = () => {
    const user = useUserContextValue()
    const ui = useUIContextValue()
    const { clientBoot } = useIntercom()

    React.useEffect(() => {
        // Boot intercom
        clientBoot({
            app_id: isProduction ? 'qu6m3nwu' : 'cw1s2grp',
            action_color: '#FFCD00',
            background_color: '#37474F',
            vertical_padding: 0,
            horizontal_padding: 100,
            alignment: 'left',
            hide_default_launcher: true,
        })
    }, [clientBoot])

    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <Auth0Provider
                    domain={REACT_APP_AUTH0_DOMAIN}
                    clientId={REACT_APP_AUTH0_CLIENT_ID}
                    authorizationParams={{
                        redirect_uri: `${window.location.origin}/auth/auth0`,
                        audience: REACT_APP_AUTH0_AUDIENCE,
                    }}
                    scope="openid profile"
                >
                    <UserContext.Provider value={user}>
                        <UIContext.Provider value={ui}>
                            <SocketContextProvider>
                                <AppRouter />
                                <GA.RouteTracker />
                                <Notifications />
                            </SocketContextProvider>

                            <ReactModal
                                isOpen={!!ui.modal.content}
                                style={{
                                    content: {
                                        ...defaultModalStyles.content,
                                        ...ui.modal?.customStyles?.content,
                                    },
                                    overlay: {
                                        ...defaultModalStyles.overlay,
                                        ...ui.modal?.customStyles?.overlay,
                                    },
                                }}
                            >
                                {ui.modal.content}
                            </ReactModal>
                        </UIContext.Provider>
                    </UserContext.Provider>
                </Auth0Provider>
            </ErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

export default App
