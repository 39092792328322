import { ConfirmMeetingChange, SuccessModal } from './ConfirmMeetingChange'

import DayJS from '../../../../../helpers/DayJS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ManageMeetingCustomStyles } from './ManageMeeting.modal'
import i18n from '../../../../../i18n'
import { useTeam } from '../../../Hooks/useTeam'
import { useTimeline } from '../../../Hooks/useTimeline'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'

export const MoveToGeneralModal = () => {
    const { t } = useTranslation('teamMonitor')
    const { setModal } = useUIContext()
    const { nextEvent, newTimelineEvent } = useTimeline()
    const { courseID } = useTeam()

    if (!courseID) return null

    const duration: [number, DayJS.ManipulateType] = nextEvent ? [5, 'seconds'] : [3, 'minutes']
    const diff = DayJS()
        .add(...duration)
        .diff(DayJS())
    const time = DayJS.duration(diff).locale(i18n.resolvedLanguage).humanize(true)

    return (
        <ConfirmMeetingChange
            icon={<FontAwesomeIcon icon={['far', 'circle-arrow-left']} />}
            text={t('Participants will be sent to the general meeting.')}
            onConfirm={() => {
                newTimelineEvent({
                    courseID,
                    room: 'general',
                    date: DayJS()
                        .add(...duration)
                        .toISOString(),
                })
                setModal({
                    content: (
                        <SuccessModal
                            text={
                                <p>
                                    {t('Participants will be sent to the general meeting')}{' '}
                                    <b>{time}</b>
                                </p>
                            }
                        />
                    ),
                    customStyles: ManageMeetingCustomStyles,
                })
            }}
        />
    )
}
