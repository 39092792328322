import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

enum PlacementName {
    bottomEnd = 'bottomEnd',
    bottomStart = 'bottomStart',
    topEnd = 'topEnd',
    topStart = 'topStart',
}

declare type PlacementType = keyof typeof PlacementName

interface ITooltipProps extends HTMLAttributes<HTMLSpanElement> {
    placement: PlacementType
    text: string
}

const ToolTip = ({ children, placement, text }: ITooltipProps) => {
    return (
        <Tip placement={placement} text={text}>
            {children}
        </Tip>
    )
}

const Tip = styled.div<ITooltipProps>(({ placement, text }) => {
    const baseStyles = css`
        background-color: #003750;
        border: 1px solid #1a506c;
        border-radius: 4px;
        color: #ffffff;
        content: '${text}';
        display: none;
        font-size: 10px;
        min-width: 100%;
        padding: 3px 9px;
        position: absolute;
        text-align: center;
        width: max-content;
        z-index: 1;
    `

    const arrow = css`
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        border-style: solid;
        border-top-color: transparent;
        border-width: 10px;
        content: '';
        display: none;
    `

    const placementStyles = {
        bottomEnd: css`
            :before {
                position: absolute;
                right: 50%;
                top: 35px;
                ${arrow}
                border-bottom-color: #1a506c;
            }
            :after {
                ${baseStyles}
                right: 0;
                top: 50px;
            }
        `,
        bottomStart: css`
            :before {
                left: 50%;
                position: absolute;
                top: 35px;
                ${arrow}
                border-bottom-color: #1a506c;
            }
            :after {
                ${baseStyles}
                left: 0;
                top: 50px;
            }
        `,
        topEnd: css`
            :before {
                ${baseStyles}
                right: 0;
                top: -30px;
            }
            :after {
                position: absolute;
                right: 50%;
                top: -15px;
                ${arrow}
                border-top-color: #1a506c;
            }
        `,
        topStart: css`
            :before {
                ${baseStyles}
                left: 0;
                top: -30px;
            }
            :after {
                position: absolute;
                left: 50%;
                top: -15px;
                ${arrow}
                border-top-color: #1a506c;
            }
        `,
    }

    return css`
        position: relative;
        :hover::before,
        :hover::after {
            display: block;
        }
        ${placementStyles[placement]};
    `
})

export default ToolTip
