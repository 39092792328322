import { FullPageMessage } from '../../components/FullPageMessage'
import { useQuery } from 'react-query'
import { useUser } from '../../context/UserContext'
import { useLocation } from 'wouter'
import useSearchParam from '../../hooks/useSearchParams'

export const LoginWithIdToken = () => {
    const [token] = useSearchParam('token')
    const [onLogin] = useSearchParam('onLogin')
    const { loginWithIdToken } = useUser()
    const [, setLocation] = useLocation()

    const { status } = useQuery(
        ['Login with idToken', token],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        async () => loginWithIdToken(token!),
        {
            enabled: !!token,
            staleTime: 0,
            onSuccess: () => setLocation(onLogin ?? '/dashboard'),
        }
    )

    if (status === 'loading')
        return <FullPageMessage message="Por favor espere mientras ingresa a la plataforma..." />

    const message =
        status === 'error'
            ? 'Error al iniciar sesión, por favor intente de nuevo.'
            : 'Por favor espere mientras ingresa a la plataforma...'
    return <FullPageMessage message={message} />
}
