import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/globals/_variables'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from 'react-slick'
import { MessagesliderSettings } from '../../styles/sliders-settings'
import { useTranslation } from 'react-i18next'

const Messages = styled.div`
    width: 100%;
    .slick-slider {
        padding: 0;
        @media screen and (min-width: 768px) {
            padding: 0 40px;
        }
    }
`

const Item = styled.div`
    margin: 10px 30px;
    display: flex !important;
    align-items: center;
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
    width: auto !important;
    box-sizing: border-box;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    .icon {
        color: ${colors.eggColor};
        font-size: 4rem;
        margin-bottom: 10px;
        @media screen and (min-width: 768px) {
            font-size: 5rem;
            margin-right: 20px;
        }
    }
    .msg-content {
        text-align: center;
        @media screen and (min-width: 768px) {
            text-align: left;
        }
        span {
            margin-top: 10px;
            margin-bottom: 5px;
            display: block;
            font-weight: bold;
            font-size: 1.2rem;
            @media screen and (min-width: 768px) {
                font-size: 1.1rem;
                margin-top: 00px;
            }
        }
        p {
            margin: 0;
            line-height: 1.4rem;
        }
    }
`

const MessagesBlock = () => {
    const { t } = useTranslation(['teamDetailStudent'])

    return (
        <Messages>
            <Slider {...MessagesliderSettings}>
                <Item className="itemmmm">
                    <FontAwesomeIcon className="icon" icon={['fal', 'fingerprint']} />
                    <div className="msg-content">
                        <span className="title">{t('messages-block.msg1.title')}</span>
                        <p>{t('messages-block.msg1.desc')}</p>
                    </div>
                </Item>
                <Item>
                    <FontAwesomeIcon className="icon" icon={['fal', 'fingerprint']} />
                    <div className="msg-content">
                        <span className="title">{t('messages-block.msg2.title')}</span>
                        <p>{t('messages-block.msg2.desc')}</p>
                    </div>
                </Item>
            </Slider>
        </Messages>
    )
}

export default MessagesBlock
