/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react'
import MainLayout from '../../../layouts/MainLayout'
import { useLocation } from 'wouter'
import { UIContext } from '../../../context/UIContext'
import { UserContext } from '../../../context/UserContext'

import EggCard from '../../../components/Styled/EggCard'
import LoadingPage from '../../../pages/Other/LoadingPage'

import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AvatarEgg from '../../../components/Styled/AvatarEgg'
import BtnSmall from '../../../components/Buttons/BtnSmall'

import { useTranslation } from 'react-i18next'

const TeamCollabView = ({
    collabDocs,
    ready,
    unsubscribeDoc,
    isUnsubscribing,
    setSelectedDoc,
    mainLinks,
}) => {
    const [, setLocation] = useLocation()
    const { titleCaseFn } = useContext(UIContext)
    const { user } = useContext(UserContext)
    const [dropdownShow, setDropdownShow] = useState(false)

    const { t } = useTranslation(['teamCollab'])

    if (!ready) return <LoadingPage />

    const openDoc = (doc) => {
        setSelectedDoc(doc)
        setLocation(`edit/${doc.document}`)
    }

    const titleDocs = (docTitle) => {
        if (docTitle === 'Untitled Document' || docTitle === 'Documento Sin Título') {
            return t('item.title')
            //<button className="title" onClick={() => openDoc(doc)}>{(titleCaseFn(doc.title))}</button>
        }
        return docTitle
    }

    return (
        <MainLayout ruta={'Collab'} links={mainLinks}>
            <div className="collab-docs-heading">
                <h3>{t('heading.title')}</h3>
                <BtnSmall icon={['fal', 'plus']} onClick={() => setLocation(`edit/new`)}>
                    {t('heading.create')}
                </BtnSmall>
            </div>
            <div className="collab-docs-list">
                {collabDocs &&
                    collabDocs.map((doc) => {
                        return (
                            <EggCard key={doc._id} className="list-item-card">
                                <div className="list-item-content">
                                    <button className="title" onClick={() => openDoc(doc)}>
                                        {titleDocs(titleCaseFn(doc.title))}
                                    </button>
                                    <div className="dates">
                                        <span className="created-at">
                                            <FontAwesomeIcon
                                                className="pencil-icon"
                                                icon={['fal', 'pencil']}
                                            />
                                            {t('item.created')}{' '}
                                            {dayjs(doc.createdAt).format('DD-MM-YYYY h:mma')}
                                            {/* por {doc.owner.name} */}
                                        </span>
                                    </div>
                                    <div className="avatars-container">
                                        {doc.users.map((user) => {
                                            return (
                                                <AvatarEgg
                                                    key={user._id}
                                                    title={`${user.name} ${user.lastname}`}
                                                    className="avatar"
                                                    size={37}
                                                    src={user.urlImage}
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="unsubscribe-block">
                                        <button
                                            onClick={() =>
                                                setDropdownShow((prev) =>
                                                    prev !== false ? false : doc._id
                                                )
                                            }
                                        >
                                            {isUnsubscribing === doc._id ? (
                                                <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                            ) : (
                                                <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
                                            )}
                                        </button>
                                    </div>
                                    {dropdownShow === doc._id && (
                                        <div className="dropdown-options">
                                            <BtnSmall
                                                icon={['fal', 'trash']}
                                                onClick={() => unsubscribeDoc(doc._id)}
                                            >
                                                {t('dropdown.unsubscribe')}
                                            </BtnSmall>
                                            {user._id === doc.owner._id && (
                                                <BtnSmall
                                                    icon={['fal', 'trash']}
                                                    onClick={() => unsubscribeDoc(doc._id, true)}
                                                >
                                                    {t('dropdown.delete')}
                                                </BtnSmall>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </EggCard>
                        )
                    })}
            </div>
        </MainLayout>
    )
}

export default TeamCollabView
