// It's easy to remove the prop-types validation. But we should deprecate all typescript components.
/* eslint-disable react/prop-types */

// API
import React, { useContext, useState } from 'react'
import { useLocation } from 'wouter'
// Context
import { UIContext } from '../../../context/UIContext'
// Hooks
import { useTranslation } from 'react-i18next'
// Componentes
import MainLayout from '../../../layouts/MainLayout'
import LinkBlockMsg from '../../../components/LinkBlockMsg'
import Slider from 'react-slick'
// Assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AvatarEgg from '../../../components/Styled/AvatarEgg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CircularAlign from '../../../components/CircularTable'
import EggLogo from '../../../assets/images/egg_shaddow.svg'
import Link from '../../../components/Link'
import { SubjectContext } from '../../../context/SubjectContext'
import UserContext from '../../../context/UserContext'
import { CourseOptions } from '../../../hooks/ApiHooks/useCourse'
import ReactGA from 'react-ga'
import { useTeam } from '../../Team/Context/TeamContext'

//director comentario
//import { ROLES } from '../../../constants';
//import UserContext from '../../../context/UserContext';

const TeamDetailView = (props) => {
    const { t } = useTranslation(['teamDetailTeacher'])
    const [, setLocation] = useLocation()
    const { team } = useTeam()

    const {
        facilitators,
        tables,
        shareUrl,
        courseName,
        hqName,
        subjectName,
        professorScheduleId,
        subjectId,
        mainLinks,
        launchAlgorithm,
        zoom,
        onVoteTable,
        options,
    } = props

    const { unassignedCount, hq, course, subject } = useContext(SubjectContext)
    const { role } = useContext(UserContext)

    const { titleCaseFn, setInfoBlock } = useContext(UIContext)

    const copyLink = (
        url,
        onSuccessMessage,
        onErrorMessage = t('teamDetailTeacher:infoBlock.copied')
    ) => () => {
        if (!url) {
            setInfoBlock(['error', onErrorMessage])
            setTimeout(() => setInfoBlock([]), 3000)
            return
        }
        if (options?.[CourseOptions.studentEnrollmentsAvailable] > 0) {
            switch (url) {
                case shareUrl.student:
                    ReactGA.event({
                        category: 'Onboarding',
                        action: 'InviteStudent',
                        dimension1: 'platform',
                        dimension2: hq?.name.toLowerCase(),
                        dimension3: course?.name.toLowerCase(),
                        dimension4: subject?.name.toLowerCase(),
                        dimension7: role,
                        dimension9: team?.algorithm
                            ? team?.algorithm?.algorithmType
                            : 'no algorithm available',
                    })
                    break
                case shareUrl.professor:
                    ReactGA.event({
                        category: 'Onboarding',
                        action: 'InviteProfessor',
                        dimension1: 'platform',
                        dimension2: hq?.name.toLowerCase(),
                        dimension3: course?.name.toLowerCase(),
                        dimension4: subject?.name.toLowerCase(),
                        dimension7: role,
                        dimension9: team?.algorithm
                            ? team?.algorithm?.algorithmType
                            : 'no algorithm available',
                    })
                    break
            }
            const link = document.createElement('textarea')
            document.body.appendChild(link)
            link.value = url
            link.select()
            document.execCommand('copy')
            document.body.removeChild(link)
            setInfoBlock(['success', onSuccessMessage])
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        } else {
            setInfoBlock(['error', t('teamDetailTeacher:infoBlock.error-full-class')])
            setTimeout(() => setInfoBlock([]), 3000)
            return
        }
    }

    const gaEventTables = () => {
        ReactGA.event({
            category: 'Team Management',
            action: 'ShowAllTeams',
            dimension1: 'platform',
            dimension2: hq?.name.toLowerCase(),
            dimension3: course?.name.toLowerCase(),
            dimension4: subject?.name.toLowerCase(),
            dimension7: role,
            dimension9: team?.algorithm ? team?.algorithm?.algorithmType : 'no algorithm available',
        })
        setLocation(`/team/${professorScheduleId}/subject/${subjectId}/manager`)
    }

    const gaEventZoomMeet = () => {
        if (!zoom?.onMeeting) return
        zoom?.onMeeting
        zoom?.onMeeting()
        ReactGA.event({
            category: 'Team Management',
            action: 'VirtualMeetingOverview',
            dimension1: 'platform',
            dimension2: hq?.name.toLowerCase(),
            dimension3: course?.name.toLowerCase(),
            dimension4: subject?.name.toLowerCase(),
            dimension7: role,
            dimension9: team?.algorithm ? team?.algorithm?.algorithmType : 'no algorithm available',
        })
    }

    return (
        <MainLayout ruta={`${titleCaseFn(hqName)} > ${titleCaseFn(courseName)}`} links={mainLinks}>
            <div className="team-detail-container">
                <div className="header">
                    <span className="hq-name">{titleCaseFn(hqName)}</span>
                    <div className="team">
                        <h1>{titleCaseFn(courseName)}</h1>
                    </div>
                    <div className="goal">
                        <FontAwesomeIcon className="icon" icon={['fal', 'fingerprint']} />{' '}
                        {titleCaseFn(subjectName)}
                    </div>
                    <div className="actions-block">
                        <button
                            onClick={copyLink(
                                shareUrl?.student,
                                t('teamDetailTeacher:infoBlock.studentCopied')
                            )}
                            className="action-link"
                        >
                            <FontAwesomeIcon className="icon" icon={['fal', 'user-graduate']} />
                            <span>{t('teamDetailTeacher:header.invite_std')}</span>
                        </button>
                        <button
                            onClick={copyLink(
                                shareUrl?.professor,
                                t('teamDetailTeacher:infoBlock.professorCopied')
                            )}
                            className="action-link"
                        >
                            <FontAwesomeIcon
                                className="icon"
                                icon={['fal', 'chalkboard-teacher']}
                            />
                            <span>{t('teamDetailTeacher:header.invite_prof')}</span>
                        </button>
                        <button
                            onClick={() => gaEventZoomMeet()}
                            className="action-link"
                            disabled={zoom?.disabledMeeting}
                        >
                            <FontAwesomeIcon className="icon" icon={['fal', 'video']} />
                            <span>{t('teamDetailTeacher:header.virtualClass')}</span>
                        </button>
                    </div>
                </div>

                {/* // Render THIS always when exists Unassigned Students. */}
                {unassignedCount > 0 && (
                    <ReassignBlock
                        quantity={unassignedCount}
                        onSubmit={launchAlgorithm}
                        labelMessage={t('teamDetailTeacher:unassigned.label_2')}
                        label={`${
                            tables
                                ? t('teamDetailTeacher:unassigned.button_reassign')
                                : t('teamDetailTeacher:unassigned.button_assign')
                        }`}
                    />
                )}

                {/* If no tables, no show */}
                {tables?.length > 0 && (
                    <TablesBlock
                        onMeeting={zoom?.onMeeting}
                        disabledMeeting={zoom?.disabledMeeting}
                        onShowTables={gaEventTables}
                        tables={tables}
                        size={3}
                        onVoteTable={onVoteTable}
                        professorScheduleId={professorScheduleId}
                        subjectId={subjectId}
                    />
                )}

                {/* If no tables, no show */}
                {tables?.length > 0 && (
                    <FacilitatorsBlock
                        facilitators={facilitators}
                        size={6}
                        professorScheduleId={professorScheduleId}
                        subjectId={subjectId}
                    />
                )}

                {/* If no tables, no show */}
                {tables?.length > 0 && (
                    <div className="messages-block">
                        <LinkBlockMsg
                            id="link-csv"
                            icon="compass"
                            title={t('teamDetailTeacher:messages-block.msg1.title')}
                            text={'teamDetailTeacher:messages-block.msg1.desc'}
                        />
                        <LinkBlockMsg
                            id="link-metodologia"
                            icon="book"
                            text={t('teamDetailTeacher:messages-block.msg2.desc')}
                            title={t('teamDetailTeacher:messages-block.msg2.title')}
                        />
                    </div>
                )}

                {/* Render THIS when no students loaded */}
                {(!tables || tables?.length === 0) && <InvitationBlock url={shareUrl?.student} />}
                {/* END rendering THIS when no students loaded */}
            </div>
        </MainLayout>
    )
}

const InvitationBlock = ({ url }) => {
    const { t } = useTranslation(['teamDetailTeacher'])
    return (
        <div>
            <div className="empty-block">
                <FontAwesomeIcon className="icon" icon={['fal', 'link']} />
                <h3>{t('teamDetailTeacher:messages-block.invitation.invitation-link')}</h3>
                <p className="global-text">
                    {t('teamDetailTeacher:messages-block.invitation.invitation-copy')}
                </p>
                <input className="form-input" type="" value={url} readOnly id="share-url" />
            </div>

            <div className="messages-block">
                <LinkBlockMsg
                    id="link-csv"
                    icon="th-list"
                    text={t('teamDetailTeacher:messages-block.msg1.desc')}
                    title={t('teamDetailTeacher:messages-block.msg1.title')}
                />
                <LinkBlockMsg
                    id="link-metodologia"
                    icon="book"
                    text={t('teamDetailTeacher:messages-block.msg2.desc')}
                    title={t('teamDetailTeacher:messages-block.msg2.title')}
                />
            </div>
        </div>
    )
}

const ReassignBlock = ({ quantity, labelMessage }) => {
    const { t } = useTranslation(['teamDetailTeacher'])
    return (
        <div className="unassigned-students-block responsive">
            <span className="label">
                {t('teamDetailTeacher:unassigned.label_1')}
                <strong>
                    {quantity} {labelMessage}
                </strong>
            </span>
        </div>
    )
}

const TablesBlock = ({
    professorScheduleId,
    subjectId,
    // These parameters are not used
    //onMeeting, disabledMeeting,
    onShowTables,
    tables,
    size,
    onVoteTable,
}) => {
    const { t } = useTranslation(['teamDetailTeacher'])

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        centerMode: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    }

    return (
        <div className="tables-block-container">
            <div className="tables-block">
                <div className="heading">
                    <div className="block-title">
                        <h3>{t('teamDetailTeacher:tables.title')}</h3>
                    </div>
                    <div className="block-actions">
                        <div className="action-link" onClick={onShowTables}>
                            <FontAwesomeIcon className="icon" icon={['fal', 'border-inner']} />
                            <span>{t('teamDetailTeacher:tables.links.showTables')}</span>
                        </div>
                    </div>
                </div>
                <div className="content slider">
                    <Slider {...settings}>
                        {fill(tables, size).map((item, i) => (
                            <CircularTable
                                table={item?.tableNumber}
                                students={item?.students}
                                key={i}
                                onVoteTable={() => onVoteTable(item?.tableNumber)}
                                voted={item?.egged}
                                professorScheduleId={professorScheduleId}
                                subjectId={subjectId}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export const fill = (arr, n) => (n > arr.length ? [...arr, ...Array(n - arr.length)] : arr)

export const CircularTablePlaceHolder = ({ key, min, max }) => {
    const { t } = useTranslation(['teamDetailTeacher'])
    const [students] = useState(Math.floor(Math.random() * (max - min) + min))
    return (
        <div className={`egg-card item slider-item is-placeholder`} key={key}>
            <div className="name-table">
                {t('teamDetailTeacher:placeholders.tables.name-table')}
            </div>
            <CircularAlign className="students-table-view">
                {[...Array(students)].map((_, i) => (
                    <div className="student-item" key={i}>
                        <AvatarEgg size={50} />
                    </div>
                ))}
            </CircularAlign>
        </div>
    )
}

export const CircularTable = ({
    table,
    students,
    key,
    onVoteTable,
    voted,
    professorScheduleId,
    subjectId,
}) => {
    const { t } = useTranslation(['teamDetailTeacher'])
    if (table === undefined) return <CircularTablePlaceHolder key={key} min={4} max={7} />

    /* Estos estilos están en globalCard no se por qué */
    return (
        <div className={`egg-card item slider-item`} key={key}>
            <div className="name-table">
                {t('teamDetailTeacher:tables.slider.name-table')} {table}
            </div>
            <CircularAlign
                className="students-table-view"
                diameter={65}
                center={<VotingButton onClick={onVoteTable} voted={voted} />}
            >
                {students?.map((item) => (
                    <Link
                        title={`${item.name} ${item.lastname}`}
                        to={`/team/${professorScheduleId}/subject/${subjectId}/profile/${item._profileId}`}
                        className="student-item"
                        key={item._profileId}
                    >
                        <AvatarEgg size={50} src={item.urlImage} />
                    </Link>
                ))}
            </CircularAlign>
        </div>
    )
}

export const VotingButton = ({ onClick, voted, ...rest }) => {
    const { t } = useTranslation(['teamManager'])

    const [sending, setSending] = useState(false)

    const vote = async () => {
        try {
            setSending(true)
            onClick && (await onClick())
        } finally {
            setSending(false)
        }
    }

    if (sending)
        return (
            <FontAwesomeIcon icon={['fa', 'spinner']} style={{ fontSize: '2rem' }} spin {...rest} />
        )

    return (
        <img
            alt="Egg award"
            src={EggLogo}
            title={!voted ? t('teamManager:voting.vote') : t('teamManager:voting.unvote')}
            onClick={vote}
            className={`central-logo ${voted ? '' : 'in'}active`}
            style={{ cursor: 'pointer' }}
            {...rest}
        />
    )
}

export const FacilitatorsBlock = ({ facilitators, size, professorScheduleId, subjectId }) => {
    const { t } = useTranslation(['teamDetailTeacher'])

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    }

    return (
        <div className="facilitadores-block">
            <div className="heading">
                <span className="block-title">
                    <h3>{t('teamDetailTeacher:facilitators.title')}</h3>
                </span>
            </div>
            <div className="content">
                <Slider {...settings}>
                    {fill(facilitators, size).map((student, i) => (
                        <FacilitatorItem
                            student={student}
                            key={i}
                            professorScheduleId={professorScheduleId}
                            subjectId={subjectId}
                            table={student?.table}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    )
}

const FacilitatorPlaceholder = ({ key }) => {
    const { t } = useTranslation(['teamDetailTeacher'])
    return (
        <div key={key} className="facilitador-item is-placeholder">
            <AvatarEgg size={80} />
            <div className="name-label">
                {t('teamDetailTeacher:placeholders.facilitators.name-label')}
            </div>
        </div>
    )
}

const FacilitatorItem = ({ student, key, professorScheduleId, subjectId, table }) => {
    const { titleCaseFn } = useContext(UIContext)
    const { t } = useTranslation(['teamDetailTeacher'])
    if (student === undefined) return <FacilitatorPlaceholder key={key} />
    else
        return (
            <div className="facilitador-item" key={key}>
                <AvatarEgg size={80} src={student.urlImage} />
                <Link
                    to={`/team/${professorScheduleId}/subject/${subjectId}/profile/${student._profileId}`}
                >
                    <div className="name-label">
                        {titleCaseFn(student.name)} {titleCaseFn(student.lastname)}
                    </div>
                    <div className="name-table">
                        {t('teamDetailTeacher:tables.slider.name-table')} {table}
                    </div>
                </Link>
            </div>
        )
}

export default TeamDetailView
