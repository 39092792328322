import React, { useContext, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { useTranslation } from 'react-i18next'
import { AssignationItem } from '../../../../../context/SubjectContext'
import ManagerContext from '../../../../../context/ManagerContext'
import { useTranslation } from 'react-i18next'

const Exam = ({ student }: { student: AssignationItem }) => {
    //const { t } = useTranslation(['teamManager'])
    const { examForm } = useContext(ManagerContext)
    const { register, errors, unregister, getValues, watch } = examForm

    const { t } = useTranslation(['teamManager'])

    const absent = watch<string, boolean>(`score.${student._profileId}.absent`)

    useEffect(() => {
        return () => unregister(`score.${student._profileId}`)
    }, [student._profileId, unregister])

    return (
        <div className="user-action-exam">
            <input
                type="number"
                className={`input-score ${errors.score?.[student._profileId] ? 'error' : ''}`}
                name={`score.${student._profileId}.score`}
                placeholder={t(`teamManager:exam.button-label-${!absent ? 'not-' : ''}absent`)}
                required
                defaultValue={''}
                ref={register({
                    validate: (score) => {
                        const absent = getValues(`score.${student._profileId}.absent`) as boolean
                        return absent || !!score
                    },
                    max: {
                        value: 100,
                        message: 'Debe ser menor que 100',
                    },
                    min: {
                        value: 0.01,
                        message: 'Debe ser positiva y no nula',
                    },
                })}
                disabled={absent}
            />
            <label className={`input-absent ${absent ? '' : 'in'}active`}>
                <FontAwesomeIcon
                    className={`icon `}
                    icon={[absent ? 'fas' : 'fal', 'user-slash']}
                />
                <input
                    type="checkbox"
                    name={`score.${student._profileId}.absent`}
                    ref={register()}
                    hidden
                />
            </label>
        </div>
    )
}

export default Exam
