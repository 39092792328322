import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/globals/_variables'

interface IProps {
    on: boolean /* Show toggle turned on or off */
    onClick?: () => void /* click action */
    inactive?: boolean /* disabled, shows gray color and cursor auto */
    submitting?: boolean /* isSubmitting, shows spinner */
    className?: string | undefined
}

const ToggleBtn = ({ on, onClick, submitting, inactive, ...rest }: IProps) => {
    return (
        <Toggle onClick={onClick} disabled={inactive} submitting={submitting} {...rest}>
            {on ? (
                <FontAwesomeIcon className="icon checked" icon={['far', 'toggle-large-on']} />
            ) : (
                <FontAwesomeIcon className="icon unchecked" icon={['far', 'toggle-large-on']} />
            )}
            {submitting && <FontAwesomeIcon className="spinner " icon="spinner" spin />}
        </Toggle>
    )
}

export default ToggleBtn

const Toggle = styled.button<{ inactive?: boolean | undefined; submitting?: boolean }>`
    border: none;
    background: none;
    font-size: 37px;
    position: relative;
    cursor: pointer;
    &:disabled {
        .icon {
            color: ${colors.mediumGreyBg} !important;
            cursor: default;
        }
    }
    .icon {
        &.checked {
            color: #00a3ff;
            visibility: ${(props) => (props.submitting ? 'hidden' : 'visible')};
        }
        &.unchecked {
            color: #003750;
            visibility: ${(props) => (props.submitting ? 'hidden' : 'visible')};
            transform: rotate(180deg);
        }
    }
    .spinner {
        position: absolute;
        right: 100%;
        color: ${colors.darkGreyEgg};
        font-size: 1.2rem;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
    }
`
