import React, { useCallback, useContext, useState } from 'react'

import Jitsi from '.'
import JitsiAPI from '../../helpers/jitsi'
import UserContext from '../../context/UserContext'
import Window from '../Window'
import { capitalize } from '../../helpers/formatter'
import { useJitsiCommandEffect } from './Jitsi'
import useJitsiTracker from './useJitstiTracker'

export type View = 'fullscreen' | 'window' | 'modal'

interface JitsiView<P extends Record<string, unknown>> {
    roomId: string
    roomName: string
    password?: string
    onClose?: () => void
    trackingPayload: P
}

export const JitsiView = <P extends Record<string, unknown>>(props: JitsiView<P>) => {
    const { roomName, roomId, password, onClose, trackingPayload } = props

    const [api, setApi] = useState<JitsiAPI>()

    const { user } = useContext(UserContext)

    useJitsiCommandEffect(api, 'displayName', capitalize(`${user?.name} ${user?.lastname}`))
    useJitsiCommandEffect(api, 'avatarUrl', user?.urlImage || '')
    useJitsiCommandEffect(api, 'subject', roomName)

    useJitsiTracker(api, { roomName: roomId, ...trackingPayload })

    const onWindowClose = useCallback(() => {
        api?.executeCommand('hangup')
        onClose?.()
    }, [api, onClose])

    if (!user) return null

    return (
        <Window onClose={onWindowClose}>
            <Jitsi
                roomName={roomId}
                onLoad={setApi}
                password={password}
                configOverwrite={config}
                onClose={onClose}
            />
        </Window>
    )
}

const config: JitsiAPI.Config = {
    toolbarButtons: [
        'microphone',
        'camera',
        'chat',
        'desktop',
        'fullscreen',
        'etherpad',
        'videoquality',
        'tileview',
        'desktop',
        'sharedvideo',
        'raisehand',
        'hangup',
        'select-background',
    ],
}

export default JitsiView
