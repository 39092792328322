/* eslint-disable react/prop-types */
import React, { useState /*useContext*/ } from 'react'
import MainLayout from '../../../layouts/MainLayout'
import StudentHeading from './StudentHeading'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(LocalizedFormat)

const StudentDetailView = ({
    stats,
    student: propStudent,
    onRubric,
    onEdit,
    onEditChange,
    role,
}) => {
    const [student, setStudent] = useState(propStudent)
    const [isEditable, setIsEditable] = useState(false)

    const saveStudent =
        onEdit &&
        (() => {
            setIsEditable(false)
            onEdit(student)
        })

    const changeHandler = (prop) => (e) => {
        setStudent({ ...student, [prop]: e.target.value })
        onEditChange && onEditChange(prop, e.target.value, e)
    }

    return (
        <MainLayout ruta={student.name}>
            <div className="student-detail-container">
                <div>
                    <StudentHeading
                        name={student.name}
                        lastname={student.lastname}
                        stats={stats}
                        photo={student.urlImage}
                        statusMessage={student.statusMessage}
                        onRubric={onRubric}
                        isEditable={isEditable}
                        setIsEditable={onEdit && setIsEditable}
                        changeHandler={changeHandler}
                        student={student}
                        setStudent={setStudent}
                        saveStudent={saveStudent}
                        onEdit={onEdit}
                        role={role}
                    />
                </div>
            </div>
        </MainLayout>
    )
}

export default StudentDetailView
