const Config = {
    API: process.env.REACT_APP_API,
    API_SIGNUP: process.env.REACT_APP_API_SIGNUP,
    API_ROTATE: process.env.REACT_APP_API_ROTATE,
    PWA: process.env.REACT_APP_PWA,
    STATS: process.env.REACT_APP_STATS,
    MEETING_STATS: process.env.REACT_APP_MEETING_STATS,
    TEAMS_BY_PAGE: +process.env.REACT_APP_TEAMS_BY_PAGE || 50,
    UNASSIGNED_BY_PAGE: +process.env.REACT_APP_UNASSIGNED_BY_PAGE || 15,

    firebaseConfig: {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
    },
}

export default Config
