import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIContext } from '../../context/UIContext'
import { useTranslation } from 'react-i18next'

const Step1View = (props) => {
    const { t } = useTranslation(['wizard'])
    const {
        onInputChange,
        onSubmit,
        hqName,
        hqSelected,
        recommendedHqs,
        onClickHq,
        isNextStepEnabled,
        isSearching,
    } = props
    const [buttonLabel, setButtonLabel] = useState(t('wizard:next'))
    const { titleCaseFn } = useContext(UIContext)

    useEffect(() => {
        if (!!hqName && hqSelected === null) {
            setButtonLabel(t('wizard:create'))
        } else if (hqName && hqSelected) {
            setButtonLabel(t('wizard:join'))
        } else {
            setButtonLabel(t('wizard:next'))
        }
    }, [hqName, hqSelected, t])

    return (
        <div className="step-container responsive">
            <div className="onboarding-img step1"></div>
            <div className="onboarding-form">
                <div className="info">
                    <span className="step-label">{t('wizard:step')} 1/5</span>
                    <span className="step-title">{t('wizard:step1.title')}</span>
                    <p className="global-text">{t('wizard:step1.desc')}</p>
                </div>
                <form id="recomendations" onSubmit={onSubmit}>
                    <div className="recomendation-container">
                        <div>
                            <input
                                className="form-input"
                                type="text"
                                value={titleCaseFn(hqName)}
                                placeholder={t('wizard:step1.input')}
                                onChange={(e) => onInputChange(e.target.value)}
                            />
                            {isSearching && (
                                <span className="loading-span">
                                    <FontAwesomeIcon icon={['fa', 'spinner']} spin size="1x" />
                                </span>
                            )}
                        </div>
                        {recommendedHqs.length > 0 ? (
                            <div className="egg-card egg-list recomendations-list">
                                {recommendedHqs.map((item) => (
                                    <div
                                        className="list-item clickeable-item"
                                        key={item._id}
                                        onClick={onClickHq(item)}
                                    >
                                        {titleCaseFn(item.name)}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                </form>
                <button
                    form="recomendations"
                    type="submit"
                    className="btn-next-wizard"
                    disabled={!isNextStepEnabled}
                >
                    {buttonLabel}
                </button>
            </div>
        </div>
    )
}

Step1View.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    hqName: PropTypes.string.isRequired,
    setHqName: PropTypes.func.isRequired,
    recommendedHqs: PropTypes.array.isRequired,
    onClickHq: PropTypes.func.isRequired,
    success: PropTypes.string,
    error: PropTypes.string,
    onInputChange: PropTypes.func,
    hqSelected: PropTypes.func,
    isNextStepEnabled: PropTypes.bool,
    isSearching: PropTypes.bool,
}

export default Step1View
