import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useTeam } from '../Context/TeamContext'
import { CoachItem } from './CoachItem'

export const CoachLists = () => {
    const { t } = useTranslation('teamManager')
    const { team } = useTeam()
    if (!team) return null
    const { coaches } = team

    return (
        <div
            style={{
                backgroundColor: '#fff',
                borderRadius: 10,
                border: '1px solid #f2f2f2',
                padding: '25px 40px',
                color: '#003750',
            }}
        >
            <div style={{ display: 'flex' }}>
                <FontAwesomeIcon
                    icon={['fas', 'user']}
                    style={{ fontSize: '18px', marginRight: '18px' }}
                />
                <h3 style={{ color: '#003750', fontSize: 20, fontWeight: 800 }}>
                    {t('titles.coach')}
                </h3>
            </div>
            {coaches.map((coach) => (
                <CoachItem {...coach} key={coach._profileId} />
            ))}
        </div>
    )
}
