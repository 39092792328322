import { useQuery } from 'react-query'
import { getValuationGraph } from '../api-reports'
import { useReports } from '../Context/ReportsContext'

export const useValuation = () => {
    const { reportQuery, enabled, staleTime } = useReports()
    const { courseId, cohorts, ...restQuery } = reportQuery
    const queryKey = [
        courseId,
        'Reports',
        'Assessments',
        'Graph',
        `Cohorts: ${cohorts?.join()}`,
        restQuery,
    ]
    const { data: valuation, ...rest } = useQuery(queryKey, () => getValuationGraph(reportQuery), {
        enabled,
        staleTime,
    })

    return { valuation, ...rest }
}
