import Axios from 'axios'
import Config from '../../config'
import { useEffect, useState } from 'react'
interface GETResponseType {
    tables: {
        tableNumber: number
        studentsVote: {
            _profileId: string
            name: string
            lastname: string
            email: string
        }[]
    }[]
    totalVotes: number
}

const useVoteList = (algorithm: string | undefined) => {
    const [votes, setVotes] = useState<GETResponseType | undefined>()

    useEffect(() => {
        algorithm &&
            Axios.get<GETResponseType>(`${Config.API}/algorithm/${algorithm}/studentsVotes`).then(
                (response) => {
                    setVotes(response.data)
                }
            )
    }, [algorithm])

    return votes
}

export default useVoteList
