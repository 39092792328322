import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
// Libraries
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
// Components
import Tooltip from '../../components/DesignSystem/components/atoms/Tooltip/Tooltip.component'
import { Menu, MenuItemContainer } from './NavBar.styles'
import useNavBarItems, { ItemMenuProps, MenuProps } from './useNavBarItems'
import { colors } from '../../styles/globals/_variables'

const NavBarDesktop = ({ mainLinks = [] }: { mainLinks?: MenuProps[] }) => {
    const componentRef = useRef()

    const { topMenuItems, bottomMenuItems, courseMenuItems, states } = useNavBarItems(mainLinks)

    return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <Menu ref={componentRef as any} className="only-desktop">
            <MenuItemContainer>
                <div>
                    <TopMenuRows>
                        {topMenuItems.map((item) => (
                            <Item item={item} states={states} key={item.label} />
                        ))}
                    </TopMenuRows>
                    <CourseMenuRows>
                        {courseMenuItems.map((item) => (
                            <Item item={item} states={states} key={item.label} />
                        ))}
                    </CourseMenuRows>
                </div>
                <BottomMenuRows>
                    {bottomMenuItems.map((item) => (
                        <Item item={item} states={states} key={item.label} />
                    ))}
                </BottomMenuRows>
            </MenuItemContainer>
        </Menu>
    )
}

export default NavBarDesktop

const Item = ({ item, states }: ItemProps) => {
    const {
        activeIcon,
        icon,
        label,
        hidden,
        svgIcon,
        activeSvgIcon,
        onClick,
        logo,
        active: isActive,
        notifications,
    } = item
    const { setActiveItem } = states

    const [userNotifications, setUserNotifications] = useState(0)

    const selectIconOrImage = (svgIconItem: ReactNode, iconItem?: IconProp) =>
        svgIconItem ??
        (iconItem ? <FontAwesomeIcon icon={iconItem} /> : <img src={logo} alt="EGG" />)

    const iconToUse = selectIconOrImage(
        isActive ? activeSvgIcon : svgIcon,
        isActive ? activeIcon : icon
    )
    const handleMenuItemOnClick = (item: ItemMenuProps) => {
        setActiveItem(item)
        onClick?.()
    }

    useEffect(() => {
        notifications && setUserNotifications(notifications)
    }, [notifications])

    if (hidden) return null
    return (
        <Tooltip placement="rightCenter" text={label} key={label}>
            <MenuRow
                onClick={() => {
                    handleMenuItemOnClick(item)
                }}
                active={isActive}
            >
                {iconToUse}
                {userNotifications > 0 && (
                    <div className="circle-container">
                        <span className="cant-notif">{notifications}</span>
                        <FontAwesomeIcon className="circle" icon={['fas', 'circle']} />
                    </div>
                )}
            </MenuRow>
        </Tooltip>
    )
}

interface ItemProps {
    item: ItemMenuProps
    states: ItemStates
}

type ItemStates = {
    activeItem: ItemMenuProps | undefined
    setActiveItem: Dispatch<SetStateAction<ItemMenuProps | undefined>>
}

const MenuRows = styled.section`
    display: grid;
    justify-items: center;
    gap: 2rem;
    width: 100%;
    @media screen and (max-width: 768px) {
        justify-items: center;
    }
`

const TopMenuRows = styled(MenuRows)`
    padding-bottom: 3rem;
`
const CourseMenuRows = styled(MenuRows)``
const BottomMenuRows = styled(MenuRows)``

const MenuRow = styled.div<{ active?: boolean }>`
    display: grid;
    justify-items: center;
    & > div {
        display: grid;
        justify-items: center;
    }
    cursor: pointer;
    color: rgb(0, 55, 80);
    * {
        color: ${({ active }) => (active ? '#00A3FF' : '#073045')};
    }
    img {
        width: 22px;
    }
    svg {
        font-size: 22px;
    }
    &:hover {
        color: ${colors.active};
    }
    & .avatar > .main > .gravatar {
        font-size: 1em;
    }
    .circle-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -8px;
        right: -8px;
        .cant-notif {
            font-weight: bold;
            font-size: 0.67rem;
            position: absolute;
            color: ${colors.white};
            width: unset;
        }
        .circle {
            padding: 2px;
            font-size: 1.1rem;
            > * {
                color: ${colors.salmon};
            }
        }
    }
`
