import Axios from 'axios'

export const ApiHCA = Axios.create({
    baseURL: process.env.REACT_APP_HCA_API,
})

export const getTeamMeetToken = async ({
    _classId,
    _profileId,
    algorithmId,
    tableNumber = 0,
    tableType = 't',
}: GetZoomTokenProps): Promise<GetZoomTokenResponse> =>
    ApiHCA.post(
        `/sdk_session/sessionId?course=${_classId}&user_id=${_profileId}&algorithm_id=${algorithmId}&rider_table=${tableType}_${Math.max(
            0,
            tableNumber - 1
        )}&rider_flag=true`,
        {}
    )
        .then(({ data }) => (typeof data === 'string' ? JSON.parse(data) : data))
        .catch(() => ({}))

export const getGeneralMeetToken = ({
    _classId,
    _profileId,
}: Pick<GetZoomTokenProps, '_classId' | '_profileId'>) =>
    ApiHCA.post(
        `/sdk_session/sessionId?course=${_classId}&user_id=${_profileId}&rider_flag=true&general_meeting=true`,
        {}
    )
        .then(({ data }) => (typeof data === 'string' ? JSON.parse(data) : data))
        .catch(() => ({}))

export const getGeneralMeetings = (profileID?: string) =>
    ApiHCA.get<GetGeneralMeetingUrlsResponse>(
        `/sdk_session/get_general_meeting_urls?profile_id=${profileID}`
    ).then(({ data }) => data)

interface GetZoomTokenProps {
    _classId?: string
    _profileId?: string
    tableNumber?: number
    tableType?: 't' | 'm' // Normal table or mentor table
    algorithmId?: string
}

interface GetZoomTokenResponse {
    token: string
    topic: string
    password: string | null
}

interface GetGeneralMeetingUrlsResponse {
    general_meetings: {
        from: number
        to: number
        url: string
        label: string
    }[]
}
