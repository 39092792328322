import React, { useCallback } from 'react'
import { useLocation } from 'wouter'

interface ILinkProps
    extends React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    > {
    to?: string
}
export const Link = (props: ILinkProps) => {
    const [location, setLocation] = useLocation()

    const { to, href, onClick, rel, children, ...rest } = props

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            // ignores the navigation when clicked using right mouse button or
            // by holding a special modifier key: ctrl, command, win, alt, shift
            const { ctrlKey, metaKey, altKey, shiftKey, button } = event
            if (ctrlKey || metaKey || altKey || shiftKey || button !== 0) return

            event.preventDefault()
            const href = props.href || props.to
            href && setLocation(href)
            onClick?.(event)
        },
        [onClick, props.href, props.to, setLocation]
    )

    let go = href || to
    if (!go) {
        // eslint-disable-next-line no-console
        console.info('Must set href or to property! Using same location as fallback.')
        go = location
    }

    return (
        <a onClick={handleClick} href={go} rel={rel || 'noopener noreferrer'} {...rest}>
            {children}
        </a>
    )
}

export default Link
