import NoHeaderLayout from '../layouts/NoHeaderLayout'
import EggLogo from '../assets/images/egg_shaddow.svg'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export const FullPageMessage = (props: { message?: ReactNode }) => {
    const { t } = useTranslation('login')

    const { message = t('Wait a moment please...') } = props
    return (
        <NoHeaderLayout>
            <div className="login-pages" style={{ display: 'flex', flexDirection: 'column' }}>
                <img src={EggLogo} style={{ width: '40px', marginBottom: '30px' }} alt="" />
                {typeof message === 'string' ? (
                    <h4 style={{ letterSpacing: '0' }}>{message}</h4>
                ) : (
                    message
                )}
            </div>
        </NoHeaderLayout>
    )
}
