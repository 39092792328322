// API
import React, { useContext, useEffect, useMemo, useState } from 'react'
// Component
import FullLayout, { Props as FullLayoutProps } from '../../../layouts/FullLayout'
import TableItem from './Components/TableItem'

// Context
import { UIContext } from '../../../context/UIContext'
// Hooks
import { useTranslation } from 'react-i18next'
// Asset
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SubjectContext } from '../../../context/SubjectContext'
import { StudentUser, ProfessorUser } from '../../../hooks/ApiHooks/useCourseMembers'
import Link from '../../../components/Link'
import ManagerContext, { ActionView } from '../../../context/ManagerContext'
import ActionHeader from './Components/ActionHeaders'
import { CourseOptions } from '../../../hooks/ApiHooks/useCourse'
import LoadingPage from '../../Other/LoadingPage'
import AddMembersList from './Components/AddMemberList'
import { ROLES } from '../../../constants'
import UserContext from '../../../context/UserContext'
import Pagination from '../../../components/Pagination'
import UserItem from './Components/UserItem'
import { iGetMentorsProps, iGetMentorsReturn, iSearchParsedResult } from './TeamManagerContainer'
import { MeetingService } from '../../../hooks/ApiHooks/useMeetings'
import { AssignationItem } from '../../../context/SubjectStudentContext'
import { iOptionsSearchBox, SearchBox } from './Components/SearchBox'
import { ShowSearchResult } from './Components/ShowSearchResult'
import { ModalMentors } from './Components/ModalMentors'
import { capitalize } from '../../../helpers/formatter'
import { ActionsMenu } from './Components/ActionsMenu'
import AttendanceView from './Components/AttendanceView'

const TeamManagerView = (props: iTeamManagerViewProps) => {
    const { mainLinks, teachers, courseName, hqName, search } = props
    const {
        mentorsSearch,
        mentorsResult,
        searchResult,
        isSearching,
        setSearchResult,
        showMentorsTeam,
    } = search
    const { role } = useContext(UserContext)
    // const { onAssignationReport } = useContext(ManagerContext)

    // Why to import from subjectContext here and in the container?
    const {
        joinMeeting: onMeeting,
        assignation,
        rubricQuestions,
        options,
        members,
        setAssignation,
        fetchAssignation,
        totalPages,
        unassigned,
        fetchUnassigned,
        totalUnassigned,
        assignationCount,
        meeting,
        teamsNumbers,
    } = useContext(SubjectContext)

    const { actionView, setActionView, shouldDisplay } = useContext(ManagerContext)
    const { titleCaseFn, searchBox } = useContext(UIContext)
    const { t } = useTranslation(['teamManager'])
    const showMembers = ![ActionView.Add].includes(actionView)

    /** Build SearchBox available search options */
    const searchOptions: iOptionsSearchBox[] = useMemo(
        () => [
            {
                type: 'people',
                label: t('teamManager:search.badge-people'),
                placeholder: t('teamManager:search.option-placeholder-people'),
            },
            {
                type: 'teams',
                label: `${t('teamManager:search.badge-team')} Nº`,
                placeholder: t('teamManager:search.option-placeholder-teams'),
            },
        ],
        [t]
    )

    /** Populate search assistant list, with student names & team numbers */
    useEffect(() => {
        const indexPeople = searchOptions.findIndex((item) => item.type === 'people')
        if (indexPeople < 0) return
        searchOptions[indexPeople]['list'] = members?.student.map(
            (student) => `${capitalize(student.name || '')} ${capitalize(student.lastname || '')}`
        )

        if (!teamsNumbers) return
        const indexTeams = searchOptions.findIndex((item) => item.type === 'teams')
        if (indexTeams < 0) return
        searchOptions[indexTeams]['list'] = teamsNumbers
    }, [members, searchOptions, teamsNumbers])

    useEffect(() => {
        setActionView(ActionView.Attendance)
    }, [courseName, setActionView])

    useEffect(() => {
        if (actionView === ActionView.Exam) {
            setSearchResult(null)
            searchBox[1](null)
        }
    }, [actionView, searchBox, setSearchResult])

    const fetchByPage = (page: number) => {
        // Scroll to top
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        fetchAssignation?.(page)
    }

    const [fetchingNewPage, setFetchingNewPage] = useState(false)
    const fetchUnassignedByPage = async (page: number) => {
        // Scroll to top
        setFetchingNewPage(true)
        const y = window.scrollY
        window.scrollTo({ top: y - 1300, left: 0, behavior: 'smooth' })
        try {
            await fetchUnassigned?.(page)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching new unassigned page', error)
        } finally {
            setFetchingNewPage(false)
        }
    }

    const isJitsiAuto = meeting?.service === MeetingService.JITSI && meeting.method === 'automatic'

    if (!options) return <LoadingPage />

    return (
        <FullLayout links={mainLinks} ruta={`${titleCaseFn(hqName)} > ${titleCaseFn(courseName)}`}>
            <div className="team-manager-container">
                <div className="container fill-gap">
                    <div className="heading">
                        <div className="block-title">
                            <h2>{t('teamManager:heading.title')}</h2>
                            {!!assignationCount && (
                                <span className="cant-badge">
                                    {assignationCount} {t('teamManager:heading.participants')}
                                </span>
                            )}
                            <div></div>
                        </div>
                        <ActionsMenu />
                    </div>

                    <AttendanceView />

                    <div className="content">
                        <SearchBox
                            options={searchOptions}
                            optionsTitle={t('teamManager:search.option-title')}
                            isBusy={isSearching}
                        />

                        {((!!unassigned && unassigned.length > 0) ||
                            (!!assignation && assignation.length > 0)) && (
                            <div className="action-view-switcher">
                                <ul className="buttons-container">
                                    <li>
                                        <button
                                            className={
                                                actionView === ActionView.Attendance
                                                    ? 'selected'
                                                    : ''
                                            }
                                            onClick={() => setActionView(ActionView.Attendance)}
                                        >
                                            {t('teamManager:switcher.assistance')}
                                        </button>
                                    </li>

                                    {options[CourseOptions.CanRubric] &&
                                        rubricQuestions &&
                                        rubricQuestions.length > 0 && (
                                            <li>
                                                <button
                                                    className={
                                                        actionView === ActionView.Rubric
                                                            ? 'selected'
                                                            : ''
                                                    }
                                                    onClick={() => setActionView(ActionView.Rubric)}
                                                >
                                                    {t('teamManager:switcher.recognize')}
                                                </button>
                                            </li>
                                        )}

                                    <li>
                                        <button
                                            className={
                                                actionView === ActionView.Stats ? 'selected' : ''
                                            }
                                            onClick={() => setActionView(ActionView.Stats)}
                                        >
                                            {t('teamManager:switcher.stats')}
                                        </button>
                                    </li>

                                    {options[CourseOptions.CanExam] && (
                                        <li>
                                            <button
                                                className={
                                                    actionView === ActionView.Exam ? 'selected' : ''
                                                }
                                                onClick={() => setActionView(ActionView.Exam)}
                                            >
                                                {t('teamManager:switcher.exam')}
                                            </button>
                                        </li>
                                    )}

                                    {options[CourseOptions.CanReassign] && (
                                        <li>
                                            <button
                                                className={
                                                    actionView === ActionView.Reassign
                                                        ? 'selected'
                                                        : ''
                                                }
                                                onClick={() => setActionView(ActionView.Reassign)}
                                            >
                                                {t('teamManager:switcher.reassign')}
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}

                        <ShowSearchResult data={searchResult} showMentors={showMentorsTeam} />

                        <ActionHeader />

                        <div className="tables-list">
                            {showMembers ? (
                                assignation ? (
                                    assignation?.map((table) => (
                                        <TableItem
                                            table={table}
                                            key={table.tableNumber}
                                            onTableMeeting={
                                                (table.meetingLink || isJitsiAuto) && onMeeting
                                                    ? () => onMeeting(table.tableNumber)
                                                    : undefined
                                            }
                                            showMentorsTeam={showMentorsTeam}
                                        />
                                    ))
                                ) : (
                                    <div className="content empty">
                                        <h3>{t('empty.nothing-show')}</h3>
                                        <p className="global-text">{t('empty.no-tables')}</p>
                                        {!members?.student.length ? (
                                            role === ROLES.DIRECTOR ? (
                                                <button
                                                    className="btn-yellow"
                                                    onClick={() => setActionView(ActionView.Add)}
                                                >
                                                    {t('empty.add-student')}
                                                </button>
                                            ) : (
                                                <Link
                                                    style={{ display: 'inline-block' }}
                                                    to="/dashboard"
                                                    className="btn-yellow"
                                                >
                                                    {t('empty.show-teams')}
                                                </Link>
                                            )
                                        ) : (
                                            <button
                                                className="btn-yellow"
                                                onClick={() => setAssignation?.launch()}
                                            >
                                                {t('empty.assign-student')}
                                            </button>
                                        )}
                                    </div>
                                )
                            ) : (
                                <AddMembersList />
                            )}
                        </div>

                        {!!totalPages && totalPages > 1 && (
                            <div className="pagination-container">
                                <Pagination
                                    onChange={fetchByPage}
                                    count={totalPages}
                                    boundary={2}
                                    middles={5}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {showMembers && unassigned && unassigned.length > 0 && (
                <div className="unassigned-grey-block">
                    <div className="container">
                        {/* Get and shows unassigned students from subjectContext */}
                        <div className="unassigned-students-list">
                            <div className="heading">
                                <h3>
                                    {t('teamManager:unassigned.title')}{' '}
                                    {fetchingNewPage && (
                                        <FontAwesomeIcon
                                            style={{ fontSize: '22px', marginLeft: '10px' }}
                                            icon={['fas', 'spinner']}
                                            spin
                                        />
                                    )}
                                </h3>
                            </div>
                            <div className="user-list">
                                {unassigned?.map((student, i) => (
                                    <UserItem
                                        isAssigned={true}
                                        student={student}
                                        key={student._profileId ?? i}
                                    />
                                ))}
                            </div>
                        </div>

                        {totalUnassigned && totalUnassigned > 1 && (
                            <div className="pagination-container">
                                <Pagination
                                    onChange={fetchUnassignedByPage}
                                    count={totalUnassigned}
                                    boundary={1}
                                    middles={5}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className="team-manager-container">
                <div className="container ">
                    {/* Get and shows unassigned students from subjectContext */}
                    {showMembers && teachers && teachers.filter(shouldDisplay).length > 0 && (
                        <div className="professor-list">
                            <div className="heading">
                                <h3>{t('teamManager:titles.coach')}</h3>
                            </div>
                            <div className="user-list">
                                {teachers.map((professor, i) => (
                                    <UserItem
                                        isAssigned={false}
                                        student={(professor as unknown) as AssignationItem}
                                        key={professor._profileId ?? i}
                                        hidden={!shouldDisplay(professor)}
                                        canDeleted={false}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ModalMentors mentorsSearch={mentorsSearch} mentorsResults={mentorsResult} />
        </FullLayout>
    )
}

export default TeamManagerView

// Interfaces

export interface iTeamManagerViewProps {
    mainLinks?: FullLayoutProps['links']
    unassignedStudents?: StudentUser[]
    teachers?: ProfessorUser[]
    courseName: string
    hqName: string
    search: {
        isSearching: boolean
        searchResult: iSearchParsedResult | null
        setSearchResult: React.Dispatch<React.SetStateAction<iSearchParsedResult | null>>
        mentorsSearch: [
            iGetMentorsProps | null,
            React.Dispatch<React.SetStateAction<iGetMentorsProps | null>>
        ]
        mentorsResult: [
            iGetMentorsReturn | null,
            React.Dispatch<React.SetStateAction<iGetMentorsReturn | null>>
        ]
        showMentorsTeam: (
            team: iGetMentorsProps['team'],
            guide: iGetMentorsProps['guide'],
            algorithm?: iGetMentorsProps['algorithm'] | undefined
        ) => void | ''
    }
}
