import React from 'react'
import Axios from 'axios'
import Config from '../../config'
import { ROLES } from '../../constants'

export interface BaseUser {
    _profileId: string
    _id: string
    name?: string
    lastname?: string
    urlImage?: string
    email?: string
}

interface IGETEndpoint {
    course: {
        attachmentContent: boolean
        attendancesRegistrationTime: {
            attendancesStartTime: number
            attendancesEndTime: number
            attendanceDelayTime: number
        }
        defaultShouldCheckPresents: boolean
        displayStats: boolean
        enabledManualAssign: boolean
        initialNumberTable: number
        isVirtual: boolean
        isVisible: boolean
        mandatoryVote: boolean
        name: string
        repeatAlgorithm: boolean
        rubricContent: boolean
        uploadExamContent: boolean
        mandatoryDataFill: boolean
        enableMeetingUnassigned: boolean
        maxEnrollStudents: number
        studentEnrollmentsAvailable: number
        termsAndConditions: boolean
        _id: string
        _hqId: string
    }
    students: BaseUser[]
    notAssignedStudents: BaseUser[]
    professors: BaseUser[]
    preceptors: BaseUser[]
    tutors: BaseUser[]
}

export type ProfessorUser = BaseUser
export type CoachUser = BaseUser
export type StudentUser = BaseUser

export interface MembersMap {
    [ROLES.STUDENT]: StudentUser[]
    [ROLES.COACH]: CoachUser[]
    [ROLES.PROFESSOR]: ProfessorUser[]
}

//mapeo entre opciones del back y el front, a la derecha el nombre que tienen en el back.
export enum CourseOptions {
    //[front] = [back]
    CanReassign = 'enabledManualAssign',
    CanExam = 'uploadExamContent',
    CanContent = 'attachmentContent',
    InitialTable = 'initialNumberTable',
    MandatoryVote = 'mandatoryVote',
    CanRubric = 'rubricContent',
    ReassignPresents = 'defaultShouldCheckPresents',
    AttendanceInterval = 'attendancesRegistrationTime',
    DisplayStats = 'displayStats',
    IsVirtual = 'isVirtual',
    IsVisible = 'isVisible',
    RepeatAlgorithm = 'repeatAlgorithm',
    MandatoryDataFill = 'mandatoryDataFill',
    EnableMeetingUnassigned = 'enableMeetingUnassigned',
    maxEnrollStudents = 'maxEnrollStudents',
    studentEnrollmentsAvailable = 'studentEnrollmentsAvailable',
    AskForTermsAndConditions = 'termsAndConditions',
}

//opciones por defecto si desde el back no las trae
const defaultOptions = {
    [CourseOptions.CanExam]: false,
    [CourseOptions.CanContent]: false,
    [CourseOptions.CanReassign]: false,
    [CourseOptions.InitialTable]: 1,
    [CourseOptions.MandatoryVote]: false,
    [CourseOptions.CanRubric]: false,
    [CourseOptions.ReassignPresents]: false,
    [CourseOptions.AttendanceInterval]: {
        attendancesStartTime: 60,
        attendancesEndTime: 60,
        attendanceDelayTime: 15,
    },
    [CourseOptions.DisplayStats]: false,
    [CourseOptions.IsVirtual]: true,
    [CourseOptions.IsVisible]: true,
    [CourseOptions.RepeatAlgorithm]: false,
    [CourseOptions.MandatoryDataFill]: true,
    [CourseOptions.EnableMeetingUnassigned]: false,
    [CourseOptions.maxEnrollStudents]: 500,
    [CourseOptions.studentEnrollmentsAvailable]: 500,
    [CourseOptions.AskForTermsAndConditions]: false,
}

export type CourseOptionsTypes = typeof defaultOptions

export function useCourse(
    //input
    course?: string,
    // eslint-disable-next-line no-console
    onError = console.error
): {
    //output
    id?: string
    name?: string
    hq: {
        id?: string
    }
    members: MembersMap | undefined | null

    // eslint-disable-next-line no-undef
    setMembers: typeof membersSetter
    options: CourseOptionsTypes

    // eslint-disable-next-line no-undef
    setOptions: typeof setOptions
} {
    const [members, setMembers] = React.useState<MembersMap | undefined | null>(undefined)
    const [config, setConfig] = React.useState<IGETEndpoint['course'] | undefined | null>(undefined)

    const fetch = React.useCallback(async () => {
        try {
            if (!course) return
            const response = await Axios.get<IGETEndpoint>(Config.API + '/class/' + course)
            const { students, professors, tutors } = response.data
            setMembers({
                [ROLES.STUDENT]: students,
                [ROLES.PROFESSOR]: tutors,
                [ROLES.COACH]: professors,
            })
            setConfig(response.data.course)
        } catch (error) {
            setMembers(null)
            setConfig(null)
            onError(error)
        }
    }, [onError, setMembers, course])

    React.useEffect(() => {
        fetch()
    }, [fetch])

    const setOptions = async <K extends keyof CourseOptionsTypes>(
        option: K,
        value: CourseOptionsTypes[K]
    ) => {
        if (!config) return
        const response = await Axios.patch(`${Config.API}/class/${course}`, { [option]: value })
        setConfig({ ...config, [option]: value })
        return response.data
    }

    type IPost = unknown
    interface IDelete {
        user: { _id: string }
        profile: { _id: string }
    }

    async function membersSetter(user: string, role: ROLES): Promise<IPost>
    async function membersSetter(user: string, role: null): Promise<IDelete>
    async function membersSetter(user: string, role: ROLES | null): Promise<IPost | IDelete> {
        if (role !== null) {
            // Add the user to the course

            if (role !== ROLES.STUDENT) throw Error(`Not implemented for role=${role}`)

            const response = await Axios.post(`${Config.API}/profile/`, {
                type: role,
                _courseId: course,
                name: '',
                lastname: '',
                email: '',
                password: '',
                password_confirmation: '',
                _userId: user,
            })
            await fetch()
            return response.data
        } else {
            // Remove the user from the course
            const response = await Axios.delete<IDelete>(
                `${Config.API}/class/${course}/student/${user}`
            )

            await fetch()
            return response.data
        }
    }

    return {
        id: course,
        name: config?.name,
        hq: {
            id: config?._hqId,
        },
        members,
        setMembers: membersSetter,
        options: config
            ? {
                  [CourseOptions.CanExam]: config[CourseOptions.CanExam],
                  [CourseOptions.CanContent]: config[CourseOptions.CanContent],
                  [CourseOptions.CanReassign]: config[CourseOptions.CanReassign],
                  [CourseOptions.InitialTable]: config[CourseOptions.InitialTable],
                  [CourseOptions.MandatoryVote]: config[CourseOptions.MandatoryVote],
                  [CourseOptions.CanRubric]: config[CourseOptions.CanRubric],
                  [CourseOptions.AttendanceInterval]: config[CourseOptions.AttendanceInterval],
                  [CourseOptions.ReassignPresents]: config[CourseOptions.ReassignPresents] ?? false,
                  [CourseOptions.DisplayStats]: config[CourseOptions.DisplayStats],
                  [CourseOptions.IsVirtual]: config[CourseOptions.IsVirtual],
                  [CourseOptions.IsVisible]: config[CourseOptions.IsVisible],
                  [CourseOptions.RepeatAlgorithm]: config[CourseOptions.RepeatAlgorithm],
                  [CourseOptions.MandatoryDataFill]: config[CourseOptions.MandatoryDataFill],
                  [CourseOptions.EnableMeetingUnassigned]:
                      config[CourseOptions.EnableMeetingUnassigned],
                  [CourseOptions.maxEnrollStudents]: config[CourseOptions.maxEnrollStudents],
                  [CourseOptions.studentEnrollmentsAvailable]:
                      config[CourseOptions.studentEnrollmentsAvailable],
                  [CourseOptions.AskForTermsAndConditions]:
                      config[CourseOptions.AskForTermsAndConditions],
              }
            : defaultOptions,
        setOptions,
    }
}

export default useCourse
