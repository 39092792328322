import { useQuery } from 'react-query'
import { getUsersGraph } from '../api-reports'
import { useTeam } from './useTeam'
import { useReports } from '../Context/ReportsContext'

export const useUsers = () => {
    const { courseID: courseId } = useTeam()
    const { reportQuery } = useReports()
    const { cohorts } = reportQuery
    const { data: usersGraph, ...rest } = useQuery(
        [courseId, 'Reports', 'Users', 'Graphs', `Cohorts: ${cohorts?.join()}`],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        () => getUsersGraph({ courseId: courseId!, cohorts }),
        {
            enabled: !!courseId && !!cohorts,
            staleTime: 1000 * 60 * 60,
        }
    )

    return { usersGraph, ...rest }
}
