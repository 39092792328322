import React, { useEffect, useRef, useState } from 'react'
import JitsiAPI from '../../helpers/jitsi/index'
import { uuidv4 } from '../../helpers/random'
import i18n from '../../i18n'
import { colors } from '../../styles/globals/_variables'

interface JitsiProps extends Partial<JitsiAPI.Options> {
    domain?: string
    password?: string
    onJoined?: (api: JitsiAPI) => unknown
    onLoad?: (api: JitsiAPI) => unknown
    onClose?: (api: JitsiAPI) => unknown
}

const Jitsi = (props: JitsiProps) => {
    const divRef = useRef<HTMLDivElement>(null)
    const { domain, password, onJoined, onLoad, onClose, ...options } = {
        ...Jitsi.defaultProps,
        ...props,
        configOverwrite: {
            ...Jitsi.defaultProps?.configOverwrite,
            ...props.configOverwrite,
            defaultLanguage: i18n.language.substr(0, 2),
        },
        interfaceConfigOverwrite: {
            ...Jitsi.defaultProps?.interfaceConfigOverwrite,
            ...props.interfaceConfigOverwrite,
        },
    }

    const api = useJitsiIframe(divRef, domain, options, onLoad)

    useJitsiPassword(api, password)
    useJitsiEffect(api, 'videoConferenceJoined', () => api && onJoined?.(api), [api, onJoined])
    useJitsiEffect(api, 'readyToClose', () => api && onClose?.(api), [api, onClose])
    //useJitsiCommandEffect(api, 'overwriteConfig', options.configOverwrite)

    return <div style={{ display: 'contents' }} ref={divRef} />
}

Jitsi.defaultProps = {
    width: '100%',
    height: '100%',
    domain: 'meet.jit.si',
    roomName: uuidv4(),
    configOverwrite: {
        prejoinPageEnabled: false,
        disableDeepLinking: true,
    },
    interfaceConfigOverwrite: {
        LANG_DETECTION: false,
        DEFAULT_BACKGROUND: colors.darkGreyEgg,
    },
}

/**
 * Base Hook to interact with the eventListener of the JitsiMeetAPI
 */
export const useJitsiEffect = <T extends keyof JitsiAPI.Events>(
    api: JitsiAPI | undefined,
    type: T,
    effect: (payload: JitsiAPI.Events[T]) => unknown,
    dependencies: React.DependencyList
) => {
    useEffect(() => {
        if (!api) return
        const listener: typeof effect = (payload) => effect(payload)
        api.addEventListener(type, listener)
        return () => {
            api.removeEventListener(type, listener)
        }
        // Do not change when effect changes as it is the effect!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, type, ...dependencies])
}

/**
 *
 * */
export const useJitsiCommandEffect = <T extends keyof JitsiAPI.Commands>(
    api: JitsiAPI | undefined,
    type: T,
    ...args: JitsiAPI.Commands[T]
) => {
    useEffect(() => {
        api?.executeCommand(type, ...args)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, type, ...args])
}

/**
 * This hooks set the password for the room and enters it when needed.
 */
const useJitsiPassword = (api: JitsiAPI | undefined, password: string | undefined) => {
    const insert = () => password && api?.executeCommand('password', password)
    useJitsiEffect(api, 'passwordRequired', insert, [password])
    useJitsiEffect(api, 'videoConferenceJoined', insert, [password])
}

/**
 * This creates the Jitsi iframe and return the JitsiMeetAPI instace when loaded.
 * */
const useJitsiIframe = <T extends HTMLElement>(
    ref: React.RefObject<T>,
    domain: string,
    options: Partial<JitsiAPI.Options>,
    onLoad?: (api: JitsiAPI) => unknown
) => {
    const [api, setApi] = useState<JitsiAPI>()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const instance = new JitsiAPI(domain, {
            parentNode: ref.current as HTMLElement,
            ...options,
            onload: () => {
                onLoad?.(instance)
                setApi(instance)
                // Make something when loaded
            },
        })

        return () => {
            instance.executeCommand('hangup')
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (!ref.current) return
            instance.dispose()
        }
        // Options is not needed cause is handled on the other useEffect
        // onLoad is not needed cause is a callback executed only when loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain, options.roomName])

    return api
}

export default Jitsi
