import React, { useState, useContext } from 'react'
import { UIContext } from '../../context/UIContext'
import styled, { css } from 'styled-components'

import { colors } from '../../styles/globals/_variables'
import { Link } from 'wouter'

import EggCard from './EggCard'
import CircularTable from './CircularTable'
import AvatarEgg from './AvatarEgg'
import { BtnPulse } from '../Buttons/BtnPulse'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MeetingService } from '../../hooks/ApiHooks/useMeetings'
import { useTranslation } from 'react-i18next'

import { SubjectStudentContext } from '../../context/SubjectStudentContext'

import { AxiosResponse } from 'axios'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { BtnCircular } from '../Buttons/BtnCircular'

import { NotificationDTO, useNotifications } from '../../hooks/useNotifications'
import UserContext from '../../context/UserContext'
import { capitalize } from '../../helpers/formatter'
import Medal from '../../assets/images/icons/medal.svg'
import LightningBlack from '../../assets/images/icons/bolt-solid.svg'
import Lightning from '../../assets/images/icons/bolt-gradient.svg'
import { useGAEvents } from '../../hooks/useGAEvents'

type IInteractiveTable = {
    table?: {
        facilitator: string
        tableNumber: number
        positions: {
            profile: {
                _id: string
            }
            user: {
                name: string
                lastname: string
                urlImage: string
            }
        }[]
    }
    // totalTables: number,
    onVoteTable: () => void
    votedTable: boolean
    voted: string
    vote?: (id?: string) => unknown
    tableLabel?: boolean
    toolbar?: boolean
    profileId: string
    showStdVoteBtn?: boolean
    sendAlert: (
        title: string,
        message: string,
        options: Record<string, unknown>
    ) => Promise<void | AxiosResponse<unknown>>
    meeting?: {
        service: MeetingService
        method: string
        general: string
        room: string
        rooms?: string[]
    }
    onMeeting?: (table?: number) => unknown
    voteOff?: boolean
    isVirtual?: boolean
    meetingEnabled: () => boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ownDataMeeting?: any
}

const InteractiveTable = (props: IInteractiveTable) => {
    const {
        table,
        onVoteTable,
        votedTable,
        voted,
        vote,
        toolbar,
        profileId,
        showStdVoteBtn,
        meeting,
        onMeeting,
        tableLabel,
        isVirtual,
        meetingEnabled,
        ownDataMeeting,
    } = props

    const [sync, setSync] = useState<number>()
    const { titleCaseFn, toastNotification } = useContext(UIContext)
    const { t } = useTranslation(['teamDetailStudent', 'notifications'])
    const breakpoint = useBreakpoint()
    const { sendNotification } = useNotifications()
    const { user } = useContext(UserContext)
    const { professorSchedule, teamData, ownData } = useContext(SubjectStudentContext)
    const displayName = `${capitalize(user?.name || '')} ${capitalize(user?.lastname || '')}`

    const { sendGA } = useGAEvents()

    // Enables table meeting button only if if is Virtual, meetingEnabled and if there is a meeting for my table.
    const enableTableButtons = () => {
        if (isVirtual) {
            if (meetingEnabled()) {
                return true
            }
        }
    }

    const shootMeeting = () => {
        if (ownDataMeeting?.meetingType === 'zoom-by-table') {
            if (ownDataMeeting?.meetings[0]) {
                sendGA({ category: 'Engagement', action: 'Join-Meeting' })
                window.open(ownDataMeeting?.meetings[0], '_blank', 'noopener noreferrer')
            } else {
                toastNotification(t('no zoom by table link'), 'error')
            }
        } else {
            if (!onMeeting) return
            sendGA({ category: 'Engagement', action: 'Join-Meeting' })
            onMeeting(table?.tableNumber)
        }
    }
    const showMeetingButton = () => {
        if (meeting) {
            return true
        }
    }

    const showHandButton = () => {
        if (meeting && meeting?.service !== MeetingService.ZOOM) {
            return true
        }
    }

    // Send help request notification to coach
    const handleHandUp = () => {
        if (!teamData || !ownData) return

        const { guide, tableNumber } = ownData.table
        const { _id: courseID, name } = teamData.course
        const teamLabel = `${tableNumber || ''}${guide ? ` ${t('guide')} ${guide}` : ''}`
        const title = t('notifications:handUpTitle', { teamLabel })
        const courseName = capitalize(`${name}`)

        const message: NotificationDTO = {
            _id: '',
            status: 'unread',
            type: 'alert',
            message: t('notifications:handUpBody', { displayName, teamLabel, courseName }),
            createdAt: `${new Date().toISOString()}`,
            action: {
                type: 'search-by',
                courseId: courseID,
                searchType: 'teams',
                searchQuery: tableNumber,
                _helpRequestId: 'none',
                closeHelpRequest: false,
            },
        }

        sendNotification(title, JSON.stringify(message), {
            _professorScheduleId: professorSchedule?.id,
        })
    }

    return (
        <Table>
            <div className="col">
                <EggCard centeredContent={true} fullHeight={true}>
                    <div className="table-container">
                        <CircularTable className="students-table-view" diameter={80}>
                            {table?.positions.map((item, index) => {
                                return (
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        key={item.profile._id}
                                        onMouseEnter={() => setSync(index)}
                                    >
                                        <Link
                                            title={`${item.user.name} ${item.user.lastname}`}
                                            to={`/profile/${item.profile._id}/from/${professorSchedule?.id}/subject/${teamData?.goal._id}`}
                                        >
                                            <AvatarEgg
                                                src={item.user.urlImage}
                                                index={index.toString()}
                                                pointed={sync?.toString()}
                                                size={80}
                                                // badge={item.profile._id === table.facilitator ? ['fas','star'] : null}
                                                facilitator={item.profile._id === table.facilitator}
                                                place={'table'}
                                            />
                                            {item.profile._id === table.facilitator && (
                                                <img
                                                    src={Medal}
                                                    alt=""
                                                    className="facilitator-badge"
                                                />
                                            )}
                                        </Link>
                                    </div>
                                )
                            })}
                        </CircularTable>
                    </div>
                    {toolbar && (
                        <div className="v-tools-bar">
                            <ul>
                                {showHandButton() && (
                                    <li>
                                        <button
                                            className="action-link"
                                            onClick={() => handleHandUp()}
                                            disabled={!enableTableButtons()}
                                        >
                                            <FontAwesomeIcon
                                                className="icon"
                                                icon={['fal', 'hand-paper']}
                                            />
                                        </button>
                                    </li>
                                )}
                                {showMeetingButton() && (
                                    <div>
                                        {ownDataMeeting?.meetingType !== 'zoom-by-table' && (
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        shootMeeting()
                                                    }}
                                                    disabled={
                                                        !enableTableButtons() ||
                                                        meeting?.room?.length === 0
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        icon={['fal', 'video']}
                                                    />
                                                </button>
                                            </li>
                                        )}

                                        {ownDataMeeting?.meetingType === 'zoom-by-table' && (
                                            <li>
                                                <button
                                                    onClick={() => {
                                                        shootMeeting()
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        icon={['fal', 'video']}
                                                    />
                                                </button>
                                            </li>
                                        )}
                                    </div>
                                )}
                            </ul>
                        </div>
                    )}
                    {tableLabel && (
                        <span className="table-number-label">
                            <strong>
                                {t('teamDetailStudent:table.label', { number: table?.tableNumber })}
                            </strong>
                            <BtnCircular
                                svg={LightningBlack}
                                svgOver={Lightning}
                                isActive={votedTable}
                                toolTips={t('table.pulse-tooltip')}
                                onClick={() => onVoteTable()}
                            />
                        </span>
                    )}
                </EggCard>
            </div>
            <div className="col items">
                {table?.positions.map((item, index) => {
                    return (
                        <ListItem
                            onMouseEnter={() => setSync(index)}
                            index={index.toString()}
                            pointed={sync?.toString()}
                            key={item.profile._id}
                            isFacilitator={item.profile._id === table.facilitator}
                        >
                            <div className="left">
                                <div className="pos global-text">{index + 1}</div>
                                <div className="info">
                                    <div className="name global-text">{`${titleCaseFn(
                                        item.user.name
                                    )} ${titleCaseFn(item.user.lastname)}`}</div>
                                    {item.profile._id === table.facilitator && (
                                        <span className="facilitator-label">
                                            {t('placeholders.facilitators.title')}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="right">
                                {showStdVoteBtn && (
                                    <BtnPulse
                                        onClick={() => vote?.(item.profile._id)}
                                        className={
                                            voted === item.profile._id
                                                ? 'active'
                                                : profileId === item.profile._id
                                                ? ''
                                                : 'inactive'
                                        }
                                        disabled={profileId === item.profile._id}
                                    >
                                        <img
                                            src={
                                                profileId === item.profile._id
                                                    ? LightningBlack
                                                    : Lightning
                                            }
                                        ></img>
                                        {breakpoint && breakpoint > 900 && t('table.vote-label')}
                                    </BtnPulse>
                                )}
                            </div>
                        </ListItem>
                    )
                })}
            </div>
        </Table>
    )
}

export default InteractiveTable

const Table = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fbfbfb;
    border-radius: 30px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    .facilitator-badge {
        position: absolute;
        bottom: 0px;
        right: -4px;
    }
    .col {
        flex: 1 1 0;
        position: relative;
        .v-tools-bar {
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: 0;
            height: auto;
            /* left: calc(50% - 46px); */
            /* transform: translateX(50%); */
            @media screen and (min-width: 768px) {
                width: auto;
                height: 100%;
                flex-direction: column;
                top: 0;
                left: 20px;
            }
            ul {
                margin: auto;
                list-style-type: none;
                margin-left: 0;
                padding-left: 0;
                display: flex;
                @media screen and (min-width: 768px) {
                    display: block;
                }
                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 46px;
                    height: 46px;
                    &:hover::not(disabled) {
                        border: 1px solid ${colors.borders};
                        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
                        border-radius: 8px;
                    }
                    button {
                        background: none;
                        border: none;
                        padding: 5px;
                        margin: 10px 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .icon {
                            font-size: 1.5rem;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                        &:disabled {
                            cursor: default;
                        }
                    }
                }
            }
        }
        .table-number-label {
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 40px;
            top: 15px;

            width: 100%;
            text-align: center;
            font-size: 1.1rem;
            margin: 0 auto;
        }
    }
    .items {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

type IListItem = {
    pointed?: string
    index?: string
    isFacilitator?: boolean
}

const ListItem = styled.div<IListItem>`
    display: flex;
    /* flex-direction:column; */
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    margin: 0 15px;
    transition: all 0.1s ease-in-out;
    min-height: 80px;
    border-bottom: 1px solid ${colors.borders};
    &:last-child {
        border-bottom: none;
    }
    @media screen and (min-width: 768px) {
        margin: 0 32px;
        padding: 20px 30px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: none;
    }
    &:hover {
        background-color: white;
        border-radius: 15px;
    }
    ${(props) =>
        props.pointed &&
        props.index === props.pointed &&
        css`
            background-color: white;
            border-radius: 15px;
        `}
    .left {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        @media screen and (min-width: 768px) {
            margin-bottom: 0;
        }
        .pos {
            font-weight: bold;
            /* border-right:1px solid ${colors.borders}; */
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 34px;
            height: 34px;

            ${(props) =>
                props.isFacilitator &&
                css`
                    background-color: ${colors.eggColor};
                    border-radius: 34px;
                `}
        }
        .info {
            padding-left: 20px;
            .facilitator-label {
                font-size: 0.7rem;
                display: block;
            }
        }
    }
`

// Interfaces
