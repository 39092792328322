import { ReactElement, useState } from 'react'
import makeMatcher from 'wouter/matcher'
import useLocation from 'wouter/use-location'
import { useEffect } from 'react'

const defaultMatcher = makeMatcher()

interface RouteFunction {
    (params: Params, location: string): ReactElement
}
interface Params<T = string> {
    [param: string]: T
}

export interface RouteType {
    [path: string]: RouteFunction | string | [RouteFunction | string, RouteType] | RouteType
}

const multipleMatcher = (
    routes: RouteType,
    path: string,
    base = ''
): [ReactElement | string | undefined, Params] => {
    for (const key in routes) {
        const selected = routes[key]
        if (typeof selected === 'object') {
            /** Nested routes! */
            const [partialMatch] = defaultMatcher(base + key + '/:rest*', path)
            if (partialMatch) {
                if (!Array.isArray(selected)) {
                    return multipleMatcher(selected, path, base + key)
                }
                const [match, params] = defaultMatcher(base + key, path)
                const [self, nested] = selected
                //console.log(base+key, path, match, params)
                if (match) {
                    return [
                        typeof self === 'string' ? self : self(params ?? {}, path),
                        params ?? {},
                    ]
                } else {
                    return multipleMatcher(nested, path, base + key)
                }
            }
        } else {
            /** End of routes */
            const [match, params] = defaultMatcher(base + key, path)
            //console.log(base+key, path, match, params)
            if (match) {
                if (typeof selected === 'string') {
                    return [selected, params ?? {}]
                } else {
                    return [selected(params ?? {}, path), params ?? {}]
                }
            }
        }
    }
    return [undefined, {}]
}

const useRouter = (
    routes: RouteType | undefined,
    base = ''
): [ReactElement | null | undefined, Params, string] => {
    const [location, setLocation] = useLocation()
    const [match, setMatch] = useState<ReactElement | null | undefined>(undefined)
    const [params, setParams] = useState<Params>({})

    //console.log("The routes are: ", routes, location, match, params)

    useEffect(() => {
        setMatch(undefined)
        setParams({})
    }, [routes])

    useEffect(() => {
        if (!routes) return
        const result = multipleMatcher(routes, location, base)

        const [chosen, params] = result
        if (chosen === undefined) {
            setMatch(null)
            setParams({})
        } else if (typeof chosen === 'string') {
            setLocation(chosen)
            setParams({})
        } else {
            setMatch(chosen)
            setParams(params)
        }
    }, [location, routes, setLocation, base])

    if (!routes) return [undefined, {}, location]

    return [match, params, location]
}

export default useRouter
