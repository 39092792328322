// API
import React, { useState, useContext } from 'react'
import Axios from 'axios'
import { useLocation } from 'wouter'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
// Helpers
import Config from '../../config'
import storageHelper from '../../helpers/storage'
//Context
import { WizardContext } from '../../context/WizardContext'
import { UserContext } from '../../context/UserContext'
import { UIContext } from '../../context/UIContext'
// Components
import Step4View from './Step4View'
// Assets
import { useTranslation } from 'react-i18next'

// Checks if selected schedules are an array longer than 1 ???
const isValidScheduleFormat = (schedules) => {
    return schedules && Array.isArray(schedules) && schedules.length
}

const Step4 = () => {
    const { t } = useTranslation(['wizard'])

    // Context
    const {
        hqSelected,
        courseSelected,
        subjectSelected,
        schedulesSelected,
        setSchedulesSelected,
    } = useContext(WizardContext)

    const { _setProfileId, setRole, user, setUserButNotSaveIt } = useContext(UserContext)

    // Inicia todos los checkbox en false
    let initSchedules = [
        {
            name: t('wizard:step4.days.monday'),
            checked: false,
            idx: 1,
            start: undefined,
            end: undefined,
        },
        {
            name: t('wizard:step4.days.tuesday'),
            checked: false,
            idx: 2,
            start: undefined,
            end: undefined,
        },
        {
            name: t('wizard:step4.days.wednesday'),
            checked: false,
            idx: 3,
            start: undefined,
            end: undefined,
        },
        {
            name: t('wizard:step4.days.thursday'),
            checked: false,
            idx: 4,
            start: undefined,
            end: undefined,
        },
        {
            name: t('wizard:step4.days.friday'),
            checked: false,
            idx: 5,
            start: undefined,
            end: undefined,
        },
        {
            name: t('wizard:step4.days.saturday'),
            checked: false,
            idx: 6,
            start: undefined,
            end: undefined,
        },
        {
            name: t('wizard:step4.days.sunday'),
            checked: false,
            idx: 0,
            start: undefined,
            end: undefined,
        },
    ]

    // State
    const [isNextStepEnabled] = useState(isValidScheduleFormat(schedulesSelected))
    const [days, setDays] = useState(initSchedules)

    // Hooks
    const [, setLocation] = useLocation()

    // Sets infoBlock error / success
    const { setInfoBlock } = useContext(UIContext)

    if (isValidScheduleFormat(schedulesSelected)) {
        initSchedules.forEach((item, index, arr) => {
            arr[index].checked = schedulesSelected.includes(item.idx)
        })
    }

    const onChangeCheckbox = (d) => {
        setDays(
            days.map((day) => {
                if (day.idx === d.idx) {
                    return Object.assign({}, day, { checked: !d.checked })
                } else {
                    return day
                }
            })
        )
    }

    /**
     *
     * @param {number} idx Index to Change
     * @param {'start' | 'end'} type
     * @returns An function that recieves an event and make all the logic
     */
    const onChangeClassTime = (idx, type) => (e) => {
        const newDays = days
        const day = newDays.find((e) => e.idx === idx)
        day && (day[type] = e.target.value)
        setDays(newDays)
        // console.log(time)
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        const schedules = days
            .filter((d) => d.checked)
            .map((fd) => ({
                start: dayjs(dayjs().format('YYYY-MM-DD') + ' ' + fd.start, 'HH:mm')
                    .day(fd.idx)
                    .utc()
                    .format(' YYYY-MM-DD[T]HH:mm:ss[Z]'),
                end: dayjs(dayjs().format('YYYY-MM-DD') + ' ' + fd.end, 'HH:mm')
                    .day(fd.idx)
                    .utc()
                    .format(' YYYY-MM-DD[T]HH:mm:ss[Z]'),
            }))

        setSchedulesSelected(schedules)
        let body = {
            hq: hqSelected,
            course: courseSelected,
            subject: subjectSelected,
            schedules,
        }

        try {
            const response = await Axios.post(Config.API + '/wizard', body)
            const { token, _userId, _profileId, role, professorSchedule, subject } = response.data
            storageHelper.setToken(token)
            // Sets data on context.
            role && setRole(role)
            _userId && setUserButNotSaveIt(Object.assign({}, user, { _id: _userId }))
            _profileId && _setProfileId(_profileId)
            // storageHelper.setRole(role);
            // storageHelper.setItem('_id', _id);
            setLocation('/team/' + professorSchedule._id + '/subject/' + subject._id + '/wizard')
            //setLocation(`/dashboard`)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            setInfoBlock([
                'error',
                error.data && error.data.message
                    ? error.data.message
                    : 'Ups! Ocurrió un error. Intenta nuevamente.',
            ])
        } finally {
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }

    return (
        <Step4View
            hqSelected={hqSelected}
            courseSelected={courseSelected}
            subjectSelected={subjectSelected}
            schedulesSelected={schedulesSelected}
            setSchedulesSelected={setSchedulesSelected}
            isNextStepEnabled={isNextStepEnabled}
            onChangeCheckbox={onChangeCheckbox}
            onChangeClassTime={onChangeClassTime}
            days={days}
            onSubmit={onSubmit}
        />
    )
}

export default Step4
