/* eslint-disable react/prop-types */
// API
import React, { useContext } from 'react'
import Axios from 'axios'
import * as firebase from 'firebase/app'
// Helpers and libs
import Config from '../../config'
// Context
import { useLocation } from 'wouter'
import { UIContext } from '../../context/UIContext'
// Hooks
import { useTranslation } from 'react-i18next'
// Components
import SignupView from './SignupView'

const SignupContainer = ({ mode }) => {
    // Hooks
    const [location, setLocation] = useLocation()

    const { t } = useTranslation(['errors'])

    // Gets token param from search query if exists
    const getTokenFromParams = () => {
        const params = new URLSearchParams(location.search)
        const token = params.get('token') // bar
        return token
    }

    // Sets infoBlock error / success
    const { setInfoBlock } = useContext(UIContext)

    const translateErrors = (error) => {
        // eslint-disable-next-line no-console
        console.error(error)
        switch (error.data.message) {
            case 'Email already exists':
                return t('errors:login.emailTaken')
            case error.data.message:
                return error.data.message
            default:
                return t('erros:generic')
        }
    }

    const onSubmit = async (values) => {
        const { email, name, lastname, password } = values
        let password_confirmation = password
        try {
            const token = getTokenFromParams()
            await Axios.post(Config.API + '/user/signup/' + mode, {
                token,
                email,
                name,
                lastname,
                password,
                password_confirmation,
            })

            // Logs in user on firebase and sends verification email.
            const FirebaseAuth = firebase.auth()
            FirebaseAuth.signInWithEmailAndPassword(email, password)
        } catch (error) {
            // console.log("error", error);
            setInfoBlock(['error', translateErrors(error)])
            if (error.data.message === 'Email already exists') {
                setLocation('/')
            }
        } finally {
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
        }
    }

    return <SignupView onSubmit={onSubmit} />
}

export default SignupContainer
