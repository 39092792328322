import styled from 'styled-components'
import baseTheme from '../../../themes/baseTheme'
import { flex } from '../../../utils/mixins'

export const HeaderBannerContainer = styled.div`
    ${flex('center', 'space-between')};
    background: ${baseTheme.colors['003750']};
    border-radius: 10px;
    height: 100px;
    padding: 32px;
    text-decoration: none;
`

export const HeaderBannerContent = styled.div`
    ${flex('center', 'space-between')};
    gap: 18px;
`

export const HeaderBannerTitle = styled.h1`
    color: ${baseTheme.colors['ffffff']};
    font-family: ${baseTheme.typography.fonts.inter};
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 0px;
    margin: 0;
    text-align: left;
`

export const HeaderBannerSubtitle = styled.h2`
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0px;
    margin: 1em 0;
    text-align: left;
`
