// API
import React, { useContext } from 'react'
// Helpers
//Context
import { UserContext } from '../../../context/UserContext'
// Components
import TeamsCreateView from './TeamsCreateView'
// Assets

const TeamsCreateContainer = () => {
    const { user } = useContext(UserContext)

    return <TeamsCreateView user={user} />
}

export default TeamsCreateContainer
