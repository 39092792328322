import React from 'react'
import ToolTip from './Styled/ToolTip'
import { copyToClipboard, getAppUrl } from '../helpers/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getOrganizationReferral } from '../apis/api.signup'
import { useQuery } from 'react-query'
import { useTeam } from '../pages/Team/Context/TeamContext'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../context/UIContext'

export const LinkInvitation = () => {
    const { t } = useTranslation('teamMonitor')
    const { toastNotification, toastError } = useUIContext()
    const { courseID } = useTeam()
    const { data } = useQuery(
        ['Referral Organization', courseID],
        () => getOrganizationReferral(`${courseID}`),
        {
            enabled: !!courseID,
            onError: toastError,
        }
    )
    const { referral, actNetworkId } = data ?? {}
    let referrer: string | undefined = undefined

    if (referral) referrer = `&referrer=${encodeURIComponent(referral)}`

    const invitationLink = `${getAppUrl()}/registro?redirect=/marketplace/${actNetworkId}/enroll${
        referrer ?? ''
    }`

    const copyInvitationLink = async () => {
        copyToClipboard(invitationLink)
        toastNotification(t('The invitation link has been copied to the clipboard.'))
    }
    const InvitationButton = () => (
        <button
            key={'collaborator'}
            onClick={() => copyInvitationLink()}
            className="outlined"
            style={{ fontSize: '13px', fontWeight: 'bold' }}
            disabled={!referral}
        >
            <FontAwesomeIcon icon={['far', 'user-plus']} />
            {t('Invitation link')}
        </button>
    )

    return referral ? (
        <InvitationButton />
    ) : (
        <ToolTip placement="topEnd" text={t('The organization has no referral')}>
            <InvitationButton />
        </ToolTip>
    )
}
