/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react'
import UserContext from '../../../context/UserContext'

export const FillInfoContext = React.createContext()

export const FillInfoContextProvider = ({ children }) => {
    const { user } = useContext(UserContext)
    const [step, setStep] = useState(user?.settings?.mandatoryDataFill ? 1 : 2)
    const [, setLocation] = useState(undefined)
    const [updatedUser, setUpdatedUser] = useState({})

    const nextStep = () => {
        if (step < 3) setStep(step + 1)
    }

    const initialValues = {
        step,
        setStep,
        nextStep,
        setLocation,
        updatedUser,
        setUpdatedUser,
    }

    return <FillInfoContext.Provider value={initialValues}>{children}</FillInfoContext.Provider>
}
