import { ManageMeetingCustomStyles, ManageMeetingModal } from './ManageMeeting.modal'
import { ROLES, isRoleAllowed } from '../../../../../constants'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import useSearchParam from '../../../../../hooks/useSearchParams'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../context/UIContext'
import { useUser } from '../../../../../context/UserContext'

export const ManageMeetingButton = () => {
    const { t } = useTranslation('teamMonitor')
    const { setModal } = useUIContext()
    const { role } = useUser()
    const [manageMeetings, setManageMeetings] = useSearchParam('manage-meetings')

    const needsToOpen = manageMeetings === 'true'
    const openModal = () =>
        setModal({
            content: <ManageMeetingModal />,
            customStyles: ManageMeetingCustomStyles,
        })

    useEffect(() => {
        if (!needsToOpen) return
        openModal()
        setManageMeetings(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Minimum role: coach
    if (!isRoleAllowed(ROLES.COACH, role)) return null

    return (
        <button className="primary" onClick={openModal}>
            <FontAwesomeIcon icon={['fas', 'sliders']} />
            {t('Manage meeting')}
        </button>
    )
}
