/** Play a sound from url */
export const playSound = (src: string, loop = false) => {
    const sound = new Audio(src)
    sound.loop = loop
    sound.addEventListener('canplaythrough', sound.play)
}

export enum SOUNDS {
    SILENT = '/sounds/silence.mp3',
    DEFAULT = '/sounds/default.mp3',
}
