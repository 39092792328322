import { GetTeamResponse } from '../../../apis/api.signup'
import { MeetButton } from './MeetButton'
import { useTeam } from '../Context/TeamContext'

const TeamHeader = (props: Props) => {
    const { algorithmID } = useTeam()

    if (!algorithmID) return null

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 18,
                marginRight: 18,
                paddingRight: 18,
                borderRight: '1px solid #eaeaea',
            }}
        >
            <MeetButton {...props} />
        </div>
    )
}

export default TeamHeader

interface Props extends Team {
    isExpanded?: boolean
    toggleExpand?: () => void
}
export type Team = GetTeamResponse['data']['teams'][0]
