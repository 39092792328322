import React, { useContext, useState } from 'react'
import MainLayout from '../../../layouts/MainLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UIContext from '../../../context/UIContext'
import { ShouldVoteType } from '../../../hooks/ApiHooks/useVote'
import { useTranslation } from 'react-i18next'
import AvatarEgg from '../../../components/Styled/AvatarEgg'
import Lightning from '../../../assets/images/icons/bolt-gradient.svg'
import { useBreakpoint } from '../../../hooks/useBreakpoint'
import { BtnPulse } from '../../../components/Buttons/BtnPulse'

interface Props {
    candidates: ShouldVoteType['candidates']
    onVote: ShouldVoteType['vote']
}
const MandatoryVote = ({ candidates, onVote }: Props) => {
    const { t } = useTranslation(['mandatoryVote'])
    const { setInfoBlock, titleCaseFn } = useContext(UIContext)
    const [value, setValue] = useState<string>()
    const [submitting, setSubmitting] = useState<boolean>()
    const breakpoint = useBreakpoint()

    const onSubmit = async () => {
        if (submitting) return

        // If the user didn't vote, show a confirm message.
        if (value || window.confirm(t('mandatoryVote:confirm-skip-vote'))) {
            try {
                setSubmitting(true)
                await onVote(value ?? null)
                setInfoBlock(['success', t('mandatoryVote:vote-success')])
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                setInfoBlock([
                    'error',
                    error.message || error.data?.message || t('mandatoryVote:generic-error'),
                ])
            } finally {
                setSubmitting(false)
                setTimeout(() => setInfoBlock([]), 2000)
            }
        }
    }

    return (
        <MainLayout>
            <div className="mandatory-vote">
                <h2 className="title">{t('mandatoryVote:vote-title')}</h2>
                <p className="desc">{t('mandatoryVote:vote-desc')}</p>
                <div className="vote-table">
                    {candidates.map((student) => (
                        <div className="item" key={student._profileId}>
                            <span className="position">{student.number}</span>
                            <div className="avatar-container">
                                <AvatarEgg src={student._userId.urlImage} />
                            </div>
                            <span className="name">
                                {titleCaseFn(student._userId.name)}{' '}
                                {titleCaseFn(student._userId.lastname)}
                            </span>

                            <BtnPulse
                                onClick={() =>
                                    setValue(
                                        student._profileId !== value
                                            ? student._profileId
                                            : undefined
                                    )
                                }
                                className={student._profileId === value ? 'active' : 'inactive'}
                            >
                                <img src={Lightning}></img>
                                {breakpoint && breakpoint > 900 && t('mandatoryVote:vote-label')}
                            </BtnPulse>
                        </div>
                    ))}
                </div>
                <div className="action-buttons">
                    <button className={'submit-btn inactive'} onClick={onSubmit}>
                        {!submitting ? (
                            t('mandatoryVote:vote-skip')
                        ) : (
                            <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                        )}
                    </button>
                    <button
                        disabled={!value}
                        className={value ? 'submit-btn active' : 'submit-btn inactive'}
                        onClick={onSubmit}
                    >
                        {!submitting ? (
                            t('mandatoryVote:vote-submit')
                        ) : (
                            <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                        )}
                    </button>
                </div>
            </div>
        </MainLayout>
    )
}

export default MandatoryVote
