import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import LoadingPage from '../../../../Other/LoadingPage'
import { getNPSCsv } from '../../../api-reports'
import { OneData } from '../../../Components/Monitor/Cards/OneData'
import { ProgressBars, ProgressProps } from '../../../Components/Monitor/Cards/ProgressBars'
import { useCsvReport } from '../../../Hooks/useCsvReport'
import { useSurveys } from '../../../Hooks/useSurveys'
import { NoData } from '../ChartComponent'
import { ReportContainer } from '../ReportContainer'

export const SurveysReports = () => {
    const { t } = useTranslation('teamReports')
    const { getFilteredReport } = useCsvReport()

    const { graphNPS, isLoading } = useSurveys()

    const {
        promoters,
        promotersPercentage,
        cohortSize,
        passives,
        passivesPercentage,
        detractors,
        detractorsPercentage,
        nps,
        totalResponses,
    } = graphNPS || {}

    const npsCardData: ProgressProps[] = nps
        ? [
              {
                  icon: ['far', 'circle-check'],
                  caption: t('promoters'),
                  value: promoters,
                  percentage: promotersPercentage,
                  variant: 'egg',
              },
              {
                  icon: ['far', 'circle-play'],
                  caption: t('passives'),
                  value: passives,
                  percentage: passivesPercentage,
                  variant: 'info',
              },
              {
                  icon: ['far', 'circle-exclamation'],
                  caption: t('detractors'),
                  value: detractors,
                  percentage: detractorsPercentage,
                  variant: 'danger',
              },
          ]
        : []

    if (isLoading) return <LoadingPage />
    return (
        <SurveysReportsStyled>
            <ReportContainer
                header={{
                    title: 'Net Promoter Score (NPS)',
                    onClick: () => getFilteredReport(getNPSCsv, 'nps'),
                    disabled: !graphNPS,
                }}
                content={
                    <>
                        {!graphNPS ? (
                            <NoData />
                        ) : (
                            <>
                                <OneData
                                    title={`${t('Cohort Size')}: ${cohortSize}`}
                                    caption=""
                                    data={nps}
                                    footerIcon={undefined}
                                    footerData={undefined}
                                    footerText={undefined}
                                />
                                <ProgressBars
                                    title={`${t('Number of responses', { totalResponses })}`}
                                    metrics={npsCardData}
                                />
                            </>
                        )}
                    </>
                }
                className="row-display surveys-chart"
            />
        </SurveysReportsStyled>
    )
}

const SurveysReportsStyled = styled.section`
    & > .chart > .row-display {
        display: grid;
        grid-template-columns: 25% 75%;
        & > section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            & > * {
                border-bottom: none;
            }
        }
    }
`
