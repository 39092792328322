import React, { useContext, useState, useMemo, useEffect } from 'react'
import dayjs from 'dayjs'
import MainLayout from '../../layouts/MainLayout'
import ProfileHeading from './ProfileHeading'
import { useTranslation } from 'react-i18next'
import { UIContext } from '../../context/UIContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserContext } from '../../context/UserContext'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { ContextInterface } from '../../context/UserContext'
import styled from 'styled-components'
import HeaderBanner from '../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'

const DivError = styled.div`
    margin-top: 10px;
    margin-bottom: -10px;
    position: absolute;
`
export interface ProfileViewProps {
    user: ContextInterface['user']
    onEdit: ContextInterface['setUser']
    handleFileSelect?: (event: React.ChangeEvent<HTMLInputElement>) => unknown
    isEditable: boolean
    setIsEditable: (editable: boolean) => unknown
    imageIsLoading: boolean
}
const ProfilePageView = (props: ProfileViewProps) => {
    const {
        user: userContext,
        onEdit,
        handleFileSelect,
        isEditable,
        setIsEditable,
        imageIsLoading,
    } = props
    const { fetchUser } = useContext(UserContext)
    const { t } = useTranslation(['profile', 'errors'])
    const [user, setUser] = useState(userContext)
    const { setInfoBlock, titleCaseFn } = useContext(UIContext)
    const [sending, setSending] = useState(false)
    const requiredLabel = useMemo(() => t('errors:forms.requiredField'), [t])
    const typeNumber = useMemo(() => t('errors:forms.type-number'), [t])
    const isLong = useMemo(() => t('errors:forms.long-field'), [t])

    const [address, setAddress] = React.useState({
        street: '',
        floor: '',
        apartment: '',
        numeration: '',
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setAddress({
            ...address,
            [event.target.name]: value,
        })
    }

    const schema = yup.object().shape({
        name: yup.string().required(requiredLabel).max(50, isLong),
        lastname: yup.string().required(requiredLabel).max(50, isLong),
        dni: yup.string().typeError(typeNumber).required(requiredLabel),
        phone: yup.number().typeError(typeNumber).required(requiredLabel).positive(),
        address: yup.object().shape({
            city: yup.string().required(requiredLabel),
            country: yup.string().required(requiredLabel),
        }),
        birthdate: yup
            .date()
            .required(requiredLabel)
            .typeError(requiredLabel)
            .min('1930-01-01T00:00:00+00:00', t('errors:forms.invalid-date'))
            .max('2014-01-01T00:00:00+00:00', t('errors:forms.too-young'))
            .transform((curr, orig) => (orig === '' ? null : curr)),
    })

    const defaultValues = useMemo(
        () => ({
            name: titleCaseFn(user?.name ?? ''),
            lastname: titleCaseFn(user?.lastname ?? ''),
            dni: user?.dni ?? '',
            birthdate: user?.birthdate ? dayjs.utc(user.birthdate).format('YYYY-MM-DD') : '',
            phone: user?.phone ?? '',
            email: user?.email ?? '',
            address: {
                country: user?.address?.country ?? '',
                city: user?.address?.city ?? '',
            },
            genre: user?.genre ?? '',
        }),
        [user, titleCaseFn]
    )

    const { register, handleSubmit, errors, trigger } = useForm({
        mode: 'onBlur',
        defaultValues,
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        trigger()
    }, [t, trigger, defaultValues])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit: SubmitHandler<any> = async (data) => {
        if (!userContext) return

        const changes: Partial<typeof data> = {}
        for (const field in data) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (data[field] !== userContext[field] && data[field] !== undefined) {
                if (field === 'address') {
                    data[field].country = data[field]?.country?.trim()
                    data[field].city = data[field]?.city?.trim()
                }
                changes[field] = data[field]
            }
        }
        changes.birthdate = dayjs(changes.birthdate).toISOString()
        changes.phone = changes.phone.toString()
        changes.address.numeration = address.numeration
        changes.address.floor = address.floor.trim()
        changes.address.street = address.street.trim()
        changes.address.apartment = address.apartment.trim()

        try {
            setSending(true)
            // Check if there were changes
            if (JSON.stringify(changes) === '{}') {
                setInfoBlock(['success', t('profile:edit_no_changes')])
            } else {
                onEdit && (await onEdit(changes))
                setInfoBlock(['success', t('profile:edit_succes')])
            }
            setIsEditable(false)

            const updatedUser = {
                name: titleCaseFn(changes?.name ?? ''),
                lastname: titleCaseFn(changes?.lastname ?? ''),
                dni: changes?.dni ?? '',
                birthdate: changes?.birthdate
                    ? dayjs.utc(changes.birthdate).format('YYYY-MM-DD')
                    : '',
                phone: changes?.phone ?? '',
                email: changes?.email ?? '',
                address: {
                    street: changes?.address?.street ?? '',
                    numeration: changes?.address?.numeration ?? '',
                    floor: changes?.address?.floor ?? ' ',
                    apartment: changes?.address?.apartment ?? ' ',
                    country: changes?.address?.country ?? '',
                    city: changes?.address?.city ?? '',
                },
                genre: changes?.genre ?? '',
            }

            await setUser({ ...user, ...updatedUser })
            await fetchUser()
        } catch (error) {
            setInfoBlock([
                'error',
                error.message || (error.data && error.data.message) || t('errors:genericRetry'),
            ])
        } finally {
            setTimeout(() => {
                setInfoBlock([])
            }, 3000)
            setSending(false)
        }
    }

    const cancelEdit = () => {
        setUser(userContext)
        setIsEditable(false)
    }
    return (
        <MainLayout bgColor={'white'} ruta={t('profile:profile')}>
            <div className="container profile-detail-container">
                <HeaderBanner title={t('profile:profile')} />
                <div>
                    <ProfileHeading
                        name={user?.name ?? ''}
                        lastname={user?.lastname ?? ''}
                        statusMessage={user?.statusMessage}
                        isEditable={isEditable}
                        handleFileSelect={handleFileSelect}
                        imageIsLoading={imageIsLoading}
                    />
                </div>
                <div className="personal-data">
                    <div className="title-edit">
                        <h3>{t('profile:personal_info')}</h3>
                        <button
                            className="action-link edit-action-link"
                            onClick={() => {
                                !isEditable ? setIsEditable(true) : cancelEdit()
                            }}
                        >
                            <FontAwesomeIcon
                                className="icon"
                                icon={['fal', isEditable ? 'times' : 'pencil']}
                            />
                            <span>{isEditable ? t('profile:cancel_edit') : t('profile:edit')}</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="data-grid">
                            <div>
                                <div className="data-item">
                                    <span className="label">{t('profile:labels.name')}</span>
                                    <input
                                        disabled={!isEditable}
                                        className="data"
                                        name="name"
                                        maxLength={32}
                                        defaultValue={defaultValues.name}
                                        ref={register}
                                    />
                                    {isEditable && (
                                        <DivError>
                                            {errors.name && (
                                                <span className="input-error profile-field-error">
                                                    {errors.name.message}
                                                </span>
                                            )}
                                        </DivError>
                                    )}
                                </div>
                                <div className="data-item">
                                    <span className="label">{t('profile:labels.lastname')}</span>
                                    <input
                                        disabled={!isEditable}
                                        className="data"
                                        name="lastname"
                                        maxLength={32}
                                        defaultValue={defaultValues.lastname}
                                        ref={register}
                                    />
                                    {isEditable && (
                                        <DivError>
                                            {errors.lastname && (
                                                <span className="input-error">
                                                    {errors.lastname.message}
                                                </span>
                                            )}
                                        </DivError>
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="data-item">
                                    <span className="label">E-MAIL</span>
                                    <input
                                        disabled={true}
                                        className={!isEditable ? 'data' : 'dataInput'}
                                        name="email"
                                        defaultValue={defaultValues.email}
                                        maxLength={64}
                                        ref={register}
                                    />
                                    {isEditable && (
                                        <DivError>
                                            {errors.email && (
                                                <span className="input-error">
                                                    {errors.email.message}
                                                </span>
                                            )}
                                        </DivError>
                                    )}
                                </div>
                                <div className="data-item">
                                    <span className="label">
                                        {user?.address?.country === 'argentina'
                                            ? t('profile:labels.id')
                                            : user?.address?.country === 'chile'
                                            ? 'RUT'
                                            : 'ID'}
                                    </span>
                                    <input
                                        disabled={!isEditable}
                                        className="data"
                                        name="dni"
                                        maxLength={12}
                                        defaultValue={defaultValues.dni}
                                        ref={register}
                                    />
                                    {isEditable && (
                                        <DivError>
                                            {errors.dni && (
                                                <span className="input-error">
                                                    {errors.dni.message}
                                                </span>
                                            )}
                                        </DivError>
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="data-item">
                                    <span className="label">{t('profile:labels.birthdate')}</span>
                                    <input
                                        disabled={!isEditable}
                                        className="data"
                                        type="date"
                                        name="birthdate"
                                        defaultValue={defaultValues.birthdate}
                                        max={dayjs().format('YYYY-MM-DD')}
                                        ref={register}
                                    />
                                    {isEditable && (
                                        <DivError>
                                            {errors.birthdate && (
                                                <span className="input-error">
                                                    {errors.birthdate.message}
                                                </span>
                                            )}
                                        </DivError>
                                    )}
                                </div>
                                <div className="data-item">
                                    <span className="label">{t('profile:labels.phone')}</span>
                                    <input
                                        disabled={!isEditable}
                                        className="data"
                                        name="phone"
                                        defaultValue={defaultValues.phone}
                                        maxLength={16}
                                        ref={register}
                                    />
                                    {isEditable && (
                                        <DivError>
                                            {errors.phone && (
                                                <span className="input-error">
                                                    {errors.phone.message}
                                                </span>
                                            )}
                                        </DivError>
                                    )}
                                </div>
                            </div>
                            <div>
                                {(isEditable || defaultValues?.address?.country != '') && (
                                    <div className="data-item">
                                        <span className="label">{t('profile:labels.country')}</span>
                                        <select
                                            disabled={!isEditable}
                                            className="data country-selector"
                                            name="address.country"
                                            onChange={(e) => {
                                                if (user?.address !== undefined) {
                                                    user.address.country = e.target.value
                                                    setUser({ ...user })
                                                }
                                            }}
                                            defaultValue={defaultValues?.address?.country}
                                            ref={register}
                                        >
                                            <option value="argentina">Argentina</option>
                                            <option value="chile">Chile</option>
                                            <option value="rotw">Resto del mundo</option>
                                        </select>
                                    </div>
                                )}
                                {(isEditable || defaultValues?.address?.city != '') && (
                                    <div className="data-item">
                                        <span className="label">{t('profile:labels.city')}</span>
                                        <input
                                            disabled={!isEditable}
                                            className="data"
                                            name="address.city"
                                            maxLength={64}
                                            defaultValue={defaultValues?.address?.city}
                                            ref={register}
                                        />
                                        {isEditable && (
                                            <DivError>
                                                {errors?.address && (
                                                    <span className="input-error">
                                                        {errors.address?.city?.message}
                                                    </span>
                                                )}
                                            </DivError>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div>
                                {(isEditable || address?.street != '') && (
                                    <div className="data-item">
                                        <span className="label">{t('profile:labels.address')}</span>
                                        <input
                                            disabled={!isEditable}
                                            className="data"
                                            name="street"
                                            maxLength={128}
                                            onChange={handleChange}
                                            value={address.street}
                                        />
                                    </div>
                                )}
                                {(isEditable || address?.numeration != '') && (
                                    <div className="data-item">
                                        <span className="label">
                                            {t('profile:labels.numeration')}
                                        </span>
                                        <input
                                            disabled={!isEditable}
                                            className="data"
                                            name="numeration"
                                            maxLength={16}
                                            onChange={handleChange}
                                            value={address.numeration}
                                        />
                                    </div>
                                )}
                            </div>
                            <div>
                                {(isEditable || address?.floor != '') && (
                                    <div className="data-item">
                                        <span className="label">{t('profile:labels.floor')}</span>
                                        <input
                                            disabled={!isEditable}
                                            className="data"
                                            name="floor"
                                            maxLength={10}
                                            onChange={handleChange}
                                            value={address.floor}
                                        />
                                    </div>
                                )}
                                {(isEditable || address?.apartment != '') && (
                                    <div className="data-item">
                                        <span className="label">
                                            {t('profile:labels.apartment')}
                                        </span>
                                        <input
                                            disabled={!isEditable}
                                            className="data"
                                            maxLength={10}
                                            name="apartment"
                                            onChange={handleChange}
                                            value={address.apartment}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        {isEditable && (
                            <button className="btn-edit-data btn-yellow small" type="submit">
                                {!sending ? (
                                    t('profile:save')
                                ) : (
                                    <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                )}
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </MainLayout>
    )
}

export default ProfilePageView
