import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface IProps {
    label: string
    className: string
    icon: IconProp
    children: React.ReactElement
}

const ReportHeaderDropdown = (props: IProps) => {
    const reportDropdownRef = useRef<HTMLDivElement>(null)
    const [reportDropdown, setReportDropDown] = useState(false)

    useEffect(function reportDropDownClickAwayListener() {
        const clickAwayListener = (e: MouseEvent) =>
            e.composedPath().every((path) => path !== reportDropdownRef.current) &&
            setReportDropDown(false)
        document.addEventListener('click', clickAwayListener)
        return () => document.removeEventListener('click', clickAwayListener)
    }, [])

    return (
        <div className={'report-action ' + props.className ?? ''} ref={reportDropdownRef}>
            <div onClick={() => setReportDropDown(!reportDropdown)} className="action-link">
                <FontAwesomeIcon className="icon" icon={props.icon} />
                <span>{props.label}</span>
                <FontAwesomeIcon
                    className={`dropdown-icon ${reportDropdown ? '' : 'in'}active`}
                    icon={['fas', 'caret-down']}
                />
            </div>
            {React.cloneElement(props.children, { hidden: !reportDropdown })}
        </div>
    )
}

export default ReportHeaderDropdown
