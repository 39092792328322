import DayJS from '../../../helpers/DayJS'
import FullLayout from '../../../layouts/FullLayout'
import HeaderBanner from '../../../components/DesignSystem/components/molecules/HeaderBanner/HeaderBanner.component'
import LoadingPage from '../../Other/LoadingPage'
import Styles from './Dashboard.module.css'
import ToolTip from '../../../components/Styled/ToolTip'
import { UserCoursesDTO } from '../../../apis/api.signup'
import { useCourses } from '../../../hooks/ApiHooks/useCourses'
import { useLocation } from 'wouter'
import { useTranslation } from 'react-i18next'

export const Dashboard = () => {
    const { t } = useTranslation(['teamDashboard'])
    const { courses, isLoading } = useCourses()

    if (isLoading) return <LoadingPage />

    return (
        <FullLayout page="dashboard">
            <div className="dashboard-grid-container container fill-gap">
                <HeaderBanner title={t('courses')} />
                <div className="teams-dashboard-container">
                    <div className="items-grid">
                        {courses?.map((item) => (
                            <CourseCard key={item.courseId} {...item} />
                        ))}
                    </div>
                </div>
            </div>
        </FullLayout>
    )
}

const CourseCard = ({ courseId, courseName, nextClass }: UserCoursesDTO) => {
    const [, setLocation] = useLocation()

    const goToCourse = () => setLocation(`/admin/overview/${courseId}/`)

    return (
        <div className={`egg-card ${Styles.card}`} onClick={goToCourse}>
            <ToolTip placement="topStart" text={courseName}>
                <h3>{courseName}</h3>
            </ToolTip>

            <NextClass {...nextClass} />
        </div>
    )
}

const NextClass = (nextClass: Partial<UserCoursesDTO['nextClass']>) => {
    const { t } = useTranslation(['teamDashboard'])

    if (!nextClass) return <b className="danger">{t('No more classes')}</b>

    const { start, end } = nextClass
    const now = DayJS()

    if (now.isBetween(start, end))
        return (
            <p>
                <b className="success">{t('In class')}</b> {t('until')}{' '}
                {DayJS(end).format('HH:mm[hs]')}
            </p>
        )

    return (
        <p>
            {t('Next class')} {now.to(start)}
        </p>
    )
}
