import React, { HTMLAttributes, ReactNode } from 'react'
import Avatar from '../../atoms/Avatar/Avatar.component'
import {
    AccordionItemContainer,
    AccordionItemContentContainer,
    AccordionItemMoreButton,
    AccordionItemNumber,
    AccordionItemOptionsContainer,
    AccordionItemSubtitle,
    AccordionItemTitle,
} from './AccordionItem.styles'

const AccordionItem = (props: IAccordionItemProps) => {
    const { avatarBadge, children, imageSrc = '', itemNumber, subtitle = '', title } = props
    return (
        <AccordionItemContainer>
            <AccordionItemContentContainer>
                <AccordionItemContentContainer>
                    <AccordionItemNumber>{itemNumber}</AccordionItemNumber>
                    <Avatar badge={avatarBadge} imageSrc={imageSrc} />
                </AccordionItemContentContainer>
                <div style={{ padding: '0 0 0 20px' }}>
                    <AccordionItemTitle>{title?.toLocaleLowerCase()}</AccordionItemTitle>
                    <AccordionItemSubtitle>{subtitle}</AccordionItemSubtitle>
                </div>
            </AccordionItemContentContainer>
            <AccordionItemOptionsContainer>
                {children && <div>{children}</div>}
                <AccordionItemMoreButton icon={'ellipsis-h'} />
            </AccordionItemOptionsContainer>
        </AccordionItemContainer>
    )
}

export default AccordionItem

export interface IAccordionItemProps extends HTMLAttributes<HTMLDivElement> {
    avatarBadge?: ReactNode
    imageSrc?: string
    itemNumber: string
    subtitle?: string
}
