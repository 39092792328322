/* eslint-disable react/prop-types */
import React, { useState, createContext } from 'react'

// Create Context Object
export const WizardContext = createContext()

// Create a provider for components to consume and subscribe to changes
export const WizardContextProvider = (props) => {
    const [step, setStep] = useState(1) // This should be 1
    const [hqSelected, setHqSelected] = useState(null)
    const [courseSelected, setCourseSelected] = useState(null)
    const [subjectSelected, setSubjectSelected] = useState(null)
    const [maxEnrollStudentSelected, setMaxEnrollStudentSelected] = useState(1)
    const [schedulesSelected, setSchedulesSelected] = useState([])

    const prevStep = () => {
        if (step > 1) setStep(step - 1)
    }

    const nextStep = () => {
        if (step < 5) setStep(step + 1)
    }

    const initialValue = {
        step,
        setStep,
        hqSelected,
        setHqSelected,
        courseSelected,
        setCourseSelected,
        subjectSelected,
        setSubjectSelected,
        maxEnrollStudentSelected,
        setMaxEnrollStudentSelected,
        schedulesSelected,
        setSchedulesSelected,
        nextStep,
        prevStep,
    }

    return <WizardContext.Provider value={initialValue}>{props.children}</WizardContext.Provider>
}
