import React, { useContext, useState } from 'react'
import NoHeaderLayout from '../../layouts/NoHeaderLayout'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'wouter'
import Pincode from '../../components/Pincode'
import { UserContext } from '../../context/UserContext'

interface Props {
    onSignout?: () => unknown
    onResend?: () => unknown
    onValidate: (code: string) => unknown
}

const VerifyView = (props: Props) => {
    const { onResend, onValidate, onSignout } = props
    const { t } = useTranslation(['verify'])
    const { user } = useContext(UserContext)
    const [verifying, setVerifiying] = useState(false)
    const [resending, setResending] = useState(false)
    const [code, setCode] = useState<string>('')
    const [, setLocation] = useLocation()

    const verifyEmail = async () => {
        try {
            setVerifiying(true)
            await onValidate?.(code)
            setLocation('/?rtt=true', { replace: true })
        } finally {
            setVerifiying(false)
        }
    }

    const send = async () => {
        if (resending) return
        try {
            setResending(true)
            await onResend?.()
        } finally {
            setResending(false)
        }
    }

    return (
        <NoHeaderLayout>
            <div className="login-pages">
                <div className="container">
                    <div className="onboarding-card responsive">
                        <div className="onboarding-img verify"></div>
                        <div className="onboarding-form">
                            <div className="info">
                                <span className="step-title">{t('verify:title')}</span>
                                <p className="global-text">
                                    {t('verify:desc-pre-email')}
                                    <b> {user?.email} </b>
                                    {t('verify:desc-post-email')}
                                </p>

                                <div className="code-input">
                                    <span>{t('verify:code-label')}</span>
                                    <Pincode
                                        type="tel"
                                        value={code}
                                        onChange={setCode}
                                        size={6}
                                        validation={/^[0-9]$/}
                                        className="pin-code"
                                    />
                                    <button
                                        className="btn-yellow"
                                        disabled={verifying || !code || /\s/.test(code)}
                                        onClick={verifyEmail}
                                        type="submit"
                                    >
                                        {!verifying ? (
                                            t('verify:button-label')
                                        ) : (
                                            <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                        )}
                                    </button>
                                </div>
                                <div className="action-buttons">
                                    {onSignout && (
                                        <div
                                            className="forgot-password action-link"
                                            onClick={onSignout}
                                        >
                                            <p>
                                                <FontAwesomeIcon icon={['fal', 'undo']} />{' '}
                                                {t('verify:logout')}{' '}
                                            </p>
                                        </div>
                                    )}
                                    {onResend && (
                                        <div
                                            style={{ marginLeft: '0px' }}
                                            className="resend-verification action-link"
                                            onClick={send}
                                        >
                                            {!resending ? (
                                                <p>
                                                    {' '}
                                                    {t('verify:resend-code')}{' '}
                                                    <FontAwesomeIcon icon={['fal', 'redo']} />
                                                </p>
                                            ) : (
                                                <FontAwesomeIcon icon={['fas', 'spinner']} spin />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NoHeaderLayout>
    )
}

export default VerifyView
