/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/globals/_variables'
import AvatarEgg from './AvatarEgg'
import { Link } from 'wouter'

import Slider from 'react-slick'
import { TFSliderSettings } from '../../styles/sliders-settings'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Block = styled.div`
    text-align: center;
    /* overflow-x:hidden; */
    width: 100%;
    h4 {
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 0.05rem;
    }
    .avatars-block {
        margin-top: 15px;
        .slick-slider {
            padding: 0 40px;
        }
    }
    .no-info {
        margin-top: 10px;
        background-color: ${colors.lightGreyBg};
        border-radius: 30px;
        display: inline-block;
        padding: 10px 25px;
        border: 1px solid ${colors.borders};
    }
`

const Item = styled.div`
    .i-cont {
        /* text-align:center; */
        display: flex;
        justify-content: center;
        margin: 10px auto;
        display: flex !important;
        align-items: center;
        text-align: center;
        cursor: pointer;
        .name {
            margin-left: 15px;
            text-align: left;
        }
    }
`

const FacilitatorsBlock = ({ facilitators, titleCaseFn, ps, s }) => {
    const { t } = useTranslation(['teamDetailStudent'])

    return (
        <Block>
            <h4>{t('facilitators.title')}</h4>
            {facilitators?.length > 0 && (
                <div className="avatars-block">
                    <Slider {...TFSliderSettings}>
                        {facilitators?.map((item) => {
                            return (
                                <Item key={item.profile._id}>
                                    <div className="i-cont">
                                        <Link
                                            key={item.profile._id}
                                            title={`${item.user.name} ${item.user.lastname}`}
                                            to={`/profile/${item.profile._id}/from/${ps}/subject/${s}`}
                                        >
                                            <AvatarEgg src={item.user.urlImage} size={55} />
                                        </Link>
                                        <div className="name">
                                            {titleCaseFn(item.user.name)}
                                            <br />
                                            {titleCaseFn(item.user.lastname)}
                                        </div>
                                    </div>
                                </Item>
                            )
                        })}
                    </Slider>
                </div>
            )}
            {facilitators?.length < 1 && (
                <div className="no-info">
                    <FontAwesomeIcon
                        style={{ marginRight: '5px' }}
                        icon={['fal', 'exclamation-circle']}
                    />
                    {t('facilitators.not-available')}
                </div>
            )}
        </Block>
    )
}

export default FacilitatorsBlock
