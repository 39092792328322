import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const Step4View = (props) => {
    const { t } = useTranslation(['wizard'])
    const [isNextStepEnabled, setIsNextStepEnabled] = useState(false)

    const { onChangeCheckbox, days, onSubmit, onChangeClassTime } = props

    useEffect(() => {
        if (days.find((day) => !!day.checked)) {
            setIsNextStepEnabled(true)
        } else {
            setIsNextStepEnabled(false)
        }
    }, [days])

    return (
        <div className="step-container responsive">
            {/* {console.log(days,'days')} */}
            <div className="onboarding-img step4"></div>
            <div className="onboarding-form">
                <div className="info">
                    <span className="step-label">{t('wizard:step')} 4/5</span>
                    <span className="step-title">{t('wizard:step4.title')}</span>
                    <p className="global-text">{t('wizard:step4.desc')}</p>
                </div>
                <div className="wizard-days-selector">
                    <div className="egg-card egg-list recomendations-list days-list">
                        {days.map((day) => (
                            <div key={day.name} className="list-item">
                                <div className="label-and-switch-container">
                                    <div className="day-label">
                                        <span className="label">{day.name}</span>
                                    </div>
                                    <div
                                        className="day-switch"
                                        onClick={() => onChangeCheckbox(day)}
                                    >
                                        {day.checked ? (
                                            <FontAwesomeIcon
                                                className="box checked"
                                                icon={['fas', 'toggle-on']}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="box unchecked"
                                                icon={['fal', 'toggle-off']}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="time-start time-input">
                                    <span className="label">{t('wizard:step4.start')}</span>
                                    <input
                                        type="time"
                                        id="day.name"
                                        name="appt"
                                        disabled={day.checked ? false : true}
                                        onChange={onChangeClassTime(day.idx, 'start')}
                                    ></input>
                                </div>
                                <div className="time-end time-input">
                                    <span className="label">{t('wizard:step4.end')}</span>
                                    <input
                                        type="time"
                                        id="day.name"
                                        name="appt"
                                        disabled={day.checked ? false : true}
                                        onChange={onChangeClassTime(day.idx, 'end')}
                                    ></input>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <button
                    onClick={onSubmit}
                    className="btn-next-wizard"
                    disabled={!isNextStepEnabled}
                >
                    {t('wizard:step4.next')}
                </button>
            </div>
        </div>
    )
}

Step4View.propTypes = {
    onChangeCheckbox: PropTypes.func.isRequired,
    days: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChangeClassTime: PropTypes.func,
}

export default Step4View
