import React, { ChangeEvent, useRef, useState } from 'react'
import { SpreadMessageModal, SpreadMessageModalStyles } from '../SpreadMessage.modal'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Recorder } from '../../../../../../components/Recorder'
import { Select } from '../../../../../../components/Select'
import Styles from '../SpreadMessage.module.css'
import styled from 'styled-components'
import { useContent } from '../../../../../../hooks/useContent'
import { useTeam } from '../../../../Hooks/useTeam'
import { useTranslation } from 'react-i18next'
import { useUIContext } from '../../../../../../context/UIContext'

export const UploadVideo = ({ state: [, setSrc] }: Props) => {
    const { t } = useTranslation('teamMonitor')
    const { setModal } = useUIContext()
    const { courseID } = useTeam()
    const { content, addContent, isLoading } = useContent()
    const fileRef = useRef<HTMLInputElement>(null)

    if (!courseID) return null

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()

        const file = event.target.files?.[0]
        if (file) addContent({ courseID, file, fileName: file.name })
    }

    const isDisabled = !content.length
    const placeholder = isLoading
        ? t('Loading...')
        : isDisabled
        ? t('No videos available')
        : t('Choose a video')

    return (
        <UploadVideoStyles>
            <div className="actions">
                <Select
                    isDisabled={isDisabled}
                    options={content}
                    placeholder={placeholder}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(item: any) => setSrc(item.value)}
                />
                <button
                    className="accent"
                    onClick={() =>
                        setModal({
                            content: <RecordVideo />,
                            customStyles: SpreadMessageModalStyles,
                        })
                    }
                >
                    <FontAwesomeIcon icon={['fas', 'circle']} />
                    {t('Record video')}
                </button>
                <input
                    ref={fileRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept=".mp4"
                    onChange={onFileChange}
                />
                <button className="white" type="button" onClick={() => fileRef.current?.click()}>
                    <FontAwesomeIcon icon={['fas', 'upload']} />
                    {t('Upload video')}
                </button>

                <small>{t('Maximum size of 50 mb. Format MP4.')}</small>
            </div>
        </UploadVideoStyles>
    )
}

interface Props {
    state: [string, React.Dispatch<React.SetStateAction<string>>]
}

const UploadVideoStyles = styled.div`
    flex: 1;
    .actions {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`

const RecordVideo = () => {
    const { t } = useTranslation('teamMonitor')
    const { setModal, closeModal, file, setFile } = useUIContext()
    const { courseID } = useTeam()
    const { isLoading, addContent } = useContent()
    const [fileName, setFileName] = useState('')

    if (!courseID) return null

    const disabled = isLoading || !file || !fileName

    const goBack = () =>
        setModal({
            content: <SpreadMessageModal />,
            customStyles: SpreadMessageModalStyles,
        })

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault()

        if (file)
            addContent({
                courseID,
                file,
                fileName,
            }).then(goBack)
    }

    return (
        <section className={Styles.section}>
            <header className={Styles.header}>
                <button style={{ border: 0, background: 0 }} onClick={goBack}>
                    <FontAwesomeIcon icon={['fal', 'angle-left']} />
                </button>
                <span className={Styles.title}>{t('Record video')}</span>
                <button
                    style={{ border: 0, background: 0 }}
                    onClick={() => {
                        closeModal()
                        setFile(null)
                    }}
                >
                    <FontAwesomeIcon icon={['far', 'times']} />
                </button>
            </header>

            <hr />

            <Recorder />

            <form onSubmit={onSubmit}>
                <hr />
                <fieldset>
                    <label htmlFor="video-name">{t('Video name')}</label>
                    <input
                        disabled={!file}
                        type="text"
                        id="video-name"
                        className="form-input-white"
                        value={fileName}
                        onChange={({ target: { value } }) => setFileName(value)}
                    />
                </fieldset>
                <button className="primary" disabled={disabled}>
                    {t('Save')}
                </button>
            </form>
        </section>
    )
}
