import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import UIContext from '../../../context/UIContext'
import DayJS from '../../../helpers/DayJS'
import downloadBlob from '../../../helpers/downloadBlob'
import { useGAEvents } from '../../../hooks/useGAEvents'
import { DateFilterProps, BasicProps } from '../api-reports'
import { useReports } from '../Context/ReportsContext'

export const useCsvReport = () => {
    const { t } = useTranslation('teamReports')
    const { reportQuery } = useReports()
    const { courseId, cohorts } = reportQuery
    const { setInfoBlock } = useContext(UIContext)
    const { sendGA } = useGAEvents()

    const getReport = async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fn: (query: BasicProps) => Promise<any>,
        csvName: string
    ) => {
        await fn({ courseId, cohorts })
            .then(({ data }) => {
                setInfoBlock(['success', t('loading report')])
                downloadBlob(data, `${t(csvName)}-${DayJS().format('YYYY-MM-DD_HH:mm:ss')}.csv`)
            })
            .catch((error) => {
                setInfoBlock(['error', error.data.message])
            })
            .finally(() => {
                setTimeout(() => setInfoBlock([]), 2000)
                sendGA({
                    category: 'Reporting',
                    action: 'GlobalReportDownload',
                })
            })
    }

    const getFilteredReport = async (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fn: (query: DateFilterProps) => Promise<any>,
        csvName: string
    ) => {
        await fn(reportQuery)
            .then(({ data }) => {
                setInfoBlock(['success', t('loading report')])
                downloadBlob(data, `${t(csvName)}-${DayJS().format('YYYY-MM-DD_HH:mm:ss')}.csv`)
            })
            .catch((error) => {
                setInfoBlock(['error', error.data.message])
            })
            .finally(() => {
                setTimeout(() => setInfoBlock([]), 2000)
                sendGA({
                    category: 'Reporting',
                    action: 'GlobalReportDownload',
                })
            })
    }
    return { getReport, getFilteredReport }
}
