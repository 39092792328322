import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { BtnCircular } from '../../../../components/Buttons/BtnCircular'
import { ManagerContext } from '../../../../context/ManagerContext'
import { SubjectContext } from '../../../../context/SubjectContext'
import { iGetMentorsProps } from '../TeamManagerContainer'
import Styles from './TeamHeader.module.css'

export const TeamHeader = (props: iTeamHeaderProps) => {
    const { t } = useTranslation(['teamManager'])
    const { course } = useContext(SubjectContext)
    const { onVoteTable } = useContext(ManagerContext)

    const isProgressive = !!course?.isProgressive

    const {
        teamNumber,
        onTableMeeting,
        isMentorTeam,
        guide,
        hasVote,
        toggleExpand,
        isExpanded,
        showMentors,
    } = props

    return (
        <header className={Styles.teamHeader}>
            <div className={Styles.teamLabel}>
                <h3 className={Styles.teamNumber}>
                    {`${t('teamManager:search.badge-team')} ${!isMentorTeam ? teamNumber : ''}`}
                </h3>
                <span className={Styles.teamGuide}>
                    {isMentorTeam
                        ? t('teamManager:search.mentors')
                        : guide && `${t('teamManager:search.guide')} ${guide}`}
                </span>
                {
                    // Meeting button
                    onTableMeeting && (
                        <div onClick={onTableMeeting} className="action-link">
                            <FontAwesomeIcon className="icon" icon={['fal', 'video']} />
                        </div>
                    )
                }
            </div>
            <div className={Styles.teamHeaderOptions}>
                {
                    // Voting button
                    !isMentorTeam && (
                        <BtnCircular
                            icon={['far', 'heart']}
                            iconOver={['fas', 'heart']}
                            isActive={hasVote}
                            toolTips={t('teamManager:tableItem.header.like')}
                            onClick={() => onVoteTable(teamNumber)}
                            style={hasVote ? { color: '#ffcd00' } : {}}
                        />
                    )
                }
                {
                    // Show Mentor button
                    isProgressive && !isMentorTeam && showMentors && guide && (
                        <BtnCircular
                            onClick={() => showMentors(teamNumber, guide)}
                            icon={['far', 'graduation-cap']}
                            iconOver={['fas', 'graduation-cap']}
                            toolTips={t('teamManager:mentor.seeMentors')}
                        />
                    )
                }

                {
                    // Expand button
                    toggleExpand && (
                        <BtnExpandList isExpanded={!!isExpanded} toggleExpand={toggleExpand} />
                    )
                }
            </div>
        </header>
    )
}

const BtnExpandList = ({
    isExpanded,
    toggleExpand,
    size = 50,
}: {
    isExpanded: boolean
    toggleExpand: () => void
    size?: number
}) => {
    const icon = isExpanded ? 'chevron-up' : 'chevron-down'
    return (
        <button
            style={{
                fontSize: size / 2,
                backgroundColor: '#F7F7F7',
                width: size,
                height: size,
                borderRadius: '50%',
                border: '1px solid #DADADA',
                boxSizing: 'border-box',
                cursor: 'pointer',
                color: 'inherit',
            }}
            onClick={toggleExpand}
        >
            <FontAwesomeIcon icon={['fas', icon]} />
        </button>
    )
}

// Interfaces
interface iTeamHeaderProps {
    teamNumber: number
    isProgressive: boolean
    isMentorTeam: boolean
    guide?: number
    hasVote?: boolean
    onTableMeeting?: () => Promise<unknown>
    isExpanded?: boolean
    toggleExpand?: () => void
    showMentors: (
        team: iGetMentorsProps['team'],
        guide: iGetMentorsProps['guide'],
        algorithm?: iGetMentorsProps['algorithm'] | undefined
    ) => void | '' | undefined
}
