import { getUserCourses as queryFn } from '../../apis/api.signup'
import { useQuery } from 'react-query'
import { useUser } from '../../context/UserContext'

export const useCourses = () => {
    const { user } = useUser()

    const queryKey = ['My Courses', user?._id ?? 'Unknown']

    const { data, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: !!user,
    })

    const getCourseData = (courseId: string) =>
        data?.courses.find((item) => item.courseId === courseId)

    return {
        getCourseData,
        ...data,
        ...rest,
    }
}
