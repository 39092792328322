import { useReducer } from 'react'

type ObjectNotFunction = { [k: string]: unknown } & { call?: never }

function useLocalStorage<T extends undefined | string | number | ObjectNotFunction>(
    key: string,
    initialValue: T
) {
    return useReducer(
        (old: T, newValue: T | ((old: T) => T)): T => {
            try {
                const value = typeof newValue === 'function' ? newValue(old) : newValue
                if (value !== undefined) {
                    window.localStorage.setItem(key, JSON.stringify(value))
                } else {
                    window.localStorage.removeItem(key)
                }
                return value
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
                return old
            }
        },
        initialValue,
        (initial): T => {
            try {
                const item = window.localStorage.getItem(key)
                return item ? JSON.parse(item) : initial
            } catch (error) {
                return initial
            }
        }
    )
}

export default useLocalStorage
