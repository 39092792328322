import Axios from 'axios'
import { useEffect } from 'react'
import Config from '../../config'
import JitsiAPI from '../../helpers/jitsi'
import useNetworkInfo from '../../hooks/useNetworkInfo'
import { useJitsiEffect } from './Jitsi'

const useJitsiTracker = <P extends Record<string, unknown>>(
    api: JitsiAPI | undefined,
    payload?: P
) => {
    useNetworkTracker('networkStatus', payload)
    useJitsiSingleTracker(api, 'videoConferenceJoined', payload)
    useJitsiSingleTracker(api, 'videoConferenceLeft', payload)
    useJitsiSingleTracker(api, 'audioMuteStatusChanged', payload)
    useJitsiSingleTracker(api, 'videoMuteStatusChanged', payload)
    useJitsiSingleTracker(api, 'cameraError', payload)
    useJitsiSingleTracker(api, 'micError', payload)
    useJitsiSingleTracker(api, 'screenSharingStatusChanged', payload)

    return null
}

const useJitsiSingleTracker = <E extends keyof JitsiAPI.Events, P extends Record<string, unknown>>(
    api: JitsiAPI | undefined,
    event: E,
    payload?: P
) =>
    useJitsiEffect(
        api,
        event,
        async (data) => {
            try {
                await Axios.post(`${Config.MEETING_STATS}/jitsi/event`, {
                    event,
                    data,
                    ...payload,
                })
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        },
        [JSON.stringify(payload)]
    )

const useNetworkTracker = <P extends Record<string, unknown>>(event: string, payload?: P) => {
    const [, networkType = null, networkSpeed = null] = useNetworkInfo()

    useEffect(() => {
        Axios.post(`${Config.MEETING_STATS}/jitsi/event`, {
            event,
            data: {
                networkType,
                networkSpeed,
            },
            ...payload,
            // eslint-disable-next-line no-console
        }).catch((err) => console.error(err))
        // This doesn't need the payload since it should update
        // the networkStatus when updated the payload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [networkType, networkSpeed])
}

export default useJitsiTracker
