import React, { useEffect, useCallback } from 'react'
import Axios from 'axios'
import Config from '../../config'
import { useLocation } from 'wouter'
// Types should be on shared more accesible files and documented ç
export type StudentType = {
    name: string
    lastname: string
    email: string
    _profileId: string
    urlImage: string
    isFacilitator: boolean
    position: number
    table?: number
    isMentor?: boolean
    tableNumber?: number
}

export type TableType = {
    tableNumber: number
    facilitator?: StudentType
    isMentorTable: boolean
    students: StudentType[]
    module?: number
    guide?: number
}

export type AssignationType = TableType[] & {
    facilitators: StudentType[]
}

export type AlgorithmResultType = {
    algorithm: {
        _id: string
        _classId: string
        results: {
            facilitator: string
            positions: {
                number: number
                _profileId: string
                _userId: string
            }[]
        }[]
        createdAt: string
    }
}

// What comes from endpoint
export type AlgorithmAssignResultType = {
    _algorithmId: string
    results: {
        tableNumber: number
        facilitator: string
        students: {
            name: string
            lastname: string
            email: string
            _profileId: string
            urlImage: string
            isFacilitator: boolean
        }[]
    }[]
}

// Professor assignation. Fetchs all data requested by Subject Context
export const useProfessorAssignation = (
    /** INPUT */
    courseId?: string,
    // eslint-disable-next-line no-console
    onError = console.error
) => {
    const [algorithmId, setAlgorithmId] = React.useState<string | undefined>()
    const [assignation, setAssignation] = React.useState<AssignationType | null | undefined>()
    const [assignationCount, setAssignationCount] = React.useState<number | undefined>(undefined)
    const [, setLocation] = useLocation()
    const [totalPages, setTotalPages] = React.useState<number>(0)
    const [totalTables, setTotalTables] = React.useState<number | undefined>(undefined)
    const [isProgressive, setIsProgressive] = React.useState(false)
    const [teamsNumbers, setTeamsNumbers] = React.useState<number[]>([])

    // This should take a pagination object with skip and limit to show a subset of tables
    // This fn should be exported to be called and refetch tables when needed on components.
    const fetchAssignation = useCallback(
        async (page?: number) => {
            // Sets pagination limit and page
            const limit = 40
            if (!courseId) return

            // Fetchs students in their tables when assignated by an algorithm.
            // if no page, skip 0. If page. skip n tables before
            await Axios.get(
                `${Config.API}/v2/course/${courseId}/algorithm/last?skip=${
                    page ? (page - 1) * limit : 0
                }&limit=${limit}`
            )
                .then((response) => {
                    // console.log(response)
                    // Append the position to all students
                    // TODO This position should come from backend.
                    // This attachs a position key to student object with position which is index + 1
                    response.data.results.forEach((table: TableType) =>
                        table.students.forEach((student, i) => (student.position = i + 1))
                    )
                    response.data.results.facilitators = response.data.results.map(
                        (table: TableType) => ({
                            ...table.students.find((student) => student.isFacilitator),
                            table: table.tableNumber,
                        })
                    )
                    const isProgressive = response.data.course.type === 'progressive'
                    setIsProgressive(isProgressive)

                    // Sets all student tables, algorithm id(?) and totalPages from
                    setAssignation(response.data.results)
                    setAlgorithmId(response.data._algorithmId)
                    // Used to show pages navigation on team manager assigned students
                    setTotalTables(response.data.pagination.totalTables)
                    setTotalPages(Math.ceil(response.data.pagination.totalTables / limit))
                    setAssignationCount(response.data.totalAssignedStudents)
                    setTeamsNumbers(response.data.tableNumbers)
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error?.data?.message)
                    //setLocation('/')
                    // Clears all tables.
                    setAssignation(null)
                    // This should have a more meaningfull name.
                    onError(error)
                })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [courseId, onError, setLocation]
    )

    // Stores quantity of users unassigned, used to calculare
    const [unassigned, setUnassigned] = React.useState<StudentType[] | null | undefined>()
    const [totalUnassigned, setTotalUnassigned] = React.useState<number | undefined>(undefined)
    const [unassignedCount, setUnassignedCount] = React.useState<number | undefined>(undefined)
    // Fetchs all unassigned students.
    // Mostly self explainatory
    const fetchUnassigned = useCallback(
        async (page?: number) => {
            const limit = 10
            if (!courseId) return
            try {
                const response = await Axios.get(
                    `${Config.API}/v2/course/${courseId}/algorithm/last/unassigned?skip=${
                        page ? (page - 1) * limit : 0
                    }&limit=${limit}`
                )
                // console.log(response, 'response unassigned')
                setUnassigned(response.data.results)
                setUnassignedCount(response.data.pagination.total)
                setTotalUnassigned(Math.ceil(response.data.pagination.total / limit))
            } catch (error) {
                setUnassigned(null)
                // eslint-disable-next-line no-console
                console.log("Couldn't fech unassigned students", error)
                onError(error)
            }
        },
        [courseId, onError]
    )

    useEffect(() => {
        fetchAssignation()
        fetchUnassigned()
    }, [fetchAssignation, fetchUnassigned])

    const setter = {
        // Algorithm can be launched to maintain positions (and only generate a new voting)
        // or to shuffle students between tables
        launch: async (absents?: string[], launchType?: 'repeated' | 'shuffle') => {
            const response = await Axios.post<AlgorithmResultType>(
                `${Config.API}/algorithm/start`,
                {
                    _classId: courseId,
                    absents: absents ?? [],
                    // sets optional repeated type of algorithm is switch on algorithmlaunch button is on
                    ...(launchType === 'repeated' && {
                        rules: [
                            {
                                name: 'repeated',
                                options: [],
                            },
                        ],
                    }),
                }
            )
            // Refetch assignations to update student tables on views
            fetchAssignation()
            fetchUnassigned()

            // Set launchType on db to remember switch position on AdvancedConfig
            await Axios.patch(Config.API + '/class/' + courseId, {
                repeatAlgorithm: launchType === 'repeated' ? true : false,
            })

            return response.data
        },
        move: async (profileId: string, index: number | null) => {
            if (!assignation) throw Error('No hay una asignación.')
            if (index === null) return setter.remove(profileId)

            //if (!Number.isInteger(index))
            //    throw Error('La mesa no es entera.')
            //if (assignation.length < index)
            //    throw Error('No hay nadie en esa mesa.')
            const response = await Axios.post(`${Config.API}/algorithm/${algorithmId}/assign`, {
                _profileId: profileId,
                tableNumber: index,
            })

            // Append the position to all students
            // response.data.results.forEach((table: TableType) => table.students.forEach((student, i) => student.position = i + 1))
            // response.data.results.facilitators = response.data.results.map((table: TableType) => ({
            //     ...table.students.find(student => student.isFacilitator),
            // }))
            // setAssignation(response.data.results)
            fetchAssignation()
            fetchUnassigned()
            return response.data as AlgorithmAssignResultType
        },
        remove: async (profileId: string) => {
            if (!assignation) throw Error('No hay una asignación.')
            const response = await Axios.delete(`${Config.API}/algorithm/${algorithmId}/unassign`, {
                data: { _profileId: profileId },
            })
            // Append the position to all students
            // response.data.results.forEach((table: TableType) => table.students.forEach((student, i) => student.position = i + 1))
            // response.data.results.facilitators = response.data.results.map((table: TableType) => ({
            //     ...table.students.find(student => student.isFacilitator),
            //     table: table.tableNumber,
            // }))
            // setAssignation(response.data.results)
            fetchAssignation()
            fetchUnassigned()
            return response.data as AlgorithmAssignResultType
        },
        force: setAssignation,
        tableNumbers: (numbers: number[]) => {
            //TODO: Implement!
            if (numbers.length !== assignation?.length)
                throw Error('Table numbers and assignation length mismatch')
            numbers.forEach((table, i) => (assignation[i].tableNumber = table))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newAssign: any = [...assignation]
            newAssign.facilitators = assignation.facilitators
            setAssignation(newAssign)
        },
    }

    // Why as an array instead of an object?
    return [
        assignation,
        setter,
        algorithmId,
        fetchAssignation,
        totalPages,
        totalTables,
        unassigned,
        fetchUnassigned,
        totalUnassigned,
        unassignedCount,
        assignationCount,
        isProgressive,
        teamsNumbers,
    ] as const
}

//(?) Donde se usa este type?
export type AlgorithmLaunchType = ReturnType<typeof useProfessorAssignation>[1]

// TODO All student logic should be apart from this file.

export type StudentAssignationReturn = [
    TableType | null | undefined,
    StudentType[] | null | undefined,
    string | undefined
]
export const useStudentAssignation = (
    courseId?: string,
    // eslint-disable-next-line no-console
    onError = console.error
): StudentAssignationReturn => {
    const [assignation, setAssignation] = React.useState<
        (TableType & { tableNumber: number }) | null | undefined
    >()
    const [facilitators, setFacilitators] = React.useState<StudentType[] | null | undefined>()
    const [algorithmId, setAlgorithmId] = React.useState<string | undefined>()

    React.useEffect(
        function getAssignation() {
            if (!courseId) return

            // Axios.get(`${Config.API}/v2/course/${courseId}/algorithm/last?skip=${1}&limit=${1}`)
            Axios.get(Config.API + '/class/' + courseId + '/getLastAlgorithm/')
                .then((response) => {
                    // Append the position to all students
                    const { table, facilitators, _algorithmId } = response.data

                    table.students.forEach(
                        (student: StudentType, i: number) => (student.position = i + 1)
                    )
                    setAssignation(table)

                    // As the list of facilitators doesn't include the tableNumber for every facilitator
                    // I have to compute it, given that the facilitators are ordered and I know the table of my facilitator.
                    const pivot = facilitators.findIndex(({ _profileId }: StudentType) => {
                        return _profileId === table.facilitator
                    })
                    const lowerBound = table.tableNumber - (pivot === -1 ? 1 : pivot)
                    facilitators.forEach(
                        (student: StudentType, i: number) => (student.table = lowerBound + i)
                    )
                    setFacilitators(facilitators)
                    setAlgorithmId(_algorithmId)
                })
                .catch((error) => {
                    setAssignation(null)
                    setFacilitators(null)
                    onError(error)
                })
        },
        [courseId, onError]
    )

    return [assignation, facilitators, algorithmId]
}

export function useAssignation(
    mode: 'student',
    courseId: string,
    onError: () => void
): ReturnType<typeof useStudentAssignation>
export function useAssignation(
    mode: 'professor',
    courseId: string,
    onError: () => void
): ReturnType<typeof useProfessorAssignation>
export function useAssignation(
    mode: 'student' | 'professor',
    courseId: string,
    // eslint-disable-next-line no-console
    onError = console.error
) {
    return {
        professor: useProfessorAssignation(mode === 'professor' ? courseId : undefined, onError),
        student: useStudentAssignation(mode === 'student' ? courseId : undefined, onError),
    }[mode]
}
export default useAssignation
