// API
import React, { useState, useContext } from 'react'
// Helpers
import ShareView from './ShareView'
// Componentes
import LoadingPage from '../Other/LoadingPage'
import { SubjectContext } from '../../context/SubjectContext'

const ShareContainer = () => {
    const { shareUrl } = useContext(SubjectContext)
    const [QRModal, setQRModal] = useState(false)

    // Sets infoBlock error / success
    //const { setInfoBlock } = useContext(UIContext)

    if (!shareUrl?.student) return <LoadingPage />
    return <ShareView url={shareUrl.student} QRModal={QRModal} setQRModal={setQRModal} />
}

export default ShareContainer
