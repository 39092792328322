import React, { useState, createContext } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CollabContext = createContext<any>({})
CollabContext.displayName = 'CollabContext'

export const CollabContextProvider = (props: { children: React.ReactElement }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [selectedDoc, setSelectedDoc] = useState<any>({})

    const value = {
        selectedDoc,
        setSelectedDoc,
    }

    return <CollabContext.Provider value={value}>{props.children}</CollabContext.Provider>
}

export default CollabContext
