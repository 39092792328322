import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

export enum Language {
    Spanish = 'es',
    English = 'en',
    Portuguese = 'pt',
}

export const defaultLanguage = Language.English

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init(
        {
            // debug: process.env.NODE_ENV === 'development',
            saveMissing: true,
            supportedLngs: Object.values(Language),
            load: 'languageOnly',
            fallbackLng: 'en',
            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json',
            },
            interpolation: { escapeValue: false },
            react: {
                useSuspense: true,
            },
            cache: ['localStorage'],
            detection: {
                order: [
                    'querystring',
                    'cookie',
                    'localStorage',
                    'sessionStorage',
                    'navigator',
                    'htmlTag',
                    'path',
                    'subdomain',
                ],
                caches: ['localStorage', 'cookie'],
                cookieOptions: { path: '/', sameSite: 'strict' },
            },
        },
        (err) => {
            // eslint-disable-next-line no-console
            if (err) return console.error('something went wrong loading', err)
        }
    )

// eslint-disable-next-line no-console
i18n.on('missingKey', (lngs, namespace, key, res) => console.info({ lngs, namespace, key, res }))

export default i18n

export const plural = {
    count: 10,
}

export type AvailableLngs = 'en' | 'es' | 'pt'
